import { DatapointType } from '../../../../models/enums/DatapointType';
import { ControllersProps } from '../ControllersProps';
import { useState } from 'react';
import GenericControls from '../generic-controls/GenericControls';
import CentralTimerProgramEditModal from '../../../../components/central-timer-program-edit-modal/CentralTimerProgramEditModal';

const CentralTimerSwitch = (props: ControllersProps): JSX.Element => {
    const { virtualDevice } = props;

    const timeProgramDatapoint = virtualDevice?.datapoints?.find((x) => x.type == DatapointType.CentralSchedulerConfig);

    const [isTimeProgramConfigModalOpen, setIsTimeProgramConfigModalOpen] = useState<boolean>(false);

    return (
        <>
            <GenericControls onEditButtonClick={() => setIsTimeProgramConfigModalOpen(true)} {...props} />
            {timeProgramDatapoint && isTimeProgramConfigModalOpen && (
                <CentralTimerProgramEditModal
                    timeProgramDatapoint={timeProgramDatapoint}
                    virtualDevice={virtualDevice}
                    closeModalRequested={() => setIsTimeProgramConfigModalOpen(false)}
                />
            )}
        </>
    );
};

export default CentralTimerSwitch;
