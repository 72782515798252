import styles from './MainPage.module.scss';
import Header from '../components/header/Header';
import MainControls from './main-controls/MainControls';
import Footer from '../components/footer/Footer';
import UpdateModal from '../components/update-modal/UpdateModal';
import { isDemo } from '../helpers/HttpMethods';

const MainPage = (): JSX.Element => {
    return (
        <div className={styles.mainContainer}>
            <Header />
            <MainControls />
            <Footer />
            {!isDemo && <UpdateModal />}
        </div>
    );
};

export default MainPage;
