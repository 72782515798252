import styles from './AlarmSystem.module.scss';
import { useState } from 'react';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { pushButtonDatapoint } from '../../../../helpers/HttpMethods';
import { DatapointNames } from '../../../../models/enums/DatapointNames';
import { getBooleanDatapointValue, getNumberDatapointValue } from '../../../../helpers/DatapointHelper';
import classNames from 'classnames';
import { ControllersProps } from '../ControllersProps';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { useTranslation } from 'react-i18next';
import CustomButton from '../components/custom-button/CustomButton';
import ControlHeader from '../components/control-header/ControlHeader';

const AlarmSystem = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room, order, forceShowAll } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const alarmIsArmedDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualAlarmSystemIsArmed,
    );
    const alarmWithoutPresenceDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualArmedWithoutPresenceDetectors,
    );
    const isAlarmDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualIsAlarm,
    );
    const activationCountdownDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualAcivationCountdown,
    );
    const quitAlarmDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualQuitAlarm,
    );
    const armAlarmDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualArmAlarmSystem,
    );
    const armAlarmDelayedDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualArmAlarmSystemDelayed,
    );
    const armWithoutPresenceDetectorsDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualArmWithoutPresenceDetectors,
    );
    const disarmDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualDisarmAlarmSystem,
    );

    const [datapointsVisible, setDatapointsVisible] = useState<boolean>(false);

    return (
        <div className={styles.mainContainer} style={{ zIndex: order, border: border }}>
            <ControlHeader virtualDevice={virtualDevice} room={room} />
            {alarmIsArmedDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('alarmSystem.alarmIsArmed')}</div>
                    <div className={styles.value}>
                        {getBooleanDatapointValue(alarmIsArmedDatapoint) ? t('general.yes') : t('general.no')}
                    </div>
                </div>
            )}
            {alarmWithoutPresenceDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('alarmSystem.armedWithoutPresenceDetectors')}</div>
                    <div className={styles.value}>
                        {getBooleanDatapointValue(alarmWithoutPresenceDatapoint) ? t('general.yes') : t('general.no')}
                    </div>
                </div>
            )}
            {isAlarmDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('alarmSystem.isAlarm')}</div>
                    <div className={styles.value}>
                        {getBooleanDatapointValue(isAlarmDatapoint) ? t('general.on') : t('general.off')}
                    </div>
                </div>
            )}
            {activationCountdownDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('alarmSystem.activationCoutdown')}</div>
                    <div className={styles.value}>{`${getNumberDatapointValue(activationCountdownDatapoint)}s`}</div>
                </div>
            )}
            {forceShowAll && (
                <div className={styles.forcedButtons}>
                    {armAlarmDatapoint && (
                        <CustomButton
                            className={styles.button}
                            onClick={() => pushButtonDatapoint(armAlarmDatapoint)}
                            title={t('alarmSystem.armAlarm')}
                        />
                    )}
                    {armAlarmDelayedDatapoint && (
                        <CustomButton
                            className={styles.button}
                            onClick={() => pushButtonDatapoint(armAlarmDelayedDatapoint)}
                            title={t('alarmSystem.armDelayedAlarm')}
                        />
                    )}
                    {armWithoutPresenceDetectorsDatapoint && (
                        <CustomButton
                            className={styles.button}
                            onClick={() => pushButtonDatapoint(armWithoutPresenceDetectorsDatapoint)}
                            title={t('alarmSystem.armWithoutPresenceDetectors')}
                        />
                    )}
                    {disarmDatapoint && (
                        <CustomButton
                            className={styles.button}
                            onClick={() => pushButtonDatapoint(disarmDatapoint)}
                            title={t('alarmSystem.disarmAlarm')}
                        />
                    )}
                    {quitAlarmDatapoint && (
                        <CustomButton
                            className={styles.button}
                            onClick={() => pushButtonDatapoint(quitAlarmDatapoint)}
                            title={t('alarmSystem.quiteAlarm')}
                        />
                    )}
                </div>
            )}
            {!forceShowAll && (
                <div
                    className={styles.datapointsContainer}
                    onMouseEnter={() => {
                        setDatapointsVisible(true);
                    }}
                    onMouseLeave={() => setDatapointsVisible(false)}
                >
                    <div className={styles.dots}>
                        <span className={styles.dot} />
                        <span className={styles.dot} />
                        <span className={styles.dot} />
                    </div>
                    {datapointsVisible && (
                        <div
                            className={styles.datapointsList}
                            style={{ zIndex: order, borderBottom: border, borderLeft: border, borderRight: border }}
                        >
                            {armAlarmDatapoint && (
                                <CustomButton
                                    className={styles.button}
                                    onClick={() => pushButtonDatapoint(armAlarmDatapoint)}
                                    title={t('alarmSystem.armAlarm')}
                                />
                            )}
                            {armAlarmDelayedDatapoint && (
                                <CustomButton
                                    className={styles.button}
                                    onClick={() => pushButtonDatapoint(armAlarmDelayedDatapoint)}
                                    title={t('alarmSystem.armDelayedAlarm')}
                                />
                            )}
                            {armWithoutPresenceDetectorsDatapoint && (
                                <CustomButton
                                    className={styles.button}
                                    onClick={() => pushButtonDatapoint(armWithoutPresenceDetectorsDatapoint)}
                                    title={t('alarmSystem.armWithoutPresenceDetectors')}
                                />
                            )}
                            {disarmDatapoint && (
                                <CustomButton
                                    className={styles.button}
                                    onClick={() => pushButtonDatapoint(disarmDatapoint)}
                                    title={t('alarmSystem.disarmAlarm')}
                                />
                            )}
                            {quitAlarmDatapoint && (
                                <CustomButton
                                    className={styles.button}
                                    onClick={() => pushButtonDatapoint(quitAlarmDatapoint)}
                                    title={t('alarmSystem.quiteAlarm')}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default AlarmSystem;
