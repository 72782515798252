import { Progress } from 'antd';
import { Handle, HandleType, NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import styles from './EnergyNode.module.scss';

import { CarOutlined, FallOutlined, RiseOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import { IoBatteryCharging } from 'react-icons/io5';
import { LuUtilityPole } from 'react-icons/lu';
import { TbSolarPanel2 } from 'react-icons/tb';
import { isEmpty } from '../../../../../helpers/StringHelper';
import { DiagramType } from '../../../../../models/EnergyConfig';
import { VirtualDevice } from '../../../../../models/VirtualDevice';

const SmallProgress = styled(Progress)`
    .ant-progress-inner {
        width: 50px !important;
        height: 50px !important;
        font-size: 7px !important;
    }
`;

type CustomPosition = Position | 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left';

interface Props {
    label: string;
    value: string;
    type: HandleType;
    position: CustomPosition;
    virtualDevice: VirtualDevice;
    index: number;
    diagramType: DiagramType;
    color: string;
    unit?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EnergyNode = ({ data }: NodeProps<Props>) => {
    const getPosition = (position: CustomPosition) => {
        if (position === 'bottom-left' || position === 'bottom-right') {
            return Position.Bottom;
        }

        if (position === 'top-left' || position === 'top-right') {
            return Position.Top;
        }

        return position;
    };

    const getStyle = (position: CustomPosition): React.CSSProperties => {
        if (position === 'bottom-left') {
            return { left: '10%', bottom: '10%' };
        }

        if (position === 'bottom-right') {
            return { left: '90%', bottom: '10%' };
        }

        if (position === 'top-left') {
            return { left: '10%', top: '10%' };
        }

        if (position === 'top-right') {
            return { left: '90%', top: '10%' };
        }

        return {};
    };

    const color = data.color;

    const image = useMemo(() => {
        const style = { color: color, height: 10, width: 10 };
        switch (data.diagramType) {
            case DiagramType.Battery:
                return <IoBatteryCharging style={style} />;
            case DiagramType.Car:
                return <CarOutlined style={style} />;
            case DiagramType.Grid:
                return <LuUtilityPole style={style} />;
            case DiagramType.PV:
                return <TbSolarPanel2 style={style} />;
            case DiagramType.GeneralConsumer:
                return <FallOutlined style={style} />;
            case DiagramType.GeneralProducer:
                return <RiseOutlined style={style} />;
        }
    }, []);

    const getNumberValue = (value: string) => {
        const num = Number(value);

        if (isNaN(num)) {
            return 0;
        }

        return num;
    };

    const getValue = (value: string) => {
        if (isEmpty(value)) {
            return '-';
        }

        if (!isEmpty(data?.unit) && !data?.unit?.startsWith('k')) {
            return (getNumberValue(value) / 1000).toFixed(2);
        }

        return getNumberValue(value).toFixed(2);
    };

    return (
        <>
            <SmallProgress
                type="circle"
                percent={100}
                size="small"
                strokeColor={color}
                trailColor={color}
                format={() => (
                    <div className={styles.progressWrapper}>
                        {image}
                        <div style={{ color: color, marginTop: 2, fontSize: 5 }}>{data.label}</div>
                        <div style={{ color: color, marginTop: 2, fontSize: 8 }}>{`${getValue(data.value)} kW`}</div>
                    </div>
                )}
            />
            <Handle
                type={data.type}
                position={getPosition(data.position)}
                id="energyHandle"
                style={{ background: 'transparent', border: 'transparent', ...getStyle(data.position) }}
                isConnectable={false}
            />
        </>
    );
};

export default EnergyNode;
