import { useEffect, useMemo } from 'react';
import ReactFlow, { ConnectionMode, useReactFlow, useStore } from 'reactflow';
import 'reactflow/dist/style.css';
import { getDevices, getInfo, getIos, getRooms } from '../../../app/globalSettings';
import { useAppSelector } from '../../../app/hooks';
import demoBulkJson from '../../../demo/energyBulk.json';
import { isDemo } from '../../../helpers/HttpMethods';
import { isEmpty } from '../../../helpers/StringHelper';
import CenterNode from '../../../main-page/main-controls/controls/energy-management/center-node/CenterNode';
import EnergyNode from '../../../main-page/main-controls/controls/energy-management/energy-node/EnergyNode';
import { Device } from '../../../models/Device';
import { RoleType } from '../../../models/EnergyConfig';
import { Info } from '../../../models/Info';
import { Ios } from '../../../models/Ios';
import { Room } from '../../../models/Room';
import { VirtualDevice } from '../../../models/VirtualDevice';
import { DatapointType } from '../../../models/enums/DatapointType';
import { DeviceType } from '../../../models/enums/DeviceType';
import styles from './FlowChart.module.scss';

const initialNodes = [
    {
        id: '1',
        type: 'energyNode',
        position: { x: 0, y: 30 },
        data: { position: 'bottom' },
    },
    {
        id: '8',
        type: 'energyNode',
        position: { x: 0, y: 180 },
        data: { position: 'top' },
    },
    {
        id: '2',
        type: 'energyNode',
        position: { x: -50, y: 50 },
        data: { position: 'bottom-right' },
    },
    {
        id: '3',
        type: 'energyNode',
        position: { x: 50, y: 50 },
        data: { position: 'bottom-left' },
    },
    {
        id: '4',
        type: 'energyNode',
        position: { x: -70, y: 100 },
        data: { position: 'right' },
    },
    {
        id: '5',
        type: 'energyNode',
        position: { x: 70, y: 100 },
        data: { position: 'left' },
    },
    {
        id: '6',
        type: 'energyNode',
        position: { x: -50, y: 150 },
        data: { position: 'top-right' },
    },
    {
        id: '7',
        type: 'energyNode',
        position: { x: 50, y: 150 },
        data: { position: 'top-left' },
    },
];

const centerNode = { id: 'center', type: 'centerNode', position: { x: 15, y: 115 }, data: {} };

const initEdgesTarget = [
    'centerTopTarget',
    'centerBottomTarget',
    'centerLeftTopTarget',
    'centerRightTopTarget',
    'centerLeftTarget',
    'centerRightTarget',
    'centerLeftBottomTarget',
    'centerRightBottomTarget',
];

const initEdgesSource = [
    'centerTopSource',
    'centerBottomSource',
    'centerLeftTopSource',
    'centerRightTopSource',
    'centerLeftSource',
    'centerRightSource',
    'centerLeftBottomSource',
    'centerRightBottomSource',
];

const proOptions = { hideAttribution: true };

type Props = {
    virtualDevice: VirtualDevice;
};

const nodeTypes = { energyNode: EnergyNode, centerNode: CenterNode };

const demoBulk = JSON.parse(JSON.stringify(demoBulkJson));

const Flowchart = ({ virtualDevice }: Props): JSX.Element => {
    const ios: Ios[] = isDemo ? demoBulk.ios : useAppSelector(getIos);
    const devices: Device[] = isDemo ? demoBulk.devices : useAppSelector(getDevices);
    const rooms: Room[] = isDemo ? demoBulk.rooms : useAppSelector(getRooms);
    const info: Info = isDemo ? demoBulk.info : useAppSelector(getInfo);
    const reactFlowInstance = useReactFlow();

    const widthSelector = (state: { width: any }) => state.width;
    const heightSelector = (state: { height: any }) => state.height;
    const reactFlowWidth = useStore(widthSelector);
    const reactFlowHeight = useStore(heightSelector);

    const energyConfig = useMemo(
        () => virtualDevice?.datapoints?.find((x) => x.type === DatapointType.CentralEnergyConfig)?.EnergyConfig,
        [virtualDevice],
    );

    const iosConfig = useMemo(
        () =>
            energyConfig?.IOConfigs?.filter((x) =>
                ios?.filter((x) => energyConfig.SinglePower.includes(x.id))?.some((z) => z.id === x.Id),
            )
                .filter((x) => x.FlowChart)
                ?.slice(0, 8) ?? [],
        [energyConfig],
    );

    useEffect(() => {
        reactFlowInstance.fitView();
    }, [reactFlowWidth, reactFlowHeight, reactFlowInstance]);

    const getName = (ioId: number) => {
        const connectedIos = ios?.find((x) => x.id === ioId);

        if (info?.starterkit) {
            const device = devices?.find((x) => x.id === connectedIos?.device);

            if (device) {
                const room = rooms?.find((x) => x.id === device.roomid);

                let deviceName = device.name;

                if (device.type === DeviceType.RadioSwitchDual || device.type === DeviceType.RadioSwitchDualPlus) {
                    if (connectedIos?.name?.includes('EI1')) {
                        deviceName += ' (EI1)';
                    } else if (connectedIos?.name?.includes('EI2')) {
                        deviceName += ' (EI2)';
                    } else if (connectedIos?.name?.includes('PI1')) {
                        deviceName += ' (PI1)';
                    } else if (connectedIos?.name?.includes('PI2')) {
                        deviceName += ' (PI2)';
                    }
                }

                if (room) {
                    return `${deviceName} - ${room.name}`;
                }
            }
        }

        return connectedIos?.name;
    };

    const getEdgeWidth = (values: number[], value: number) => {
        if (value === 0) {
            return 1;
        }

        const absValue = Math.abs(value);
        const absValues = values.map((x) => Math.abs(x));
        const maxValues = Math.max(...absValues);
        const minValues = Math.min(...absValues);
        const third = (maxValues - minValues) / 3;

        if (absValue === minValues && absValue === maxValues) {
            return 2;
        }

        if (absValue < minValues + third) {
            return 1;
        } else if (absValue > maxValues - third) {
            return 3;
        }

        return 2;
    };

    const edges = useMemo(
        () =>
            iosConfig?.map((io, index, elements) => {
                if (
                    io.Role === RoleType.Consumer ||
                    (io.Role === RoleType.ProducerAndConsumer && Number(ios?.find((x) => x.id === io.Id)?.value) < 0)
                ) {
                    return {
                        id: index.toString(),
                        source: 'center',
                        sourceHandle: initEdgesSource[index],
                        target: initialNodes[index].id,
                        targetHandle: 'energyHandle',
                        animated: Number(ios?.find((x) => x.id === io.Id)?.value) !== 0,
                        style: {
                            stroke: io.Color,
                            strokeWidth: getEdgeWidth(
                                elements.map((x) => Number(ios?.find((z) => z.id === x.Id)?.value)),
                                Number(ios?.find((x) => x.id === io.Id)?.value),
                            ),
                        },
                    };
                } else {
                    return {
                        id: index.toString(),
                        source: initialNodes[index].id,
                        sourceHandle: 'energyHandle',
                        target: 'center',
                        targetHandle: initEdgesTarget[index],
                        animated: Number(ios?.find((x) => x.id === io.Id)?.value) !== 0,
                        style: {
                            stroke: io.Color,
                            strokeWidth: getEdgeWidth(
                                elements.map((x) => Number(ios?.find((z) => z.id === x.Id)?.value)),
                                Number(ios?.find((x) => x.id === io.Id)?.value),
                            ),
                        },
                    };
                }
            }) ?? [],

        [iosConfig, ios],
    );

    const getNumber = (value: string, unit: string) => {
        const num = Number(value);

        if (isNaN(num)) {
            return 0;
        }

        if (!isEmpty(unit) && !unit?.startsWith('k')) {
            return num / 1000;
        }

        return num;
    };

    const nodes = useMemo(
        () => [
            ...(iosConfig?.map((io, index) => {
                return {
                    ...initialNodes[index],
                    data: {
                        ...initialNodes[index].data,
                        label: getName(io.Id),
                        value: ios?.find((x) => x.id === io.Id)?.value ?? '',
                        type:
                            io.Role === RoleType.Consumer ||
                            (io.Role === RoleType.ProducerAndConsumer &&
                                Number(ios?.find((x) => x.id === io.Id)?.value) < 0)
                                ? 'target'
                                : 'source',
                        virtualDevice: virtualDevice,
                        index: index,
                        diagramType: io.Diagram,
                        color: io.Color,
                        unit: io.Unit,
                    },
                };
            }) ?? []),
            {
                ...centerNode,
                data: {
                    total: iosConfig.reduce((sum, current) => {
                        const value = getNumber(
                            ios?.find((x) => x.id === current.Id)?.value?.toString() ?? '0',
                            current.Unit,
                        );
                        if (
                            current.Role === RoleType.Consumer ||
                            (current.Role === RoleType.ProducerAndConsumer && value < 0)
                        ) {
                            return sum - value;
                        }

                        return sum + value;
                    }, 0),
                },
            },
        ],
        [iosConfig, ios],
    );

    return (
        <div className={styles.wrapper}>
            <ReactFlow
                panOnDrag={false}
                nodesDraggable={false}
                draggable={false}
                nodeTypes={nodeTypes}
                nodes={nodes}
                edges={edges}
                zoomOnDoubleClick={false}
                zoomOnScroll={false}
                fitView
                proOptions={proOptions}
                connectionMode={ConnectionMode.Loose}
            />
        </div>
    );
};

export default Flowchart;
