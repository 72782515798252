import AlarmClock from '../main-page/main-controls/controls/alarm-clock/AlarmClock';
import AlarmSystem from '../main-page/main-controls/controls/alarm-system/AlarmSystem';
import AlphatronicControl from '../main-page/main-controls/controls/alphatornic-control/AlphatronicControl';
import Astro from '../main-page/main-controls/controls/astro/Astro';
import Battery from '../main-page/main-controls/controls/battery/Battery';
import Blinds from '../main-page/main-controls/controls/blinds/Blinds';
import Calendar from '../main-page/main-controls/controls/calendar/Calendar';
import Camera from '../main-page/main-controls/controls/camera/Camera';
import CentralBlinds from '../main-page/main-controls/controls/central-blinds/CentralBlinds';
import CentralHomeButtons from '../main-page/main-controls/controls/central-home-buttons/CentralHomeButtons';
import CentralTimerSwitch from '../main-page/main-controls/controls/central-timer-switch/CentralTimerSwitch.tsx';
import { ControllersProps } from '../main-page/main-controls/controls/ControllersProps';
import Dimmer from '../main-page/main-controls/controls/dimmer/Dimmer';
import EnergyConfiguration from '../main-page/main-controls/controls/energy-configuration/EnergyConfiguration';
import EnergyMonitor from '../main-page/main-controls/controls/energy-monitor/EnergyMonitor';
import FireAndWaterAlarm from '../main-page/main-controls/controls/fire-and-water-alarm/FireAndWaterAlarm';
import Gate from '../main-page/main-controls/controls/gate/Gate';
import GenericControls from '../main-page/main-controls/controls/generic-controls/GenericControls';
import LightController from '../main-page/main-controls/controls/light-controller/LightController';
import Monitor from '../main-page/main-controls/controls/monitor/Monitor';
import OperationalModeSelector from '../main-page/main-controls/controls/operational-mode-selector/OperationalModeSelector';
import PresenceSimulation from '../main-page/main-controls/controls/presence-simulation/PresenceSimulation';
import RoomController from '../main-page/main-controls/controls/room-controller/RoomController';
import StaircaseLight from '../main-page/main-controls/controls/staircase-light/StaircaseLight';
import Timer from '../main-page/main-controls/controls/timer/Timer';
import ToiletVentilation from '../main-page/main-controls/controls/toilet-ventilation/ToiletVentilation';
import WindowsAndDoors from '../main-page/main-controls/controls/windows-and-doors/WindowsAndDoors';
import { DatapointType } from '../models/enums/DatapointType';
import { VirtualDeviceType } from '../models/enums/VirtualDeviceType';
import { VirtualDevice } from '../models/VirtualDevice';

export const getControl = (
    virtualDevice: VirtualDevice | undefined,
    props: ControllersProps,
): JSX.Element | undefined => {
    let view;
    switch (virtualDevice?.type) {
        case VirtualDeviceType.LightController:
            view = <LightController {...props} />;
            break;
        case VirtualDeviceType.ConstantLightController:
        case VirtualDeviceType.Dimmer:
            view = <Dimmer {...props} />;
            break;
        case VirtualDeviceType.Schedule:
            view = <Timer {...props} />;
            break;
        case VirtualDeviceType.ToiletVentilationController:
            view = <ToiletVentilation {...props} />;
            break;
        case VirtualDeviceType.FireAndWaterAlarm:
            view = <FireAndWaterAlarm {...props} />;
            break;
        case VirtualDeviceType.BurglarAlarm:
            view = <AlarmSystem {...props} />;
            break;
        case VirtualDeviceType.AlarmClock:
            view = <AlarmClock {...props} />;
            break;
        case VirtualDeviceType.GarageGate:
        case VirtualDeviceType.CentralGarageGate:
            view = <Gate {...props} />;
            break;
        case VirtualDeviceType.StaircaseTimer:
            view = <StaircaseLight {...props} />;
            break;
        case VirtualDeviceType.Blinds:
            view = <Blinds {...props} />;
            break;
        case VirtualDeviceType.RoomController:
        case VirtualDeviceType.CentralRoomControl:
            view = <RoomController {...props} />;
            break;
        case VirtualDeviceType.Monitor:
            view = <Monitor {...props} />;
            break;
        case VirtualDeviceType.CentralBattery:
            view = <Battery {...props} />;
            break;
        case VirtualDeviceType.CentralHomeButtons:
            view = <CentralHomeButtons {...props} />;
            break;
        case VirtualDeviceType.CentralWindowsAndDoors:
            view = <WindowsAndDoors {...props} />;
            break;
        case VirtualDeviceType.TotalEnergyMonitor:
            view = <EnergyMonitor {...props} />;
            break;
        case VirtualDeviceType.CentralBlindsController:
            view = <CentralBlinds {...props} />;
            break;
        case VirtualDeviceType.Camera:
            view = <Camera {...props} />;
            break;
        case VirtualDeviceType.Calendar:
            view = <Calendar {...props} />;
            break;
        case VirtualDeviceType.PresenceSimulation:
            view = <PresenceSimulation {...props} />;
            break;
        case VirtualDeviceType.CentralScheduler:
            view = <CentralTimerSwitch {...props} />;
            break;
        case VirtualDeviceType.AstroFunction:
            view = <Astro {...props} />;
            break;
        case VirtualDeviceType.EnergyManagement:
            view = <EnergyConfiguration {...props} />;
            break;
        case VirtualDeviceType.OperationalModeSelector:
            view = <OperationalModeSelector {...props} />;
            break;
        case VirtualDeviceType.Alphatronics:
            view = <AlphatronicControl {...props} />;
            break;
        default:
            if (ControlContainsDatapointsToShow(virtualDevice)) {
                view = <GenericControls {...props} />;
            }
            break;
    }

    return view;
};

const ControlContainsDatapointsToShow = (virtualDevice?: VirtualDevice): boolean => {
    return !!virtualDevice?.datapoints?.find(
        (x) =>
            x.type == DatapointType.Number ||
            x.type == DatapointType.Brightness ||
            x.type == DatapointType.Switch ||
            x.type == DatapointType.StatusLight ||
            x.type == DatapointType.Button,
    );
};
