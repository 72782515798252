export enum VirtualDeviceCategory {
    Wrong = -1,
    Undefined,
    LightingCategory,
    Blinds,
    WindowsAndDoors,
    Heating,
    Energy,
    Central,
    Airing,
    PresenceDetector,
    Water,
    Smoke,
    Security,
    Battery,
    Siren,
    PowerSocket,
}
