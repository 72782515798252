import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AlphatronicConfig } from '../../../../models/AlphatronicConfig';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { ControllersProps } from '../ControllersProps';
import ControlHeader from '../components/control-header/ControlHeader';
import styles from './AlphatronicControl.module.scss';
import AlphatronicGroupView from './AlphatronicGroupView/AlphatronicGroupView';

const AlphatronicControl = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room, onEditButtonClick } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const config: AlphatronicConfig | undefined = useMemo(() => {
        try {
            return JSON.parse(
                virtualDevice.datapoints.find((x) => x.type == DatapointType.AlphatronicUIConfig)?.value?.toString() ??
                    '',
            );
        } catch {
            return undefined;
        }
    }, [virtualDevice]);

    const controlsDatapoints = useMemo(
        () =>
            virtualDevice.datapoints
                .filter(
                    (x) =>
                        ((x.type === DatapointType.Number && x.writeprotect) ||
                            x.type === DatapointType.StatusLight ||
                            x.type === DatapointType.Button) &&
                        x.customname === '',
                )
                .map((x) => x.id),
        [],
    );

    return (
        <div className={styles.mainContainer} style={{ border: border }}>
            <ControlHeader onEditButtonClick={onEditButtonClick} virtualDevice={virtualDevice} room={room} />
            {config && (
                <AlphatronicGroupView
                    group={{ Groups: [], Datapoints: controlsDatapoints, Name: '' }}
                    config={config}
                    virtualDevice={virtualDevice}
                />
            )}
            {config &&
                config.Groups.length > 0 &&
                config.Groups.map((z, index) => (
                    <AlphatronicGroupView
                        key={z.Name + index}
                        group={z}
                        config={config}
                        virtualDevice={virtualDevice}
                    />
                ))}
        </div>
    );
};

export default AlphatronicControl;
