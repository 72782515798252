import styles from './Blinds.module.scss';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { pushButtonDatapoint, updateDatapointValue } from '../../../../helpers/HttpMethods';
import { DatapointNames } from '../../../../models/enums/DatapointNames';
import { getNumberDatapointValue, getValueValidatedByRange } from '../../../../helpers/DatapointHelper';
import { ControllersProps } from '../ControllersProps';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CustomButton from '../components/custom-button/CustomButton';
import CustomRange from '../components/custom-slider/CustomRange';
import { toast } from 'react-toastify';
import { StatusCodes } from 'http-status-codes';
import { Datapoint } from '../../../../models/Datapoint';
import ControlHeader from '../components/control-header/ControlHeader';

const Blinds = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const blindsPositionDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualBlindsPosition,
    );
    const slatsPositionDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualSlatsPosition,
    );
    const setBlindsDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualSetBlinds,
    );
    const setSlatsDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualSetSlats,
    );
    const shadingDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualShadingOn,
    );

    const onDatapointValueChanged = async (value: boolean | number | string, datapoint?: Datapoint) => {
        if (datapoint) {
            const result = await updateDatapointValue(datapoint, value);
            if (result.status !== StatusCodes.OK) {
                toast.error(t('errors.errorWhileSendingValue'));
                return;
            }
        }
    };

    return (
        <div className={styles.mainContainer} style={{ border: border }}>
            <ControlHeader virtualDevice={virtualDevice} room={room} />
            {blindsPositionDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('blinds.blindsPosition')}</div>
                    <div>{`${getNumberDatapointValue(blindsPositionDatapoint)}%`}</div>
                </div>
            )}
            {setBlindsDatapoint && (
                <div className={styles.sliderDatapoint}>
                    <CustomRange
                        step={1}
                        min={Number(setBlindsDatapoint?.range[0] ?? 0)}
                        max={Number(setBlindsDatapoint?.range[1] ?? 100)}
                        value={getValueValidatedByRange(setBlindsDatapoint)}
                        minTrackColor={category?.color ?? '#e0e0e0'}
                        onValueChanged={(value) => onDatapointValueChanged(value, setBlindsDatapoint)}
                    />
                </div>
            )}
            {slatsPositionDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('blinds.slatsPosition')}</div>
                    <div>{`${getNumberDatapointValue(slatsPositionDatapoint)}%`}</div>
                </div>
            )}
            {setSlatsDatapoint && (
                <div className={styles.sliderDatapoint}>
                    <CustomRange
                        step={1}
                        min={Number(setSlatsDatapoint?.range[0] ?? 0)}
                        max={Number(setSlatsDatapoint?.range[1] ?? 100)}
                        value={getValueValidatedByRange(setSlatsDatapoint)}
                        minTrackColor={category?.color ?? '#e0e0e0'}
                        onValueChanged={(value) => onDatapointValueChanged(value, setSlatsDatapoint)}
                    />
                </div>
            )}
            {shadingDatapoint && (
                <div className={styles.button}>
                    <CustomButton onClick={() => pushButtonDatapoint(shadingDatapoint)} title={t('blinds.shadeMode')} />
                </div>
            )}
        </div>
    );
};

export default Blinds;
