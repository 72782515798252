import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Space, Tabs, TimePicker } from 'antd';
import { BaseButtonProps } from 'antd/lib/button/button';
import classNames from 'classnames';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getSetupInfo } from '../../app/globalSettings';
import { useAppSelector } from '../../app/hooks';
import useWindowDimensions from '../../helpers/Hooks/useWindowDimensions';
import { isDemo, updateDatapoint } from '../../helpers/HttpMethods';
import { findDuplicates, findEmpty, getNewTimeProgramName, isEmpty } from '../../helpers/StringHelper';
import EditImg from '../../images/edit.svg';
import exImg from '../../images/ex.svg';
import CustomRange from '../../main-page/main-controls/controls/components/custom-slider/CustomRange';
import { SwitchPoint } from '../../models/SwitchPoint';
import { TimeProgram } from '../../models/TimeProgram';
import { VirtualDeviceConfigPoint } from '../../models/VirtualDeviceConfigPoint';
import { VirtualDeviceCategorySettings } from '../../models/constants/VirtualDeviceCategorySettings';
import { DatapointType } from '../../models/enums/DatapointType';
import { VirtualDeviceType } from '../../models/enums/VirtualDeviceType';
import YesNoModal from '../yes-no-modal/YesNoModal';
import styles from './CentralTimerProgramEditModal.module.scss';
import { CentralTimerProgramEditModalProps } from './CentralTimerProgramEditModalProps';

export enum TimeProgramType {
    Heating,
    Timer,
}

const CentralTimerProgramEditModal = (props: CentralTimerProgramEditModalProps): JSX.Element => {
    const { timeProgramDatapoint, virtualDevice, closeModalRequested } = props;
    const setupInfo = useAppSelector(getSetupInfo);
    const [timePrograms, setTimePrograms] = useState<TimeProgram[]>(timeProgramDatapoint?.TimeProgramList ?? []);
    const { t } = useTranslation();
    const { windowHeight, windowWidth } = useWindowDimensions();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const [yesNoModalVisible, setYesNoModalVisible] = useState<{ onYesClicked: () => void; onNoClicked: () => void }>();
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState<string>('0');
    const [selectedSwitchPointToEdit, setSelectedSwitchPointToEdit] = useState<SwitchPoint | undefined>();
    const isMobile = windowWidth < 650;
    const [mobileConfigVisible, setMobileConfigVisible] = useState(!isMobile);

    const isEdited = timeProgramDatapoint?.TimeProgramList != timePrograms;
    const ignore = { label: t('general.ignore'), value: 'Ignore' };
    const contentRef = useRef<HTMLDivElement>(null);
    const isError = findDuplicates(timePrograms.map((x) => x.Name)) || findEmpty(timePrograms.map((x) => x.Name));

    useEffect(() => {
        setMobileConfigVisible(!isMobile);
        setSelectedSwitchPointToEdit(isMobile ? undefined : timePrograms?.[0]?.SwitchPoints?.[0]);
    }, [isMobile]);

    useEffect(() => {
        if (isMobile) {
            setMobileConfigVisible(!!selectedSwitchPointToEdit);
        }
    }, [selectedSwitchPointToEdit]);

    const addTimeProgram = async () => {
        const newTimeProgram: TimeProgram = {
            Name: getNewTimeProgramName(timePrograms),
            days: [],
            SwitchPoints: [
                {
                    ...timePrograms[0].SwitchPoints[0],
                    FB: timePrograms[0].SwitchPoints[0].FB?.map((x) => ({ ...x, Value: ignore.value })),
                    Time: getNewSwitchpointTime(),
                },
            ],
        };

        const newTimePrograms = [...timePrograms, newTimeProgram];

        setTimePrograms(newTimePrograms);
        setSelectedSwitchPointToEdit(newTimeProgram.SwitchPoints[0]);
        setYesNoModalVisible(undefined);
        setActiveTab(String(newTimePrograms.length - 1));
    };

    const removeTimeProgram = async (index: number) => {
        const newTimePrograms = timePrograms.filter((x) => x !== timePrograms[index]);

        if (newTimePrograms.length === 0) {
            return;
        }

        setTimePrograms(newTimePrograms);

        const activeTabNumber = Number(activeTab);
        if (activeTabNumber >= index) {
            const numberToSet = activeTabNumber === 0 ? 0 : activeTabNumber - 1;
            setActiveTab(String(numberToSet));
            setSelectedSwitchPointToEdit(newTimePrograms[numberToSet].SwitchPoints[0]);
        }
    };

    const saveTimeProgram = async () => {
        try {
            setIsLoading(true);
            const newTimeProgramDatapoint = {
                ...timeProgramDatapoint,
                TimeProgram: { ...timeProgramDatapoint.TimeProgram, TimeProgramList: timePrograms },
            };

            const result = await updateDatapoint(newTimeProgramDatapoint);

            if (result?.status != StatusCodes.OK) {
                showError();
                setIsLoading(false);
                return;
            }

            if (isDemo) {
                closeModalRequested();
            }
        } catch {
            showError();
        } finally {
            setIsLoading(false);
        }
    };

    const showError = () => {
        toast.error(t('errors.errorWhileSendingValue'));
    };

    const getNewSwitchpointTime = () => {
        return `00:00`;
    };

    const onCloseRequested = () => {
        if (isEdited) {
            setYesNoModalVisible({
                onYesClicked: closeModalRequested,
                onNoClicked: () => setYesNoModalVisible(undefined),
            });
            return;
        }

        closeModalRequested();
    };

    const setSwitchPoint = (index: number, switchPoints: SwitchPoint[]) => {
        setTimePrograms(
            timePrograms.map((x) =>
                timePrograms.indexOf(x) === index
                    ? {
                          ...x,
                          SwitchPoints: switchPoints,
                      }
                    : x,
            ),
        );
    };

    const addSwitchPoint = (index: number) => {
        const newSwitchPoint: SwitchPoint = {
            ...timePrograms[0].SwitchPoints[0],
            FB: timePrograms[0].SwitchPoints[0]?.FB?.map((x) => ({ ...x, Value: ignore.value })) ?? [],
            Time: getNewSwitchpointTime(),
        };
        const newSwitchPoints: SwitchPoint[] = [...timePrograms[index].SwitchPoints, newSwitchPoint];

        setSwitchPoint(
            index,
            newSwitchPoints.sort((a: SwitchPoint, b: SwitchPoint) => {
                return moment(`01.01.2022 ${a.Time}`, 'dd.MM.yyyy HH:mm').diff(
                    moment(`01.01.2022 ${b.Time}`, 'dd.MM.yyyy HH:mm'),
                );
            }),
        );
    };

    const removeSwitchPoint = (index: number, switchPoint: SwitchPoint) => {
        const newSwitchPoints = timePrograms[index].SwitchPoints.filter((x) => x !== switchPoint);
        setSwitchPoint(index, newSwitchPoints);

        if (switchPoint === selectedSwitchPointToEdit) {
            setSelectedSwitchPointToEdit(newSwitchPoints[0]);
        }
    };

    const onDayClicked = (index: number, day: number) => {
        if (!dayIsEnabled(index, day)) {
            return;
        }

        setTimePrograms(
            timePrograms.map((x) =>
                x === timePrograms[index]
                    ? {
                          ...timePrograms[index],
                          days: timePrograms[index].days.includes(day)
                              ? timePrograms[index].days.filter((x) => x !== day)
                              : [...timePrograms[index].days, day].sort((a, b) => a - b),
                      }
                    : x,
            ),
        );
    };

    const dayIsEnabled = (index: number, day: number) => {
        return !timePrograms.filter((x) => x !== timePrograms[index]).some((x) => x.days.some((y) => y === day));
    };

    const onEditSwitchPoint = (switchPoint: SwitchPoint) => {
        setSelectedSwitchPointToEdit(switchPoint);
    };

    const getVirtualDevice = (id: number) => {
        return setupInfo?.objects?.items?.find((x) => x.id == id);
    };

    const onValueChanged = (index: number, point: VirtualDeviceConfigPoint, value: string) => {
        if (selectedSwitchPointToEdit) {
            const newFB = selectedSwitchPointToEdit?.FB?.map((x) => (x === point ? { ...x, Value: value } : x));
            const newSwitchPoint = {
                ...selectedSwitchPointToEdit,
                FB: newFB,
            };

            setSwitchPoint(
                index,
                timePrograms[index].SwitchPoints.map((x) => (x === selectedSwitchPointToEdit ? newSwitchPoint : x)),
            );
            setSelectedSwitchPointToEdit(newSwitchPoint);
        }
    };

    const getOptions = (point: VirtualDeviceConfigPoint) => {
        if (point.Type === VirtualDeviceType.LightController) {
            return [
                ignore,
                ...(setupInfo?.objects?.items
                    ?.find((x) => x.id === point.ObjectID)
                    ?.datapoints?.find((x) => x.type === DatapointType.ScenesLight)
                    ?.Scenes?.map((x) => ({ label: x.Name, value: x.Name })) ?? []),
            ];
        }

        if (point.Type === VirtualDeviceType.Blinds) {
            return [
                ignore,
                { label: t('centralTimerProgramEdit.completelyUp'), value: 'CompletelyUp' },
                { label: t('centralTimerProgramEdit.completelyDown'), value: 'CompletelyDown' },
                { label: t('centralTimerProgramEdit.shadowing'), value: 'Shadowing' },
            ];
        }

        if (
            point.Type === VirtualDeviceType.Schedule ||
            point.Type === VirtualDeviceType.ToiletVentilationController ||
            point.Type === VirtualDeviceType.StaircaseTimer ||
            point.Type === VirtualDeviceType.PushButton ||
            point.Type === VirtualDeviceType.PushSwitch ||
            point.Type === VirtualDeviceType.Switch
        ) {
            return [ignore, { label: t('general.off'), value: 'Off' }, { label: t('general.on'), value: 'On' }];
        }

        if (point.Type === VirtualDeviceType.Dimmer) {
            return [ignore, { label: '0-100%', value: '0' }];
        }

        return [];
    };

    const onSave = async () => {
        await saveTimeProgram();
        closeModalRequested();
    };

    const dayButtonProps = (
        index: number,
        day: number,
    ): BaseButtonProps & { style: React.CSSProperties } & { onClick: () => void } => ({
        type: 'primary',
        shape: 'circle',
        style: {
            border: `2px solid ${category?.color}`,
            backgroundColor: timePrograms[index].days.includes(day) ? category?.color : 'transparent',
            opacity: dayIsEnabled(index, day) ? 1 : 0.5,
            cursor: dayIsEnabled(index, day) ? 'pointer' : 'auto',
            color: 'black',
        },
        onClick: () => onDayClicked(index, day),
    });

    const onTabChanged = (key: string) => {
        setActiveTab(key);
        setSelectedSwitchPointToEdit(isMobile ? undefined : timePrograms[Number(key)].SwitchPoints[0]);
    };

    return (
        <Modal
            title={virtualDevice.name}
            open={true}
            onCancel={onCloseRequested}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{
                disabled: isError,
                loading: isLoading,
            }}
            okText={t('general.save')}
            onOk={onSave}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            width={700}
        >
            <div className={styles.mainContainer}>
                <Tabs
                    activeKey={activeTab}
                    onChange={onTabChanged}
                    onEdit={(targetKey, action) => {
                        if (action === 'add') {
                            addTimeProgram();
                        } else {
                            removeTimeProgram(Number(targetKey));
                        }
                    }}
                    tabBarExtraContent={{
                        left: (
                            <Button
                                onClick={(e) => {
                                    onTabChanged(Number(activeTab) <= 0 ? '0' : String(Number(activeTab) - 1));
                                    e.currentTarget.blur();
                                }}
                                className={styles.leftArrow}
                            >
                                <LeftOutlined />
                            </Button>
                        ),
                        right: (
                            <Button
                                onClick={(e) => {
                                    onTabChanged(
                                        Number(activeTab) >= timePrograms.length - 1
                                            ? String(timePrograms.length - 1)
                                            : String(Number(activeTab) + 1),
                                    );
                                    e.currentTarget.blur();
                                }}
                                className={styles.leftArrow}
                            >
                                <RightOutlined />
                            </Button>
                        ),
                    }}
                    type="editable-card"
                    style={{ width: '100%' }}
                    hideAdd={timePrograms.length === 7}
                    items={timePrograms.map((timeProgram, tpIndex) => ({
                        label: timeProgram.Name,
                        key: tpIndex.toString(),
                        closable: timePrograms.length > 1,
                        children: (
                            <div className={styles.tabContainer}>
                                {(!isMobile || (isMobile && !mobileConfigVisible)) && (
                                    <div className={styles.tabContent}>
                                        <Input
                                            status={
                                                timePrograms
                                                    .filter((x) => x !== timePrograms[tpIndex])
                                                    .find((y) => timePrograms[tpIndex].Name === y.Name) ||
                                                isEmpty(timeProgram.Name)
                                                    ? 'error'
                                                    : undefined
                                            }
                                            onChange={(value) => {
                                                setTimePrograms(
                                                    timePrograms.map((x) =>
                                                        x === timePrograms[tpIndex]
                                                            ? {
                                                                  ...timePrograms[tpIndex],
                                                                  Name: value.currentTarget.value,
                                                              }
                                                            : x,
                                                    ),
                                                );
                                            }}
                                            value={timeProgram.Name}
                                            placeholder={t('timeProgramEdit.timeProgramName')}
                                        />
                                        {timePrograms
                                            .filter((x) => x !== timePrograms[tpIndex])
                                            .find((y) => timePrograms[tpIndex].Name === y.Name) && (
                                            <div className={styles.nameError}>{t('errors.NameAlreadyExist')}</div>
                                        )}
                                        {isEmpty(timeProgram.Name) && (
                                            <div className={styles.nameError}>{t('errors.fieldCannotBeEmpty')}</div>
                                        )}
                                        <Space className={styles.daysContainer}>
                                            <Button {...dayButtonProps(tpIndex, 1)}>{t('timeProgramEdit.mo')}</Button>
                                            <Button {...dayButtonProps(tpIndex, 2)}>{t('timeProgramEdit.tu')}</Button>
                                            <Button {...dayButtonProps(tpIndex, 3)}>{t('timeProgramEdit.we')}</Button>
                                            <Button {...dayButtonProps(tpIndex, 4)}>{t('timeProgramEdit.th')}</Button>
                                            <Button {...dayButtonProps(tpIndex, 5)}>{t('timeProgramEdit.fr')}</Button>
                                            <Button {...dayButtonProps(tpIndex, 6)}>{t('timeProgramEdit.sa')}</Button>
                                            <Button {...dayButtonProps(tpIndex, 0)}>{t('timeProgramEdit.su')}</Button>
                                        </Space>
                                        <div
                                            className={classNames(styles.contentContainer, {
                                                [styles.overflowAuto]:
                                                    (contentRef.current?.offsetHeight ?? 0) > windowHeight * 0.48,
                                            })}
                                            ref={contentRef}
                                        >
                                            {timeProgram.SwitchPoints.map((switchPoint, index) => (
                                                <div key={index} className={styles.sliderDatapoint}>
                                                    <div className={styles.switchPointContainer}>
                                                        {selectedSwitchPointToEdit === switchPoint && (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    width: '116%',
                                                                    height: '100%',
                                                                    marginLeft: '-8%',
                                                                    marginRight: '-8%',
                                                                    backgroundColor: category?.color,
                                                                    opacity: 0.25,
                                                                    borderRadius: 5,
                                                                }}
                                                            />
                                                        )}
                                                        {timeProgram.SwitchPoints.length > 1 ? (
                                                            <img
                                                                onClick={() => removeSwitchPoint(tpIndex, switchPoint)}
                                                                className={styles.removeImg}
                                                                src={exImg}
                                                            />
                                                        ) : (
                                                            <div className={styles.removeImg} />
                                                        )}
                                                        <TimePicker
                                                            allowClear={false}
                                                            style={{ width: 100 }}
                                                            format="HH:mm"
                                                            showNow={false}
                                                            value={moment(switchPoint.Time, 'HH:mm')}
                                                            onBlur={() =>
                                                                setSwitchPoint(
                                                                    tpIndex,
                                                                    timeProgram.SwitchPoints.sort(
                                                                        (a: SwitchPoint, b: SwitchPoint) => {
                                                                            return moment(
                                                                                `01.01.2022 ${a.Time}`,
                                                                                'dd.MM.yyyy HH:mm',
                                                                            ).diff(
                                                                                moment(
                                                                                    `01.01.2022 ${b.Time}`,
                                                                                    'dd.MM.yyyy HH:mm',
                                                                                ),
                                                                            );
                                                                        },
                                                                    ),
                                                                )
                                                            }
                                                            onSelect={(value) => {
                                                                const newSwitchPoint: SwitchPoint = {
                                                                    ...switchPoint,
                                                                    Time: moment(value).format('HH:mm'),
                                                                };
                                                                setSwitchPoint(
                                                                    tpIndex,
                                                                    timeProgram.SwitchPoints.map((x, spIndex) =>
                                                                        spIndex === index ? newSwitchPoint : x,
                                                                    ),
                                                                );
                                                                if (switchPoint === selectedSwitchPointToEdit) {
                                                                    setSelectedSwitchPointToEdit(newSwitchPoint);
                                                                }
                                                            }}
                                                            onChange={(value) => {
                                                                const newSwitchPoint: SwitchPoint = {
                                                                    ...switchPoint,
                                                                    Time: moment(value).format('HH:mm'),
                                                                };
                                                                setSwitchPoint(
                                                                    tpIndex,
                                                                    timeProgram.SwitchPoints.map((x, spIndex) =>
                                                                        spIndex === index ? newSwitchPoint : x,
                                                                    ),
                                                                );
                                                                if (switchPoint === selectedSwitchPointToEdit) {
                                                                    setSelectedSwitchPointToEdit(newSwitchPoint);
                                                                }
                                                            }}
                                                        />
                                                        <Button
                                                            onClick={() => onEditSwitchPoint(switchPoint)}
                                                            shape="circle"
                                                        >
                                                            <img className={styles.editImg} src={EditImg} />
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className={styles.addSwitchPointButtonContainer}>
                                                <Button
                                                    onClick={(e) => {
                                                        addSwitchPoint(tpIndex);
                                                        e.currentTarget.blur();
                                                    }}
                                                >
                                                    {'+'}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!isMobile && <div className={styles.divider} />}
                                {mobileConfigVisible && (
                                    <div
                                        className={classNames(styles.contentContainer, styles.configElements, {
                                            [styles.overflowAuto]:
                                                (contentRef.current?.offsetHeight ?? 0) > windowHeight * 0.48,
                                        })}
                                    >
                                        {isMobile && (
                                            <Button
                                                className={styles.mobileClose}
                                                onClick={(e) => {
                                                    setSelectedSwitchPointToEdit(undefined);
                                                    e.currentTarget.blur();
                                                }}
                                            >
                                                {t('general.return')}
                                            </Button>
                                        )}
                                        {selectedSwitchPointToEdit &&
                                            selectedSwitchPointToEdit?.FB?.filter((x) => x.IsSelected).map(
                                                (virtualDevicePoint, index) => (
                                                    <div key={index}>
                                                        {virtualDevicePoint.Type === VirtualDeviceType.Dimmer &&
                                                            getVirtualDevice(virtualDevicePoint.ObjectID) && (
                                                                <div>
                                                                    <div className={styles.dimmerTitle}>
                                                                        {
                                                                            getVirtualDevice(
                                                                                virtualDevicePoint.ObjectID,
                                                                            )?.name
                                                                        }
                                                                    </div>
                                                                    <Select
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: (base) => ({
                                                                                ...base,
                                                                                zIndex: 99999,
                                                                                fontSize: 14,
                                                                            }),
                                                                        }}
                                                                        onChange={(value) =>
                                                                            value &&
                                                                            onValueChanged(
                                                                                tpIndex,
                                                                                virtualDevicePoint,
                                                                                value?.value === ignore.value
                                                                                    ? ignore.value
                                                                                    : (
                                                                                          getOptions(virtualDevicePoint)
                                                                                              .map((x) => x.value)
                                                                                              .indexOf(value.value) - 1
                                                                                      ).toString(),
                                                                            )
                                                                        }
                                                                        value={
                                                                            virtualDevicePoint.Value === ignore.value
                                                                                ? getOptions(virtualDevicePoint)[0]
                                                                                : getOptions(virtualDevicePoint)[1]
                                                                        }
                                                                        className={styles.sceneSelect}
                                                                        options={getOptions(virtualDevicePoint) ?? []}
                                                                        theme={(theme) => ({
                                                                            ...theme,
                                                                            colors: {
                                                                                ...theme.colors,
                                                                                primary: '#a1a1a1',
                                                                            },
                                                                        })}
                                                                    />
                                                                    {virtualDevicePoint.Value !== ignore.value && (
                                                                        <div className={styles.range}>
                                                                            <CustomRange
                                                                                step={1}
                                                                                min={0}
                                                                                max={100}
                                                                                value={
                                                                                    !isEmpty(
                                                                                        virtualDevicePoint.Value,
                                                                                    ) &&
                                                                                    virtualDevicePoint.Value !==
                                                                                        ignore.value
                                                                                        ? +virtualDevicePoint.Value
                                                                                        : 0
                                                                                }
                                                                                minTrackColor={
                                                                                    category?.color ?? '#e0e0e0'
                                                                                }
                                                                                onValueChanged={(value) =>
                                                                                    onValueChanged(
                                                                                        tpIndex,
                                                                                        virtualDevicePoint,
                                                                                        value.toString(),
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        {(virtualDevicePoint.Type ===
                                                            VirtualDeviceType.LightController ||
                                                            virtualDevicePoint.Type === VirtualDeviceType.Blinds ||
                                                            virtualDevicePoint.Type ===
                                                                VirtualDeviceType.StaircaseTimer ||
                                                            virtualDevicePoint.Type ===
                                                                VirtualDeviceType.ToiletVentilationController ||
                                                            virtualDevicePoint.Type === VirtualDeviceType.Schedule ||
                                                            virtualDevicePoint.Type === VirtualDeviceType.PushButton ||
                                                            virtualDevicePoint.Type === VirtualDeviceType.PushSwitch ||
                                                            virtualDevicePoint.Type === VirtualDeviceType.Switch) &&
                                                            getVirtualDevice(virtualDevicePoint.ObjectID) && (
                                                                <div className={styles.config}>
                                                                    {
                                                                        getVirtualDevice(virtualDevicePoint.ObjectID)
                                                                            ?.name
                                                                    }
                                                                    <Select
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: (base) => ({
                                                                                ...base,
                                                                                zIndex: 99999,
                                                                                fontSize: 14,
                                                                            }),
                                                                        }}
                                                                        onChange={(value) =>
                                                                            value &&
                                                                            onValueChanged(
                                                                                tpIndex,
                                                                                virtualDevicePoint,
                                                                                virtualDevicePoint.Type ===
                                                                                    VirtualDeviceType.LightController
                                                                                    ? value?.value === ignore.value
                                                                                        ? ignore.value
                                                                                        : (
                                                                                              getOptions(
                                                                                                  virtualDevicePoint,
                                                                                              )
                                                                                                  .map((x) => x.value)
                                                                                                  .indexOf(
                                                                                                      value.value,
                                                                                                  ) - 1
                                                                                          ).toString()
                                                                                    : value.value,
                                                                            )
                                                                        }
                                                                        value={
                                                                            virtualDevicePoint.Value === ignore.value
                                                                                ? getOptions(virtualDevicePoint)[0]
                                                                                : !isNaN(
                                                                                      Number(virtualDevicePoint.Value),
                                                                                  )
                                                                                ? getOptions(virtualDevicePoint)[
                                                                                      +virtualDevicePoint.Value + 1
                                                                                  ]
                                                                                : getOptions(virtualDevicePoint).find(
                                                                                      (x) =>
                                                                                          x.value ===
                                                                                          virtualDevicePoint.Value,
                                                                                  )
                                                                        }
                                                                        className={styles.sceneSelect}
                                                                        options={getOptions(virtualDevicePoint) ?? []}
                                                                        theme={(theme) => ({
                                                                            ...theme,
                                                                            colors: {
                                                                                ...theme.colors,
                                                                                primary: '#a1a1a1',
                                                                            },
                                                                        })}
                                                                    />
                                                                </div>
                                                            )}
                                                    </div>
                                                ),
                                            )}
                                    </div>
                                )}
                            </div>
                        ),
                    }))}
                />
            </div>
            {yesNoModalVisible && (
                <YesNoModal
                    onYesClicked={yesNoModalVisible.onYesClicked}
                    onNoClicked={yesNoModalVisible.onNoClicked}
                    description={t('timeProgramEdit.changesNotSaved')}
                    isVisible={true}
                />
            )}
        </Modal>
    );
};

export default CentralTimerProgramEditModal;
