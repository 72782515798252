import { HomeOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Tabs } from 'antd';
import { StatusCodes } from 'http-status-codes';
import i18n from 'i18next';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getSetupInfo } from '../../app/globalSettings';
import { useAppSelector } from '../../app/hooks';
import { updateDatapoint } from '../../helpers/HttpMethods';
import { isEmpty } from '../../helpers/StringHelper';
import CustomRange from '../../main-page/main-controls/controls/components/custom-slider/CustomRange';
import { AstroFunctionBlock } from '../../models/AstroFunctionBlock';
import { Datapoint } from '../../models/Datapoint';
import { VirtualDevice } from '../../models/VirtualDevice';
import { VirtualDeviceCategorySettings } from '../../models/constants/VirtualDeviceCategorySettings';
import { DatapointType } from '../../models/enums/DatapointType';
import { VirtualDeviceType } from '../../models/enums/VirtualDeviceType';
import YesNoModal from '../yes-no-modal/YesNoModal';
import styles from './AstroEditModal.module.scss';
import AstroMap from './AstroMap';

interface Props {
    onCloseRequested: () => void;
    virtualDevice: VirtualDevice;
    datapoint: Datapoint;
}

enum AstroType {
    Sunrise,
    Sunset,
    Dawn,
    Twilight,
}

const provider = new OpenStreetMapProvider({ params: { 'accept-language': i18n.resolvedLanguage } });
const astroTypes = [AstroType.Sunrise, AstroType.Sunset, AstroType.Dawn, AstroType.Twilight];

const AstroEditModal = (props: Props): JSX.Element => {
    const { virtualDevice, datapoint, onCloseRequested } = props;
    const { t } = useTranslation();
    const setupInfo = useAppSelector(getSetupInfo);

    const ignore = { label: t('general.ignore'), value: 'Ignore' };
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);

    const dpBlocks = datapoint?.AstroConfig?.FunctionBlocks;

    const [isLoading, setIsLoading] = useState(false);
    const [currentPosition, setCurrentPosition] = useState({
        lat: datapoint?.AstroConfig?.Latitude ?? '49',
        lng: datapoint?.AstroConfig?.Longitude ?? '9',
    });
    const [mapVisible, setMapVisible] = useState(false);
    const [label, setLabel] = useState('');
    const [activeTab, setActiveTab] = useState<string>('0');
    const [functionBlocks, setFunctionBlocks] = useState(
        setupInfo?.objects?.items
            ?.filter(
                (vd) =>
                    vd.type === VirtualDeviceType.Dimmer ||
                    vd.type === VirtualDeviceType.LightController ||
                    vd.type === VirtualDeviceType.Blinds ||
                    vd.type === VirtualDeviceType.StaircaseTimer ||
                    vd.type === VirtualDeviceType.ToiletVentilationController ||
                    vd.type === VirtualDeviceType.Schedule ||
                    vd.type === VirtualDeviceType.BurglarAlarm ||
                    vd.type === VirtualDeviceType.PresenceSimulation ||
                    vd.type === VirtualDeviceType.PushButton ||
                    vd.type === VirtualDeviceType.PushSwitch ||
                    vd.type === VirtualDeviceType.Switch,
            )
            ?.map((x) => ({
                ID: dpBlocks?.find((z) => z.ObjectID === x.id)?.ID ?? `_${x.id}`,
                ObjectID: x.id,
                Type: x.type,
                Dawn: dpBlocks.find((z) => z.ObjectID === x.id)?.Dawn ?? ignore.value,
                DawnDelay: 0,
                Sunrise: dpBlocks.find((z) => z.ObjectID === x.id)?.Sunrise ?? ignore.value,
                SunriseDelay: 0,
                Sunset: dpBlocks.find((z) => z.ObjectID === x.id)?.Sunset ?? ignore.value,
                SunsetDelay: 0,
                Twilight: dpBlocks.find((z) => z.ObjectID === x.id)?.Twilight ?? ignore.value,
                TwilightDelay: 0,
            })) ?? [],
    );
    const [yesNoModalVisible, setYesNoModalVisible] = useState<{ onYesClicked: () => void; onNoClicked: () => void }>();

    const isEdited =
        (datapoint?.AstroConfig?.FunctionBlocks !== functionBlocks ||
            currentPosition.lat !== datapoint?.AstroConfig?.Latitude) ??
        ('49' || currentPosition.lng !== datapoint?.AstroConfig?.Longitude) ??
        '9';

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const result = await provider.search({
                query: `${Number(currentPosition.lat).toFixed(14)}, ${Number(currentPosition.lng).toFixed(14)}`,
            });

            setLabel(result?.[0]?.label ?? '');
            setIsLoading(false);
        })();
    }, [currentPosition]);

    const getAstroName = (type: AstroType) => {
        switch (type) {
            case AstroType.Sunrise:
                return t('general.sunrise');
            case AstroType.Sunset:
                return t('general.sunset');
            case AstroType.Dawn:
                return t('general.dawn');
            case AstroType.Twilight:
                return t('general.twilight');
        }
    };

    const onTabChanged = (key: string) => {
        setActiveTab(key);
    };

    const onClose = () => {
        if (isEdited) {
            setYesNoModalVisible({
                onYesClicked: onCloseRequested,
                onNoClicked: () => setYesNoModalVisible(undefined),
            });
            return;
        }

        onCloseRequested();
    };

    const onSave = async () => {
        try {
            setIsLoading(true);
            const newDatapoint: Datapoint = {
                ...datapoint,
                AstroConfig: {
                    Latitude: currentPosition.lat,
                    Longitude: currentPosition.lng,
                    FunctionBlocks: functionBlocks,
                },
            };

            const result = await updateDatapoint(newDatapoint);

            if (result?.status != StatusCodes.OK) {
                showError();
                setIsLoading(false);
                return;
            }

            onCloseRequested();
        } catch {
            showError();
        } finally {
            setIsLoading(false);
        }
    };

    const showError = () => {
        toast.error(t('errors.errorWhileSendingValue'));
    };

    const getVirtualDevice = (id: number) => {
        return setupInfo?.objects?.items?.find((x) => x.id == id);
    };

    const onValueChanged = (index: number, functionBlock: AstroFunctionBlock, value: string, type: AstroType) => {
        const newFunctionBlocks = functionBlocks?.map((x) =>
            x === functionBlock ? changeFunctionBlockValue(functionBlock, value, type) : x,
        );

        setFunctionBlocks(newFunctionBlocks);
    };

    const changeFunctionBlockValue = (
        functionBlock: AstroFunctionBlock,
        value: string,
        type: AstroType,
    ): AstroFunctionBlock => {
        switch (type) {
            case AstroType.Sunrise:
                return { ...functionBlock, Sunrise: value };
            case AstroType.Sunset:
                return { ...functionBlock, Sunset: value };
            case AstroType.Dawn:
                return { ...functionBlock, Dawn: value };
            case AstroType.Twilight:
                return { ...functionBlock, Twilight: value };
        }
    };

    const getFunctionBlockAstroTypeValue = (functionBlock: AstroFunctionBlock, type: AstroType) => {
        switch (type) {
            case AstroType.Sunrise:
                return functionBlock.Sunrise;
            case AstroType.Sunset:
                return functionBlock.Sunset;
            case AstroType.Dawn:
                return functionBlock.Dawn;
            case AstroType.Twilight:
                return functionBlock.Twilight;
        }
    };

    const getOptions = (point: AstroFunctionBlock) => {
        if (point.Type === VirtualDeviceType.LightController) {
            return [
                ignore,
                ...(setupInfo?.objects?.items
                    ?.find((x) => x.id === point.ObjectID)
                    ?.datapoints?.find((x) => x.type === DatapointType.ScenesLight)
                    ?.Scenes?.map((x) => ({ label: x.Name, value: x.Name })) ?? []),
            ];
        }

        if (point.Type === VirtualDeviceType.Blinds) {
            return [
                ignore,
                { label: t('centralTimerProgramEdit.completelyUp'), value: 'CompletelyUp' },
                { label: t('centralTimerProgramEdit.completelyDown'), value: 'CompletelyDown' },
                { label: t('centralTimerProgramEdit.shadowing'), value: 'Shadowing' },
            ];
        }

        if (point.Type === VirtualDeviceType.PresenceSimulation) {
            return [
                ignore,
                { label: t('centralTimerProgramEdit.activate'), value: 'Activate' },
                { label: t('centralTimerProgramEdit.disabled'), value: 'Disabled' },
            ];
        }

        if (point.Type === VirtualDeviceType.BurglarAlarm) {
            return [
                ignore,
                { label: t('centralTimerProgramEdit.activate'), value: 'Activate' },
                { label: t('centralTimerProgramEdit.activateDelayed'), value: 'ActivateDelayed' },
                { label: t('centralTimerProgramEdit.activateWithoutPresence'), value: 'ActivateWithoutPresence' },
                { label: t('centralTimerProgramEdit.disabled'), value: 'Disabled' },
            ];
        }

        if (
            point.Type === VirtualDeviceType.Schedule ||
            point.Type === VirtualDeviceType.ToiletVentilationController ||
            point.Type === VirtualDeviceType.StaircaseTimer ||
            point.Type === VirtualDeviceType.PushButton ||
            point.Type === VirtualDeviceType.PushSwitch ||
            point.Type === VirtualDeviceType.Switch
        ) {
            return [ignore, { label: t('general.off'), value: 'Off' }, { label: t('general.on'), value: 'On' }];
        }

        if (point.Type === VirtualDeviceType.Dimmer) {
            return [ignore, { label: '0-100%', value: '0' }];
        }

        return [];
    };

    return (
        <Modal
            title={virtualDevice.name}
            open={true}
            onCancel={onClose}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{
                loading: isLoading,
            }}
            okText={t('general.save')}
            onOk={onSave}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            width="80vw"
        >
            <div className={styles.mainContainer}>
                <Space size="middle" className={styles.positionContainer}>
                    <HomeOutlined style={{ fontSize: 20 }} />
                    <div>
                        {label}
                        {`, Latitude: ${Number(currentPosition.lat).toFixed(4)}, Longitude: ${Number(
                            currentPosition.lng,
                        ).toFixed(4)}`}
                    </div>

                    <Button onClick={() => setMapVisible(true)}>{t('general.change')}</Button>
                </Space>
                <div className={styles.tabs}>
                    <Tabs
                        activeKey={activeTab}
                        onChange={onTabChanged}
                        tabBarExtraContent={{
                            left: (
                                <Button
                                    onClick={(e) => {
                                        onTabChanged(Number(activeTab) <= 0 ? '0' : String(Number(activeTab) - 1));
                                        e.currentTarget.blur();
                                    }}
                                    className={styles.leftArrow}
                                >
                                    <LeftOutlined />
                                </Button>
                            ),
                            right: (
                                <Button
                                    onClick={(e) => {
                                        onTabChanged(
                                            Number(activeTab) >= astroTypes.length - 1
                                                ? String(astroTypes.length - 1)
                                                : String(Number(activeTab) + 1),
                                        );
                                        e.currentTarget.blur();
                                    }}
                                    className={styles.leftArrow}
                                >
                                    <RightOutlined />
                                </Button>
                            ),
                        }}
                        type="editable-card"
                        style={{ width: '100%' }}
                        hideAdd={true}
                        items={astroTypes.map((type, tpIndex) => ({
                            label: getAstroName(type),
                            key: tpIndex.toString(),
                            closable: false,
                            children: (
                                <div className={styles.functionBlocksContainer}>
                                    {functionBlocks &&
                                        functionBlocks.map((functionBlock, index) => (
                                            <div key={index}>
                                                {functionBlock.Type === VirtualDeviceType.Dimmer &&
                                                    getVirtualDevice(functionBlock.ObjectID) && (
                                                        <div>
                                                            <div className={styles.dimmerTitle}>
                                                                {getVirtualDevice(functionBlock.ObjectID)?.name}
                                                            </div>
                                                            <Select
                                                                menuPlacement="auto"
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    menuPortal: (base) => ({
                                                                        ...base,
                                                                        zIndex: 99999,
                                                                        fontSize: 14,
                                                                    }),
                                                                }}
                                                                onChange={(value) =>
                                                                    value &&
                                                                    onValueChanged(
                                                                        tpIndex,
                                                                        functionBlock,
                                                                        value?.value === ignore.value
                                                                            ? ignore.value
                                                                            : (
                                                                                  getOptions(functionBlock)
                                                                                      .map((x) => x.value)
                                                                                      .indexOf(value.value) - 1
                                                                              ).toString(),
                                                                        type,
                                                                    )
                                                                }
                                                                value={
                                                                    getFunctionBlockAstroTypeValue(
                                                                        functionBlock,
                                                                        type,
                                                                    ) === ignore.value
                                                                        ? getOptions(functionBlock)[0]
                                                                        : getOptions(functionBlock)[1]
                                                                }
                                                                className={styles.sceneSelect}
                                                                options={getOptions(functionBlock) ?? []}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary: '#a1a1a1',
                                                                    },
                                                                })}
                                                            />
                                                            {getFunctionBlockAstroTypeValue(functionBlock, type) !==
                                                                ignore.value && (
                                                                <div className={styles.rangeContainer}>
                                                                    <CustomRange
                                                                        step={1}
                                                                        min={0}
                                                                        max={100}
                                                                        value={
                                                                            !isEmpty(
                                                                                getFunctionBlockAstroTypeValue(
                                                                                    functionBlock,
                                                                                    type,
                                                                                ),
                                                                            ) &&
                                                                            getFunctionBlockAstroTypeValue(
                                                                                functionBlock,
                                                                                type,
                                                                            ) !== ignore.value
                                                                                ? +getFunctionBlockAstroTypeValue(
                                                                                      functionBlock,
                                                                                      type,
                                                                                  )
                                                                                : 0
                                                                        }
                                                                        minTrackColor={category?.color ?? '#e0e0e0'}
                                                                        onValueChanged={(value) =>
                                                                            onValueChanged(
                                                                                tpIndex,
                                                                                functionBlock,
                                                                                value.toString(),
                                                                                type,
                                                                            )
                                                                        }
                                                                    />
                                                                    <div className={styles.rangeValue}>
                                                                        {(!isEmpty(
                                                                            getFunctionBlockAstroTypeValue(
                                                                                functionBlock,
                                                                                type,
                                                                            ),
                                                                        ) &&
                                                                        getFunctionBlockAstroTypeValue(
                                                                            functionBlock,
                                                                            type,
                                                                        ) !== ignore.value
                                                                            ? +getFunctionBlockAstroTypeValue(
                                                                                  functionBlock,
                                                                                  type,
                                                                              )
                                                                            : 0
                                                                        ).toString() + '%'}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                {(functionBlock.Type === VirtualDeviceType.LightController ||
                                                    functionBlock.Type === VirtualDeviceType.Blinds ||
                                                    functionBlock.Type === VirtualDeviceType.StaircaseTimer ||
                                                    functionBlock.Type ===
                                                        VirtualDeviceType.ToiletVentilationController ||
                                                    functionBlock.Type === VirtualDeviceType.Schedule ||
                                                    functionBlock.Type === VirtualDeviceType.BurglarAlarm ||
                                                    functionBlock.Type === VirtualDeviceType.PresenceSimulation ||
                                                    functionBlock.Type === VirtualDeviceType.PushButton ||
                                                    functionBlock.Type === VirtualDeviceType.PushSwitch ||
                                                    functionBlock.Type === VirtualDeviceType.Switch) &&
                                                    getVirtualDevice(functionBlock.ObjectID) && (
                                                        <div className={styles.config}>
                                                            {getVirtualDevice(functionBlock.ObjectID)?.name}
                                                            <Select
                                                                menuPlacement="auto"
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    menuPortal: (base) => ({
                                                                        ...base,
                                                                        zIndex: 99999,
                                                                        fontSize: 14,
                                                                    }),
                                                                }}
                                                                onChange={(value) =>
                                                                    value &&
                                                                    onValueChanged(
                                                                        tpIndex,
                                                                        functionBlock,
                                                                        functionBlock.Type ===
                                                                            VirtualDeviceType.LightController
                                                                            ? value?.value === ignore.value
                                                                                ? ignore.value
                                                                                : (
                                                                                      getOptions(functionBlock)
                                                                                          .map((x) => x.value)
                                                                                          .indexOf(value.value) - 1
                                                                                  ).toString()
                                                                            : value.value,
                                                                        type,
                                                                    )
                                                                }
                                                                value={
                                                                    getFunctionBlockAstroTypeValue(
                                                                        functionBlock,
                                                                        type,
                                                                    ) === ignore.value
                                                                        ? getOptions(functionBlock)[0]
                                                                        : !isNaN(
                                                                              Number(
                                                                                  getFunctionBlockAstroTypeValue(
                                                                                      functionBlock,
                                                                                      type,
                                                                                  ),
                                                                              ),
                                                                          )
                                                                        ? getOptions(functionBlock)[
                                                                              +getFunctionBlockAstroTypeValue(
                                                                                  functionBlock,
                                                                                  type,
                                                                              ) + 1
                                                                          ]
                                                                        : getOptions(functionBlock).find(
                                                                              (x) =>
                                                                                  x.value ===
                                                                                  getFunctionBlockAstroTypeValue(
                                                                                      functionBlock,
                                                                                      type,
                                                                                  ),
                                                                          )
                                                                }
                                                                className={styles.sceneSelect}
                                                                options={getOptions(functionBlock) ?? []}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary: '#a1a1a1',
                                                                    },
                                                                })}
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                        ))}
                                </div>
                            ),
                        }))}
                    />
                </div>
            </div>
            {mapVisible && (
                <AstroMap
                    position={currentPosition}
                    onSave={(position) => {
                        setCurrentPosition(position);
                        setMapVisible(false);
                    }}
                    onCloseRequested={() => setMapVisible(false)}
                />
            )}
            {yesNoModalVisible && (
                <YesNoModal
                    onYesClicked={yesNoModalVisible.onYesClicked}
                    onNoClicked={yesNoModalVisible.onNoClicked}
                    description={t('timeProgramEdit.changesNotSaved')}
                    isVisible={true}
                />
            )}
        </Modal>
    );
};

export default AstroEditModal;
