import styles from './AlarmClock.module.scss';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { pushButtonDatapoint, updateDatapointValue } from '../../../../helpers/HttpMethods';
import { DatapointNames } from '../../../../models/enums/DatapointNames';
import {
    getBooleanDatapointValue,
    getNumberDatapointValue,
    getValueValidatedByRange,
} from '../../../../helpers/DatapointHelper';
import { ControllersProps } from '../ControllersProps';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CustomButton from '../components/custom-button/CustomButton';
import { useState } from 'react';
import CustomRange from '../components/custom-slider/CustomRange';
import { toast } from 'react-toastify';
import { StatusCodes } from 'http-status-codes';
import { Datapoint } from '../../../../models/Datapoint';
import ControlHeader from '../components/control-header/ControlHeader';
import AlarmClockProgramEditModal from '../../../../components/alarm-clock-program-edit-modal/AlarmClockProgramEditModal';

const AlarmClock = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room, order, forceShowAll } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const isAlarmDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualIsAlarm,
    );
    const isSnoozeDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualIsSnooze,
    );
    const snoozeIntervalDurationDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualSnoozeIntervalDuration,
    );
    const quiteAlarmDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualQuitAlarmClock,
    );
    const startSnoozeDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualStartSnoozeTimer,
    );
    const timeProgramDatapoint = virtualDevice?.datapoints?.find((x) => x.type == DatapointType.TimeProgram);

    const [isTimeProgramConfigModalOpen, setIsTimeProgramConfigModalOpen] = useState<boolean>(false);
    const [datapointsVisible, setDatapointsVisible] = useState<boolean>(false);

    const onDatapointValueChanged = async (value: boolean | number | string, datapoint?: Datapoint) => {
        if (datapoint) {
            const result = await updateDatapointValue(datapoint, value);
            if (result.status !== StatusCodes.OK) {
                toast.error(t('errors.errorWhileSendingValue'));
                return;
            }
        }
    };

    return (
        <div className={styles.mainContainer} style={{ zIndex: order, border: border }}>
            <ControlHeader
                onEditButtonClick={() => setIsTimeProgramConfigModalOpen(true)}
                virtualDevice={virtualDevice}
                room={room}
            />
            {isAlarmDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('alarmClock.isAlarm')}</div>
                    <div>{getBooleanDatapointValue(isAlarmDatapoint) ? t('general.on') : t('general.off')}</div>
                </div>
            )}
            {isSnoozeDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('alarmClock.snooze')}</div>
                    <div>{getBooleanDatapointValue(isSnoozeDatapoint) ? t('general.on') : t('general.off')}</div>
                </div>
            )}
            {snoozeIntervalDurationDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('alarmClock.snoozeIntervalDuration')}</div>
                    <div>{`${getNumberDatapointValue(snoozeIntervalDurationDatapoint)} min`}</div>
                </div>
            )}
            {forceShowAll && (
                <div className={styles.forcedButtons}>
                    {snoozeIntervalDurationDatapoint && (
                        <div className={styles.sliderDatapoint}>
                            <CustomRange
                                step={1}
                                min={Number(snoozeIntervalDurationDatapoint?.range[0] ?? 0)}
                                max={Number(snoozeIntervalDurationDatapoint?.range[1] ?? 30)}
                                value={getValueValidatedByRange(snoozeIntervalDurationDatapoint)}
                                minTrackColor={category?.color ?? '#e0e0e0'}
                                onValueChanged={(value) =>
                                    onDatapointValueChanged(value, snoozeIntervalDurationDatapoint)
                                }
                            />
                        </div>
                    )}
                    {quiteAlarmDatapoint && (
                        <CustomButton
                            className={styles.button}
                            onClick={() => pushButtonDatapoint(quiteAlarmDatapoint)}
                            title={t('alarmClock.quiteAlarm')}
                        />
                    )}
                    {startSnoozeDatapoint && (
                        <CustomButton
                            className={styles.button}
                            onClick={() => pushButtonDatapoint(startSnoozeDatapoint)}
                            title={t('alarmClock.startSnoozeTimer')}
                        />
                    )}
                </div>
            )}
            {!forceShowAll && (
                <div
                    className={styles.datapointsContainer}
                    onMouseEnter={() => {
                        setDatapointsVisible(true);
                    }}
                    onMouseLeave={() => setDatapointsVisible(false)}
                >
                    <div className={styles.dots}>
                        <span className={styles.dot} />
                        <span className={styles.dot} />
                        <span className={styles.dot} />
                    </div>
                    {datapointsVisible && (
                        <div
                            className={styles.datapointsList}
                            style={{ zIndex: order, borderBottom: border, borderLeft: border, borderRight: border }}
                        >
                            {snoozeIntervalDurationDatapoint && (
                                <div className={styles.sliderDatapoint}>
                                    <CustomRange
                                        step={1}
                                        min={Number(snoozeIntervalDurationDatapoint?.range[0] ?? 0)}
                                        max={Number(snoozeIntervalDurationDatapoint?.range[1] ?? 30)}
                                        value={getValueValidatedByRange(snoozeIntervalDurationDatapoint)}
                                        minTrackColor={category?.color ?? '#e0e0e0'}
                                        onValueChanged={(value) =>
                                            onDatapointValueChanged(value, snoozeIntervalDurationDatapoint)
                                        }
                                    />
                                </div>
                            )}
                            {quiteAlarmDatapoint && (
                                <CustomButton
                                    className={styles.button}
                                    onClick={() => pushButtonDatapoint(quiteAlarmDatapoint)}
                                    title={t('alarmClock.quiteAlarm')}
                                />
                            )}
                            {startSnoozeDatapoint && (
                                <CustomButton
                                    className={styles.button}
                                    onClick={() => pushButtonDatapoint(startSnoozeDatapoint)}
                                    title={t('alarmClock.startSnoozeTimer')}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
            {timeProgramDatapoint && isTimeProgramConfigModalOpen && (
                <AlarmClockProgramEditModal
                    timeProgramDatapoint={timeProgramDatapoint}
                    virtualDevice={virtualDevice}
                    closeModalRequested={() => setIsTimeProgramConfigModalOpen(false)}
                />
            )}
        </div>
    );
};

export default AlarmClock;
