export enum Cookies {
    UserCredential = 'x-local-csw',
    CloudCredential = 'x-cloud-new',
    CloudUnsavedCredential = 'cloudUnsavedCredential',
    SelectedTab = 'selectedTab',
    SelectedFloor = 'selectedFloor',
    SelectedPageAfterLogin = 'selectedPageAfterLogin',
    ControlsSortingMode = 'controlsSortingMode',
    Theme = 'theme',
}
