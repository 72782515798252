import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import {
    getSetupInfo,
    getToken,
    setDatapointValue,
    setLastWebsocketMessage,
    setMonitorValue,
    setSetupInfo,
    setVirtualDeviceProps,
} from '../../app/globalSettings';
import { useAppSelector } from '../../app/hooks';
import { isRelease } from '../../env.json';
import { defaultServer, fetchSetupInfo, isDemo } from '../../helpers/HttpMethods';
import { WebsocketMessage } from '../../models/WebsocketMessage';
import { DatapointType } from '../../models/enums/DatapointType';
import { VirtualDeviceType } from '../../models/enums/VirtualDeviceType';

const WebsocketUpdater = (): JSX.Element => {
    const token = useSelector(getToken);
    const setupInfo = useAppSelector(getSetupInfo);
    const dispatch = useDispatch();
    const hostname = window.location.hostname.includes('localhost') ? defaultServer : window.location.hostname;
    const [initialized, setInitialized] = useState(false);
    const [currentToken, setCurrentToken] = useState(token);

    const onWebsocketClosed = async (e: CloseEvent) => {
        const result = await fetchSetupInfo();

        if (!isRelease) {
            console.log(e, result);
        }

        if (result?.token?.token) {
            setCurrentToken(result.token.token);
        }
    };

    const { lastJsonMessage, sendJsonMessage, readyState } = useWebSocket(
        currentToken && !isDemo ? `ws://${hostname}/ws?token=${currentToken}` : null,
        {
            onClose: onWebsocketClosed,
            reconnectAttempts: 5,
        },
    );

    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            return;
        }
        setCurrentToken(token);
    }, [token]);

    useEffect(() => {
        if (setupInfo && setupInfo.objects && !initialized) {
            setInitialized(true);
        }
    }, [setupInfo]);

    useEffect(() => {
        const lastMessage = lastJsonMessage as WebsocketMessage;
        if (!lastMessage) {
            return;
        }

        if (!isRelease) {
            console.log(lastMessage);
        }

        dispatch(setLastWebsocketMessage(lastMessage));

        switch (lastMessage.header) {
            case 'dpupdate':
                UpdateDatapointValue(lastMessage);
                break;
            case 'dpconfigupdate':
                UpdateDatapointValue(lastMessage);
                break;
            case 'deviceupdate':
            case 'deviceresponse':
                if (lastMessage.ioid && lastMessage.value) {
                    dispatch(setMonitorValue(lastMessage));
                }
                break;
            case 'configupdate':
                if (lastMessage?.payload?.access) {
                    if (lastMessage.api == 'objects') {
                        const object: { favourite: boolean; objId: number; ranking: number } =
                            lastMessage?.payload?.access?.[0];
                        dispatch(setVirtualDeviceProps(object));
                    }
                } else {
                    (async () => {
                        const result = await fetchSetupInfo();
                        dispatch(setSetupInfo(result));
                    })();
                }
                break;
        }
    }, [lastJsonMessage]);

    useEffect(() => {
        if (setupInfo && setupInfo.objects && !isDemo) {
            const ios: number[] = [];
            setupInfo.objects.items
                .filter((x) => x.type == VirtualDeviceType.Monitor)
                .forEach((x) => {
                    x.datapoints
                        .find((y) => y.type == DatapointType.MonitorConfig)
                        ?.Monitor?.forEach((z) => ios.push(z.IOid));
                });
            setupInfo.objects.items
                .filter((x) => x.type == VirtualDeviceType.FloorPlan)
                .forEach((x) =>
                    x.datapoints
                        .find((y) => y.type == DatapointType.FloorConfig)
                        ?.Floors.forEach((z) => z.Objects.filter((io) => io.IOID).forEach((io) => ios.push(io.IOID))),
                );
            setupInfo.objects.items
                .filter((x) => x.type == VirtualDeviceType.TotalEnergyMonitor)
                .forEach((x) =>
                    x.datapoints
                        .find((y) => y.type == DatapointType.CentralEnergyConfig)
                        ?.EnergyConfig?.IOConfigs.forEach((z) => ios.push(z.Id)),
                );
            sendJsonMessage({
                header: 'devicerequest',
                cmd: 'GET',
                cache: true,
                observe: true,
                ios: ios.filter((io, index, self) => index === self.indexOf(io)),
            });
        }
    }, [currentToken, initialized]);

    const UpdateDatapointValue = (message: WebsocketMessage): void => {
        dispatch(setDatapointValue(message));
    };

    return <></>;
};

export default WebsocketUpdater;
