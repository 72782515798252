import styles from './CentralBlinds.module.scss';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { pushButtonDatapoint } from '../../../../helpers/HttpMethods';
import { DatapointNames } from '../../../../models/enums/DatapointNames';
import { ControllersProps } from '../ControllersProps';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CustomButton from '../components/custom-button/CustomButton';
import DownArrowImg from '../../../../images/down_arrow.svg';
import ControlHeader from '../components/control-header/ControlHeader';

const CentralBlinds = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const triggerUpDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualTriggerUp,
    );
    const triggerDownDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualTriggerDown,
    );
    const stopDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualCentralBlindsStop,
    );
    const shadowingDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualShadowing,
    );

    return (
        <div className={styles.mainContainer} style={{ border: border }}>
            <ControlHeader virtualDevice={virtualDevice} room={room} />
            <div className={styles.datapointContainer}>
                <div className={styles.buttonsContainer}>
                    <div className={styles.moveButtons}>
                        {triggerUpDatapoint && (
                            <CustomButton
                                className={classNames(styles.button, styles.roundButton, styles.rotate)}
                                onClick={() => pushButtonDatapoint(triggerUpDatapoint)}
                                img={DownArrowImg}
                            />
                        )}
                        {triggerDownDatapoint && (
                            <CustomButton
                                className={classNames(styles.button, styles.roundButton)}
                                imgClassName={styles.buttonImg}
                                onClick={() => pushButtonDatapoint(triggerDownDatapoint)}
                                img={DownArrowImg}
                            />
                        )}
                    </div>
                    {stopDatapoint && (
                        <CustomButton
                            className={styles.button}
                            onClick={() => pushButtonDatapoint(stopDatapoint)}
                            title={t('gate.stop')}
                        />
                    )}
                    {shadowingDatapoint && (
                        <CustomButton
                            className={styles.button}
                            onClick={() => pushButtonDatapoint(shadowingDatapoint)}
                            title={t('blinds.shadeMode')}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default CentralBlinds;
