import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Select, Switch, Tabs } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getBooleanValue } from '../../helpers/DatapointHelper';
import { updateDatapoint } from '../../helpers/HttpMethods';
import { isEmpty } from '../../helpers/StringHelper';
import CustomRange from '../../main-page/main-controls/controls/components/custom-slider/CustomRange';
import { Datapoint } from '../../models/Datapoint';
import { OpeartionModeConfigType, OperationMode } from '../../models/OperationModeConfig';
import { VirtualDevice } from '../../models/VirtualDevice';
import { VirtualDeviceCategorySettings } from '../../models/constants/VirtualDeviceCategorySettings';
import YesNoModal from '../yes-no-modal/YesNoModal';
import styles from './OperationModeConfigurationModal.module.scss';

interface Props {
    onCloseRequested: () => void;
    virtualDevice: VirtualDevice;
    datapoint: Datapoint;
}

const OperationModeConfigurationModal = (props: Props): JSX.Element => {
    const { virtualDevice, datapoint, onCloseRequested } = props;
    const { t } = useTranslation();
    const category = useMemo(
        () => VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category),
        [virtualDevice],
    );

    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState<string>('0');
    const [modes, setModes] = useState(datapoint?.Modes ?? []);
    const [yesNoModalVisible, setYesNoModalVisible] = useState<{ onYesClicked: () => void; onNoClicked: () => void }>();

    const isEdited = datapoint?.Modes !== modes;

    const onTabChanged = (key: string) => {
        setActiveTab(key);
    };

    const onClose = () => {
        if (isEdited) {
            setYesNoModalVisible({
                onYesClicked: onCloseRequested,
                onNoClicked: () => setYesNoModalVisible(undefined),
            });
            return;
        }

        onCloseRequested();
    };

    const onSave = async () => {
        try {
            setIsLoading(true);
            const newDatapoint: Datapoint = {
                ...datapoint,
                Modes: modes,
            };

            const result = await updateDatapoint(newDatapoint);

            if (result?.status != StatusCodes.OK) {
                showError();
                setIsLoading(false);
                return;
            }

            onCloseRequested();
        } catch {
            showError();
        } finally {
            setIsLoading(false);
        }
    };

    const showError = () => {
        toast.error(t('errors.errorWhileSendingValue'));
    };

    const getConfigForName = (name: string) => {
        return datapoint.Configs?.find((x) => x.Name === name);
    };

    const setModeValues = (
        index: number,
        lights: {
            Name: string;
            Value: string | boolean;
        }[],
    ) => {
        setModes(
            modes.map((x) =>
                modes.indexOf(x) === index
                    ? {
                          ...x,
                          values: lights,
                      }
                    : x,
            ),
        );
    };

    const modeValueChanged = (index: number, valueName: string, value: number | boolean) => {
        let newValue: string | boolean = value.toString();

        if (typeof value === 'boolean') {
            newValue = value;
        }

        setModeValues(
            index,
            modes[index].values.map((x) =>
                x.Name == valueName
                    ? {
                          ...x,
                          Value: newValue,
                      }
                    : x,
            ),
        );
    };

    const getNewModeName = () => {
        const newSceneTranslation = t('sceneEdit.newMode');
        if (!modes.find((x) => x.Name == newSceneTranslation)) {
            return newSceneTranslation;
        }

        let i = 1;
        while (modes.find((x) => x.Name == `${newSceneTranslation} ${i}`)) {
            i++;
        }

        return `${newSceneTranslation} ${i}`;
    };

    const getDefaultModeValue = (valueName: string) => {
        const config = getConfigForName(valueName);

        switch (config?.Type) {
            case OpeartionModeConfigType.Switch:
                return true;
            case OpeartionModeConfigType.Dropdown:
                return config.DropdownList[0].Value.toString();
            default:
                return '100';
        }
    };

    const addMode = async () => {
        const newMode: OperationMode = {
            ID: Math.max(...modes.map((x) => x.ID)) + 1,
            Name: getNewModeName(),
            values: modes[0].values.map((value) => ({ ...value, Value: getDefaultModeValue(value.Name) })),
        };

        const newModes = [...modes, newMode];

        setModes(newModes);
        setActiveTab((newModes.length - 1).toString());
    };

    const removeMode = async (index: number) => {
        const newModes = modes.filter((x) => x != modes[index]);

        if (newModes.length === 0) {
            return;
        }

        setModes(newModes);

        const activeTabNumber = Number(activeTab);
        if (activeTabNumber >= index) {
            const numberToSet = activeTabNumber === 0 ? 0 : activeTabNumber - 1;
            setActiveTab(String(numberToSet));
        }
    };

    return (
        <Modal
            title={virtualDevice.name}
            open={true}
            onCancel={onClose}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{
                loading: isLoading,
                disabled: !modes || modes.length === 0,
            }}
            okText={t('general.save')}
            onOk={onSave}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            width="80vw"
        >
            <div className={styles.tabs}>
                <Tabs
                    onEdit={(targetKey, action) => {
                        if (action === 'add') {
                            addMode();
                        } else {
                            removeMode(Number(targetKey));
                        }
                    }}
                    activeKey={activeTab}
                    onChange={onTabChanged}
                    tabBarExtraContent={{
                        left: (
                            <Button
                                onClick={(e) => {
                                    onTabChanged(Number(activeTab) <= 0 ? '0' : String(Number(activeTab) - 1));
                                    e.currentTarget.blur();
                                }}
                                className={styles.leftArrow}
                            >
                                <LeftOutlined />
                            </Button>
                        ),
                        right: (
                            <Button
                                onClick={(e) => {
                                    onTabChanged(
                                        Number(activeTab) >= modes.length - 1
                                            ? String(modes.length - 1)
                                            : String(Number(activeTab) + 1),
                                    );
                                    e.currentTarget.blur();
                                }}
                                className={styles.leftArrow}
                            >
                                <RightOutlined />
                            </Button>
                        ),
                    }}
                    type="editable-card"
                    style={{ width: '100%' }}
                    items={modes.map((mode, modeIndex) => ({
                        label: mode.Name,
                        key: modeIndex.toString(),
                        closable: modeIndex !== 0,
                        children: (
                            <div className={styles.tabContent}>
                                <Input
                                    status={
                                        modes
                                            .filter((x) => x !== modes[modeIndex])
                                            .find((y) => modes[modeIndex].Name === y.Name) || isEmpty(mode.Name)
                                            ? 'error'
                                            : undefined
                                    }
                                    onChange={(value) => {
                                        setModes(
                                            modes.map((x) =>
                                                x === modes[modeIndex]
                                                    ? {
                                                          ...modes[modeIndex],
                                                          Name: value.currentTarget.value,
                                                      }
                                                    : x,
                                            ),
                                        );
                                    }}
                                    value={mode.Name}
                                    placeholder={t('sceneEdit.sceneName')}
                                />
                                {modes.filter((x) => x !== mode).find((y) => mode.Name === y.Name) && (
                                    <div className={styles.nameError}>{t('errors.NameAlreadyExist')}</div>
                                )}
                                {isEmpty(mode.Name) && (
                                    <div className={styles.nameError}>{t('errors.fieldCannotBeEmpty')}</div>
                                )}
                                {mode.values.map((value, valueIndex) => (
                                    <div className={styles.lights} key={valueIndex}>
                                        {getConfigForName(value.Name)?.Type == OpeartionModeConfigType.Dimmer && (
                                            <div className={styles.sliderDatapoint}>
                                                <div className={styles.sliderDatapointTitle}>
                                                    <div>{getConfigForName(value.Name)?.Description}</div>
                                                    {`${
                                                        value?.Value &&
                                                        Number(value?.Value) >=
                                                            Number(Number(getConfigForName(value.Name)?.Minimum) ?? 0)
                                                            ? Number(value?.Value)
                                                            : Number(Number(getConfigForName(value.Name)?.Minimum) ?? 0)
                                                    } ${getConfigForName(value.Name)?.Unit ?? ''}`}
                                                </div>
                                                <CustomRange
                                                    step={Number(getConfigForName(value.Name)?.Step ?? 1)}
                                                    min={Number(getConfigForName(value.Name)?.Minimum ?? 0)}
                                                    max={Number(getConfigForName(value.Name)?.Maximum ?? 100)}
                                                    unit={getConfigForName(value.Name)?.Unit ?? ''}
                                                    value={
                                                        value?.Value &&
                                                        Number(value?.Value) >=
                                                            Number(Number(getConfigForName(value.Name)?.Minimum) ?? 0)
                                                            ? Number(value?.Value)
                                                            : Number(Number(getConfigForName(value.Name)?.Minimum) ?? 0)
                                                    }
                                                    minTrackColor={'#ffffff'}
                                                    onValueChanged={(newValue) =>
                                                        modeValueChanged(modeIndex, value.Name, newValue)
                                                    }
                                                />
                                            </div>
                                        )}
                                        {getConfigForName(value.Name)?.Type == OpeartionModeConfigType.Switch && (
                                            <div className={styles.switchDatapoint}>
                                                {getConfigForName(value.Name)?.Description}
                                                <Switch
                                                    onChange={(checked) =>
                                                        modeValueChanged(modeIndex, value.Name, checked)
                                                    }
                                                    checked={getBooleanValue(value.Value?.toString())}
                                                    style={{
                                                        backgroundColor: getBooleanValue(value?.Value?.toString())
                                                            ? category?.color
                                                            : 'rgba(0, 0, 0, 0.25)',
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {getConfigForName(value.Name)?.Type == OpeartionModeConfigType.Dropdown && (
                                            <div className={styles.switchDatapoint}>
                                                {getConfigForName(value.Name)?.Description}
                                                <Select
                                                    value={Number(value.Value)}
                                                    style={{
                                                        width: '20%',
                                                        minWidth: 120,
                                                        marginTop: 10,
                                                    }}
                                                    options={getConfigForName(value.Name)?.DropdownList.map((x) => ({
                                                        label: x.Name,
                                                        value: Number(x.Value),
                                                    }))}
                                                    onChange={(v) => modeValueChanged(modeIndex, value.Name, v)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ),
                    }))}
                />
            </div>
            {yesNoModalVisible && (
                <YesNoModal
                    onYesClicked={yesNoModalVisible.onYesClicked}
                    onNoClicked={yesNoModalVisible.onNoClicked}
                    description={t('timeProgramEdit.changesNotSaved')}
                    isVisible={true}
                />
            )}
        </Modal>
    );
};

export default OperationModeConfigurationModal;
