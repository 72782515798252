import Cookies from 'js-cookie';
import { useCallback, useState } from 'react';

const useCookie = <T>(name: string, defaultValue: T) => {
    const [value, setValue] = useState<T | null>(() => {
        const cookie = Cookies.get(name);

        if (cookie !== undefined) {
            try {
                return JSON.parse(atob(cookie));
            } catch {
                Cookies.set(name, btoa(JSON.stringify(defaultValue)));
                return defaultValue ?? '';
            }
        }

        Cookies.set(name, btoa(JSON.stringify(defaultValue)));
        return defaultValue ?? '';
    });

    const updateCookie = useCallback(
        (newValue: T, options?: Cookies.CookieAttributes) => {
            Cookies.set(name, btoa(JSON.stringify(newValue)), options);
            setValue(newValue);
        },
        [name],
    );

    const deleteCookie = useCallback(() => {
        Cookies.remove(name);
        setValue(null);
    }, [name]);

    return { value, updateCookie, deleteCookie };
};

export default useCookie;
