import { useMemo, useState } from 'react';
import CalendarTimeProgramEditModal from '../../../../components/calendar-time-program-edit-modal/CalendarTimeProgramEditModal';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { ControllersProps } from '../ControllersProps';
import GenericControls from '../generic-controls/GenericControls';

const Calendar = (props: ControllersProps): JSX.Element => {
    const { virtualDevice } = props;

    const timeProgramDatapoint = useMemo(
        () => virtualDevice?.datapoints?.find((x) => x.type == DatapointType.TimeProgram),
        [virtualDevice?.datapoints],
    );

    const [isTimeProgramConfigModalOpen, setIsTimeProgramConfigModalOpen] = useState<boolean>(false);

    return (
        <>
            <GenericControls onEditButtonClick={() => setIsTimeProgramConfigModalOpen(true)} {...props} />
            {timeProgramDatapoint && isTimeProgramConfigModalOpen && (
                <CalendarTimeProgramEditModal
                    timeProgramDatapoint={timeProgramDatapoint}
                    virtualDevice={virtualDevice}
                    closeModalRequested={() => setIsTimeProgramConfigModalOpen(false)}
                />
            )}
        </>
    );
};

export default Calendar;
