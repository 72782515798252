export enum HttpApi {
    Info = 'info',
    Bulk = 'bulk',
    Datapoint = 'datapoint',
    Camera = 'camera',
    Token = 'token',
    Objects = 'objects',
    Statistic = 'statistic',
    Datalog = 'datalog',
    UserGroups = 'usergroups',
    Users = 'users',
    Assignments = 'assignments',
    PropertyTemplates = 'propertytemplates',
    ObjectProperties = 'objectproperties',
    Update = 'update',
    Webimages = 'webimages',
    Systemnotification = 'systemnotification',
    Notification = 'notification',
    Netatmo = 'netatmo',
}
