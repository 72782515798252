import styles from './FireAndWaterAlarm.module.scss';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { pushButtonDatapoint } from '../../../../helpers/HttpMethods';
import { DatapointNames } from '../../../../models/enums/DatapointNames';
import { getBooleanDatapointValue } from '../../../../helpers/DatapointHelper';
import { ControllersProps } from '../ControllersProps';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CustomButton from '../components/custom-button/CustomButton';
import ControlHeader from '../components/control-header/ControlHeader';

const FireAndWaterAlarm = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const fireAlarmDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualFireAlarm,
    );
    const smokeAlarmDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualSmokeAlarm,
    );
    const waterAlarmDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualWaterAlarm,
    );
    const quiteAlarmButtonDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualQuitAlarm,
    );

    return (
        <div className={styles.mainContainer} style={{ border: border }}>
            <ControlHeader virtualDevice={virtualDevice} room={room} />
            {fireAlarmDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('fireAndWaterAlarm.fireAlarm')}</div>
                    <div>{getBooleanDatapointValue(fireAlarmDatapoint) ? t('general.on') : t('general.off')}</div>
                </div>
            )}
            {smokeAlarmDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('fireAndWaterAlarm.smokeAlarm')}</div>
                    <div>{getBooleanDatapointValue(smokeAlarmDatapoint) ? t('general.on') : t('general.off')}</div>
                </div>
            )}
            {waterAlarmDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('fireAndWaterAlarm.waterAlarm')}</div>
                    <div>{getBooleanDatapointValue(waterAlarmDatapoint) ? t('general.on') : t('general.off')}</div>
                </div>
            )}
            {quiteAlarmButtonDatapoint && (
                <div className={styles.button}>
                    <CustomButton
                        onClick={() => pushButtonDatapoint(quiteAlarmButtonDatapoint)}
                        title={t('fireAndWaterAlarm.quiteAlarm')}
                    />
                </div>
            )}
        </div>
    );
};

export default FireAndWaterAlarm;
