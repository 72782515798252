import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Modal, Tabs } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getSetupInfo } from '../../app/globalSettings';
import { useAppSelector } from '../../app/hooks';
import { updateDatapoint } from '../../helpers/HttpMethods';
import { isEmpty } from '../../helpers/StringHelper';
import CustomRange from '../../main-page/main-controls/controls/components/custom-slider/CustomRange';
import { CominghomeBlock } from '../../models/CominghomeBlock';
import { Datapoint } from '../../models/Datapoint';
import { VirtualDevice } from '../../models/VirtualDevice';
import { VirtualDeviceCategorySettings } from '../../models/constants/VirtualDeviceCategorySettings';
import { DatapointType } from '../../models/enums/DatapointType';
import { VirtualDeviceType } from '../../models/enums/VirtualDeviceType';
import YesNoModal from '../yes-no-modal/YesNoModal';
import styles from './HomeButtonsEditModal.module.scss';

interface Props {
    onCloseRequested: () => void;
    virtualDevice: VirtualDevice;
    datapoint: Datapoint;
}

enum HomeButtonsType {
    ComingHome,
    LeavingHome,
    Sleep,
}

const homeButtonsTypes = [HomeButtonsType.ComingHome, HomeButtonsType.LeavingHome, HomeButtonsType.Sleep];

const HomeButtonsEditModal = (props: Props): JSX.Element => {
    const { virtualDevice, datapoint, onCloseRequested } = props;
    const { t } = useTranslation();
    const setupInfo = useAppSelector(getSetupInfo);

    const ignore = { label: t('general.ignore'), value: 'Ignore' };
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);

    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState<string>('0');
    const [functionBlocks, setFunctionBlocks] = useState(datapoint?.CominghomeConfig?.Buttons);
    const [yesNoModalVisible, setYesNoModalVisible] = useState<{ onYesClicked: () => void; onNoClicked: () => void }>();

    const isEdited = datapoint?.CominghomeConfig?.Buttons !== functionBlocks;

    const getHomeButtonsName = (type: HomeButtonsType) => {
        switch (type) {
            case HomeButtonsType.ComingHome:
                return t('general.comingHome');
            case HomeButtonsType.LeavingHome:
                return t('general.leavingHome');
            case HomeButtonsType.Sleep:
                return t('general.sleep');
        }
    };

    const onTabChanged = (key: string) => {
        setActiveTab(key);
    };

    const onClose = () => {
        if (isEdited) {
            setYesNoModalVisible({
                onYesClicked: onCloseRequested,
                onNoClicked: () => setYesNoModalVisible(undefined),
            });
            return;
        }

        onCloseRequested();
    };

    const onSave = async () => {
        try {
            setIsLoading(true);
            const newDatapoint: Datapoint = {
                ...datapoint,
                CominghomeConfig: {
                    Buttons: functionBlocks,
                },
            };

            const result = await updateDatapoint(newDatapoint);

            if (result?.status != StatusCodes.OK) {
                showError();
                setIsLoading(false);
                return;
            }

            onCloseRequested();
        } catch {
            showError();
        } finally {
            setIsLoading(false);
        }
    };

    const showError = () => {
        toast.error(t('errors.errorWhileSendingValue'));
    };

    const getVirtualDevice = (id: number) => {
        return setupInfo?.objects?.items?.find((x) => x.id == id);
    };

    const onValueChanged = (index: number, functionBlock: CominghomeBlock, value: string, type: HomeButtonsType) => {
        const newFunctionBlocks = functionBlocks?.map((x) =>
            x === functionBlock ? changeFunctionBlockValue(functionBlock, value, type) : x,
        );

        setFunctionBlocks(newFunctionBlocks);
    };

    const changeFunctionBlockValue = (
        functionBlock: CominghomeBlock,
        value: string,
        type: HomeButtonsType,
    ): CominghomeBlock => {
        switch (type) {
            case HomeButtonsType.ComingHome:
                return { ...functionBlock, ComingHome: value };
            case HomeButtonsType.LeavingHome:
                return { ...functionBlock, LeavingHome: value };
            case HomeButtonsType.Sleep:
                return { ...functionBlock, Sleep: value };
        }
    };

    const getFunctionBlockTypeValue = (functionBlock: CominghomeBlock, type: HomeButtonsType) => {
        switch (type) {
            case HomeButtonsType.ComingHome:
                return functionBlock.ComingHome;
            case HomeButtonsType.LeavingHome:
                return functionBlock.LeavingHome;
            case HomeButtonsType.Sleep:
                return functionBlock.Sleep;
        }
    };

    const getOptions = (point: CominghomeBlock) => {
        if (point.Type === VirtualDeviceType.LightController) {
            return [
                ignore,
                ...(setupInfo?.objects?.items
                    ?.find((x) => x.id === point.ObjectID)
                    ?.datapoints?.find((x) => x.type === DatapointType.ScenesLight)
                    ?.Scenes?.map((x) => ({ label: x.Name, value: x.Name })) ?? []),
            ];
        }

        if (point.Type === VirtualDeviceType.Blinds) {
            return [
                ignore,
                { label: t('centralTimerProgramEdit.completelyUp'), value: 'CompletelyUp' },
                { label: t('centralTimerProgramEdit.completelyDown'), value: 'CompletelyDown' },
                { label: t('centralTimerProgramEdit.shadowing'), value: 'Shadowing' },
            ];
        }

        if (point.Type === VirtualDeviceType.PresenceSimulation) {
            return [
                ignore,
                { label: t('centralTimerProgramEdit.activate'), value: 'Activate' },
                { label: t('centralTimerProgramEdit.disabled'), value: 'Disabled' },
            ];
        }

        if (point.Type === VirtualDeviceType.BurglarAlarm) {
            return [
                ignore,
                { label: t('centralTimerProgramEdit.activate'), value: 'Activate' },
                { label: t('centralTimerProgramEdit.activateDelayed'), value: 'ActivateDelayed' },
                { label: t('centralTimerProgramEdit.activateWithoutPresence'), value: 'ActivateWithoutPresence' },
                { label: t('centralTimerProgramEdit.disabled'), value: 'Disabled' },
            ];
        }

        if (point.Type === VirtualDeviceType.GarageGate) {
            return [
                ignore,
                { label: t('general.open'), value: 'Open' },
                { label: t('general.close'), value: 'Close' },
                { label: t('centralTimerProgramEdit.closeWithDelay'), value: 'CloseDelay' },
            ];
        }

        if (
            point.Type === VirtualDeviceType.Schedule ||
            point.Type === VirtualDeviceType.ToiletVentilationController ||
            point.Type === VirtualDeviceType.StaircaseTimer ||
            point.Type === VirtualDeviceType.PushButton ||
            point.Type === VirtualDeviceType.PushSwitch ||
            point.Type === VirtualDeviceType.Switch
        ) {
            return [ignore, { label: t('general.off'), value: 'Off' }, { label: t('general.on'), value: 'On' }];
        }

        if (point.Type === VirtualDeviceType.Dimmer) {
            return [ignore, { label: '0-100%', value: '0' }];
        }

        return [];
    };

    return (
        <Modal
            title={virtualDevice.name}
            open={true}
            onCancel={onClose}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{
                loading: isLoading,
                disabled: !functionBlocks || functionBlocks.length === 0,
            }}
            okText={t('general.save')}
            onOk={onSave}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            width="80vw"
        >
            <div className={styles.tabs}>
                <Tabs
                    activeKey={activeTab}
                    onChange={onTabChanged}
                    tabBarExtraContent={{
                        left: (
                            <Button
                                onClick={(e) => {
                                    onTabChanged(Number(activeTab) <= 0 ? '0' : String(Number(activeTab) - 1));
                                    e.currentTarget.blur();
                                }}
                                className={styles.leftArrow}
                            >
                                <LeftOutlined />
                            </Button>
                        ),
                        right: (
                            <Button
                                onClick={(e) => {
                                    onTabChanged(
                                        Number(activeTab) >= homeButtonsTypes.length - 1
                                            ? String(homeButtonsTypes.length - 1)
                                            : String(Number(activeTab) + 1),
                                    );
                                    e.currentTarget.blur();
                                }}
                                className={styles.leftArrow}
                            >
                                <RightOutlined />
                            </Button>
                        ),
                    }}
                    type="editable-card"
                    style={{ width: '100%' }}
                    hideAdd={true}
                    items={homeButtonsTypes.map((type, tpIndex) => ({
                        label: getHomeButtonsName(type),
                        key: tpIndex.toString(),
                        closable: false,
                        children: (
                            <div className={styles.functionBlocksContainer}>
                                {functionBlocks &&
                                    functionBlocks.map((functionBlock, index) => (
                                        <div key={index}>
                                            {functionBlock.Type === VirtualDeviceType.Dimmer &&
                                                getVirtualDevice(functionBlock.ObjectID) && (
                                                    <div>
                                                        <div className={styles.dimmerTitle}>
                                                            {getVirtualDevice(functionBlock.ObjectID)?.name}
                                                        </div>
                                                        <Select
                                                            menuPlacement="auto"
                                                            menuPortalTarget={document.body}
                                                            styles={{
                                                                menuPortal: (base) => ({
                                                                    ...base,
                                                                    zIndex: 99999,
                                                                    fontSize: 14,
                                                                }),
                                                            }}
                                                            onChange={(value) =>
                                                                value &&
                                                                onValueChanged(
                                                                    tpIndex,
                                                                    functionBlock,
                                                                    value?.value === ignore.value
                                                                        ? ignore.value
                                                                        : (
                                                                              getOptions(functionBlock)
                                                                                  .map((x) => x.value)
                                                                                  .indexOf(value.value) - 1
                                                                          ).toString(),
                                                                    type,
                                                                )
                                                            }
                                                            value={
                                                                getFunctionBlockTypeValue(functionBlock, type) ===
                                                                ignore.value
                                                                    ? getOptions(functionBlock)[0]
                                                                    : getOptions(functionBlock)[1]
                                                            }
                                                            className={styles.sceneSelect}
                                                            options={getOptions(functionBlock) ?? []}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary: '#a1a1a1',
                                                                },
                                                            })}
                                                        />
                                                        {getFunctionBlockTypeValue(functionBlock, type) !==
                                                            ignore.value && (
                                                            <div className={styles.rangeContainer}>
                                                                <CustomRange
                                                                    step={1}
                                                                    min={0}
                                                                    max={100}
                                                                    value={
                                                                        !isEmpty(
                                                                            getFunctionBlockTypeValue(
                                                                                functionBlock,
                                                                                type,
                                                                            ),
                                                                        ) &&
                                                                        getFunctionBlockTypeValue(
                                                                            functionBlock,
                                                                            type,
                                                                        ) !== ignore.value
                                                                            ? +getFunctionBlockTypeValue(
                                                                                  functionBlock,
                                                                                  type,
                                                                              )
                                                                            : 0
                                                                    }
                                                                    minTrackColor={category?.color ?? '#e0e0e0'}
                                                                    onValueChanged={(value) =>
                                                                        onValueChanged(
                                                                            tpIndex,
                                                                            functionBlock,
                                                                            value.toString(),
                                                                            type,
                                                                        )
                                                                    }
                                                                />
                                                                <div className={styles.rangeValue}>
                                                                    {(!isEmpty(
                                                                        getFunctionBlockTypeValue(functionBlock, type),
                                                                    ) &&
                                                                    getFunctionBlockTypeValue(functionBlock, type) !==
                                                                        ignore.value
                                                                        ? +getFunctionBlockTypeValue(
                                                                              functionBlock,
                                                                              type,
                                                                          )
                                                                        : 0
                                                                    ).toString() + '%'}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            {(functionBlock.Type === VirtualDeviceType.LightController ||
                                                functionBlock.Type === VirtualDeviceType.BurglarAlarm ||
                                                functionBlock.Type === VirtualDeviceType.GarageGate ||
                                                functionBlock.Type === VirtualDeviceType.PresenceSimulation ||
                                                functionBlock.Type === VirtualDeviceType.Blinds ||
                                                functionBlock.Type === VirtualDeviceType.StaircaseTimer ||
                                                functionBlock.Type === VirtualDeviceType.ToiletVentilationController ||
                                                functionBlock.Type === VirtualDeviceType.Schedule ||
                                                functionBlock.Type === VirtualDeviceType.PushButton ||
                                                functionBlock.Type === VirtualDeviceType.PushSwitch ||
                                                functionBlock.Type === VirtualDeviceType.Switch) &&
                                                getVirtualDevice(functionBlock.ObjectID) && (
                                                    <div className={styles.config}>
                                                        {getVirtualDevice(functionBlock.ObjectID)?.name}
                                                        <Select
                                                            menuPlacement="auto"
                                                            menuPortalTarget={document.body}
                                                            styles={{
                                                                menuPortal: (base) => ({
                                                                    ...base,
                                                                    zIndex: 99999,
                                                                    fontSize: 14,
                                                                }),
                                                            }}
                                                            onChange={(value) =>
                                                                value &&
                                                                onValueChanged(
                                                                    tpIndex,
                                                                    functionBlock,
                                                                    functionBlock.Type ===
                                                                        VirtualDeviceType.LightController
                                                                        ? value?.value === ignore.value
                                                                            ? ignore.value
                                                                            : (
                                                                                  getOptions(functionBlock)
                                                                                      .map((x) => x.value)
                                                                                      .indexOf(value.value) - 1
                                                                              ).toString()
                                                                        : value.value,
                                                                    type,
                                                                )
                                                            }
                                                            value={
                                                                getFunctionBlockTypeValue(functionBlock, type) ===
                                                                ignore.value
                                                                    ? getOptions(functionBlock)[0]
                                                                    : !isNaN(
                                                                          Number(
                                                                              getFunctionBlockTypeValue(
                                                                                  functionBlock,
                                                                                  type,
                                                                              ),
                                                                          ),
                                                                      )
                                                                    ? getOptions(functionBlock)[
                                                                          +getFunctionBlockTypeValue(
                                                                              functionBlock,
                                                                              type,
                                                                          ) + 1
                                                                      ]
                                                                    : getOptions(functionBlock).find(
                                                                          (x) =>
                                                                              x.value ===
                                                                              getFunctionBlockTypeValue(
                                                                                  functionBlock,
                                                                                  type,
                                                                              ),
                                                                      )
                                                            }
                                                            className={styles.sceneSelect}
                                                            options={getOptions(functionBlock) ?? []}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary: '#a1a1a1',
                                                                },
                                                            })}
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    ))}
                            </div>
                        ),
                    }))}
                />
            </div>
            {yesNoModalVisible && (
                <YesNoModal
                    onYesClicked={yesNoModalVisible.onYesClicked}
                    onNoClicked={yesNoModalVisible.onNoClicked}
                    description={t('timeProgramEdit.changesNotSaved')}
                    isVisible={true}
                />
            )}
        </Modal>
    );
};

export default HomeButtonsEditModal;
