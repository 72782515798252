import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { setDemo } from '../helpers/HttpMethods';
import { Routings } from '../models/enums/Routings';

const CustomDemo = (): JSX.Element => {
    const history = useHistory();
    useEffect(() => {
        setDemo(true, true);
        history.replace(Routings.MainPage);
    }, []);
    return <></>;
};

export default CustomDemo;
