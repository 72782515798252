import { Modal } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './YesNoModal.module.scss';

interface Props {
    isVisible: boolean;
    yesButtonText?: string;
    noButtonText?: string;
    title?: string;
    description?: string;
    yesDanger?: boolean;
    onYesClicked?: () => void;
    onNoClicked?: () => void;
}

const YesNoModal = (props: Props): JSX.Element => {
    const { isVisible, yesButtonText, noButtonText, title, description, yesDanger, onYesClicked, onNoClicked } = props;
    const { t } = useTranslation();
    return (
        <Modal
            width="fit-content"
            title={title ?? ''}
            open={isVisible}
            cancelText={noButtonText ?? t('general.no')}
            okText={yesButtonText ?? t('general.yes')}
            onOk={onYesClicked}
            onCancel={onNoClicked}
            okButtonProps={{ danger: yesDanger }}
            style={{ top: 200 }}
            closable={false}
            maskClosable={false}
        >
            <div className={classNames(styles.description)}>{description}</div>
        </Modal>
    );
};

export default YesNoModal;
