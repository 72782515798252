import { Button } from 'antd';
import classNames from 'classnames';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ColorSwitch from '../../../../components/color-switch/ColorSwitch';
import SceneEditModal from '../../../../components/scene-edit-modal/SceneEditModal';
import {
    getBooleanDatapointValue,
    getColorStringWithChangedColorToSend,
    getColorStringWithChangedValueToSend,
    getLightColor,
    getLightValue,
} from '../../../../helpers/DatapointHelper';
import { updateDatapointValue } from '../../../../helpers/HttpMethods';
import editImg from '../../../../images/edit.svg';
import { Datapoint } from '../../../../models/Datapoint';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { DatapointNames } from '../../../../models/enums/DatapointNames';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { ControllersProps } from '../ControllersProps';
import ControlHeader from '../components/control-header/ControlHeader';
import CustomRange from '../components/custom-slider/CustomRange';
import styles from './LightController.module.scss';

const LightController = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room, order, forceShowAll } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;
    const sceneConfigDatapoint = virtualDevice?.datapoints?.find((x) => x.type == DatapointType.ScenesLight);
    const sceneSelectionDatapoint = virtualDevice?.datapoints?.find((x) => x.type == DatapointType.SceneSelection);
    const mainSwitchDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Switch && x.name == DatapointNames.VirtualSwitch,
    );
    const lightsColorDatapoints = virtualDevice?.datapoints?.filter(
        (x) =>
            (x.type == DatapointType.Color ||
                x.type == DatapointType.Color_strip ||
                x.type == DatapointType.Brightness ||
                x.type == DatapointType.Switch) &&
            x.name.includes('light'),
    );
    const [scenes, setScenes] = useState<{ value: string; label: string }[] | undefined>([]);
    const [selectedScene, setSelectedScene] = useState<{ value: string; label: string }>();
    const [datapointsVisible, setDatapointsVisible] = useState<boolean>(false);
    const [isSceneMenuOpen, setIsSceneMenuOpen] = useState<boolean>(false);
    const [isSceneConfigModalOpen, setIsSceneConfigModalOpen] = useState<boolean>(false);
    const [colorEditLight, setColorEditLight] = useState<Datapoint>();
    const [selectedColor, setSelectedColor] = useState<string>();

    const onDatapointValueChanged = async (value: boolean | number | string, datapoint?: Datapoint) => {
        if (datapoint) {
            const result = await updateDatapointValue(datapoint, value);
            if (result.status !== StatusCodes.OK) {
                toast.error(t('errors.errorWhileSendingValue'));
                return;
            }
        }
    };

    const onLightColorSliderValueChanged = (value: number, datapoint: Datapoint) => {
        onDatapointValueChanged(getColorStringWithChangedValueToSend(value, datapoint.value.toString()), datapoint);
    };

    const onLightColorSliderColorChanged = (value: string, datapoint: Datapoint) => {
        onDatapointValueChanged(getColorStringWithChangedColorToSend(value, datapoint.value.toString()), datapoint);
    };

    const onLightBrightnessSliderValueChanged = (value: number, datapoint: Datapoint) => {
        onDatapointValueChanged(value, datapoint);
    };

    useEffect(() => {
        const allScenes = virtualDevice?.datapoints
            ?.find((x) => x.type == DatapointType.ScenesLight)
            ?.Scenes?.map((x) => ({ value: x.Name, label: x.Name }));
        setScenes(allScenes);

        if (allScenes) {
            const selectedSceneIndex = sceneSelectionDatapoint?.value as number;
            const selected = allScenes[selectedSceneIndex];
            setSelectedScene(selected);
        }
    }, [virtualDevice]);

    const onSceneChanged = async (newValue: string | undefined) => {
        const sceneIndex = scenes?.findIndex((x) => x.value == newValue);
        if (newValue && sceneIndex !== undefined && sceneSelectionDatapoint) {
            const result = await updateDatapointValue(sceneSelectionDatapoint, sceneIndex);

            if (result.status !== StatusCodes.OK) {
                toast.error('Error during sending a value');
                return;
            }

            setSelectedScene({ value: newValue, label: newValue });
        }
    };

    const getDatapointViews = (): JSX.Element => {
        return (
            <div
                className={forceShowAll ? undefined : styles.datapointsList}
                style={{
                    zIndex: order,
                    borderBottom: forceShowAll ? undefined : border,
                    borderLeft: forceShowAll ? undefined : border,
                    borderRight: forceShowAll ? undefined : border,
                }}
            >
                {lightsColorDatapoints &&
                    lightsColorDatapoints.map((datapoint, index) => (
                        <div
                            className={classNames({
                                [styles.sliderDatapoint]: datapoint.type !== DatapointType.Switch,
                                [styles.switchDatapoint]: datapoint.type == DatapointType.Switch,
                            })}
                            key={datapoint.id}
                        >
                            <div
                                className={classNames({
                                    [styles.sliderDatapointTitle]: datapoint.type !== DatapointType.Switch,
                                })}
                            >
                                {datapoint.customname}
                            </div>
                            {(datapoint.type == DatapointType.Color || datapoint.type == DatapointType.Color_strip) && (
                                <div className={styles.sliderContainer}>
                                    <CustomRange
                                        step={1}
                                        min={Number(datapoint?.range[0] ?? 0)}
                                        max={Number(datapoint?.range[1] ?? 100)}
                                        value={getLightValue(datapoint.value.toString(), datapoint.range)}
                                        minTrackColor={getLightColor(datapoint.value.toString())}
                                        onValueChanged={(value) => onLightColorSliderValueChanged(value, datapoint)}
                                    />
                                    <Button
                                        className={styles.editButton}
                                        onClick={() => {
                                            if (colorEditLight) {
                                                setColorEditLight(undefined);
                                                return;
                                            }
                                            setColorEditLight(datapoint);
                                            setSelectedColor(getLightColor(datapoint.value.toString() ?? ''));
                                        }}
                                        shape="circle"
                                    >
                                        <img className={styles.editSmallImg} src={editImg} />
                                    </Button>
                                </div>
                            )}
                            {datapoint.type == DatapointType.Brightness && (
                                <CustomRange
                                    step={1}
                                    min={Number(datapoint?.range[0] ?? 0)}
                                    max={Number(datapoint?.range[1] ?? 100)}
                                    value={
                                        datapoint?.value && datapoint?.value >= Number(datapoint?.range[0] ?? 0)
                                            ? Number(datapoint?.value)
                                            : Number(datapoint?.range[0] ?? 0)
                                    }
                                    minTrackColor={'#ffffff'}
                                    onValueChanged={(value) => onLightBrightnessSliderValueChanged(value, datapoint)}
                                />
                            )}
                            {datapoint.type == DatapointType.Switch && (
                                <ColorSwitch
                                    className={styles.switch}
                                    onColor={category?.color}
                                    onChange={(checked) => onDatapointValueChanged(checked, datapoint)}
                                    checked={getBooleanDatapointValue(datapoint)}
                                />
                            )}
                            {colorEditLight?.id == datapoint.id && (
                                <div
                                    className={styles.colorPicker}
                                    style={{
                                        top: index === 0 ? 0 : undefined,
                                        bottom: index === lightsColorDatapoints.length - 1 ? 0 : undefined,
                                    }}
                                    onMouseLeave={() => {
                                        setColorEditLight(undefined);
                                        if (selectedColor) {
                                            onLightColorSliderColorChanged(selectedColor.substring(1), datapoint);
                                        }
                                    }}
                                >
                                    <SketchPicker
                                        onChange={(color) => setSelectedColor(color.hex)}
                                        color={selectedColor}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
            </div>
        );
    };

    return (
        <div className={styles.mainContainer} style={{ zIndex: order, border: border }}>
            <ControlHeader virtualDevice={virtualDevice} room={room} switchDatapoint={mainSwitchDatapoint} />
            <div className={styles.sceneSelectContainer}>
                <Select
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 999, fontSize: 14 }) }}
                    onMenuOpen={() => setIsSceneMenuOpen(true)}
                    onMenuClose={() => setIsSceneMenuOpen(false)}
                    onChange={(value) => onSceneChanged(value?.value)}
                    value={selectedScene}
                    className={classNames(styles.sceneSelect)}
                    options={scenes}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: '#a1a1a1',
                        },
                    })}
                />
                <Button
                    size="large"
                    className={styles.editButton}
                    onClick={() => setIsSceneConfigModalOpen(true)}
                    shape="circle"
                >
                    <img className={styles.editImg} src={editImg} />
                </Button>
            </div>
            {forceShowAll && getDatapointViews()}
            {!forceShowAll && (
                <div
                    onMouseEnter={() => {
                        if (!isSceneMenuOpen) setDatapointsVisible(true);
                    }}
                    onMouseLeave={() => {
                        setDatapointsVisible(false);
                        setColorEditLight(undefined);
                    }}
                >
                    <div className={styles.dots}>
                        <span className={styles.dot} />
                        <span className={styles.dot} />
                        <span className={styles.dot} />
                    </div>
                    {datapointsVisible && getDatapointViews()}
                </div>
            )}
            {sceneConfigDatapoint && isSceneConfigModalOpen && (
                <SceneEditModal
                    virtualDevice={virtualDevice}
                    selectedSceneIndex={Number(sceneSelectionDatapoint?.value ?? 0)}
                    sceneDatapoint={sceneConfigDatapoint}
                    closeModalRequested={() => setIsSceneConfigModalOpen(false)}
                />
            )}
        </div>
    );
};

export default LightController;
