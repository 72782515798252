import { Button, Checkbox, Form, Input } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isCloud } from '../env.json';
import { getCloudCredentials, getSelectedPageAfterLogin, getUserCredential } from '../helpers/CookieHelper';
import { authenticate, checkCloudConnection, logout, setDemo } from '../helpers/HttpMethods';
import { useImages } from '../helpers/ImagesHelper';
import logo from '../images/tem_smarthome_logo_light.png';
import { CloudCredential, UserCredential } from '../models/UserCredential';
import { Routings } from '../models/enums/Routings';
import styles from './LoginPage.module.scss';
import backgroundImg from './backgroundImg.jpg';

enum FormFields {
    username = 'username',
    password = 'password',
    save = 'save',
    serverName = 'serverName',
}

const LoginPage = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();
    const location = useLocation();
    const [form] = Form.useForm();
    const save = Form.useWatch(FormFields.save, form);
    const { darkImages } = useImages();

    const [server, setServer] = useState<CloudCredential>();

    useEffect(() => {
        if (isCloud) {
            const userCredential = getCloudCredentials();
            setServer(userCredential);
            return;
        }

        const userCredential = getUserCredential();
        if (userCredential && userCredential.refresh_token) {
            history.replace(Routings.MainPage);
        }

        const queryParameters = new URLSearchParams(location.search);
        const token = queryParameters?.get('token');

        if (token) {
            const params = atob(token);
            const usrpass = params.split(':');

            if (usrpass.length < 2) {
                return;
            }

            const username = usrpass[0];
            const password = usrpass[1];

            checkConnection({ username, password, save: false, serverName: '' });
        }
    }, []);

    const doLogin = async (e: { username: string; password: string; save: boolean; serverName: string }) => {
        const username = e.username;
        const password = e.password;
        const save = e.save;
        const serverName = e.serverName ?? '';

        isCloud
            ? checkCloud({ username, password, isCurrent: true, save, serverName })
            : checkConnection({ username, password, save, serverName });
    };

    const checkCloud = async (userCredential?: CloudCredential) => {
        setIsLoading(true);
        const result = await checkCloudConnection(userCredential);

        if (result) {
            history.replace(Routings.MainPage);
            return;
        }

        setIsLoading(false);
        toast.error(t('errors.connectionFailed'));
    };

    const checkConnection = async (userCredential: UserCredential) => {
        setIsLoading(true);

        if (await authenticate(userCredential)) {
            history.replace(getSelectedPageAfterLogin() ?? Routings.MainPage);
            return;
        }

        setIsLoading(false);
    };

    const onDemo = () => {
        setDemo(true);
        history.replace(Routings.MainPage);
    };

    const onConnectToServer = (server: CloudCredential) => {
        setIsLoading(true);
        checkCloud(server);
    };

    return (
        <div className={styles.mainContainer}>
            <img className={styles.backgroundImg} src={backgroundImg} />
            <div className={styles.demo}>
                <Button className={styles.demoButton} onClick={onDemo} type="primary">
                    {t('loginPage.demo')}
                </Button>
            </div>
            <img className={styles.logoImg} src={logo} />
            {isLoading && <div className={styles.backgroundLoaderContainer} />}
            {isLoading && (
                <div className={styles.loaderContaier}>
                    <Loader type="Circles" color="white" height={100} width={100} />
                </div>
            )}
            {server && !isLoading && (
                <div className={styles.serversWrapper}>
                    <div className={styles.serverWrapper} onClick={() => onConnectToServer(server)}>
                        <img className={styles.serverIcon} src={darkImages.server.default} />
                        {server.serverName}
                    </div>

                    <div
                        className={classNames(styles.serverWrapper, styles.addNewWrapper)}
                        onClick={() => {
                            logout();
                            setServer(undefined);
                        }}
                    >
                        {t('header.logout')}
                    </div>
                </div>
            )}
            {!server && !isLoading && (
                <div className={styles.loginContainer}>
                    <Form form={form} onFinish={doLogin}>
                        <div className={styles.textContainer}>{t('loginPage.username')}</div>
                        <Form.Item
                            name={FormFields.username}
                            rules={[
                                {
                                    required: true,
                                    message: t('errors.fieldCannotBeEmpty'),
                                },
                            ]}
                        >
                            <Input className={styles.input} />
                        </Form.Item>
                        <div className={styles.textContainer}>{t('loginPage.password')}</div>
                        <Form.Item
                            name={FormFields.password}
                            rules={[
                                {
                                    required: true,
                                    message: t('errors.fieldCannotBeEmpty'),
                                },
                            ]}
                        >
                            <Input.Password className={styles.input} />
                        </Form.Item>
                        <div>
                            <Form.Item name={FormFields.save} valuePropName="checked">
                                <Checkbox className={styles.remamberCheckbox}>
                                    {t('loginPage.saveCredentials')}
                                </Checkbox>
                            </Form.Item>
                        </div>
                        {save && isCloud && (
                            <div className={classNames(styles.textContainer, styles.textContainerServerNames)}>
                                {t('general.serverName')}
                            </div>
                        )}
                        {save && isCloud && (
                            <Form.Item
                                name={FormFields.serverName}
                                rules={[
                                    {
                                        required: true,
                                        message: t('errors.fieldCannotBeEmpty'),
                                    },
                                ]}
                            >
                                <Input maxLength={25} className={styles.input} />
                            </Form.Item>
                        )}
                        <Button className={styles.submitButton} type="primary" htmlType="submit">
                            {t('loginPage.login')}
                        </Button>
                    </Form>
                </div>
            )}
        </div>
    );
};

export default LoginPage;
