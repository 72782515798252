import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSetupInfo } from '../../../../app/globalSettings';
import { useAppSelector } from '../../../../app/hooks';
import ChartsImg from '../../../../images/charts.svg';
import { MonitorDatapoint } from '../../../../models/MonitorDatapoint';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { DatapointType } from '../../../../models/enums/DatapointType';
import MonitorLiveView from '../../../../monitor-live-view/MonitorLiveView';
import { ControllersProps } from '../ControllersProps';
import ControlHeader from '../components/control-header/ControlHeader';
import styles from './Monitor.module.scss';

const Monitor = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room, order, forceShowAll } = props;
    const { t } = useTranslation();
    const setupInfo = useAppSelector(getSetupInfo);
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const monitorConfigDatapoint = virtualDevice?.datapoints?.find((x) => x.type == DatapointType.MonitorConfig);

    const [datapointsVisible, setDatapointsVisible] = useState(false);
    const [liveViewVisible, setLiveViewVisible] = useState(false);

    const getMoniorValue = (datapoint: MonitorDatapoint): string => {
        const ioValue = setupInfo?.ios?.find((x) => x.id == datapoint.IOid)?.value;

        if (ioValue === undefined || ioValue === '') {
            return t('monitor.invalid');
        }

        if (!datapoint.Configuration || !Number(ioValue)) {
            return `${ioValue}${datapoint.Unit}`;
        }

        const value = Number(ioValue);
        const offset = Number(datapoint.Configuration.Offset);
        const lowerLimit = Number(datapoint.Configuration.LowerLimit);
        const lowerLimitCorrection = Number(datapoint.Configuration.LowerLimitCorrection);
        const upperLimit = Number(datapoint.Configuration.UpperLimit);
        const upperLimitCorrection = Number(datapoint.Configuration.UpperLimitCorrection);

        if (!value || !offset || !lowerLimit || !lowerLimitCorrection || !upperLimit || !upperLimitCorrection) {
            return `${ioValue}${datapoint.Unit}`;
        }

        const diff = upperLimit - lowerLimit;
        const m = diff == 0 ? 0 : (upperLimitCorrection - lowerLimitCorrection) / diff;
        const b = upperLimitCorrection - m * upperLimit;

        return ((value + offset) * m - b).toString();
    };

    return (
        <div className={classNames(styles.mainContainer)} style={{ zIndex: order, border: border }}>
            <ControlHeader
                virtualDevice={virtualDevice}
                room={room}
                onEditButtonClick={
                    monitorConfigDatapoint?.Monitor.some((x) => x.Log) ? () => setLiveViewVisible(true) : undefined
                }
                editButtonImg={ChartsImg}
                imgStyle={{ width: 20, height: 20, marginLeft: -2 }}
            />
            {monitorConfigDatapoint?.Monitor.filter((x) => x.View)
                .slice(0, forceShowAll ? monitorConfigDatapoint?.Monitor.length : 4)
                .map((x) => (
                    <div key={x.IOid} className={classNames(styles.titleValue, styles.datapointTop)}>
                        <div className={styles.titleText}>{x.Name}</div>
                        <div>{getMoniorValue(x)}</div>
                    </div>
                ))}
            {monitorConfigDatapoint &&
                !forceShowAll &&
                monitorConfigDatapoint?.Monitor.filter((x) => x.View).length > 4 && (
                    <div
                        className={styles.dots}
                        onMouseEnter={() => {
                            setDatapointsVisible(true);
                        }}
                        onMouseLeave={() => setDatapointsVisible(false)}
                    >
                        <span className={styles.dot} />
                        <span className={styles.dot} />
                        <span className={styles.dot} />
                        {datapointsVisible && (
                            <div
                                className={styles.datapointsList}
                                style={{
                                    zIndex: order,
                                    borderBottom: border,
                                    borderLeft: border,
                                    borderRight: border,
                                }}
                            >
                                {monitorConfigDatapoint.Monitor.filter((x) => x.View)
                                    .slice(4)
                                    .map((x) => (
                                        <div key={x.IOid} className={classNames(styles.titleValue, styles.datapoint)}>
                                            <div className={styles.titleText}>{x.Name}</div>
                                            <div>{getMoniorValue(x)}</div>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                )}
            {liveViewVisible && monitorConfigDatapoint?.Monitor.some((x) => x.Log) && (
                <MonitorLiveView onClose={() => setLiveViewVisible(false)} virtualDevice={virtualDevice} />
            )}
        </div>
    );
};

export default Monitor;
