import styles from './Battery.module.scss';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { updateDatapointValue } from '../../../../helpers/HttpMethods';
import { DatapointNames } from '../../../../models/enums/DatapointNames';
import { getNumberDatapointValue, getValueValidatedByRange } from '../../../../helpers/DatapointHelper';
import { ControllersProps } from '../ControllersProps';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useState } from 'react';
import CustomRange from '../components/custom-slider/CustomRange';
import { toast } from 'react-toastify';
import { StatusCodes } from 'http-status-codes';
import { Datapoint } from '../../../../models/Datapoint';
import { isEmpty } from '../../../../helpers/StringHelper';
import ControlHeader from '../components/control-header/ControlHeader';

const Battery = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room, order, forceShowAll } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const lowBatteryLimitDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualLowBatteryLimit,
    );

    const valuesDatapoints = virtualDevice?.datapoints?.filter((x) => x.type == DatapointType.Number && x.writeprotect);

    const [datapointsVisible, setDatapointsVisible] = useState<boolean>(false);

    const onDatapointValueChanged = async (value: boolean | number | string, datapoint?: Datapoint) => {
        if (datapoint) {
            const result = await updateDatapointValue(datapoint, value);
            if (result.status !== StatusCodes.OK) {
                toast.error(t('errors.errorWhileSendingValue'));
                return;
            }
        }
    };

    return (
        <div
            className={classNames(styles.mainContainer, {
                [styles.mainContainerZeroValue]: (valuesDatapoints?.length ?? 0) == 0,
                [styles.mainContainerOneValue]: (valuesDatapoints?.length ?? 0) == 1,
                [styles.mainContainerTwoValue]: (valuesDatapoints?.length ?? 0) == 2,
            })}
            style={{ zIndex: order, border: border }}
        >
            <ControlHeader virtualDevice={virtualDevice} room={room} />
            {lowBatteryLimitDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapointTop)}>
                    <div>{t('battery.lowBatteryLimit')}</div>
                    <div>{`${getNumberDatapointValue(lowBatteryLimitDatapoint)}%`}</div>
                </div>
            )}
            {lowBatteryLimitDatapoint && (
                <div className={styles.sliderDatapoint}>
                    <CustomRange
                        step={1}
                        min={Number(lowBatteryLimitDatapoint?.range[0] ?? 0)}
                        max={Number(lowBatteryLimitDatapoint?.range[1] ?? 100)}
                        value={getValueValidatedByRange(lowBatteryLimitDatapoint)}
                        minTrackColor={category?.color ?? '#e0e0e0'}
                        onValueChanged={(value) => onDatapointValueChanged(value, lowBatteryLimitDatapoint)}
                    />
                </div>
            )}
            {valuesDatapoints?.slice(0, forceShowAll ? valuesDatapoints.length : 2).map((x) => (
                <div key={x.id} className={classNames(styles.titleValue, styles.datapointTop)}>
                    <div>{!isEmpty(x.customname) && x.name.startsWith('_') ? x.customname : x.name}</div>
                    <div>
                        {isEmpty(x.value.toString())
                            ? t('general.noData')
                            : Number(x.value) > 100
                            ? t('general.changeBattery')
                            : `${x.value}%`}
                    </div>
                </div>
            ))}
            {valuesDatapoints.length > 2 && !forceShowAll && (
                <div
                    className={styles.dots}
                    onMouseEnter={() => {
                        setDatapointsVisible(true);
                    }}
                    onMouseLeave={() => setDatapointsVisible(false)}
                >
                    <span className={styles.dot} />
                    <span className={styles.dot} />
                    <span className={styles.dot} />
                    {datapointsVisible && (
                        <div
                            className={styles.datapointsList}
                            style={{
                                zIndex: order,
                                borderBottom: border,
                                borderLeft: border,
                                borderRight: border,
                            }}
                        >
                            {valuesDatapoints?.slice(2).map((x) => (
                                <div key={x.id} className={classNames(styles.titleValue, styles.datapoint)}>
                                    <div>
                                        {!isEmpty(x.customname) && x.name.startsWith('_') ? x.customname : x.name}
                                    </div>
                                    <div className={styles.value}>
                                        {isEmpty(x.value.toString())
                                            ? t('general.noData')
                                            : Number(x.value) > 100
                                            ? t('general.changeBattery')
                                            : `${x.value}%`}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Battery;
