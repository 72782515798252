import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EnergyMonitorStatisticsModal from '../../../../components/energy-monitor-statistics-modal/EnergyMonitorStatisticsModal';
import { getNumberWithoutMin } from '../../../../helpers/DatapointHelper';
import ChartsImg from '../../../../images/charts.svg';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { DatapointNames } from '../../../../models/enums/DatapointNames';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { ControllersProps } from '../ControllersProps';
import ControlHeader from '../components/control-header/ControlHeader';
import styles from './EnergyMonitor.module.scss';

const EnergyMonitor = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const energyHourDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.EnergyStatistics_Hour && x.name == DatapointNames.VirtualHourEnergy,
    );
    const energyDayDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.EnergyStatistics_Day && x.name == DatapointNames.VirtualDayEnergy,
    );
    const energyMonthDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.EnergyStatistics_Month && x.name == DatapointNames.VirtualMonthEnergy,
    );
    const energyYearDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.EnergyStatistics_Year && x.name == DatapointNames.VirtualYearEnergy,
    );

    const [showEnergyMonitorStatisticsModal, setShowEnergyMonitorStatisticsModal] = useState(false);

    return (
        <div className={styles.mainContainer} style={{ border: border }}>
            <ControlHeader
                virtualDevice={virtualDevice}
                room={room}
                onEditButtonClick={() => setShowEnergyMonitorStatisticsModal(true)}
                imgStyle={{ width: 20, height: 20, marginLeft: -2 }}
                editButtonImg={ChartsImg}
            />
            {energyHourDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('general.hourly')}</div>
                    <div>{`${getNumberWithoutMin(energyHourDatapoint).toFixed(2)} kWh`}</div>
                </div>
            )}
            {energyDayDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('general.daily')}</div>
                    <div>{`${getNumberWithoutMin(energyDayDatapoint).toFixed(2)} kWh`}</div>
                </div>
            )}
            {energyMonthDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('general.monthly')}</div>
                    <div>{`${getNumberWithoutMin(energyMonthDatapoint).toFixed(2)} kWh`}</div>
                </div>
            )}
            {energyYearDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('general.yearly')}</div>
                    <div>{`${getNumberWithoutMin(energyYearDatapoint).toFixed(2)} kWh`}</div>
                </div>
            )}
            {showEnergyMonitorStatisticsModal && (
                <EnergyMonitorStatisticsModal
                    onClose={() => setShowEnergyMonitorStatisticsModal(false)}
                    virtualDevice={virtualDevice}
                />
            )}
        </div>
    );
};

export default EnergyMonitor;
