import { Input, Modal } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getValidationNameError, isEmpty, validatePassword } from '../../helpers/StringHelper';
import styles from './SingleInputModal.module.scss';

interface Props {
    isVisible: boolean;
    yesButtonText?: string;
    noButtonText?: string;
    defaultValue?: string;
    title?: string;
    description?: string;
    yesDanger?: boolean;
    placeholder?: string;
    valueOtherThan?: string[];
    isLoading?: boolean;
    isPassword?: boolean;
    doNameValidation?: boolean;
    onAddClicked?: (value: string) => void;
    onCancelClicked?: () => void;
}

const SingleInputModal = (props: Props): JSX.Element => {
    const {
        isVisible,
        yesButtonText,
        noButtonText,
        title,
        placeholder,
        valueOtherThan,
        defaultValue,
        isLoading,
        isPassword,
        doNameValidation,
        onAddClicked,
        onCancelClicked,
    } = props;
    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState(defaultValue ?? '');

    const inputError = doNameValidation ? useMemo(() => getValidationNameError(inputValue), [inputValue]) : undefined;

    return (
        <Modal
            width={300}
            title={title ?? ''}
            open={isVisible}
            cancelText={noButtonText ?? t('general.cancel')}
            okText={yesButtonText ?? t('general.add')}
            okButtonProps={{
                disabled:
                    isEmpty(inputValue) ||
                    valueOtherThan?.some((x) => x === inputValue) ||
                    !!inputError ||
                    (!isEmpty(inputValue) && isPassword && !validatePassword(inputValue)),
                loading: isLoading,
            }}
            onOk={() => onAddClicked?.(inputValue)}
            onCancel={onCancelClicked}
            style={{ top: 200 }}
        >
            {isPassword ? (
                <Input.Password
                    status={
                        isEmpty(inputValue) ||
                        valueOtherThan?.some((x) => x === inputValue) ||
                        (!isEmpty(inputValue) && isPassword && !validatePassword(inputValue)) ||
                        inputError
                            ? 'error'
                            : undefined
                    }
                    onChange={(value) => {
                        setInputValue(value.currentTarget.value);
                    }}
                    value={inputValue}
                    placeholder={placeholder}
                />
            ) : (
                <Input
                    status={
                        isEmpty(inputValue) ||
                        valueOtherThan?.some((x) => x === inputValue) ||
                        (!isEmpty(inputValue) && isPassword && !validatePassword(inputValue)) ||
                        inputError
                            ? 'error'
                            : undefined
                    }
                    onChange={(value) => {
                        setInputValue(value.currentTarget.value);
                    }}
                    value={inputValue}
                    placeholder={placeholder}
                />
            )}
            {valueOtherThan?.some((x) => x === inputValue) && (
                <div className={styles.nameError}>{t('errors.NameAlreadyExist')}</div>
            )}
            {isEmpty(inputValue) && <div className={styles.nameError}>{t('errors.fieldCannotBeEmpty')}</div>}
            {!isEmpty(inputValue) && isPassword && !validatePassword(inputValue) && (
                <div className={styles.nameError}>{t('errors.passwordNotValid')}</div>
            )}
            {!isEmpty(inputValue) && inputError && <div className={styles.nameError}>{inputError}</div>}
        </Modal>
    );
};

export default SingleInputModal;
