export enum DatapointType {
    MobileAppIgnore2 = -2,
    MobileAppIgnore = -1,
    Undefined,
    Button,
    Temperature,
    Color,
    Brightness,
    TimeProgram,
    Switch,
    Number,
    HeatingTimeProgram,
    ScenesLight,
    StatusLight,
    EnergyStatistics_Hour,
    EnergyStatistics_Day,
    EnergyStatistics_Month,
    EnergyStatistics_Year,
    LineChartStatistic,
    RoomControllerOperationMode,
    SceneSelection,
    ScenesLightRGBW,
    Humidity,
    CentralAlarmConfig,
    CentralFireAlarmConfig,
    CentralHeatingTimeProgram,
    CentralComingHomeConfig,
    CentralBatteryConfig,
    CentralEnergyConfig,
    PresenceSimulationConfig,
    CentralSchedulerConfig,
    CameraConfig,
    Color_strip,
    NetatmoDevice,
    MonitorConfig,
    FloorConfig,
    AstroFunctionConfig,
    eHandleWindow,
    VisualizationBlockConfig,
    EnergyManagementDeviceMode,
    EnergyManagementConfig,
    AlphatronicManagerConfig,
    AlphatronicUIConfig,
}
