export enum RoomType {
    Undefined,
    Bathroom,
    Room,
    Bedroom,
    LivingRoom,
    Kitchen,
    HobbyRoom,
    DiningRoom,
    DressingRoom,
    Garage,
    KidsBedroom,
    Office,
    Washroom,
    Balcony,
    Cellar,
    Toilet,
    Central,
}
