import { Switch, SwitchProps } from 'antd';

type Props = SwitchProps &
    React.RefAttributes<HTMLElement> & {
        onColor?: string;
    };

const ColorSwitch = ({ onColor, style, ...rest }: Props): JSX.Element => {
    return (
        <Switch
            {...rest}
            style={
                onColor && rest.checked
                    ? {
                          ...style,
                          backgroundColor: onColor,
                      }
                    : { ...style }
            }
        />
    );
};

export default ColorSwitch;
