import { PlusCircleOutlined, TeamOutlined } from '@ant-design/icons';
import { TFunction } from 'i18next';
import { Routings } from '../models/enums/Routings';

const iconStyle: React.CSSProperties = { fontSize: 20 };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SettingsTabs = (t: TFunction) => [
    {
        name: t('settings.usersConfiguration'),
        route: Routings.UsersConfigurationPage,
        description: t('settings.usersConfigurationDescription'),
        icon: <TeamOutlined style={iconStyle} />,
    },
    {
        name: t('settings.advancedProperties'),
        route: Routings.AdvancedPropertiesPage,
        description: t('settings.advancedPropertiesDescription'),
        icon: <PlusCircleOutlined style={iconStyle} />,
    },
];
