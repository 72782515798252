import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    getSetupInfo,
    setDatapointValue,
    setLastWebsocketMessage,
    setMonitorValue,
    setSetupInfo,
    setVirtualDeviceProps,
} from '../../app/globalSettings';
import { useAppSelector } from '../../app/hooks';
import { isCloud, isRelease } from '../../env.json';
import { getCloudCredentials, getUserCredential } from '../../helpers/CookieHelper';
import { cloudToken, fetchSetupInfo, isDemo } from '../../helpers/HttpMethods';
import { WebsocketMessage } from '../../models/WebsocketMessage';
import { DatapointType } from '../../models/enums/DatapointType';
import { VirtualDeviceType } from '../../models/enums/VirtualDeviceType';

const SignalrRUpdater = (): JSX.Element => {
    const setupInfo = useAppSelector(getSetupInfo);
    const dispatch = useDispatch();
    const [connection, setConnection] = useState<HubConnection>();

    useEffect(() => {
        connect();
    }, [setupInfo]);

    const connect = () => {
        if (setupInfo && connection?.state !== HubConnectionState.Connected && !isDemo) {
            const newConnection = new HubConnectionBuilder()
                .withUrl('https://dtem-homesrv-apis-signalr.azurewebsites.net/SmartHomeHub', {
                    accessTokenFactory: () => {
                        return cloudToken ?? '';
                    },
                })
                .withAutomaticReconnect()
                .build();

            newConnection.serverTimeoutInMilliseconds = 86400000;
            newConnection.on('NewMessage', (data) => {
                const lastMessage = JSON.parse(data) as WebsocketMessage;
                if (!lastMessage) {
                    return;
                }

                if (!isRelease) {
                    console.log(lastMessage);
                }

                dispatch(setLastWebsocketMessage(lastMessage));

                switch (lastMessage.header) {
                    case 'dpupdate':
                        UpdateDatapointValue(lastMessage);
                        break;
                    case 'dpconfigupdate':
                        UpdateDatapointValue(lastMessage);
                        break;
                    case 'deviceupdate':
                    case 'deviceresponse':
                        if (lastMessage.ioid && lastMessage.value) {
                            dispatch(setMonitorValue(lastMessage));
                        }
                        break;
                    case 'configupdate':
                        if (lastMessage?.payload?.access) {
                            if (lastMessage.api == 'objects') {
                                const object: { favourite: boolean; objId: number; ranking: number } =
                                    lastMessage?.payload?.access?.[0];
                                dispatch(setVirtualDeviceProps(object));
                            }
                        } else {
                            (async () => {
                                const result = await fetchSetupInfo();
                                dispatch(setSetupInfo(result));
                            })();
                        }
                        break;
                }
            });
            newConnection.start().then(() => setConnection(newConnection));
        }
    };

    useEffect(() => {
        if (connection?.state === HubConnectionState.Connected) {
            connection.stop();

            if (!!cloudToken) {
                connect();
            }
        }
    }, [cloudToken]);

    useEffect(() => {
        try {
            if (setupInfo && setupInfo.objects && connection?.state === HubConnectionState.Connected) {
                const user = isCloud ? getCloudCredentials() : getUserCredential();
                const ios: number[] = [];

                setupInfo.objects.items
                    .filter((x) => x.type == VirtualDeviceType.Monitor)
                    .forEach((x) => {
                        x.datapoints
                            .find((y) => y.type == DatapointType.MonitorConfig)
                            ?.Monitor?.forEach((z) => ios.push(z.IOid));
                    });
                setupInfo.objects.items
                    .filter((x) => x.type == VirtualDeviceType.FloorPlan)
                    .forEach((x) =>
                        x.datapoints
                            .find((y) => y.type == DatapointType.FloorConfig)
                            ?.Floors.forEach((z) =>
                                z.Objects.filter((io) => io.IOID).forEach((io) => ios.push(io.IOID)),
                            ),
                    );
                setupInfo.objects.items
                    .filter((x) => x.type == VirtualDeviceType.TotalEnergyMonitor)
                    .forEach((x) =>
                        x.datapoints
                            .find((y) => y.type == DatapointType.CentralEnergyConfig)
                            ?.EnergyConfig?.IOConfigs?.forEach((z) => ios.push(z.Id)),
                    );

                connection.send(
                    'SendMessageToServer',
                    JSON.stringify({
                        header: 'devicerequest',
                        cmd: 'GET',
                        cache: true,
                        observe: true,
                        ios: ios.filter((io, index, self) => index === self.indexOf(io)),
                    }),
                    user?.username,
                    user?.password,
                );
            }
        } catch (ex) {
            console.log(ex);
        }
    }, [connection?.state]);

    const UpdateDatapointValue = (message: WebsocketMessage): void => {
        dispatch(setDatapointValue(message));
    };

    return <></>;
};

export default SignalrRUpdater;
