import UniversalCookie from 'universal-cookie';
import { Room } from '../models/Room';
import { CloudCredential, UserCredential } from '../models/UserCredential';
import { ControlsSortingMode } from '../models/enums/ControlsSortingMode';
import { Cookies } from '../models/enums/Cookies';
import { Routings } from '../models/enums/Routings';
import { TabType } from '../models/enums/TabType';
import { VirtualDeviceCategory } from '../models/enums/VirtualDeviceCategory';

const cookies = new UniversalCookie();

const year = 315569520000;

export const getCloudCredentials = (): CloudCredential | undefined => {
    const cookie = cookies.get(Cookies.CloudCredential);

    if (!cookie) {
        return undefined;
    }

    return JSON.parse(atob(cookie));
};

export const setCloudCredentials = (userCredential?: CloudCredential): void => {
    if (!userCredential) {
        cookies.remove(Cookies.CloudCredential);
        return;
    }

    cookies.set(Cookies.CloudCredential, btoa(JSON.stringify(userCredential)), {
        expires: userCredential.save ? new Date(Date.now() + year) : undefined,
    });
};

export const getUserCredential = (): UserCredential | undefined => {
    const res = cookies.get(Cookies.UserCredential);

    if (!res) {
        return;
    }

    return JSON.parse(atob(res));
};

export const saveUserCredential = (userCredential: UserCredential): void => {
    cookies.set(
        Cookies.UserCredential,
        btoa(JSON.stringify(userCredential)),
        userCredential.save
            ? {
                  expires: new Date(Date.now() + year),
              }
            : undefined,
    );
};

export const removeUserCredential = (): void => {
    cookies.remove(Cookies.UserCredential);
};

export const getSavedSelectedTab = (): { tab: TabType; room: Room; category: VirtualDeviceCategory } => {
    return cookies.get(Cookies.SelectedTab);
};

export const saveSelectedTab = (tab: TabType, room?: Room, category?: VirtualDeviceCategory): void => {
    cookies.set(Cookies.SelectedTab, { tab: tab, room: room, category: category });
};

export const getSavedSelectedFloor = (): { datapointId: number; floorId: string } => {
    return cookies.get(Cookies.SelectedFloor);
};

export const saveSelectedFloor = (datapointId: number, floorId: string): void => {
    cookies.set(Cookies.SelectedFloor, { datapointId: datapointId, floorId: floorId });
};

export const saveSelectedPageAfterLogin = (page: Routings): void => {
    cookies.set(Cookies.SelectedPageAfterLogin, page);
};

export const getSelectedPageAfterLogin = (): Routings => {
    return cookies.get(Cookies.SelectedPageAfterLogin);
};

export const saveControlsSortingMode = (page: ControlsSortingMode): void => {
    cookies.set(Cookies.ControlsSortingMode, page);
};

export const getControlsSortingMode = (): ControlsSortingMode => {
    return cookies.get(Cookies.ControlsSortingMode);
};
