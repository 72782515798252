import { CheckCircleOutlined, CloudDownloadOutlined, ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Modal, Progress, Spin } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getLastWebsocketMessage, getSetupInfo, setSetupInfo } from '../../app/globalSettings';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchSetupInfo, fetchUpdateDetails, fetchUpdateInfo, startUpdate } from '../../helpers/HttpMethods';
import { Info } from '../../models/Info';
import { UpdateVersionInfo } from '../../models/UpdateVersionInfo';
import { WebsocketCode } from '../../models/enums/WebsocketCode';
import styles from './UpdateModal.module.scss';

const UpdateModal = (): JSX.Element => {
    const { t } = useTranslation();
    const setupInfo = useAppSelector(getSetupInfo);
    const websocketMessage = useAppSelector(getLastWebsocketMessage);
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
    const [updateDetails, setUpdateDetails] = useState<UpdateVersionInfo>();
    const [serverInfo, setServerInfo] = useState<Info>();
    const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);
    const [currentProgress, setCurrentProgress] = useState(0);
    const [isReconnecting, setIsReconnecting] = useState(false);

    let interval: NodeJS.Timeout;

    useEffect(() => {
        if (setupInfo && !updateDetails) {
            setServerInfo(setupInfo.info);

            if (setupInfo?.update?.state?.includes('%')) {
                setIsLoading(false);
                setIsUpdateInProgress(true);
                setOpen(true);
                return;
            }

            getUpdateDetails();
        }
    }, [setupInfo]);

    useEffect(() => {
        if (websocketMessage && websocketMessage.code === WebsocketCode.UpdateProcessStarted) {
            getUpdateInfo();
        }
    }, [websocketMessage]);

    const getUpdateDetails = async () => {
        setIsLoading(true);
        const info = await fetchUpdateDetails();
        setUpdateDetails(info.data);

        if (info?.data?.summary?.available && info?.data?.summary?.url) {
            setIsUpdateAvailable(true);
            setOpen(true);
        } else {
            setIsUpdateAvailable(false);
        }

        setIsLoading(false);
    };

    const getUpdateInfo = async () => {
        const info = await fetchUpdateInfo();

        if (info.status !== StatusCodes.OK) {
            if (isUpdateInProgress) {
                setIsReconnecting(true);
            }
            return;
        }

        if (isReconnecting) {
            await getUpdateDetails();
            await refreshServerInfo();
            setCurrentProgress(0);
            setIsUpdateInProgress(false);
            setIsReconnecting(false);
            return;
        }

        const state = info.data.state;

        if (state.includes('%')) {
            setOpen(true);
            setIsUpdateInProgress(true);

            if (state.includes('Prepare')) {
                setCurrentProgress(Number(state.replace('Prepare', '').replace(' ', '').replace('%', '')) + 100);
            } else if (state.includes('Download')) {
                setCurrentProgress(Number(state.replace('Download', '').replace(' ', '').replace('%', '')));
            }
        }
    };

    const onUpdate = async () => {
        if (!updateDetails?.summary?.url) {
            return;
        }
        const startUpdateResult = await startUpdate(updateDetails.summary.url);

        if (startUpdateResult.status !== StatusCodes.OK) {
            toast.error(t('errors.errorWhileStartingUpdate'));
            return;
        }

        setIsUpdateInProgress(true);
    };

    useEffect(() => {
        function startTimer() {
            clearInterval(interval);
            interval = setInterval(() => {
                getUpdateInfo();
            }, 6000);
        }

        if (isReconnecting) {
            clearInterval(interval);
        }

        if (isUpdateInProgress) {
            startTimer();
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isUpdateInProgress, isReconnecting]);

    const refreshServerInfo = async () => {
        setIsLoading(true);
        await refreshSetupInfo();
        setIsLoading(false);
    };

    const refreshSetupInfo = async () => {
        const result = await fetchSetupInfo();

        if (result.status !== StatusCodes.OK) {
            return;
        }
        setServerInfo(result.info);
        dispatch(setSetupInfo(result));
    };

    return (
        <Modal
            width="fit-content"
            title={t('general.serverUpdate')}
            open={open}
            cancelText={t('general.close')}
            onCancel={() => setOpen(false)}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ disabled: isUpdateInProgress }}
            style={{ top: 200 }}
            closable={false}
            maskClosable={false}
        >
            <div className={styles.mainContainer}>
                {isLoading && !isUpdateInProgress && <Spin size="large" />}
                {isUpdateInProgress && (
                    <>
                        {!isReconnecting && (
                            <div className={styles.progressContainer}>
                                <Spin size="large" />
                                <Progress
                                    className={styles.progress}
                                    percent={Math.floor(currentProgress / 2)}
                                    status="active"
                                />
                            </div>
                        )}
                        {isReconnecting && (
                            <div className={styles.progressContainer}>
                                <Spin size="large" />
                                <div className={styles.progress}>{t('updateModal.reconnecting')}</div>
                            </div>
                        )}
                    </>
                )}
                {!isLoading && !isUpdateInProgress && (
                    <>
                        {isUpdateAvailable && (
                            <>
                                {serverInfo?.sdcard && (
                                    <div className={styles.updateContainer}>
                                        <CloudDownloadOutlined
                                            style={{ fontSize: 50, color: 'green', justifyContent: 'center' }}
                                        />
                                        <div className={styles.currentVersion}>
                                            {`${t('updateModal.currentVersion')}: ` + serverInfo?.version}
                                        </div>
                                        <div className={styles.newVersion}>
                                            {`${t('updateModal.newVersion')}: ` +
                                                updateDetails?.sender?.latest?.version}
                                        </div>
                                        <Button onClick={onUpdate} className={styles.updateButton} type="primary">
                                            {t('updateModal.update')}
                                        </Button>
                                    </div>
                                )}
                                {!serverInfo?.sdcard && (
                                    <div className={styles.missingSdCardContainer}>
                                        <ExclamationCircleOutlined
                                            style={{ fontSize: 50, color: 'red', justifyContent: 'center' }}
                                        />
                                        <div className={styles.currentVersion}>
                                            {`${t('updateModal.currentVersion')}: ` + serverInfo?.version}
                                        </div>
                                        <div className={styles.newVersion}>
                                            {`${t('updateModal.newVersion')}: ` +
                                                updateDetails?.sender?.latest?.version}
                                        </div>
                                        <div className={styles.missingSdCard}>
                                            <div>{t('updateModal.missingSdCard')}</div>
                                            <Button
                                                onClick={refreshServerInfo}
                                                className={styles.refreshButton}
                                                shape="circle"
                                                icon={<SyncOutlined />}
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                        {!isUpdateAvailable && (
                            <div className={styles.updateUpToDateContainer}>
                                <CheckCircleOutlined
                                    style={{ fontSize: 50, color: 'green', justifyContent: 'center' }}
                                />
                                <div className={styles.upToDateVersion}>{serverInfo?.version}</div>
                                {t('updateModal.versionUpToDate')}
                            </div>
                        )}
                    </>
                )}
            </div>
        </Modal>
    );
};

export default UpdateModal;
