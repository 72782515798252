import BalkonyImg from '../images/balkony.svg';
import BathroomImg from '../images/bathroom.svg';
import BedroomImg from '../images/bedroom.svg';
import CellarImg from '../images/cellar.svg';
import CentralImg from '../images/central.svg';
import DiningRoomImg from '../images/dining_room.svg';
import DressingRoomImg from '../images/dressing_room.svg';
import GarageImg from '../images/garage.svg';
import HobbyRoomImg from '../images/hobby_room.svg';
import KidsRoomImg from '../images/kids_bedroom.svg';
import KitchenImg from '../images/kitchen.svg';
import LivingRoomImg from '../images/living_room.svg';
import OfficeImg from '../images/office.svg';
import RoomImg from '../images/room_icon.svg';
import ToiletImg from '../images/toilet.svg';
import UnknownImg from '../images/unknown.svg';
import WashroomImg from '../images/washroom.svg';
import { Datapoint } from '../models/Datapoint';
import { WebsocketMessage } from '../models/WebsocketMessage';
import { RoomType } from '../models/enums/RoomType';
import { isEmpty } from './StringHelper';

export const getBooleanDatapointValue = (datapoint?: Datapoint): boolean => {
    try {
        if (!datapoint) {
            return false;
        }
        const value = datapoint?.value.toString();

        if (!value || value.length == 0) {
            return false;
        }

        return Boolean(JSON.parse(value));
    } catch {
        return false;
    }
};

export const getBooleanValue = (value?: string): boolean => {
    if (!value) {
        return false;
    }

    if (!value || value.length == 0) {
        return false;
    }

    return Boolean(JSON.parse(value.toLowerCase()));
};

export const getNumberWithoutMin = (datapoint?: Datapoint): number => {
    if (datapoint?.value !== undefined && typeof datapoint?.value === 'boolean') {
        return datapoint?.value ? 1 : 0;
    }

    if (datapoint?.value && datapoint?.value === 'true') {
        return 1;
    }

    if (datapoint?.value && datapoint?.value === 'false') {
        return 0;
    }

    const dpValue = datapoint?.value?.toString();

    if (isEmpty(dpValue) || !dpValue) {
        return 0;
    }

    const value = parseFloat(dpValue);

    return value;
};

export const getNumberDatapointValue = (datapoint?: Datapoint, range?: string[]): number => {
    if (datapoint?.value !== undefined && typeof datapoint?.value === 'boolean') {
        return datapoint?.value ? 1 : 0;
    }

    if (datapoint?.value && datapoint?.value === 'true') {
        return 1;
    }

    if (datapoint?.value && datapoint?.value === 'false') {
        return 0;
    }

    const min = Number(range?.[0] ?? 0);
    const dpValue = datapoint?.value?.toString();

    if (isEmpty(dpValue) || !dpValue) {
        return min;
    }

    const value = parseFloat(dpValue);

    return value < min ? min : value;
};

export const getLightValue = (lightValue: string, range: string[]): number => {
    const min = Number(range?.[0] ?? 0);

    if (isEmpty(lightValue)) {
        return min;
    }
    let value = parseInt(lightValue.toString().substring(1, 3), 16);
    if (!value) {
        value = min;
    }
    return value < min ? min : value;
};

export const getLightColor = (lightValue: string): string => {
    if (isEmpty(lightValue)) {
        return '#ffffff';
    }
    const substring = lightValue.substring(13, 19);

    if (!substring) {
        return 'white';
    }
    return '#' + substring;
};

export const getDatapointColorStringToSend = (
    value: string,
    warmwhite: string,
    useWarmWhite: string,
    color: string,
): string => {
    return `%23${value.toString().length == 1 ? '0' : ''}${value}000000${warmwhite}0${Number(useWarmWhite)}${color}`;
};

export const getColorStringWithChangedValueToSend = (newValue: number, oldValueString: string): string => {
    const warmWhite = oldValueString.substring(9, 11);
    const useWarmWhite = oldValueString.substring(12, 13);
    const color = oldValueString.substring(13, 19);
    const hexValue = newValue.toString(16);

    return getDatapointColorStringToSend(
        hexValue,
        warmWhite,
        useWarmWhite,
        color == '000000' && newValue > 0 ? 'FFFFFF' : color,
    );
};

export const getColorStringWithChangedColorToSend = (color: string, oldValueString: string): string => {
    const warmWhite = oldValueString.substring(9, 11);
    const useWarmWhite = oldValueString.substring(12, 13);
    const hexValue = oldValueString.substring(1, 3);

    return getDatapointColorStringToSend(hexValue, warmWhite, useWarmWhite, color);
};

export const getDatapointColorString = (
    value: string,
    warmwhite: string,
    useWarmWhite: string,
    color: string,
): string => {
    return `#${value.toString().length == 1 ? '0' : ''}${value}000000${warmwhite}0${Number(useWarmWhite)}${color}`;
};

export const getColorStringWithChangedValue = (newValue: number, oldValueString: string): string => {
    const warmWhite = oldValueString.substring(9, 11);
    const useWarmWhite = oldValueString.substring(12, 13);
    let color = oldValueString.substring(13, 19);
    const hexValue = newValue.toString(16);

    if (color == '000000' && newValue > 0) {
        color = 'FFFFFF';
    } else if (newValue === 0 && color === 'FFFFFF') {
        color = '000000';
    }

    return getDatapointColorString(hexValue, warmWhite, useWarmWhite, color);
};

export const getColorStringWithChangedColor = (color: string, oldValueString: string): string => {
    const warmWhite = oldValueString.substring(9, 11);
    const useWarmWhite = oldValueString.substring(12, 13);
    const hexValue = oldValueString.substring(1, 3);

    return getDatapointColorString(hexValue, warmWhite, useWarmWhite, color);
};

export const getValueValidatedByRange = (datapoint?: Datapoint): number => {
    if (!datapoint) {
        return 0;
    }
    const value = Number(datapoint.value);
    const min = Number(datapoint.range[0] ?? 0);

    if (!value) {
        return min ?? 0;
    }

    return value < min ? min : value;
};

export const GetRoomCategoryImage = (category: RoomType): string => {
    switch (category) {
        case RoomType.Bathroom:
            return BathroomImg;
        case RoomType.Room:
            return RoomImg;
        case RoomType.Bedroom:
            return BedroomImg;
        case RoomType.LivingRoom:
            return LivingRoomImg;
        case RoomType.Kitchen:
            return KitchenImg;
        case RoomType.HobbyRoom:
            return HobbyRoomImg;
        case RoomType.DiningRoom:
            return DiningRoomImg;
        case RoomType.DressingRoom:
            return DressingRoomImg;
        case RoomType.Garage:
            return GarageImg;
        case RoomType.KidsBedroom:
            return KidsRoomImg;
        case RoomType.Office:
            return OfficeImg;
        case RoomType.Washroom:
            return WashroomImg;
        case RoomType.Balcony:
            return BalkonyImg;
        case RoomType.Cellar:
            return CellarImg;
        case RoomType.Toilet:
            return ToiletImg;
        case RoomType.Central:
            return CentralImg;
        default:
            return UnknownImg;
    }
};

export const convertMonitorValue = (value: string): string => {
    if (value === 'true') {
        return '1';
    }

    if (value === 'false') {
        return '0';
    }

    if (value.includes('#')) {
        return getLightValue(value, ['0', '100']).toString();
    }

    return value;
};
