import { DatapointType } from '../../../../models/enums/DatapointType';
import { ControllersProps } from '../ControllersProps';
import { useState } from 'react';
import GenericControls from '../generic-controls/GenericControls';
import AstroEditModal from '../../../../components/astro-edit-modal/AstroEditModal';

const Astro = (props: ControllersProps): JSX.Element => {
    const { virtualDevice } = props;

    const timeProgramDatapoint = virtualDevice?.datapoints?.find((x) => x.type == DatapointType.AstroFunctionConfig);

    const [isTimeProgramConfigModalOpen, setIsTimeProgramConfigModalOpen] = useState<boolean>(false);

    return (
        <>
            <GenericControls onEditButtonClick={() => setIsTimeProgramConfigModalOpen(true)} {...props} />
            {timeProgramDatapoint && isTimeProgramConfigModalOpen && (
                <AstroEditModal
                    datapoint={timeProgramDatapoint}
                    virtualDevice={virtualDevice}
                    onCloseRequested={() => setIsTimeProgramConfigModalOpen(false)}
                />
            )}
        </>
    );
};

export default Astro;
