import { useTranslation } from 'react-i18next';
import { EnergyManagementDevice, EnergyManagementDeviceProperty } from '../../../models/EnergyManagementDevice';
import SummaryDevice from './SummaryDevice/SummaryDevice';

import styles from './SummaryTab.module.scss';

type Props = {
    functionBlocks: EnergyManagementDevice[];
    onPropertyChanged: (
        device: EnergyManagementDevice,
        property: EnergyManagementDeviceProperty,
        newProperty: EnergyManagementDeviceProperty,
    ) => void;
};

const SummaryTab = ({ functionBlocks, onPropertyChanged }: Props): JSX.Element => {
    const { t } = useTranslation();
    const allBlocks = functionBlocks.flatMap((x) => x.Priorities.map((z) => ({ block: x, priority: z })));
    const onBlocks = allBlocks.filter((x) => x.priority.Mode === 1);
    const offBlocks = allBlocks.filter((x) => x.priority.Mode === 0);
    const otherBlocks = allBlocks
        .filter((x) => x.priority.Mode !== 0 && x.priority.Mode !== 1)
        .sort((x, y) => (x.priority.Priority ?? 0) - (y.priority.Priority ?? 0));
    const blocksToShow = [...onBlocks, ...otherBlocks, ...offBlocks];
    return (
        <div className={styles.wrapper}>
            <div className={styles.headerWrapper}>
                <div className={styles.header}>{t('general.name')}</div>
                <div className={styles.header}>{t('energyManagementConfiguration.priority')}</div>
                <div className={styles.header}>{t('energyManagementConfiguration.level')}</div>
                <div className={styles.header}>{t('energyManagementConfiguration.mode')}</div>
                <div className={styles.header}>{t('energyManagementConfiguration.minPower') + ' (kW)'}</div>
                <div className={styles.header}>{t('energyManagementConfiguration.maxPower') + ' (kW)'}</div>
                <div className={styles.header}>{t('energyManagementConfiguration.maxEnergy') + ' (kWh)'}</div>
                <div className={styles.header}>{t('energyManagementConfiguration.preparationTime') + ' (min)'}</div>
                <div className={styles.lastHeader}>{t('energyManagementConfiguration.minRuntime') + ' (min)'}</div>
            </div>
            {blocksToShow.map(({ block, priority }, index) => (
                <SummaryDevice
                    allDevices={functionBlocks}
                    allPriority={allBlocks.map((x) => x.priority)}
                    onPropertyChanged={onPropertyChanged}
                    priority={priority}
                    key={index}
                    device={block}
                />
            ))}
        </div>
    );
};

export default SummaryTab;
