import { Card } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getSetupInfo, setSetupInfo } from '../app/globalSettings';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import { isCloud } from '../env.json';
import { getCloudCredentials, getUserCredential, removeUserCredential } from '../helpers/CookieHelper';
import { fetchSetupInfo } from '../helpers/HttpMethods';
import { SettingsTabs } from '../helpers/TabsHelper';
import { Routings } from '../models/enums/Routings';
import styles from './Settings.module.scss';

const SettingsPage = (): JSX.Element => {
    const setupInfo = useAppSelector(getSetupInfo);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (setupInfo) {
                setIsLoading(false);
                return;
            }
            setIsLoading(true);
            const userCredential = isCloud ? getCloudCredentials() : getUserCredential();
            if (!userCredential) {
                history.replace(Routings.LoginPage);
                return;
            }

            const result = await fetchSetupInfo();

            if (result.status !== StatusCodes.OK) {
                removeUserCredential();
                history.replace(Routings.LoginPage);
                return;
            }
            setIsLoading(false);
            dispatch(setSetupInfo(result));
        })();
    }, []);

    return (
        <div className={styles.mainContainer}>
            <Header />
            {!isLoading && (
                <Container className={styles.settingsContainer}>
                    <Row>
                        {SettingsTabs(t).map((tab, index) => (
                            <Col xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                                <Card className={styles.card} onClick={() => history.push(tab.route)}>
                                    <div className={styles.cardHeader}>
                                        <div className={styles.cardIcon}>{tab.icon}</div>
                                        <div>{tab.name}</div>
                                    </div>
                                    <div className={styles.tabDesc}>{tab.description}</div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            )}

            <Footer />
        </div>
    );
};

export default SettingsPage;
