import UniversalCookie from 'universal-cookie';
import { getTheme, setTheme } from '../app/globalSettings';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Cookies } from '../models/enums/Cookies';

const cookies = new UniversalCookie();

export enum ThemeType {
    light = 0,
    dark = 1,
}

const lightColors = {
    background_Button_Layer0: '#BEBEBE',
    background_Button_Layer1: '#C8C8C8',
    background_Button_Layer2: '#F0F0F0',
    text_Button_Layer0: '#505050',
    text_Button_Layer1: '#505050',
    text_Label_Title: '#505050',
    page_background_Layer0: '#AFAFAF',
    page_Background_Layer1: '#D2D2D2',
    page_Background_Layer2: '#E6E6E6',
    entry_Background_Layer2: '#DCDCDC',
};

const darkColors = {
    background_Button_Layer0: '#464646',
    background_Button_Layer1: '#6E6E6E',
    background_Button_Layer2: '#7D7D7D',
    text_Button_Layer0: '#E2E2E2',
    text_Button_Layer1: '#919191',
    text_Label_Title: '#FFFFFF',
    page_background_Layer0: '#373737',
    page_Background_Layer1: '#555555',
    page_Background_Layer2: '#6E6E6E',
    entry_Background_Layer2: '#646464',
};

export const getStorageTheme = (): ThemeType => {
    const result = cookies.get(Cookies.Theme);

    if (!result) {
        return ThemeType.light;
    }

    return Number(result);
};

export const saveThemeToStorage = (theme: ThemeType): void => {
    cookies.set(Cookies.Theme, theme);
};

export const useTheme = () => {
    const dispatch = useAppDispatch();
    const theme = useAppSelector(getTheme);
    const colors = theme === ThemeType.dark ? darkColors : lightColors;

    const getDisabledColor = (color: string) => {
        return `${color}59`;
    };

    const changeTheme = async (newTheme: ThemeType) => {
        await saveThemeToStorage(newTheme);
        dispatch(setTheme(newTheme));
    };

    return { theme, colors, darkColors, changeTheme, getDisabledColor };
};
