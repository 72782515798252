import styles from './StaircaseLight.module.scss';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { pushButtonDatapoint } from '../../../../helpers/HttpMethods';
import { DatapointNames } from '../../../../models/enums/DatapointNames';
import { getBooleanDatapointValue, getNumberDatapointValue } from '../../../../helpers/DatapointHelper';
import { ControllersProps } from '../ControllersProps';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CustomButton from '../components/custom-button/CustomButton';
import ControlHeader from '../components/control-header/ControlHeader';

const StaircaseLight = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const mainSwitchDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Switch && x.name == DatapointNames.VirtualSwitch,
    );
    const activeDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualOutput,
    );
    const countdownDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualCountdown,
    );
    const startCountdownDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualStartTimer,
    );

    return (
        <div className={styles.mainContainer} style={{ border: border }}>
            <ControlHeader virtualDevice={virtualDevice} room={room} switchDatapoint={mainSwitchDatapoint} />
            {activeDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('staircaseLight.lightStatus')}</div>
                    <div>{getBooleanDatapointValue(activeDatapoint) ? t('general.on') : t('general.off')}</div>
                </div>
            )}
            {countdownDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('staircaseLight.countdown')}</div>
                    <div>{`${getNumberDatapointValue(countdownDatapoint)}s`}</div>
                </div>
            )}
            {startCountdownDatapoint && (
                <div className={styles.buttonContainer}>
                    <CustomButton
                        className={styles.button}
                        onClick={() => pushButtonDatapoint(startCountdownDatapoint)}
                        title={t('staircaseLight.start')}
                    />
                </div>
            )}
        </div>
    );
};

export default StaircaseLight;
