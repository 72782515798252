export enum NotificationType {
    DeviceNotResponding = -1,
    Undefined,
    SystemAlarm,
    UnableToSetAlarm,
    FireAlarm,
    WaterAlarm,
    SmokeAlarm,
    Battery,
    Custom,
    ServerUnavailable,
    Energy,
}
