export enum OpeartionModeConfigType {
    Switch = 1,
    Dimmer = 2,
    Dropdown = 3,
}

export interface OperationModeConfig {
    Description: string;
    Name: string;
    ReadOnly: boolean;
    Type: OpeartionModeConfigType;
    DropdownList: {
        Name: string;
        Value: number;
    }[];
    Maximum?: string;
    Minimum?: string;
    Step?: string;
    Unit?: string;
}

export interface OperationMode {
    ID: number;
    Name: string;
    values: {
        Name: string;
        Value: string | boolean;
    }[];
}
