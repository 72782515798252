import { Datum, Line } from '@ant-design/charts';
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Select, Space } from 'antd';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSetupInfo } from '../app/globalSettings';
import { useAppSelector } from '../app/hooks';
import { convertMonitorValue } from '../helpers/DatapointHelper';
import { DatapointType } from '../models/enums/DatapointType';
import { Ios } from '../models/Ios';
import { VirtualDevice } from '../models/VirtualDevice';
import styles from './MonitorLiveView.module.scss';

const { Option } = Select;

const dateFormat = 'yyyy-MM-DD HH:mm:ss';

interface Props {
    virtualDevice: VirtualDevice;
    onClose: () => void;
}

const MonitorLiveView = (props: Props): JSX.Element => {
    const { virtualDevice, onClose } = props;
    const setupInfo = useAppSelector(getSetupInfo);
    const { t } = useTranslation();

    const [dataLogs, setDataLogs] = useState<{ date: string; ios: Ios }[][]>([[]]);
    const [isPaused, setIsPaused] = useState(false);

    const supportedIos: Ios[] = useMemo(() => {
        if (!setupInfo?.ios) {
            return [];
        }

        const monitor = setupInfo?.objects?.items?.find((x) => x.id === virtualDevice.id);
        const datapoints = monitor?.datapoints?.find((x) => x.type === DatapointType.MonitorConfig);

        if (!datapoints) {
            return [];
        }

        const ioIds = datapoints.Monitor.filter((x) => x.Log).map((x) => x.IOid);

        return setupInfo?.ios.filter((x) => ioIds.some((y) => y === x.id));
    }, [setupInfo?.objects]);

    const defaultOption = supportedIos ? supportedIos.map((x) => x.id.toString()) : [];
    const [selectedIos, setSelectedIos] = useState<string[]>(defaultOption);

    const [time, setTime] = useState(0);

    useEffect(() => {
        const timer = window.setInterval(() => {
            setTime((prev) => prev + 1);
        }, 10000);
        return () => {
            window.clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (!setupInfo?.ios || isPaused) {
            return;
        }

        setDataLogs((prev) => [
            ...prev,
            setupInfo.ios
                .filter((x) => supportedIos.some((y) => x.id === y.id))
                .map((x) => ({ date: moment().format(dateFormat), ios: x })),
        ]);
    }, [time]);

    const chartData = useMemo(
        () =>
            dataLogs
                .flatMap((x) => x)
                .sort((a, b) => {
                    return moment(a.date, dateFormat).diff(moment(b.date, dateFormat));
                })
                .filter(
                    (x) =>
                        supportedIos.some((y) => x.ios.id === y.id) && selectedIos.some((y) => x.ios.id === Number(y)),
                )
                .map((x) => ({
                    date: x.date,
                    data: Number(convertMonitorValue(x.ios.value?.toString() ?? '')),
                    category: `(${(supportedIos.findIndex((y) => y.id === x.ios.id) + 1).toString()}) ${
                        x.ios?.name ?? ''
                    }`,
                    unit:
                        setupInfo?.objects?.items
                            ?.find((x) => x.id === virtualDevice.id)
                            ?.datapoints?.find((x) => x.type === DatapointType.MonitorConfig)
                            ?.Monitor.find((y) => y.IOid === x.ios.id)?.Unit ?? '',
                })),
        [dataLogs, selectedIos],
    );

    const chartConfig = useMemo(
        () => ({
            xField: 'date',
            yField: 'data',
            seriesField: 'category',
            stepType: 'hv',
            smooth: false,
            animation: {
                appear: {
                    animation: 'path-in',
                    duration: 0,
                },
            },
            slider: isPaused
                ? {
                      start: 0,
                      end: 1,
                  }
                : undefined,
            tooltip: {
                formatter: (datum: Datum) => {
                    return {
                        name: datum.category,
                        value:
                            datum.data +
                            ' ' +
                            (chartData
                                .slice()
                                .reverse()
                                .find((x) => x.category === datum.category)?.unit ?? ''),
                    };
                },
            },
        }),
        [chartData, isPaused],
    );

    const options = supportedIos?.map((x, index) => (
        <Option value={x.id.toString()} key={`(${index + 1}) ${x.name}`}>{`(${index + 1}) ${x.name}`}</Option>
    ));

    return (
        <Modal
            width="fit-content"
            title={virtualDevice?.name ?? ''}
            cancelText={t('general.close')}
            style={{ top: 30, minWidth: '80vw' }}
            open={true}
            onCancel={onClose}
            okButtonProps={{ style: { display: 'none' } }}
        >
            <div className={styles.mainContainer}>
                {supportedIos.length > 0 && setupInfo && (
                    <div className={styles.configContainer}>
                        <Select
                            mode="multiple"
                            maxTagCount={1}
                            maxTagTextLength={33}
                            allowClear={false}
                            style={{ width: '100%', maxWidth: 350, marginRight: 20, maxHeight: 35 }}
                            defaultValue={defaultOption}
                            onChange={(v) => setSelectedIos(v)}
                        >
                            {options}
                        </Select>
                        <Space>
                            <Button className={styles.startButton} onClick={() => setIsPaused((prev) => !prev)}>
                                {isPaused ? (
                                    <>
                                        <PlayCircleOutlined style={{ fontSize: 16, marginRight: 7 }} />
                                        {t('general.start')}
                                    </>
                                ) : (
                                    <>
                                        <PauseCircleOutlined style={{ fontSize: 16, marginRight: 7 }} />
                                        {t('general.stop')}
                                    </>
                                )}
                            </Button>
                            <Button
                                onClick={() =>
                                    setDataLogs([
                                        setupInfo.ios
                                            .filter((x) => supportedIos.some((y) => x.id === y.id))
                                            .map((x) => ({ date: moment().format(dateFormat), ios: x })),
                                    ])
                                }
                            >
                                {t('general.reset')}
                            </Button>
                        </Space>
                    </div>
                )}
                <Line {...chartConfig} data={chartData} style={{ width: '100%', height: '75vh' }} />
            </div>
        </Modal>
    );
};

export default MonitorLiveView;
