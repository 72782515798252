export enum WebsocketCode {
    Unknown = -1,

    //Info
    StartApplication = 1,
    ConfigureIoConfiguration = 2,
    DeviceAvailable = 3,
    StartIoTHubClient = 4,
    StopIoTHubClient = 5,
    UpdateAvailable = 6,
    UpdateProcessStarted = 7,

    //Warninig
    DeviceNotResponding = 10000,
    SetIoConfigurationFails = 10001,
    ZwNodeSecurityTimedOut = 10002,

    //Error
    ApplicationCrash = 20000,
    ZWaveInitFailed = 20001,
    IoTHubClientCrash = 20002,
    UpdateProcessFailed = 20003,
    UpdateDataCorrupt = 20004,
    GetUpdateInfoFailed = 20005,
    ComponentInfoMismatch = 20006,

    //Update error 21000 - 21999
    UpdateCheckerErrorOccured = 21000,

    //Debug
    ConnectedDeviceRestarted = 30000,
    DeviceRemovedByFactoryReset = 30001,
    CheckForUpdates = 30002,
    CheckForUpdatesFailed = 30003,
}
