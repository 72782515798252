import { Colors } from './Colors';
import BulbImg from '../../images/light.svg';
import BlindsImg from '../../images/blinds.svg';
import AiringImg from '../../images/airing.svg';
import WindowDoorImg from '../../images/window_door.svg';
import HeatingImg from '../../images/heating.svg';
import EnergyImg from '../../images/energy.svg';
import CentralImg from '../../images/central.svg';
import RadioMotionImg from '../../images/radio_motion.svg';
import WaterImg from '../../images/water.svg';
import SmokeImg from '../../images/radio_smoke.svg';
import SecurityImg from '../../images/security.svg';
import BatteryImg from '../../images/battery.svg';
import SirenImg from '../../images/siren.svg';
import TimerImg from '../../images/timer.svg';
import UnknownImg from '../../images/unknown.svg';
import { VirtualDeviceCategory } from '../enums/VirtualDeviceCategory';
import { TFunction } from 'react-i18next';

export const VirtualDeviceCategorySettings = (
    t: TFunction,
): { category?: number; color?: string; img?: string; name?: string }[] => [
    {
        category: VirtualDeviceCategory.Wrong,
        color: Colors.undefinedCategory,
        img: UnknownImg,
    },
    {
        category: VirtualDeviceCategory.Undefined,
        color: Colors.undefinedCategory,
        img: UnknownImg,
    },
    {
        category: VirtualDeviceCategory.LightingCategory,
        color: Colors.lightCategory,
        img: BulbImg,
        name: t('virtualDeviceCategorySettings.light'),
    },
    {
        category: VirtualDeviceCategory.Blinds,
        color: Colors.blindsCategory,
        img: BlindsImg,
        name: t('virtualDeviceCategorySettings.blinds'),
    },
    {
        category: VirtualDeviceCategory.WindowsAndDoors,
        color: Colors.windowsAndDoorsCategory,
        img: WindowDoorImg,
        name: t('virtualDeviceCategorySettings.windowsAndDoors'),
    },
    {
        category: VirtualDeviceCategory.Heating,
        color: Colors.heatingCategory,
        img: HeatingImg,
        name: t('virtualDeviceCategorySettings.heating'),
    },
    {
        category: VirtualDeviceCategory.Energy,
        color: Colors.energyCategory,
        img: EnergyImg,
        name: t('virtualDeviceCategorySettings.energy'),
    },
    {
        category: VirtualDeviceCategory.Central,
        color: Colors.centralCategory,
        img: CentralImg,
        name: t('virtualDeviceCategorySettings.central'),
    },
    {
        category: VirtualDeviceCategory.Airing,
        color: Colors.airingCategory,
        img: AiringImg,
        name: t('virtualDeviceCategorySettings.airing'),
    },
    {
        category: VirtualDeviceCategory.PresenceDetector,
        color: Colors.presenceDetectorCategory,
        img: RadioMotionImg,
        name: t('virtualDeviceCategorySettings.presenceDetector'),
    },
    {
        category: VirtualDeviceCategory.Water,
        color: Colors.waterCategory,
        img: WaterImg,
        name: t('virtualDeviceCategorySettings.water'),
    },
    {
        category: VirtualDeviceCategory.Smoke,
        color: Colors.smokeCategory,
        img: SmokeImg,
        name: t('virtualDeviceCategorySettings.smoke'),
    },
    {
        category: VirtualDeviceCategory.Security,
        color: Colors.securityCategory,
        img: SecurityImg,
        name: t('virtualDeviceCategorySettings.security'),
    },
    {
        category: VirtualDeviceCategory.Battery,
        color: Colors.batteryCategory,
        img: BatteryImg,
        name: t('virtualDeviceCategorySettings.battery'),
    },
    {
        category: VirtualDeviceCategory.Siren,
        color: Colors.sirenCategory,
        img: SirenImg,
        name: t('virtualDeviceCategorySettings.siren'),
    },
    {
        category: VirtualDeviceCategory.PowerSocket,
        color: Colors.powerSocketCategory,
        img: TimerImg,
        name: t('virtualDeviceCategorySettings.powerSocket'),
    },
];
