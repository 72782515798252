import styles from './Gate.module.scss';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { pushButtonDatapoint } from '../../../../helpers/HttpMethods';
import { DatapointNames } from '../../../../models/enums/DatapointNames';
import { getNumberDatapointValue } from '../../../../helpers/DatapointHelper';
import { ControllersProps } from '../ControllersProps';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CustomButton from '../components/custom-button/CustomButton';
import { Range, getTrackBackground, Direction } from 'react-range';
import DownArrowImg from '../../../../images/down_arrow.svg';
import ControlHeader from '../components/control-header/ControlHeader';

const Gate = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const triggerOpenDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualTriggerOpen,
    );
    const triggerCloseDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualTriggerClose,
    );
    const stopDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualStop,
    );
    const positionDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualPosition,
    );

    return (
        <div className={styles.mainContainer} style={{ border: border }}>
            <ControlHeader virtualDevice={virtualDevice} room={room} />
            <div className={styles.datapointContainer}>
                <div className={styles.buttonsContainer}>
                    <div className={styles.moveButtons}>
                        {triggerOpenDatapoint && (
                            <CustomButton
                                className={classNames(styles.button, styles.roundButton, styles.rotate)}
                                onClick={() => pushButtonDatapoint(triggerOpenDatapoint)}
                                img={DownArrowImg}
                            />
                        )}
                        {triggerCloseDatapoint && (
                            <CustomButton
                                className={classNames(styles.button, styles.roundButton)}
                                imgClassName={styles.buttonImg}
                                onClick={() => pushButtonDatapoint(triggerCloseDatapoint)}
                                img={DownArrowImg}
                            />
                        )}
                    </div>
                    {stopDatapoint && (
                        <CustomButton
                            className={styles.button}
                            onClick={() => pushButtonDatapoint(stopDatapoint)}
                            title={t('gate.stop')}
                        />
                    )}
                </div>
                {positionDatapoint && (
                    <div>
                        <Range
                            direction={Direction.Up}
                            values={[getNumberDatapointValue(positionDatapoint)]}
                            step={1}
                            min={0}
                            max={100}
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onChange={() => {}}
                            renderTrack={({ props, children }) => (
                                <div
                                    onMouseDown={props.onMouseDown}
                                    onTouchStart={props.onTouchStart}
                                    style={{
                                        ...props.style,
                                        flexGrow: 1,
                                        display: 'flex',
                                        height: '100%',
                                        cursor: 'auto',
                                    }}
                                >
                                    <div
                                        ref={props.ref}
                                        style={{
                                            width: '5px',
                                            height: '100%',
                                            borderRadius: '4px',
                                            background: getTrackBackground({
                                                values: [getNumberDatapointValue(positionDatapoint)],
                                                colors: ['#ccc', category?.color ?? '#548BF4'],
                                                min: 0,
                                                max: 100,
                                                direction: Direction.Up,
                                            }),
                                            alignSelf: 'center',
                                        }}
                                    >
                                        {children}
                                    </div>
                                </div>
                            )}
                            renderThumb={({ props }) => (
                                <div {...props} style={{ cursor: 'auto' }} className={styles.sliderThumb}>
                                    {getNumberDatapointValue(positionDatapoint)}
                                </div>
                            )}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Gate;
