import { Select } from 'antd';
import classNames from 'classnames';
import { StatusCodes } from 'http-status-codes';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import EnergyManagementConfigurationModal from '../../../../components/energy-management-configuration-modal/EnergyManagementConfigurationModal';
import { updateDatapointValue } from '../../../../helpers/HttpMethods';
import { Datapoint } from '../../../../models/Datapoint';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { ControllersProps } from '../ControllersProps';
import ControlHeader from '../components/control-header/ControlHeader';
import styles from './EnergyConfiguration.module.scss';

const EnergyConfiguration = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room, order, forceShowAll } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const values2Datapoints = virtualDevice?.datapoints?.filter((x) => x.type == DatapointType.Number);

    const valuesDatapoints = virtualDevice?.datapoints
        ?.filter((x) => x.type == DatapointType.EnergyManagementDeviceMode)
        .filter((x, index) => !!values2Datapoints[index]);

    const energyConfigurationDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.EnergyManagementConfig,
    );

    const [datapointsVisible, setDatapointsVisible] = useState<boolean>(false);
    const [configModalVisible, setConfigModalVisible] = useState(false);

    const onDatapointValueChanged = async (value: boolean | number | string, datapoint?: Datapoint) => {
        if (datapoint) {
            const result = await updateDatapointValue(datapoint, value);
            if (result.status !== StatusCodes.OK) {
                toast.error(t('errors.errorWhileSendingValue'));
                return;
            }
        }
    };

    const selectOptions = useMemo(
        () => [
            {
                value: 0,
                label: t('general.off'),
            },
            {
                value: 1,
                label: t('general.auto'),
            },
            {
                value: 2,
                label: t('general.on'),
            },
            {
                value: 3,
                label: t('general.solar'),
            },
            {
                value: 4,
                label: t('general.solarAndLowPrice'),
            },
        ],
        [],
    );

    const getValue = (index: number) => {
        const dp = values2Datapoints[index];
        const config = energyConfigurationDatapoint?.EnergyManagementConfig?.Devices?.[index];

        if (dp?.value === undefined) {
            return '';
        }

        if (typeof dp.value === 'boolean') {
            return dp.value ? `${config?.MinPower?.toFixed(1) ?? '0.0'} kW` : `0.0 kW`;
        }

        if (dp.value.toString() === 'false') {
            return `0.0 kW`;
        }

        if (dp.value.toString() === 'true') {
            return `${config?.MinPower?.toFixed(1) ?? '0.0'} kW`;
        }

        const numberValue = Number(dp.value);

        if (!isNaN(numberValue)) {
            return `${numberValue.toFixed(1)} kW`;
        }

        return dp.value.toString();
    };

    return (
        <div
            className={classNames(styles.mainContainer, {
                [styles.mainContainerZeroValue]: (valuesDatapoints?.length ?? 0) == 0,
                [styles.mainContainerOneValue]: (valuesDatapoints?.length ?? 0) == 1,
                [styles.mainContainerTwoValue]: (valuesDatapoints?.length ?? 0) == 2,
            })}
            style={{ zIndex: order, border: border }}
        >
            <ControlHeader
                virtualDevice={virtualDevice}
                room={room}
                onEditButtonClick={() => setConfigModalVisible(true)}
            />
            {valuesDatapoints?.slice(0, forceShowAll ? valuesDatapoints.length : 4).map((x, index) => (
                <div key={x.id} className={classNames(styles.titleValue, styles.datapointTop)}>
                    <div className={styles.name}>
                        {energyConfigurationDatapoint?.EnergyManagementConfig?.Devices?.[index]?.Name}
                    </div>
                    <Select
                        onChange={(v) => onDatapointValueChanged(v, x)}
                        value={+x.value}
                        style={{ width: 100 }}
                        options={selectOptions}
                    />
                    <div style={{ minWidth: 50, textAlign: 'end' }}>{getValue(index)}</div>
                </div>
            ))}
            {valuesDatapoints.length > 4 && !forceShowAll && (
                <div
                    className={styles.dots}
                    onMouseEnter={() => {
                        setDatapointsVisible(true);
                    }}
                    onMouseLeave={() => setDatapointsVisible(false)}
                >
                    <span className={styles.dot} />
                    <span className={styles.dot} />
                    <span className={styles.dot} />
                    {datapointsVisible && (
                        <div
                            className={styles.datapointsList}
                            style={{
                                zIndex: order,
                                borderBottom: border,
                                borderLeft: border,
                                borderRight: border,
                            }}
                        >
                            {valuesDatapoints?.slice(4).map((x, index) => (
                                <div key={x.id} className={classNames(styles.titleValue, styles.datapoint)}>
                                    <div className={styles.name}>
                                        {
                                            energyConfigurationDatapoint?.EnergyManagementConfig?.Devices?.[index + 4]
                                                ?.Name
                                        }
                                    </div>
                                    <Select
                                        value={+x.value}
                                        style={{ width: 100, marginRight: 5 }}
                                        options={selectOptions}
                                        onChange={(v) => onDatapointValueChanged(v, x)}
                                    />
                                    <div style={{ marginRight: 5, minWidth: 50, textAlign: 'end' }}>
                                        {getValue(index + 4)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
            {energyConfigurationDatapoint && configModalVisible && (
                <EnergyManagementConfigurationModal
                    onCloseRequested={() => setConfigModalVisible(false)}
                    virtualDevice={virtualDevice}
                    datapoint={energyConfigurationDatapoint}
                />
            )}
        </div>
    );
};

export default EnergyConfiguration;
