import { Modal, Spin } from 'antd';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import i18n from 'i18next';

interface LocationMarkerProps {
    positionClicked?: (position: { lat: number; lng: number }) => void;
}

const provider = new OpenStreetMapProvider({ params: { 'accept-language': i18n.resolvedLanguage } });

const Search = () => {
    const map = useMap();

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const searchControl = new GeoSearchControl({
            provider,
            showMarker: false,
        });

        map.addControl(searchControl);
        return () => map.removeControl(searchControl);
    }, []);

    return null;
};

const LocationMarker = ({ positionClicked }: LocationMarkerProps) => {
    useMapEvents({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        click(e: any) {
            positionClicked?.(e.latlng);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        'geosearch/showlocation'(e: any) {
            positionClicked?.({ lat: e.location.y, lng: e.location.x });
        },
    });

    return null;
};

interface Props {
    onCloseRequested: () => void;
    onSave: (position: { lat: string; lng: string }) => void;
    position: { lat: string; lng: string };
}

export const getLabel = async (lat: number, lng: number): Promise<string> => {
    const result = await provider.search({
        query: `${lat.toFixed(14)}, ${lng.toFixed(14)}`,
    });

    return result?.[0]?.label ?? '?';
};

const AstroMap = (props: Props): JSX.Element => {
    const { position, onSave, onCloseRequested } = props;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const markerRef = useRef<any>(null);

    const [isLabelLoading, setIsLabelLoading] = useState(false);
    const [currentPosition, setCurrentPosition] = useState([Number(position.lat), Number(position.lng)]);
    const [label, setLabel] = useState('');

    useEffect(() => {
        (async () => {
            setIsLabelLoading(true);
            setLabel(await getLabel(Number(currentPosition[0]), Number(currentPosition[1])));
            markerRef?.current?.openPopup();
            setIsLabelLoading(false);
        })();
    }, [currentPosition]);

    return (
        <Modal
            open={true}
            onCancel={onCloseRequested}
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={() => onSave({ lat: currentPosition[0].toString(), lng: currentPosition[1].toString() })}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            width="80vw"
        >
            <div style={{ height: '60vh' }}>
                <MapContainer style={{ height: '100%' }} center={currentPosition} zoom={13}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <LocationMarker positionClicked={(e) => setCurrentPosition([e.lat, e.lng])} />
                    <Search />
                    <Marker ref={markerRef} position={currentPosition}>
                        <Popup>{isLabelLoading ? <Spin /> : label}</Popup>
                    </Marker>
                </MapContainer>
            </div>
        </Modal>
    );
};

export default AstroMap;
