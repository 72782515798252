import classNames from 'classnames';
import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ColorSwitch from '../../../../components/color-switch/ColorSwitch';
import TimeProgramEditModal, {
    TimeProgramType,
} from '../../../../components/time-program-edit-modal/TimeProgramEditModal';
import { getBooleanDatapointValue } from '../../../../helpers/DatapointHelper';
import { updateDatapointValue } from '../../../../helpers/HttpMethods';
import { Datapoint } from '../../../../models/Datapoint';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { DatapointNames } from '../../../../models/enums/DatapointNames';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { ControllersProps } from '../ControllersProps';
import ControlHeader from '../components/control-header/ControlHeader';
import styles from './Timer.module.scss';

const Timer = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const mainSwitchDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Switch && x.name == DatapointNames.VirtualSwitch,
    );
    const schedulerOnDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Switch && x.name == DatapointNames.VirtualSchedulerOn,
    );
    const timeProgramDatapoint = virtualDevice?.datapoints?.find((x) => x.type == DatapointType.TimeProgram);

    const [isTimeProgramConfigModalOpen, setIsTimeProgramConfigModalOpen] = useState<boolean>(false);

    const onDatapointValueChanged = async (value: boolean | number | string, datapoint?: Datapoint) => {
        if (datapoint) {
            const result = await updateDatapointValue(datapoint, value);
            if (result.status !== StatusCodes.OK) {
                toast.error(t('errors.errorWhileSendingValue'));
                return;
            }
        }
    };

    return (
        <div className={styles.mainContainer} style={{ border: border }}>
            <ControlHeader
                onEditButtonClick={() => setIsTimeProgramConfigModalOpen(true)}
                editButtonDisabled={timeProgramDatapoint?.writeprotect}
                virtualDevice={virtualDevice}
                room={room}
                switchDatapoint={mainSwitchDatapoint}
            />
            {schedulerOnDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('timer.shedulerOn')}</div>
                    <ColorSwitch
                        disabled={timeProgramDatapoint?.writeprotect ?? false}
                        className={styles.switch}
                        onColor={category?.color}
                        onChange={(checked) => onDatapointValueChanged(checked, schedulerOnDatapoint)}
                        checked={getBooleanDatapointValue(schedulerOnDatapoint)}
                    />
                </div>
            )}
            {timeProgramDatapoint && isTimeProgramConfigModalOpen && (
                <TimeProgramEditModal
                    closeModalRequested={() => setIsTimeProgramConfigModalOpen(false)}
                    timeProgramDatapoint={timeProgramDatapoint}
                    virtualDevice={virtualDevice}
                    type={TimeProgramType.Timer}
                />
            )}
        </div>
    );
};

export default Timer;
