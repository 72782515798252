export enum DatapointNames {
    VirtualSwitch = 'VirtualSwitch',
    VirtualSlider = 'VirtualSlider',
    VirtualOutput = 'VirtualOutput',
    VirtualSchedulerOn = 'VirtualSchedulerOn',
    VirtualTrigger = 'VirtualTrigger',
    VirtualLight = 'VirtualLight',
    VirtualFan = 'VirtualFan',
    VirtualFireAlarm = 'VirtualFireAlarm',
    VirtualSmokeAlarm = 'VirtualSmokeAlarm',
    VirtualWaterAlarm = 'VirtualWaterAlarm',
    VirtualQuitAlarm = 'VirtualQuitAlarm',
    VirtualAlarmSystemIsArmed = 'VirtualAlarmSystemIsArmed',
    VirtualArmedWithoutPresenceDetectors = 'VirtualArmedWithoutPresenceDetectors',
    VirtualAcivationCountdown = 'VirtualAcivationCountdown',
    VirtualIsAlarm = 'VirtualIsAlarm',
    IsAlarm = 'IsAlarm',
    VirtualArmAlarmSystem = 'VirtualArmAlarmSystem',
    VirtualArmAlarmSystemDelayed = 'VirtualArmAlarmSystemDelayed',
    VirtualArmWithoutPresenceDetectors = 'VirtualArmWithoutPresenceDetectors',
    VirtualDisarmAlarmSystem = 'VirtualDisarmAlarmSystem',
    VirtualIsSnooze = 'VirtualIsSnooze',
    VirtualSnoozeIntervalDuration = 'VirtualSnoozeIntervalDuration',
    VirtualQuitAlarmClock = 'VirtualQuitAlarmClock',
    VirtualStartSnoozeTimer = 'VirtualStartSnoozeTimer',
    VirtualTriggerOpen = 'VirtualTriggerOpen',
    VirtualTriggerClose = 'VirtualTriggerClose',
    VirtualStop = 'VirtualStop',
    VirtualPosition = 'VirtualPosition',
    VirtualCountdown = 'VirtualCountdown',
    VirtualStartTimer = 'VirtualStartTimer',
    VirtualBlindsPosition = 'VirtualBlindsPosition',
    VirtualSlatsPosition = 'VirtualSlatsPosition',
    VirtualSetBlinds = 'VirtualSetBlinds',
    VirtualSetSlats = 'VirtualSetSlats',
    VirtualShadingOn = 'VirtualShadingOn',
    VirtualIsRoomTemperature = 'VirtualIsRoomTemperature',
    VirtualOperationMode = 'VirtualOperationMode',
    VirtualManualModeHeating = 'VirtualManualModeHeating',
    VirtualStandbyTemperature = 'VirtualStandbyTemperature',
    VirtualManualModeCooling = 'VirtualManualModeCooling',
    VirtualSetpointManualModeCooling = 'VirtualSetpointManualModeCooling',
    VirtualSetpointManualModeHeating = 'VirtualSetpointManualModeHeating',
    VirtualLowBatteryLimit = 'VirtualLowBatteryLimit',
    VirtualComingHome = 'VirtualComingHome',
    VirtualSleep = 'VirtualSleep',
    VirtualLeavingHome = 'VirtualLeavingHome',
    VirtualNumberOfOpen = 'VirtualNumberOfOpen',
    VirtualNumberOfClosed = 'VirtualNumberOfClosed',
    VirtualHourEnergy = 'VirtualHourEnergy',
    VirtualDayEnergy = 'VirtualDayEnergy',
    VirtualMonthEnergy = 'VirtualMonthEnergy',
    VirtualYearEnergy = 'VirtualYearEnergy',
    VirtualReset = 'VirtualReset',
    VirtualTriggerDown = 'VirtualTriggerDown',
    VirtualTriggerUp = 'VirtualTriggerUp',
    VirtualShadowing = 'VirtualShadowing',
    VirtualCentralBlindsStop = 'VirtualCentralBlindsStop',
    VirtualEnable = 'VirtualEnable',
    VirtualConnection = 'VirtualConnection',
    VirtualNumberOfTiledWindows = 'VirtualNumberOfTiledWindows',
    VirtualIsBurglaryAlarm = 'VirtualIsBurglaryAlarm',
    VirtualIsFireAlarm = 'VirtualIsFireAlarm',
    VirtualIsTamperAlarm = 'VirtualIsTamperAlarm',
    VirtualIsHoldupAlarm = 'VirtualIsHoldupAlarm',
    VirtualIsMedicalAlarm = 'VirtualIsMedicalAlarm',
    VirtualIsGasAlarm = 'VirtualIsGasAlarm',
    VirtualIsWaterAlarm = 'VirtualIsWaterAlarm',
    VirtualIsTechnicalAlarm = 'VirtualIsTechnicalAlarm',
    VirtualIsDirDialerInputAlarm = 'VirtualIsDirDialerInputAlarm',
    VirtualIsEN54FireAlarm = 'VirtualIsEN54FireAlarm',
    VirtualIsEN54FireMCPAlarm = 'VirtualIsEN54FireMCPAlarm',
    VirtualIsEN54FaultAlarm = 'VirtualIsEN54FaultAlarm',
    VirtualIsGlassbreakAlarm = 'VirtualIsGlassbreakAlarm',
    IsArmed = 'IsArmed',
    QuitAlarm = 'QuitAlarm',
    Disarm = 'Disarm',
    Arm = 'Arm',
}
