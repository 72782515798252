import classNames from 'classnames';
import styles from './CustomButton.module.scss';
import { CustomButtonProps } from './CustomButtonProps';

const CustomButton = (props: CustomButtonProps): JSX.Element => {
    const { title, className, img, imgClassName, style, imgStyle, otherProps, onClick } = props;

    return (
        <button
            onClick={onClick}
            style={style}
            className={classNames(styles.button, className)}
            title={title}
            {...otherProps}
        >
            {img && <img style={imgStyle} className={imgClassName} src={img} />}
            {title && title}
        </button>
    );
};

export default CustomButton;
