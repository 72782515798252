import styles from './ToiletVentilation.module.scss';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { DatapointNames } from '../../../../models/enums/DatapointNames';
import { getBooleanDatapointValue } from '../../../../helpers/DatapointHelper';
import { ControllersProps } from '../ControllersProps';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ControlHeader from '../components/control-header/ControlHeader';

const ToiletVentilation = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const mainSwitchDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Switch && x.name == DatapointNames.VirtualTrigger,
    );
    const lightValueDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualLight,
    );
    const fanValueDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualFan,
    );

    return (
        <div className={styles.mainContainer} style={{ border: border }}>
            <ControlHeader virtualDevice={virtualDevice} room={room} switchDatapoint={mainSwitchDatapoint} />
            {lightValueDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('toiletVentilation.lightStatus')}</div>
                    <div>{getBooleanDatapointValue(lightValueDatapoint) ? t('general.on') : t('general.off')}</div>
                </div>
            )}
            {fanValueDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('toiletVentilation.fanStatus')}</div>
                    <div>{getBooleanDatapointValue(fanValueDatapoint) ? t('general.on') : t('general.off')}</div>
                </div>
            )}
        </div>
    );
};

export default ToiletVentilation;
