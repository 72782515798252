export enum DeviceType {
    WrongTypeDevice = -1,
    Undefined,
    RadioTouch,
    RadioSocket,
    ZWaveBulb,
    ZWaveWindowContact,
    ZWaveThermostat,
    ZWaveSmokeDetector,
    ZWaveMotionDetector,
    Dimmer,
    IOExtensionHalf,
    IOExtensionBig,
    RelayExtension,
    IOServer,
    RadioServer,
    RadioBase,
    DaliBase = 16,
    DaliSwitch = 17,
    DaliDimmer = 18,
    DaliLedDimmer = 19,
    DaliRGBW = 20,
    DaliVirtualRGBW = 21,
    Siren = 22,
    WaterSensor = 23,
    RadioSwitchDual = 27,
    RadioSwitchDualPlus = 28,
    RadioSwitchDimmer = 29,
    MultiSensor = 37,
    RadioSwitchShutter = 48,
    RadioSwitchShutterPlus = 49,
    ModbusRTUDevice = 52,
    RojaflexGateway = 54,
    RojaflexDevice = 55,
    RadioEHandleConnectSense = 57,
}
