import { Button, Input, Modal, Space, Switch, TimePicker } from 'antd';
import { BaseButtonProps } from 'antd/lib/button/button';
import classNames from 'classnames';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useWindowDimensions from '../../helpers/Hooks/useWindowDimensions';
import { isDemo, updateDatapoint } from '../../helpers/HttpMethods';
import exImg from '../../images/ex.svg';
import { AlarmTimeProgram } from '../../models/AlarmTimeProgram';
import { Datapoint } from '../../models/Datapoint';
import { VirtualDeviceCategorySettings } from '../../models/constants/VirtualDeviceCategorySettings';
import YesNoModal from '../yes-no-modal/YesNoModal';
import styles from './AlarmClockProgramEditModal.module.scss';
import { AlarmClockProgramEditModalProps } from './AlarmClockProgramEditModalProps';

const AlarmClockProgramEditModal = (props: AlarmClockProgramEditModalProps): JSX.Element => {
    const { timeProgramDatapoint, virtualDevice, closeModalRequested } = props;
    const { t } = useTranslation();
    const { windowHeight, windowWidth } = useWindowDimensions();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const [alarms, setAlarms] = useState<AlarmTimeProgram[]>(timeProgramDatapoint?.TimeProgram?.Alarms ?? []);
    const [yesNoModalVisible, setYesNoModalVisible] = useState<{ onYesClicked: () => void; onNoClicked: () => void }>();
    const [isLoading, setIsLoading] = useState(false);

    const isEdited = alarms != timeProgramDatapoint?.TimeProgram?.Alarms;

    useEffect(() => {
        if (isEdited) {
            closeModalRequested();
            setIsLoading(false);
        }
    }, [timeProgramDatapoint?.TimeProgram?.Alarms]);

    const dayButtonProps = (active: boolean): BaseButtonProps & { style: React.CSSProperties } => ({
        type: 'primary',
        shape: 'circle',
        style: {
            border: `2px solid ${category?.color}`,
            backgroundColor: active ? category?.color : 'transparent',
            color: 'black',
            marginRight: 3,
        },
    });

    const saveTimeProgram = async () => {
        try {
            setIsLoading(true);
            const filteredAlarms: AlarmTimeProgram[] = [];

            alarms.forEach((alarm) => {
                if (
                    !filteredAlarms.find(
                        (x) =>
                            x.Time === alarm.Time &&
                            x.Description === alarm.Description &&
                            x.Monday === alarm.Monday &&
                            x.Tuesday === alarm.Tuesday &&
                            x.Wednesday === alarm.Wednesday &&
                            x.Thursday === alarm.Thursday &&
                            x.Friday === alarm.Friday &&
                            x.Saturday === alarm.Saturday &&
                            x.Sunday === alarm.Sunday &&
                            x.Active === alarm.Active,
                    )
                ) {
                    filteredAlarms.push(alarm);
                }
            });

            const newTimeProgramDatapoint: Datapoint = {
                ...timeProgramDatapoint,
                TimeProgram: {
                    Alarms: filteredAlarms,
                },
            };

            const result = await updateDatapoint(newTimeProgramDatapoint);

            if (result?.status != StatusCodes.OK) {
                showError();
                setIsLoading(false);
                return;
            }
            setAlarms(filteredAlarms);

            if (isDemo) {
                closeModalRequested();
            }
        } catch {
            showError();
            setIsLoading(false);
        }
    };

    const showError = () => {
        toast.error(t('errors.errorWhileSendingValue'));
    };

    const onCloseRequested = () => {
        if (isEdited) {
            setYesNoModalVisible({
                onYesClicked: closeModalRequested,
                onNoClicked: () => setYesNoModalVisible(undefined),
            });
            return;
        }

        closeModalRequested();
    };

    const switchChanged = (alarmTimeProgram: AlarmTimeProgram, value: boolean) => {
        setAlarms(
            alarms.map((x) =>
                x == alarmTimeProgram
                    ? {
                          ...x,
                          Active: value,
                      }
                    : x,
            ),
        );
    };

    const addSwitchPoint = () => {
        const newAlarm: AlarmTimeProgram = {
            Time: '00:00',
            Active: true,
            Monday: true,
            Tuesday: true,
            Wednesday: true,
            Thursday: true,
            Friday: true,
            Saturday: true,
            Sunday: true,
            Description: '',
        };

        const newAlarms: AlarmTimeProgram[] = [newAlarm, ...alarms];

        setAlarms(newAlarms);
    };

    const removeSwitchPoint = (alarm: AlarmTimeProgram) => {
        setAlarms(alarms.filter((x) => x !== alarm));
    };

    const onDayClicked = (alarm: AlarmTimeProgram, day: number) => {
        switch (day) {
            case 0:
                setAlarms(
                    alarms.map((x) =>
                        x == alarm
                            ? {
                                  ...x,
                                  Monday: !x.Monday,
                              }
                            : x,
                    ),
                );
                break;
            case 1:
                setAlarms(
                    alarms.map((x) =>
                        x == alarm
                            ? {
                                  ...x,
                                  Tuesday: !x.Tuesday,
                              }
                            : x,
                    ),
                );
                break;
            case 2:
                setAlarms(
                    alarms.map((x) =>
                        x == alarm
                            ? {
                                  ...x,
                                  Wednesday: !x.Wednesday,
                              }
                            : x,
                    ),
                );
                break;
            case 3:
                setAlarms(
                    alarms.map((x) =>
                        x == alarm
                            ? {
                                  ...x,
                                  Thursday: !x.Thursday,
                              }
                            : x,
                    ),
                );
                break;
            case 4:
                setAlarms(
                    alarms.map((x) =>
                        x == alarm
                            ? {
                                  ...x,
                                  Friday: !x.Friday,
                              }
                            : x,
                    ),
                );
                break;
            case 5:
                setAlarms(
                    alarms.map((x) =>
                        x == alarm
                            ? {
                                  ...x,
                                  Saturday: !x.Saturday,
                              }
                            : x,
                    ),
                );
                break;
            case 6:
                setAlarms(
                    alarms.map((x) =>
                        x == alarm
                            ? {
                                  ...x,
                                  Sunday: !x.Sunday,
                              }
                            : x,
                    ),
                );
                break;
        }
    };

    return (
        <Modal
            title={virtualDevice.name}
            open={true}
            onCancel={onCloseRequested}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ loading: isLoading }}
            okText={t('general.save')}
            onOk={saveTimeProgram}
            width="fit-content"
        >
            <div
                className={classNames(styles.contentContainer, {
                    [styles.contentOverflow]: windowHeight * 0.7 - 116 < alarms.length * (windowWidth > 680 ? 54 : 96),
                })}
            >
                {alarms &&
                    alarms
                        .slice()
                        ?.sort((a: AlarmTimeProgram, b: AlarmTimeProgram) => {
                            return moment(a.Time, 'HH:mm').diff(moment(b.Time, 'HH:mm'));
                        })
                        .map((alarm, index) => (
                            <Space key={index} className={styles.switchPointContainer}>
                                <Space>
                                    <img
                                        onClick={() => removeSwitchPoint(alarm)}
                                        className={styles.removeImg}
                                        src={exImg}
                                    />
                                    <TimePicker
                                        allowClear={false}
                                        format="HH:mm"
                                        showNow={false}
                                        value={moment(alarm.Time, 'HH:mm')}
                                        onBlur={() =>
                                            setAlarms(
                                                [...alarms].sort((a: AlarmTimeProgram, b: AlarmTimeProgram) => {
                                                    return moment(a.Time, 'HH:mm').diff(moment(b.Time, 'HH:mm'));
                                                }),
                                            )
                                        }
                                        onChange={(value) => {
                                            setAlarms(
                                                alarms.map(
                                                    (x, spIndex): AlarmTimeProgram =>
                                                        spIndex === index
                                                            ? {
                                                                  ...x,
                                                                  Time: !!value
                                                                      ? moment(value).format('HH:mm')
                                                                      : '00:00',
                                                              }
                                                            : x,
                                                ),
                                            );
                                        }}
                                    />
                                    <Input
                                        placeholder={t('alarmClockProgram.description')}
                                        value={alarm.Description ?? ''}
                                        onChange={(value) => {
                                            setAlarms(
                                                alarms.map((x) =>
                                                    x == alarm
                                                        ? {
                                                              ...x,
                                                              Description: value.currentTarget.value,
                                                          }
                                                        : x,
                                                ),
                                            );
                                        }}
                                    />
                                </Space>
                                <Space>
                                    <div className={styles.switchpointRow}>
                                        <Button
                                            {...dayButtonProps(alarm.Monday)}
                                            onClick={() => onDayClicked(alarm, 0)}
                                        >
                                            {t('timeProgramEdit.mo')}
                                        </Button>
                                        <Button
                                            {...dayButtonProps(alarm.Tuesday)}
                                            onClick={() => onDayClicked(alarm, 1)}
                                        >
                                            {t('timeProgramEdit.tu')}
                                        </Button>
                                        <Button
                                            {...dayButtonProps(alarm.Wednesday)}
                                            onClick={() => onDayClicked(alarm, 2)}
                                        >
                                            {t('timeProgramEdit.we')}
                                        </Button>
                                        <Button
                                            {...dayButtonProps(alarm.Thursday)}
                                            onClick={() => onDayClicked(alarm, 3)}
                                        >
                                            {t('timeProgramEdit.th')}
                                        </Button>
                                        <Button
                                            {...dayButtonProps(alarm.Friday)}
                                            onClick={() => onDayClicked(alarm, 4)}
                                        >
                                            {t('timeProgramEdit.fr')}
                                        </Button>
                                        <Button
                                            {...dayButtonProps(alarm.Saturday)}
                                            onClick={() => onDayClicked(alarm, 5)}
                                        >
                                            {t('timeProgramEdit.sa')}
                                        </Button>
                                        <Button
                                            {...dayButtonProps(alarm.Sunday)}
                                            onClick={() => onDayClicked(alarm, 6)}
                                        >
                                            {t('timeProgramEdit.su')}
                                        </Button>
                                    </div>
                                    <Switch
                                        style={{
                                            backgroundColor: alarm?.Active ? category?.color : 'rgba(0, 0, 0, 0.25)',
                                        }}
                                        disabled={timeProgramDatapoint?.writeprotect ?? false}
                                        onChange={(checked) => switchChanged(alarm, checked)}
                                        checked={alarm?.Active ?? false}
                                    />
                                </Space>
                            </Space>
                        ))}
            </div>
            <div className={styles.addSwitchPointButtonContainer}>
                <Button
                    onClick={(e) => {
                        addSwitchPoint();
                        e.currentTarget.blur();
                    }}
                >
                    {'+'}
                </Button>
            </div>
            {yesNoModalVisible && (
                <YesNoModal
                    onYesClicked={yesNoModalVisible.onYesClicked}
                    onNoClicked={yesNoModalVisible.onNoClicked}
                    description={t('timeProgramEdit.changesNotSaved')}
                    isVisible={true}
                />
            )}
        </Modal>
    );
};

export default AlarmClockProgramEditModal;
