import { useMemo } from 'react';
import { EnergyManagementDevice, EnergyManagementDeviceProperty } from '../../../../models/EnergyManagementDevice';

import { useTranslation } from 'react-i18next';
import { getFreePriorities } from '../../EnergyManagementConfigurationModal';
import EditableCell from './EditableCell/EditableCell';
import EditableModeCell from './EditableModeCell/EditableModeCell';
import styles from './SummaryDevice.module.scss';

type Props = {
    allDevices: EnergyManagementDevice[];
    device: EnergyManagementDevice;
    allPriority: EnergyManagementDeviceProperty[];
    priority: EnergyManagementDeviceProperty;
    onPropertyChanged: (
        device: EnergyManagementDevice,
        property: EnergyManagementDeviceProperty,
        newProperty: EnergyManagementDeviceProperty,
    ) => void;
};

const SummaryDevice = ({ allDevices, device, priority, allPriority, onPropertyChanged }: Props): JSX.Element => {
    const { t } = useTranslation();
    const sortedPrio = useMemo(() => [...device.Priorities].sort((x, y) => x.Level - y.Level), [device]);
    const sortedDevices = useMemo(
        () =>
            sortedPrio
                .sort((x, y) => (x.Priority ?? 0) - (y.Priority ?? 0))
                .map((sprio, index) => ({
                    ...sprio,
                    maxLevel: sortedPrio.length - 1 === index ? '+' : ' - ' + sortedPrio[index + 1].Level,
                })),
        [sortedPrio],
    );
    const block =
        sortedDevices.find(
            (x) => x.Level === priority.Level && x.Mode === priority.Mode && x.Priority === priority.Priority,
        ) ?? sortedDevices[0];

    return (
        <div className={styles.wrapper}>
            <div className={styles.nameWrapper}>{device.Name}</div>
            <div className={styles.levelsWrapper}>
                <div className={styles.blockWrapper}>
                    <div className={styles.propWrapper}>
                        {block.Priority && block.Mode !== 0 && block.Mode !== 1 ? (
                            <EditableCell
                                value={block.Priority}
                                onChange={(v) =>
                                    onPropertyChanged(device, priority, {
                                        ...priority,
                                        Priority: v,
                                    })
                                }
                                takenNumbers={allPriority
                                    .map((x) => x.Priority ?? 0)
                                    .filter((x) => x !== priority.Priority)}
                            />
                        ) : (
                            block.Priority
                        )}
                    </div>
                    <div className={styles.propWrapper}>{block.Level + block.maxLevel}</div>
                    <div className={styles.propLastWrapper}>
                        <EditableModeCell
                            value={block.Mode}
                            onChange={(v) =>
                                onPropertyChanged(device, priority, {
                                    ...priority,
                                    Mode: v,
                                    Priority:
                                        v === 0 || v === 1
                                            ? undefined
                                            : priority.Priority
                                            ? priority.Priority
                                            : getFreePriorities(allDevices)[0],
                                })
                            }
                        />
                    </div>
                </div>
            </div>
            <div className={styles.nameWrapper}>{device.MinPower ?? '-'}</div>
            <div className={styles.nameWrapper}>{device.MaxPower ?? '-'}</div>
            <div className={styles.nameWrapper}>{device.MaxEnergy ?? '-'}</div>
            <div className={styles.nameWrapper}>{device.PreparationTime ?? '-'}</div>
            <div className={styles.nameLastWrapper}>{device.MinRuntime ?? '-'}</div>
        </div>
    );
};

export default SummaryDevice;
