import { BellOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { StatusCodes } from 'http-status-codes';
import { TFunction } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons, toast } from 'react-toastify';
import {
    getDevices,
    getLastWebsocketMessage,
    getNotifications,
    getRooms,
    getUsers,
    getVirtualDevices,
} from '../../app/globalSettings';
import { useAppSelector } from '../../app/hooks';
import { getCloudCredentials, getUserCredential } from '../../helpers/CookieHelper';
import { fetchSystemNotifications, isDemo } from '../../helpers/HttpMethods';
import { ServerNotification } from '../../models/ServerNotification';
import { NotificationType } from '../../models/enums/NotificationType';
import { RoomType } from '../../models/enums/RoomType';
import { WebsocketCode } from '../../models/enums/WebsocketCode';
import styles from './Notifications.module.scss';
import NotificationView from './notificationView/NotificationView';

export type NotificationInfo = {
    title: string;
    message: string;
    type: 'error' | 'info';
    notificationType: NotificationType;
    virtualDeviceId: number;
    id: number;
};

export type NotificationInfoTyped = ServerNotification & { isWebsocket: boolean; isSystem: boolean };

const getAlarmText = (
    t: TFunction,
    type: NotificationType,
    deviceName: string,
    roomName: string,
    notificationText: string,
    virtualDeviceId: number,
    id: number,
): NotificationInfo | undefined => {
    const props = { id, virtualDeviceId, notificationType: type };
    switch (type) {
        case NotificationType.UnableToSetAlarm:
            return {
                title: t('notifiactions.unableToSetAlarm'),
                message: `${t('notifiactions.issueReportedBy')} ${deviceName} ${t('notifiactions.inRoom')} ${roomName}`,
                type: 'error',
                ...props,
            };
        case NotificationType.Battery:
            return {
                title: t('notifiactions.lowBatteryLevel'),
                message: `${t('notifiactions.lowBatteryLevelIn')} ${deviceName} ${t(
                    'notifiactions.inRoom',
                )} ${roomName}`,
                type: 'info',
                ...props,
            };
        case NotificationType.Custom:
            return {
                title: t('notifiactions.notification'),
                message: notificationText,
                type: 'info',
                ...props,
            };
        case NotificationType.Energy:
            return {
                title: t('notifiactions.energyManager'),
                message: `${t('notifiactions.device')} ${deviceName} ${t('notifiactions.cannotBeSwitchedOn')}`,
                type: 'error',
                ...props,
            };
        case NotificationType.SmokeAlarm:
            return {
                title: t('notifiactions.smokeAlarm'),
                message: `${t('notifiactions.alarmReportedBy')} ${deviceName} ${t('notifiactions.inRoom')} ${roomName}`,
                type: 'error',
                ...props,
            };
        case NotificationType.WaterAlarm:
            return {
                title: t('notifiactions.waterAlarm'),
                message: `${t('notifiactions.alarmReportedBy')} ${deviceName} ${t('notifiactions.inRoom')} ${roomName}`,
                type: 'error',
                ...props,
            };
        case NotificationType.FireAlarm:
            return {
                title: t('notifiactions.fireAlarm'),
                message: `${t('notifiactions.alarmReportedBy')} ${deviceName} ${t('notifiactions.inRoom')} ${roomName}`,
                type: 'error',
                ...props,
            };
        case NotificationType.SystemAlarm:
            return {
                title: t('notifiactions.alarm'),
                message: `${t('notifiactions.alarmReportedBy')} ${deviceName} ${t('notifiactions.inRoom')} ${roomName}`,
                type: 'error',
                ...props,
            };
        case NotificationType.DeviceNotResponding:
            return {
                title: t('notifiactions.deviceNotResponding'),
                message: `${deviceName} ${t('notifiactions.notResponingInRoom')} ${roomName}`,
                type: 'info',
                ...props,
            };
    }
};

const Notifications = (): JSX.Element => {
    const devices = useAppSelector(getDevices) ?? [];
    const rooms = useAppSelector(getRooms) ?? [];
    const notifiactions = useAppSelector(getNotifications);
    const virtualDevices = useAppSelector(getVirtualDevices);
    const lastWebsocketMessage = useAppSelector(getLastWebsocketMessage);
    const users = useAppSelector(getUsers);
    const loggedUser = getUserCredential();
    const currentSavedUsername = isDemo
        ? 'admin'
        : users?.find((x) => x.name === loggedUser?.username)?.name ?? getCloudCredentials()?.username;
    const currentUser = users?.find((x) => x.name === currentSavedUsername);

    const [init, setInit] = useState(false);
    const { t } = useTranslation();
    const [allNotifications, setAllNotifications] = useState<NotificationInfoTyped[]>();
    const [alarmModalVisible, setAlarmModalVisile] = useState(false);

    const getAlarmConfig = (notification: ServerNotification) => {
        const device = devices.find((x) => x.id === notification.deviceid);
        const deviceName = device?.name ?? t('general.unknownDevice') ?? '';
        const room = rooms.find((x) => x.id === (device?.roomid ?? 0));
        const roomName = room?.name ?? t('general.unassigned') ?? '';

        const notificationConfig = getAlarmText(
            t,
            notification.type,
            deviceName,
            roomName,
            notification.text,
            notification.objectId,
            notification.id,
        );

        if (!notificationConfig) {
            return;
        }

        return notificationConfig;
    };

    const allNotificationsConfigs = useMemo(
        () => allNotifications?.map((x) => getAlarmConfig(x)) ?? [],
        [allNotifications],
    );

    const onShow = (notification: ServerNotification) => {
        if (
            !notification.ugpushconf.Users ||
            notification.ugpushconf.Users.length === 0 ||
            (currentUser && !notification.ugpushconf.Users.includes(currentUser.id))
        ) {
            return;
        }

        const notificationConfig = getAlarmConfig(notification);

        if (!notificationConfig) {
            return;
        }

        showNotification(notificationConfig);

        try {
            if (
                Notification.permission === 'granted' &&
                (notificationConfig.notificationType === NotificationType.FireAlarm ||
                    notificationConfig.notificationType === NotificationType.SmokeAlarm ||
                    notificationConfig.notificationType === NotificationType.WaterAlarm ||
                    notificationConfig.notificationType === NotificationType.SystemAlarm)
            ) {
                const options = {
                    body: notificationConfig.message,
                    icon: 'https://web.mytem-smarthome.com/logo512.png',
                };
                new Notification(notificationConfig.title, options);
            }
        } catch {}
    };

    const showNotification = ({ title, message, type }: NotificationInfo) => {
        const body = (
            <div className={styles.titleWrapper}>
                <div className={styles.title}>{title}</div>
                <div>{message}</div>
            </div>
        );
        type === 'info'
            ? toast.info(body, { onClick: () => setAlarmModalVisile(true) })
            : toast.error(body, { onClick: () => setAlarmModalVisile(true) });
    };

    const initNotifications = async () => {
        if (notifiactions === undefined) {
            return;
        }

        const systemNotificationsResponse = await fetchSystemNotifications();

        if (systemNotificationsResponse.status !== StatusCodes.OK) {
            return;
        }

        const serverNotifications: NotificationInfoTyped[] = notifiactions.map((x) => ({
            ...x,
            isWebsocket: false,
            isSystem: false,
        }));
        const systemNotifications: NotificationInfoTyped[] = [];

        const nonEmptyDeviceNotifications = systemNotificationsResponse.data.filter(
            (x) =>
                !!x.deviceid &&
                (x.code === WebsocketCode.DeviceNotResponding || x.code === WebsocketCode.DeviceAvailable),
        );
        for (const deviceId of nonEmptyDeviceNotifications
            .map((x) => x.deviceid)
            .filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            })) {
            const deviceNotifications = nonEmptyDeviceNotifications.filter((x) => x.deviceid === deviceId);
            const lastNotification = deviceNotifications[deviceNotifications.length - 1];

            if (lastNotification?.code === WebsocketCode.DeviceNotResponding) {
                const systemNotification: NotificationInfoTyped = {
                    deviceid: lastNotification.deviceid ?? 0,
                    id: lastNotification.id,
                    objectId: 0,
                    room: '',
                    roomcategory: RoomType.Undefined,
                    text: '',
                    type: NotificationType.DeviceNotResponding,
                    ugpushconf: {
                        Groups: [],
                        Users: [currentUser?.id ?? 0],
                    },
                    isWebsocket: false,
                    isSystem: true,
                };

                systemNotifications.push(systemNotification);
            }
        }

        const allNotificationsToShow = [...serverNotifications, ...systemNotifications];

        setAllNotifications(allNotificationsToShow);

        for (const notification of allNotificationsToShow) {
            onShow(notification);
        }
    };

    useEffect(() => {
        setInit(false);
    }, [notifiactions]);

    useEffect(() => {
        if (notifiactions === undefined || init || isDemo) {
            return;
        }

        setInit(true);
        initNotifications();
    }, [notifiactions, init]);

    useEffect(() => {
        if (!lastWebsocketMessage) {
            return;
        }

        if (lastWebsocketMessage?.header === 'notification') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any

            if (allNotifications?.some((x) => x.id === lastWebsocketMessage.id && !x.isSystem)) {
                return;
            }

            const newNotification = lastWebsocketMessage as any as ServerNotification;
            onShow(newNotification);
            setAllNotifications((prev) => [
                ...(prev ?? []),
                { ...newNotification, isWebsocket: true, isSystem: false },
            ]);
        }

        if (lastWebsocketMessage.code === WebsocketCode.DeviceNotResponding) {
            if (allNotifications?.some((x) => x.id === lastWebsocketMessage.id && x.isSystem)) {
                return;
            }

            const newNotification = {
                deviceid: lastWebsocketMessage.deviceid ?? 0,
                id: Number(lastWebsocketMessage.id.toString() + lastWebsocketMessage.code.toString()),
                objectId: 0,
                room: '',
                roomcategory: RoomType.Undefined,
                text: '',
                type: NotificationType.DeviceNotResponding,
                ugpushconf: {
                    Groups: [],
                    Users: [currentUser?.id ?? 0],
                },
                isWebsocket: false,
                isSystem: true,
            };

            onShow(newNotification);
            setAllNotifications((prev) => [...(prev ?? []), { ...newNotification, isWebsocket: true }]);
        }
    }, [lastWebsocketMessage]);

    if (allNotifications === undefined || virtualDevices === undefined || virtualDevices.length === 0) {
        return <></>;
    }

    const onToggleModal = () => {
        setAlarmModalVisile((prev) => !prev);
        document.getElementsByTagName('body')[0].style.overflowY = alarmModalVisible ? 'auto' : 'hidden';
    };

    const onRemoveNotification = (index: number) => {
        setAllNotifications((prev) => prev?.filter((x) => x !== prev[index]));
    };

    return (
        <>
            <div className={styles.wrapper} onClick={onToggleModal}>
                <BellOutlined style={{ fontSize: 30, opacity: 0.8 }} />
                {allNotificationsConfigs.length > 0 && (
                    <div className={styles.numberOfNotification}>{allNotifications.length}</div>
                )}
            </div>
            <div
                onClick={onToggleModal}
                className={classNames(styles.backgroundBlur, { [styles.blurVisible]: alarmModalVisible })}
            ></div>
            <div className={classNames(styles.mainModalWrapper, { [styles.modalVisible]: alarmModalVisible })}>
                <div className={classNames(styles.modalContent, { [styles.contentVisible]: alarmModalVisible })}>
                    {allNotificationsConfigs.length === 0 && (
                        <div className={styles.emptyWrapper}>
                            <div className={styles.iconWrapper}>
                                {Icons.success({
                                    type: 'success',
                                    theme: 'light',
                                })}
                            </div>
                            <div className={styles.emptyTitle}>{t('notifiactions.noNotifications')}</div>
                        </div>
                    )}
                    {allNotificationsConfigs?.map((not, index) => (
                        <div key={index}>
                            {not && (
                                <NotificationView
                                    notification={not}
                                    index={index}
                                    onRemoveNotification={onRemoveNotification}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Notifications;
