import { Button, Modal, Switch } from 'antd';
import FileSaver from 'file-saver';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
    Area,
    Bar,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { utils, write } from 'xlsx';
import { getDevices, getInfo, getIos, getRooms, setSetupInfo } from '../../../app/globalSettings';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import demoBulkJson from '../../../demo/energyBulk.json';
import useCookie from '../../../helpers/Hooks/useCookie';
import { fetchSetupInfo, isDemo, updateDatapoint } from '../../../helpers/HttpMethods';
import { Datapoint } from '../../../models/Datapoint';
import { Device } from '../../../models/Device';
import { GraphType, IoConfig } from '../../../models/EnergyConfig';
import { EnergyStatisticItem } from '../../../models/EnergyStatistic';
import { Info } from '../../../models/Info';
import { Ios } from '../../../models/Ios';
import { Room } from '../../../models/Room';
import { VirtualDevice } from '../../../models/VirtualDevice';
import { DatapointType } from '../../../models/enums/DatapointType';
import { DeviceType } from '../../../models/enums/DeviceType';
import { FrameSelectionOption } from '../EnergyManagementModal';
import styles from './MainChart.module.scss';

type Props = {
    stats: EnergyStatisticItem[];
    frame: FrameSelectionOption;
    endDate: string;
    iosConfig: IoConfig[];
    isLoading: boolean;
    unit: string;
    title: string;
    downloadTitle: string;
    virtualDevice: VirtualDevice;
};

const serverFormat = 'yyyy-MM-DDTHH:mm:ss';
const demoBulk = JSON.parse(JSON.stringify(demoBulkJson));

const MainChart = ({
    stats,
    frame,
    iosConfig: propsIosConfig,
    isLoading,
    endDate,
    unit,
    title,
    downloadTitle,
    virtualDevice,
}: Props): JSX.Element => {
    const ios: Ios[] = isDemo ? demoBulk.ios : useAppSelector(getIos);
    const devices: Device[] = isDemo ? demoBulk.devices : useAppSelector(getDevices);
    const rooms: Room[] = isDemo ? demoBulk.rooms : useAppSelector(getRooms);
    const info: Info = isDemo ? demoBulk.info : useAppSelector(getInfo);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [demoIosConfig, setDemoIosConfig] = useState(propsIosConfig);

    const iosConfig = useMemo(() => (isDemo ? demoIosConfig : propsIosConfig), [isDemo, demoIosConfig, propsIosConfig]);

    const lineIos = useMemo(() => iosConfig?.filter((x) => x.Graph === GraphType.Line), [iosConfig]);
    const areaIos = useMemo(() => iosConfig?.filter((x) => x.Graph === GraphType.Area), [iosConfig]);
    const beamIos = useMemo(() => iosConfig?.filter((x) => x.Graph === GraphType.Beam), [iosConfig]);

    const [isModalLoading, setIsModalLoading] = useState(false);

    const { value: chartsProps, updateCookie: setChartsProps } = useCookie<{ id: number; hide: boolean }[]>(
        btoa(info.serialnumber ?? 'serial') + `-${unit}` + '-chartprops-old',
        [
            { id: 0, hide: false },
            ...(iosConfig
                ?.filter((x) => x.Graph === GraphType.Line || x.Graph === GraphType.Area || x.Graph === GraphType.Beam)
                ?.map((x) => ({ id: x.Id, hide: false })) ?? []),
        ],
    );

    const [selectedChartIo, setSelectedChartIo] = useState<number>();

    const chartTypes = useMemo(
        () => [
            { value: GraphType.Line, label: t('general.line') },
            { value: GraphType.Area, label: t('general.area') },
            { value: GraphType.Beam, label: t('general.beam') },
        ],
        [],
    );

    const getName = (ioId: number) => {
        const connectedIos = ios?.find((x) => x.id === ioId);

        if (info?.starterkit) {
            const device = devices?.find((x) => x.id === connectedIos?.device);

            if (device) {
                const room = rooms?.find((x) => x.id === device.roomid);

                let deviceName = device.name;

                if (device.type === DeviceType.RadioSwitchDual || device.type === DeviceType.RadioSwitchDualPlus) {
                    if (connectedIos?.name?.includes('EI1')) {
                        deviceName += ' (EI1)';
                    } else if (connectedIos?.name?.includes('EI2')) {
                        deviceName += ' (EI2)';
                    } else if (connectedIos?.name?.includes('PI1')) {
                        deviceName += ' (PI1)';
                    } else if (connectedIos?.name?.includes('PI2')) {
                        deviceName += ' (PI2)';
                    }
                }

                if (room) {
                    return `${deviceName} - ${room.name}`;
                }
            }
        }

        return connectedIos?.name;
    };

    const selectBar = (e: any) => {
        setSelectedChartIo(e.dataKey);
    };

    const getDateFormat = () => {
        switch (frame) {
            case FrameSelectionOption.hourly:
                return 'DD.MM.yyyy HH:mm';
            case FrameSelectionOption.daily:
                return 'DD.MM.yyyy';
            case FrameSelectionOption.monthly:
                return 'MM.yyyy';
            case FrameSelectionOption.yearly:
                return 'yyyy';
            default:
                return 'DD.MM.yyyy HH:mm:ss';
        }
    };

    const formatedLogs = useMemo(() => {
        if (!stats?.length) {
            return { chart: [], download: [] };
        }

        const formated = stats.map((point) => {
            const data = point.items.reduce((prev, curr) => {
                return {
                    ...prev,
                    [curr.ioid]: Number((curr.value / 1000).toFixed(4)),
                };
            }, {});

            if (point.total !== undefined && point.total !== null) {
                return {
                    name: moment(point.date, serverFormat).format(getDateFormat()),
                    ...data,
                    0: Number((point.total / 1000).toFixed(4)),
                };
            }

            return {
                name: moment(point.date, serverFormat).format(getDateFormat()),
                ...data,
            };
        });

        const formatedDownload = stats.map((point, index) => {
            const data = point.items.reduce((prev, curr) => {
                return {
                    ...prev,
                    [getName(curr.ioid) ?? '']: (curr.value / 1000).toFixed(4),
                };
            }, {});

            return {
                'From date': moment(point.date, serverFormat).format(getDateFormat()),
                'To date':
                    index === stats.length - 1
                        ? moment(endDate, 'yyyy-MM-DD HH:mm').format(getDateFormat())
                        : moment(stats[index + 1].date, serverFormat).format(getDateFormat()),
                ...data,
                Total: point.total ? (point.total / 1000).toFixed(4) : 0,
            };
        });

        return { chart: formated, download: formatedDownload };
    }, [stats, frame]);

    const onDownload = () => {
        const workbook = utils.book_new();
        const dataSheet = utils.json_to_sheet(formatedLogs.download);
        utils.book_append_sheet(workbook, dataSheet, downloadTitle.replace('/', ''));
        const excel = write(workbook, { type: 'buffer', bookType: 'xlsx' });
        const finalData = new Blob([excel]);
        FileSaver.saveAs(finalData, downloadTitle + '.xlsx');
    };

    return (
        <div className={styles.wrapper}>
            {formatedLogs?.download?.length > 0 && (
                <Button
                    onClick={onDownload}
                    disabled={isLoading}
                    type="primary"
                    style={{ width: 'fit-content', marginLeft: 'auto' }}
                >
                    {t('energyManagement.downloadDataSheet')}
                </Button>
            )}
            <ResponsiveContainer height={400}>
                <ComposedChart data={formatedLogs.chart}>
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" />
                    <YAxis
                        width={100}
                        tickFormatter={(label) => {
                            return label + ` ${unit}`;
                        }}
                    />
                    <Tooltip formatter={(label) => label + ` ${unit}`} />
                    <Legend onClick={selectBar} />
                    <ReferenceLine y={0} stroke="#d1d1d1" strokeDasharray="3 3" />
                    {areaIos?.map((x) => (
                        <Area
                            key={x.Id}
                            type="step"
                            dataKey={x.Id}
                            name={getName(x.Id)}
                            fill={x.Color}
                            fillOpacity={0.3}
                            stroke={x.Color}
                            hide={chartsProps?.find((z) => z.id === x.Id)?.hide}
                            dot={false}
                        />
                    ))}
                    {beamIos?.map((x) => (
                        <Bar
                            key={x.Id}
                            dataKey={x.Id}
                            name={getName(x.Id)}
                            barSize={20}
                            fill={x.Color}
                            hide={chartsProps?.find((z) => z.id === x.Id)?.hide}
                        />
                    ))}
                    {lineIos?.map((x) => (
                        <Line
                            dot={false}
                            key={x.Id}
                            dataKey={x.Id}
                            name={getName(x.Id)}
                            type="step"
                            stroke={x.Color}
                            hide={chartsProps?.find((z) => z.id === x.Id)?.hide}
                        />
                    ))}
                    <Line
                        key={0}
                        dataKey={0}
                        name={'Total'}
                        type="step"
                        stroke={'#000000'}
                        hide={chartsProps?.find((z) => z.id === 0)?.hide}
                        dot={false}
                    />
                </ComposedChart>
            </ResponsiveContainer>
            {selectedChartIo !== undefined && (
                <Modal
                    title={getName(selectedChartIo) ?? (selectedChartIo === 0 ? 'Total' : '')}
                    width={300}
                    cancelText={t('general.close')}
                    style={{ top: '25%' }}
                    onCancel={() => setSelectedChartIo(undefined)}
                    open={true}
                    closable={!isModalLoading}
                    footer={null}
                >
                    <Select
                        isOptionDisabled={selectedChartIo === 0 ? () => true : undefined}
                        isLoading={isModalLoading}
                        isSearchable={false}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 999999, fontSize: 14 }) }}
                        onChange={async (v) => {
                            if (isDemo) {
                                setDemoIosConfig((prev) =>
                                    prev.map((x) =>
                                        x.Id === selectedChartIo ? { ...x, Graph: v?.value ?? GraphType.Line } : x,
                                    ),
                                );
                                return;
                            }

                            const dp = virtualDevice?.datapoints?.find(
                                (x) => x.type === DatapointType.CentralEnergyConfig,
                            );
                            const connectedConfig = dp?.EnergyConfig?.IOConfigs.find((x) => x.Id === selectedChartIo);
                            if (!connectedConfig || !dp?.EnergyConfig?.IOConfigs || !v) {
                                return;
                            }
                            setIsModalLoading(true);
                            const newDatapoint: Datapoint = {
                                ...dp,
                                EnergyConfig: {
                                    ...dp.EnergyConfig,
                                    IOConfigs: dp.EnergyConfig.IOConfigs.map((x) =>
                                        x.Id === selectedChartIo ? { ...connectedConfig, Graph: v.value } : x,
                                    ),
                                },
                            };

                            await updateDatapoint(newDatapoint);

                            const result = await fetchSetupInfo();

                            if (result.status !== StatusCodes.OK) {
                                setIsModalLoading(false);
                                toast.error(t('errors.errorWhileSendingValue'));
                                return;
                            }
                            dispatch(setSetupInfo(result));
                            setIsModalLoading(false);
                            setSelectedChartIo(undefined);
                        }}
                        value={
                            selectedChartIo === 0
                                ? chartTypes.find((x) => x.value === GraphType.Line)
                                : chartTypes.find(
                                      (x) => x.value === iosConfig.find((x) => x.Id === selectedChartIo)?.Graph,
                                  )
                        }
                        options={chartTypes}
                        isDisabled={isLoading}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#a1a1a1',
                            },
                        })}
                    />
                    <div className={styles.editModalWrapper}>
                        Visible
                        <Switch
                            onChange={(value) =>
                                setChartsProps(
                                    chartsProps?.map((x) =>
                                        x.id === selectedChartIo ? { ...x, hover: false, hide: !value } : x,
                                    ) ?? [],
                                    { expires: 365 },
                                )
                            }
                            checked={!chartsProps?.find((x) => x.id === selectedChartIo)?.hide}
                        />
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default MainChart;
