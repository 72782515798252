import { Button, Card, InputNumber, Select } from 'antd';
import { StatusCodes } from 'http-status-codes';
import i18n from 'i18next';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { editObjectProperties } from '../../helpers/HttpMethods';
import { AdvancedProperty } from '../../models/AdvancedProperty';
import { PropertyTemplate } from '../../models/PropertyTemplate';
import { VirtualDevice } from '../../models/VirtualDevice';
import styles from './RoomAdvanceProperty.module.scss';

interface Props {
    propertyTemplates: PropertyTemplate[];
    virtualDevice: VirtualDevice;
}

enum PropertyTemplateType {
    Input,
    Picker,
}

interface PropertyView {
    propId: number;
    type: PropertyTemplateType;
    value: string;
    pindid: string;
    name: string;
    hint: string;
    unit: string;
    max?: string;
    min?: string;
    readonly: boolean;
    mainName?: string;
    options?: string[];
}

const RoomAdvanceProperty = (props: Props): JSX.Element => {
    const { propertyTemplates, virtualDevice } = props;
    const { t } = useTranslation();

    const [properties, setProperties] = useState<AdvancedProperty[]>(virtualDevice?.properties ?? []);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isEdited, setIsEdited] = useState(false);

    const getPropertyType = (prop?: PropertyTemplate) => {
        if (!prop) {
            return PropertyTemplateType.Input;
        }

        const translation = getTranslationForProp(prop);

        if (prop.config.range.type === 'bool') {
            return PropertyTemplateType.Picker;
        }

        if (translation?.hint?.includes('0:')) {
            return PropertyTemplateType.Picker;
        }

        return PropertyTemplateType.Input;
    };

    const getTranslationForProp = (prop?: PropertyTemplate) => {
        if (!prop) {
            return undefined;
        }

        const deTranslation = prop?.config?.transl?.find((x) => x.lang === 'de-DE');

        if (i18n?.resolvedLanguage?.includes('fr')) {
            return prop?.config?.transl?.find((x) => x.lang === 'fr-FR') ?? deTranslation;
        }

        if (i18n?.resolvedLanguage?.includes('de')) {
            return deTranslation;
        }

        if (i18n?.resolvedLanguage?.includes('it')) {
            return prop?.config?.transl?.find((x) => x.lang === 'it-IT') ?? deTranslation;
        }
        return prop?.config?.transl?.find((x) => x.lang === 'en-US') ?? deTranslation;
    };

    const getViewProp = (advanceProp: AdvancedProperty): PropertyView => {
        const template = propertyTemplates.find((x) => x.pinid === advanceProp.pinid);

        const type = getPropertyType(template);
        const translation = getTranslationForProp(template);

        let options: string[] | undefined = undefined;

        if (type === PropertyTemplateType.Picker) {
            if (template?.config?.range?.type === 'bool') {
                options = [t('general.inactive'), t('general.active')];
            }

            if (translation?.hint?.includes('0:')) {
                options = [];
                translation?.hint.split(';').forEach((value, index) => options?.push(value.replace(`${index}: `, '')));
            }
        }

        return {
            propId: advanceProp.id,
            value: advanceProp.value,
            pindid: advanceProp.pinid,
            readonly: advanceProp.readonly,
            mainName: template?.name,
            type: type,
            name: translation?.name ?? '',
            hint: translation?.hint ?? '',
            unit: translation?.unit ?? '',
            max: template?.config?.range?.max,
            min: template?.config?.range?.min,
            options: options,
        };
    };

    const propertyView: PropertyView[] = useMemo(() => properties.map((x) => getViewProp(x)), [properties]);

    const onValueChanged = (value: string, prop: PropertyView) => {
        let v = value;

        if (prop.mainName === 'MaxHeatingTemperature') {
            const v1 = propertyView.find((x) => x.mainName === 'MinHeatingTemperature')?.value;
            if (Number(v) < Number(v1)) {
                v = v1 ?? v;
            }
        }

        if (prop.mainName === 'MaxCoolingTemperature') {
            const v1 = propertyView.find((x) => x.mainName === 'MinCoolingTemperature')?.value;
            if (Number(v) < Number(v1)) {
                v = v1 ?? v;
            }
        }

        if (prop.mainName === 'MaxStandbyTemperature') {
            const v1 = propertyView.find((x) => x.mainName === 'MinStandbyTemperature')?.value;
            if (Number(v) < Number(v1)) {
                v = v1 ?? v;
            }
        }

        if (prop.mainName === 'MinStandbyTemperature') {
            const v1 = propertyView.find((x) => x.mainName === 'MaxStandbyTemperature')?.value;
            if (Number(v) > Number(v1)) {
                v = v1 ?? v;
            }
        }

        if (prop.mainName === 'MinCoolingTemperature') {
            const v1 = propertyView.find((x) => x.mainName === 'MaxCoolingTemperature')?.value;
            if (Number(v) > Number(v1)) {
                v = v1 ?? v;
            }
        }

        if (prop.mainName === 'MinHeatingTemperature') {
            const v1 = propertyView.find((x) => x.mainName === 'MaxHeatingTemperature')?.value;
            if (Number(v) > Number(v1)) {
                v = v1 ?? v;
            }
        }

        setProperties((prev) => prev.map((x) => (x.id === prop.propId ? { ...x, value: v } : x)));
        setIsEdited(true);
    };

    const getValueForOption = (option: string, optionIndex: number) => {
        if (option === t('general.inactive')) {
            return 'false';
        }

        if (option === t('general.active')) {
            return 'true';
        }
        return optionIndex.toString();
    };

    const onSave = async () => {
        try {
            setIsUpdating(true);

            const result = await editObjectProperties(properties);

            if (result.status !== StatusCodes.OK) {
                showError();
                return;
            }
        } catch {
        } finally {
            setIsEdited(false);
            setIsUpdating(false);
        }
    };

    const showError = () => {
        toast.error(t('errors.errorWhileSendingValue'));
    };

    return (
        <Card
            title={
                <div className={styles.header}>
                    <div>{virtualDevice.name}</div>
                    {isEdited && (
                        <Button onClick={onSave} loading={isUpdating} type="primary">
                            {t('general.save')}
                        </Button>
                    )}
                </div>
            }
            className={styles.card}
        >
            {propertyView.map((x, index) => (
                <div key={index}>
                    <div className={styles.title}>{x.name}</div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        {x.type === PropertyTemplateType.Picker && (
                            <Select
                                disabled={x.readonly}
                                style={{ flex: 1 }}
                                options={x.options?.map((x, index) => ({
                                    label: x,
                                    value: getValueForOption(x, index),
                                }))}
                                value={x.value}
                                onChange={(value) => onValueChanged(value, x)}
                            />
                        )}
                        {x.type === PropertyTemplateType.Input && (
                            <InputNumber
                                min={x.min}
                                max={x.max}
                                style={{ flex: 1 }}
                                value={x.value ?? ''}
                                onChange={(value) => {
                                    if (value === null) {
                                        return;
                                    }
                                    onValueChanged(value, x);
                                }}
                            />
                        )}
                        <div>{x.unit ?? ''}</div>
                    </div>
                </div>
            ))}
        </Card>
    );
};

export default RoomAdvanceProperty;
