import { useState, useEffect } from 'react';

function getWindowDimensions() {
    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
    const { width: screenWidth, height: screenHeight } = window.screen;

    const isHorizontal = windowWidth > windowHeight && screenWidth < screenHeight;

    return {
        windowWidth: isHorizontal
            ? windowWidth > screenHeight
                ? screenHeight
                : windowWidth
            : windowWidth > screenWidth
            ? screenWidth
            : windowWidth,
        windowHeight: isHorizontal
            ? windowWidth > screenWidth
                ? screenWidth
                : windowWidth
            : windowHeight > screenHeight
            ? screenHeight
            : windowHeight,
    };
}

export default function useWindowDimensions(): {
    windowWidth: number;
    windowHeight: number;
} {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    function handleResize() {
        setWindowDimensions(getWindowDimensions());
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
