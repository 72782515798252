import { AccountInfo, Configuration, PublicClientApplication } from '@azure/msal-browser';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import sha256 from 'crypto-js/sha256';
import { StatusCodes } from 'http-status-codes';
import { Agent } from 'https';
import i18n from 'i18next';
import { Moment } from 'moment';
import { toast } from 'react-toastify';
import { isCloud } from '../env.json';
import { AdvancedProperty } from '../models/AdvancedProperty';
import { Assignment } from '../models/Assignment';
import { AuthPayload } from '../models/AuthPayload';
import { AuthResponse } from '../models/AuthResponse';
import { DatalogItem } from '../models/DatalogItem';
import { Datapoint } from '../models/Datapoint';
import { HttpRequest } from '../models/HttpRequest';
import { Info } from '../models/Info';
import { PropertyTemplate } from '../models/PropertyTemplate';
import { SetupInfo } from '../models/SetupInfo';
import { StatisticItem } from '../models/StatisticItem';
import { User } from '../models/User';
import { CloudCredential, UserCredential } from '../models/UserCredential';
import { UserGroup } from '../models/UserGroup';
import { VirtualDevice } from '../models/VirtualDevice';
import { HttpApi } from '../models/enums/HttpApi';
import { getCloudCredentials, getUserCredential, saveUserCredential, setCloudCredentials } from './CookieHelper';
import { encryptRSA } from './HttpRSAHelper';

import { setDatapointValue, setSetupInfo, setVirtualDeviceProps } from '../app/globalSettings';
import { store } from '../app/store';
import { FrameSelectionOption } from '../components/energy-management-modal/EnergyManagementModal';
import bulkDemo from '../demo/bulk.json';
import bulkCustomDemo from '../demo/bulkCustom.json';
import { EnergyStatistic } from '../models/EnergyStatistic';
import { NetatmoValidation } from '../models/NetatmoValidation';
import { SystemNotification } from '../models/SystemNotification';
import { UpdateInfo } from '../models/UpdateInfo';
import { UpdateVersionInfo } from '../models/UpdateVersionInfo';
import { WebsocketMessage } from '../models/WebsocketMessage';

export let isDemo = false;
export let demoMode: 'normal' | 'custom' = 'normal';
export const defaultServer = '192.168.100.17';
const httpAgent = new Agent({ rejectUnauthorized: false });
const hostname = window.location.hostname.includes('localhost') ? defaultServer : window.location.hostname;

const tenantName = 'temb2c';
const signInPolicy = 'B2C_1A_signup_signin';
const applicationID = '08f75a7f-9842-42f7-ad63-528955a00c23';
export const reactRedirectUri = window.location.hostname.includes('localhost')
    ? 'http://localhost:3000/'
    : `https://${window.location.hostname}/`;
const AuthorityUrl = `https://${tenantName}.b2clogin.com/tfp/${tenantName}.onmicrosoft.com/${signInPolicy}`;
const resetPolicy = 'B2C_1_reset';
const ResetAuthorityUrl = `https://${tenantName}.b2clogin.com/tfp/${tenantName}.onmicrosoft.com/${resetPolicy}`;
const trused = `${tenantName}.b2clogin.com`;

const configuration: Configuration = {
    auth: {
        clientId: applicationID,
        authority: AuthorityUrl,
        knownAuthorities: [trused],
        redirectUri: reactRedirectUri,
    },
    cache: { cacheLocation: 'localStorage', storeAuthStateInCookie: true },
};
export const msalInstance = new PublicClientApplication(configuration);

const createCloudUrl = (api: HttpApi) => {
    return `https://dtem-homesrv-apis-homeserver.azurewebsites.net/api/${api}`;
};

const createUrl = (hostname: string, api: HttpApi, forceCloud?: boolean) => {
    return isCloud || forceCloud ? createCloudUrl(api) : `http://${hostname}/api/web/${api}`;
};

export let cloudToken: string | undefined;

export const setDemo = (value: boolean, custom?: boolean): void => {
    isDemo = value;
    demoMode = custom ? 'custom' : 'normal';

    store.dispatch(setSetupInfo(undefined));
};

export const logout = async (): Promise<void> => {
    cloudToken = undefined;
    const cred = getCloudCredentials();

    await msalInstance.logoutPopup({ account: cred?.account });
    setCloudCredentials(undefined);
};

export const getCloudAccount = (): AccountInfo => {
    return msalInstance?.getAllAccounts()?.[0];
};

export const checkCloudConnection = async (userCredential?: CloudCredential, force?: boolean): Promise<boolean> => {
    try {
        const info = await fetchInfo(userCredential, force);

        const result = info.status == StatusCodes.OK;

        return result;
    } catch (err) {
        console.log(err);
        // handle error
        return false;
    }
};

const getConfig = async (credentials?: UserCredential, forceCloud?: boolean): Promise<AxiosRequestConfig> => {
    if (isCloud || forceCloud) {
        const cloudCredentials = getCloudCredentials();
        const cloudCredential: CloudCredential | undefined = credentials
            ? {
                  ...credentials,
                  isCurrent: true,
              }
            : cloudCredentials;

        if ((!cloudToken || !!credentials) && cloudCredential) {
            const loginRequest = {
                scopes: ['https://temb2c.onmicrosoft.com/homeapi/read', 'https://temb2c.onmicrosoft.com/homeapi/write'],
                extraQueryParameters: {
                    ui_locales: i18n.resolvedLanguage,
                },
                forceRefresh: true,
            };

            try {
                if (cloudCredential?.account) {
                    const result = await msalInstance.acquireTokenSilent({
                        ...loginRequest,
                        account: cloudCredential.account,
                    });
                    cloudToken = result.accessToken;
                    setCloudCredentials({ ...cloudCredential, account: result.account ? result.account : undefined });
                } else {
                    const result = await msalInstance.acquireTokenPopup({ ...loginRequest, prompt: 'login' });
                    cloudToken = result.accessToken;

                    setCloudCredentials({ ...cloudCredential, account: result.account ? result.account : undefined });
                }
            } catch (exception) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const ex = exception as any;

                if (ex.toString().includes('AADB2C90118')) {
                    try {
                        const result = await msalInstance.acquireTokenPopup({
                            ...loginRequest,
                            authority: ResetAuthorityUrl,
                        });
                        cloudToken = result.accessToken;
                        setCloudCredentials({
                            ...cloudCredential,
                            account: result.account ? result.account : undefined,
                        });
                    } catch {}
                } else {
                    try {
                        const result = await msalInstance.acquireTokenPopup(loginRequest);
                        cloudToken = result.accessToken;
                        setCloudCredentials({
                            ...cloudCredential,
                            account: result.account ? result.account : undefined,
                        });
                    } catch {
                        throw new Error('Cannot get token');
                    }
                }
            }
        }

        return {
            method: 'GET',
            httpsAgent: httpAgent,
            headers: {
                Authorization: `Bearer ${cloudToken}`,
                username: cloudCredential?.username,
                password: cloudCredential?.password,
            },
        };
    }

    const userCredentials = credentials ?? getUserCredential();

    if (userCredentials?.accessTokenExpire && new Date(userCredentials?.accessTokenExpire) < new Date()) {
        const tokens = await getTokens({
            payload: { refresh_token: userCredentials.refresh_token, scope: userCredentials.scope },
        });

        if (tokens.status == StatusCodes.OK) {
            const d = new Date();
            d.setHours(d.getHours(), d.getMinutes() + 10, d.getSeconds(), d.getMilliseconds());
            saveUserCredential({
                username: userCredentials.username,
                accessTokenExpire: d,
                ...tokens,
                save: userCredentials.save,
                serverName: userCredentials.serverName,
            });
            userCredentials.access_token = tokens.access_token;
        }
    }

    return {
        httpsAgent: httpAgent,
        headers: { Authorization: `Bearer ${userCredentials?.access_token}` },
    };
};

const getNonce = async (): Promise<AuthResponse> => {
    const result = await axios
        .post(createUrl(hostname, HttpApi.Token), { httpsAgent: httpAgent })
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { ...result?.data, status: result?.status };
};

const getTokens = async (payload: AuthPayload): Promise<AuthResponse> => {
    const result = await axios.post(createUrl(hostname, HttpApi.Token), payload).catch((error: AxiosError) => {
        return error.response;
    });
    return { ...result?.data, status: result?.status };
};

export const authenticate = async (credentials: UserCredential): Promise<boolean> => {
    const nonce = (await getNonce())?.nonce;

    if (!nonce) {
        return false;
    }

    const newPassword = sha256(credentials.password + nonce).toString();
    const encryptedPassword = await encryptRSA(newPassword);
    const tokens = await getTokens({
        payload: { nonce: nonce, name: credentials.username, password: encryptedPassword },
    });

    if (tokens.status == StatusCodes.OK) {
        const d = new Date();
        d.setHours(d.getHours(), d.getMinutes() + 10, d.getSeconds(), d.getMilliseconds());
        saveUserCredential({
            username: credentials.username,
            accessTokenExpire: d,
            ...tokens,
            save: credentials.save,
            serverName: credentials.serverName,
        });
        return true;
    }

    if (
        tokens.reason === 'Password mismatch' ||
        tokens.reason === 'User not exists' ||
        tokens.status === StatusCodes.UNAUTHORIZED
    ) {
        toast.error(i18n.t('errors.wrongUsernameOrPassword'));
    } else if (tokens.status === StatusCodes.FORBIDDEN) {
        toast.error(i18n.t('errors.serverBlockedTryAgain'));
    } else {
        toast.error(i18n.t('errors.connectionFailed'));
    }

    return false;
};

export const fetchInfo = async (credentials?: UserCredential, forceCloud?: boolean): Promise<Info> => {
    const result = await axios
        .get(createUrl(hostname, HttpApi.Info, forceCloud), await getConfig(credentials, forceCloud))
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { ...result?.data, status: result?.status };
};

export const fetchSetupInfo = async (): Promise<SetupInfo> => {
    if (isDemo) {
        return demoMode === 'normal'
            ? { ...JSON.parse(JSON.stringify(bulkDemo)), status: StatusCodes.OK, statusText: '' }
            : { ...JSON.parse(JSON.stringify(bulkCustomDemo)), status: StatusCodes.OK, statusText: '' };
    }

    const result = await axios.get(createUrl(hostname, HttpApi.Bulk), await getConfig()).catch((error: AxiosError) => {
        return error.response;
    });

    let objects: VirtualDevice[] = result?.data?.objects?.items ?? [];

    try {
        if (result?.data?.objects?.more) {
            let getNext;
            do {
                const objResult = await getVirtualDevices('startid=' + (objects[objects.length - 1].id + 1));
                objects = [...objects, ...(objResult?.objects ?? [])];
                getNext = objResult.more;
            } while (getNext);
        }
    } catch {}

    return { ...result?.data, status: result?.status, objects: { items: objects } };
};

export const getVirtualDevices = async (params?: string): Promise<{ objects: VirtualDevice[]; more: boolean }> => {
    const result = await axios
        .get(createUrl(hostname, HttpApi.Objects) + (params ? `?${params}` : ''), await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return result?.data;
};

export const updateDatapointValue = async (
    datapoint: Datapoint,
    newValue: string | number | boolean,
): Promise<HttpRequest> => {
    if (isDemo) {
        const message: WebsocketMessage = { id: datapoint.id, header: '', value: newValue, ioid: 0, api: '' };
        store.dispatch(setDatapointValue(message));
        return { status: StatusCodes.OK, statusText: '' };
    }
    const result = await axios
        .put(`${createUrl(hostname, HttpApi.Datapoint)}?id=${datapoint.id}&value=${newValue}`, {}, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { ...result?.data, status: result?.status };
};

export const updateDatapoint = async (datapoint: Datapoint): Promise<HttpRequest> => {
    if (isDemo) {
        return { status: StatusCodes.OK, statusText: '' };
    }
    const result = await axios
        .put(
            `${createUrl(hostname, HttpApi.Datapoint)}?id=${datapoint.id}`,
            { ...datapoint, header: 'dprequest' },
            await getConfig(),
        )
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { ...result?.data, status: result?.status };
};

export const pushButtonDatapoint = async (datapoint: Datapoint, code?: string): Promise<HttpRequest> => {
    if (isDemo) {
        return { status: StatusCodes.OK, statusText: '' };
    }
    const result = isCloud
        ? await axios
              .put(
                  `${createUrl(hostname, HttpApi.Datapoint)}?id=${datapoint.id}`,
                  { value: true, tag: 'auto_off', code: code },
                  await getConfig(),
              )
              .catch((error: AxiosError) => {
                  return error.response;
              })
        : await axios
              .put(
                  `${createUrl(hostname, HttpApi.Datapoint)}?id=${datapoint.id}&value=${true}&tag=auto_off` +
                      (code ? `&code=${code}` : ''),
                  {},
                  await getConfig(),
              )
              .catch((error: AxiosError) => {
                  return error.response;
              });
    return { ...result?.data, status: result?.status };
};

export const fetchCameraImg = async (cameraImageUrl: string): Promise<{ data: string; status: StatusCodes }> => {
    const result = await axios
        .get(`${createUrl(hostname, HttpApi.Camera)}?url=${cameraImageUrl}`, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { ...result?.data, status: result?.status };
};

export const fetchImage = async (base64Name: string): Promise<{ data: string; status: StatusCodes }> => {
    const result = await axios
        .get(`${createUrl(hostname, HttpApi.Webimages)}?image=${base64Name}`, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { ...result?.data, status: result?.status };
};

export const updateVirtualDevice = async (
    virtualDeviceId: number,
    favorite: boolean,
    ranking: number,
): Promise<HttpRequest> => {
    if (isDemo) {
        const object: { favourite: boolean; objId: number; ranking: number } = {
            favourite: favorite,
            objId: virtualDeviceId,
            ranking: ranking,
        };
        store.dispatch(setVirtualDeviceProps(object));
        return { status: StatusCodes.OK, statusText: '' };
    }
    const result = await axios
        .put(
            `${createUrl(hostname, HttpApi.Objects)}`,
            {
                objects: [
                    {
                        id: virtualDeviceId,
                        favourite: favorite,
                        ranking: ranking,
                    },
                ],
            },
            await getConfig(),
        )
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { ...result?.data, status: result?.status };
};

export const fetchStatistics = async (
    datapointID: string,
    startDate: Moment,
    endDate: Moment,
): Promise<{ statistic: StatisticItem[]; status: StatusCodes }> => {
    const dateFormat = 'yyyy-MM-DDTHH:mm:ss';
    const result = await axios
        .get(
            `${createUrl(hostname, HttpApi.Statistic)}?id=${datapointID}&startdate=${startDate.format(
                dateFormat,
            )}&enddate=${endDate.format(dateFormat)}`,
            await getConfig(),
        )
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { ...result?.data, status: result?.status };
};

export const fetchDatalog = async (
    iosIds: number[],
    startDate: Moment,
    endDate: Moment,
): Promise<{ data: DatalogItem[]; status: StatusCodes }> => {
    const result = await axios
        .post(
            `${createUrl(hostname, HttpApi.Datalog)}`,
            {
                startDate: startDate.format('yyyy-MM-DDTHH:mm:ss'),
                endDate: endDate.format('yyyy-MM-DDTHH:mm:ss'),
                maxSize: 128000,
                ios: iosIds,
            },
            await getConfig(),
        )
        .catch((error: AxiosError) => {
            return error.response;
        });

    let objects: DatalogItem[] = result?.data?.result ?? [];

    try {
        if (result?.data?.more) {
            let getNext;
            do {
                const objResult = await axios.post(
                    `${createUrl(hostname, HttpApi.Datalog)}`,
                    {
                        startDate: startDate.format('yyyy-MM-DDTHH:mm:ssZ'),
                        endDate: endDate.format('yyyy-MM-DDTHH:mm:ssZ'),
                        maxSize: 128000,
                        ios: iosIds,
                        startId: objects[objects.length - 1].id + 1,
                    },
                    await getConfig(),
                );
                objects = [...objects, ...(objResult?.data?.result ?? [])];
                getNext = objResult?.data?.more;
            } while (getNext);
        }
    } catch {}

    return { data: objects, status: result?.status as StatusCodes };
};

export const fetchEnergyStatistics = async (
    monitorObjectId: number,
    startDate: Moment,
    endDate: Moment,
    type: FrameSelectionOption,
): Promise<{ data: EnergyStatistic; status: StatusCodes }> => {
    const result = await axios
        .post(
            `${createUrl(hostname, HttpApi.Statistic)}`,
            {
                payload: {
                    startDate: startDate.format('yyyy-MM-DDTHH:mm:ss'),
                    endDate: endDate.format('yyyy-MM-DDTHH:mm:ss'),
                    monitorObjectId,
                    type,
                    maxCount: 60,
                },
            },
            await getConfig(),
        )
        .catch((error: AxiosError) => {
            return error.response;
        });

    return { data: result?.data?.statistic, status: result?.status as StatusCodes };
};

export const fetchUserGroups = async (): Promise<{ data: UserGroup[]; status: StatusCodes }> => {
    const result = await axios
        .get(`${createUrl(hostname, HttpApi.UserGroups)}`, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { data: result?.data?.usergroups, status: result?.status as StatusCodes };
};

export const editUserGroups = async (groups: UserGroup[]): Promise<{ status: StatusCodes }> => {
    if (isDemo) {
        return { status: StatusCodes.OK };
    }
    const result = await axios
        .put(`${createUrl(hostname, HttpApi.UserGroups)}`, { usergroups: groups }, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { status: result?.status as StatusCodes };
};

export const deleteUserGroup = async (group: UserGroup): Promise<{ status: StatusCodes }> => {
    if (isDemo) {
        return { status: StatusCodes.OK };
    }
    const result = await axios
        .delete(`${createUrl(hostname, HttpApi.UserGroups)}`, {
            ...(await getConfig()),
            data: { usergroups: [group] },
        })
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { status: result?.status as StatusCodes };
};

export const addUserGroup = async (group: UserGroup): Promise<{ status: StatusCodes }> => {
    if (isDemo) {
        return { status: StatusCodes.OK };
    }
    const result = await axios
        .post(`${createUrl(hostname, HttpApi.UserGroups)}`, { usergroups: [group] }, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { status: result?.status as StatusCodes };
};

export const editUser = async (user: User): Promise<{ status: StatusCodes }> => {
    if (isDemo) {
        return { status: StatusCodes.OK };
    }
    const result = await axios
        .put(`${createUrl(hostname, HttpApi.Users)}`, { users: [user] }, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { status: result?.status as StatusCodes };
};

export const deleteUser = async (user: User): Promise<{ status: StatusCodes }> => {
    if (isDemo) {
        return { status: StatusCodes.OK };
    }
    const result = await axios
        .delete(`${createUrl(hostname, HttpApi.Users)}`, {
            ...(await getConfig()),
            data: { users: [user] },
        })
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { status: result?.status as StatusCodes };
};

export const addUser = async (user: User): Promise<{ status: StatusCodes }> => {
    if (isDemo) {
        return { status: StatusCodes.OK };
    }
    const result = await axios
        .post(`${createUrl(hostname, HttpApi.Users)}`, { users: [user] }, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { status: result?.status as StatusCodes };
};

export const fetchAssignments = async (): Promise<{ data: Assignment[]; status: StatusCodes }> => {
    const result = await axios
        .get(`${createUrl(hostname, HttpApi.Assignments)}`, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { data: result?.data?.assignments, status: result?.status as StatusCodes };
};

export const editAssignement = async (assignments: Assignment[]): Promise<{ status: StatusCodes }> => {
    if (isDemo) {
        return { status: StatusCodes.OK };
    }
    const result = await axios
        .put(`${createUrl(hostname, HttpApi.Assignments)}`, { assignments: assignments }, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { status: result?.status as StatusCodes };
};

export const fetchAdvancedProperties = async (): Promise<{ data: PropertyTemplate[]; status: StatusCodes }> => {
    const result = await axios
        .get(`${createUrl(hostname, HttpApi.PropertyTemplates)}`, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { data: result?.data?.propertytemplates, status: result?.status as StatusCodes };
};

export const editObjectProperties = async (properties: AdvancedProperty[]): Promise<{ status: StatusCodes }> => {
    if (isDemo) {
        return { status: StatusCodes.OK };
    }
    const result = await axios
        .put(
            `${createUrl(hostname, HttpApi.ObjectProperties)}`,
            { properties: properties.map((x) => ({ id: x.id, value: x.value.toString() })) },
            await getConfig(),
        )
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { status: result?.status as StatusCodes };
};

export const fetchUpdateDetails = async (): Promise<{ data: UpdateVersionInfo; status: StatusCodes }> => {
    const updateDependencies = {
        check: 1,
        ci: {
            sid: '001',
            version: '2.44.0',
            name: 'mobileapp',
            dependencies: {
                radioserver: '>=2.16.0',
                smartserver: '>=2.16.0',
            },
        },
    };
    const result = await axios
        .post(`${createUrl(hostname, HttpApi.Update)}`, updateDependencies, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { data: result?.data, status: result?.status as StatusCodes };
};

export const fetchUpdateInfo = async (): Promise<{ data: UpdateInfo; status: StatusCodes }> => {
    const result = await axios
        .get(`${createUrl(hostname, HttpApi.Update)}`, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { data: result?.data, status: result?.status as StatusCodes };
};

export const startUpdate = async (url: string): Promise<{ status: StatusCodes }> => {
    const result = await axios
        .post(`${createUrl(hostname, HttpApi.Update)}`, { url }, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { status: result?.status as StatusCodes };
};

export const fetchSystemNotifications = async (): Promise<{ data: SystemNotification[]; status: StatusCodes }> => {
    const result = await axios
        .get(`${createUrl(hostname, HttpApi.Systemnotification)}`, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { data: result?.data.systemnotifications, status: result?.status as StatusCodes };
};

export const delayNotification = async (
    api: HttpApi,
    body: { id: number; postpone: string },
): Promise<{ status: StatusCodes }> => {
    const result = await axios
        .post(`${createUrl(hostname, api)}`, body, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { status: result?.status as StatusCodes };
};

export const fetchNetatmoDevices = async (): Promise<{ data: NetatmoValidation[]; status: StatusCodes }> => {
    const result = await axios
        .get(`${createUrl(hostname, HttpApi.Netatmo)}`, await getConfig())
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { data: result?.data.devices, status: result?.status as StatusCodes };
};

export const updateNetatmoDeviceToken = async (
    deviceId: number,
    code: string,
    redirectUri: string,
): Promise<{ status: StatusCodes }> => {
    const result = await axios
        .put(
            `${createUrl(hostname, HttpApi.Netatmo)}?id=${deviceId}&code=${code}&redirectUri=${redirectUri}`,
            undefined,
            await getConfig(),
        )
        .catch((error: AxiosError) => {
            return error.response;
        });
    return { status: result?.status as StatusCodes };
};
