import styles from './CentralHomeButtons.module.scss';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { pushButtonDatapoint } from '../../../../helpers/HttpMethods';
import { DatapointNames } from '../../../../models/enums/DatapointNames';
import { ControllersProps } from '../ControllersProps';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { useTranslation } from 'react-i18next';
import CustomButton from '../components/custom-button/CustomButton';
import classNames from 'classnames';
import SleepImg from '../../../../images/sleep.svg';
import InHomeImg from '../../../../images/in_home.svg';
import AwayFromHomeImg from '../../../../images/away_from_home.svg';
import ControlHeader from '../components/control-header/ControlHeader';
import { useState } from 'react';
import HomeButtonsEditModal from '../../../../components/home-buttons-edit-modal/HomeButtonsEditModal';

const CentralHomeButtons = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const [isTimeProgramConfigModalOpen, setIsTimeProgramConfigModalOpen] = useState(false);

    const commingHomeDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualComingHome,
    );
    const sleepDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualSleep,
    );
    const leavingHomeDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualLeavingHome,
    );
    const buttonsConfigDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.CentralComingHomeConfig,
    );

    const onEditButtonClick = () => {
        setIsTimeProgramConfigModalOpen(true);
    };

    return (
        <div className={styles.mainContainer} style={{ border: border }}>
            <ControlHeader virtualDevice={virtualDevice} room={room} onEditButtonClick={onEditButtonClick} />
            <div className={styles.buttonsContainer}>
                {commingHomeDatapoint && (
                    <CustomButton
                        className={classNames(styles.button, styles.roundButton)}
                        imgClassName={styles.buttonImg}
                        onClick={() => pushButtonDatapoint(commingHomeDatapoint)}
                        img={InHomeImg}
                    />
                )}
                {leavingHomeDatapoint && (
                    <CustomButton
                        className={classNames(styles.button, styles.roundButton)}
                        imgClassName={styles.buttonImg}
                        onClick={() => pushButtonDatapoint(leavingHomeDatapoint)}
                        img={AwayFromHomeImg}
                    />
                )}
                {sleepDatapoint && (
                    <CustomButton
                        className={classNames(styles.button, styles.roundButton)}
                        imgClassName={styles.buttonImg}
                        onClick={() => pushButtonDatapoint(sleepDatapoint)}
                        img={SleepImg}
                    />
                )}
            </div>
            {buttonsConfigDatapoint && isTimeProgramConfigModalOpen && (
                <HomeButtonsEditModal
                    datapoint={buttonsConfigDatapoint}
                    virtualDevice={virtualDevice}
                    onCloseRequested={() => setIsTimeProgramConfigModalOpen(false)}
                />
            )}
        </div>
    );
};

export default CentralHomeButtons;
