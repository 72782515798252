import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, Modal, Slider, Space, Switch, Tabs, TimePicker } from 'antd';
import { BaseButtonProps } from 'antd/lib/button/button';
import classNames from 'classnames';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { updateDatapoint } from '../../helpers/HttpMethods';
import { findDuplicates, findEmpty, getNewTimeProgramName, isEmpty } from '../../helpers/StringHelper';
import exImg from '../../images/ex.svg';
import { SwitchPoint } from '../../models/SwitchPoint';
import { TimeProgram } from '../../models/TimeProgram';
import { VirtualDeviceCategorySettings } from '../../models/constants/VirtualDeviceCategorySettings';
import { DatapointNames } from '../../models/enums/DatapointNames';
import { DatapointType } from '../../models/enums/DatapointType';
import YesNoModal from '../yes-no-modal/YesNoModal';
import styles from './TimeProgramEditModal.module.scss';
import { TimeProgramEditModalProps } from './TimeProgramEditModalProps';

export enum TimeProgramType {
    Heating,
    Timer,
}

const TimeProgramEditModal = (props: TimeProgramEditModalProps): JSX.Element => {
    const { timeProgramDatapoint, virtualDevice, type, closeModalRequested } = props;
    const sortedTimePrograms = timeProgramDatapoint?.TimeProgram.TimeProgramList?.map((x) => ({
        ...x,
        SwitchPoints: x.SwitchPoints.slice().sort((a: SwitchPoint, b: SwitchPoint) => {
            return moment(a.Time, 'HH:mm').diff(moment(b.Time, 'HH:mm'));
        }),
    }));
    const [timePrograms, setTimePrograms] = useState<TimeProgram[]>(sortedTimePrograms ?? []);
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState<string>('0');
    const [yesNoModalVisible, setYesNoModalVisible] = useState<{ onYesClicked: () => void; onNoClicked: () => void }>();

    const isEdited = timePrograms != sortedTimePrograms;
    const isError = findDuplicates(timePrograms.map((x) => x.Name)) || findEmpty(timePrograms.map((x) => x.Name));

    const manualCoolingDatapoint = useMemo(
        () =>
            virtualDevice?.datapoints?.find(
                (x) =>
                    x.type == DatapointType.Temperature &&
                    (x.name == DatapointNames.VirtualManualModeCooling ||
                        x.name == DatapointNames.VirtualSetpointManualModeCooling),
            ),
        [virtualDevice?.datapoints],
    );

    const manualHeatingDatapoint = useMemo(
        () =>
            virtualDevice?.datapoints?.find(
                (x) =>
                    x.type == DatapointType.Temperature &&
                    (x.name == DatapointNames.VirtualManualModeHeating ||
                        x.name == DatapointNames.VirtualSetpointManualModeHeating),
            ),
        [virtualDevice?.datapoints],
    );

    const addTimeProgram = async () => {
        const newTimeProgram: TimeProgram = {
            Name: getNewTimeProgramName(timePrograms),
            days: [],
            SwitchPoints: [
                {
                    Time: '12:00',
                    Heating: type === TimeProgramType.Heating ? 22 : undefined,
                    Cooling:
                        type === TimeProgramType.Heating && timeProgramDatapoint?.TimeProgram?.CoolingSupport
                            ? 20
                            : undefined,
                    Value: type === TimeProgramType.Timer ? true : undefined,
                },
            ],
        };

        const newTimePrograms = [...timePrograms, newTimeProgram];

        setTimePrograms(newTimePrograms);
        setYesNoModalVisible(undefined);
        setActiveTab(String(newTimePrograms.length - 1));
    };

    const removeTimeProgram = async (index: number) => {
        const newTimePrograms = timePrograms.filter((x) => x !== timePrograms[index]);

        if (newTimePrograms.length === 0) {
            return;
        }

        setTimePrograms(newTimePrograms);

        const activeTabNumber = Number(activeTab);
        if (activeTabNumber >= index) {
            const numberToSet = activeTabNumber === 0 ? 0 : activeTabNumber - 1;
            setActiveTab(String(numberToSet));
        }
    };

    const saveTimeProgram = async () => {
        try {
            setIsLoading(true);
            const filteredTimePrograms: TimeProgram[] = [];

            timePrograms.forEach((timeProgram) => {
                const filteredSwitchPoints: SwitchPoint[] = [];

                timeProgram.SwitchPoints.forEach((point) => {
                    if (
                        !filteredSwitchPoints.find(
                            (x) =>
                                x.Time === point.Time &&
                                x.Cooling === point.Cooling &&
                                x.Heating === point.Heating &&
                                x.Value === point.Value,
                        )
                    ) {
                        filteredSwitchPoints.push(point);
                    }
                });

                filteredTimePrograms.push({ ...timeProgram, SwitchPoints: filteredSwitchPoints });
            });

            const newTimeProgramDatapoint = {
                ...timeProgramDatapoint,
                TimeProgram: { ...timeProgramDatapoint.TimeProgram, TimeProgramList: timePrograms },
            };

            const result = await updateDatapoint(newTimeProgramDatapoint);

            if (result?.status != StatusCodes.OK) {
                showError();
            }
        } catch {
            showError();
        } finally {
            setIsLoading(false);
        }
    };

    const showError = () => {
        toast.error(t('errors.errorWhileSendingValue'));
    };

    const onCloseRequested = () => {
        if (isEdited) {
            setYesNoModalVisible({
                onYesClicked: closeModalRequested,
                onNoClicked: () => setYesNoModalVisible(undefined),
            });
            return;
        }

        closeModalRequested();
    };

    const sliderHeatingChanged = (index: number, switchPoint: SwitchPoint, heating: number) => {
        setSwitchPoint(
            index,
            timePrograms[index].SwitchPoints.map((x) =>
                x === switchPoint
                    ? {
                          ...x,
                          Heating: heating,
                      }
                    : x,
            ),
        );
    };

    const sliderCoolingChanged = (index: number, switchPoint: SwitchPoint, cooling: number) => {
        setSwitchPoint(
            index,
            timePrograms[index].SwitchPoints.map((x) =>
                x === switchPoint
                    ? {
                          ...x,
                          Cooling: cooling,
                      }
                    : x,
            ),
        );
    };

    const switchChanged = (index: number, switchPoint: SwitchPoint, value: boolean) => {
        setSwitchPoint(
            index,
            timePrograms[index].SwitchPoints.map((x) =>
                x === switchPoint
                    ? {
                          ...x,
                          Value: value,
                      }
                    : x,
            ),
        );
    };

    const setSwitchPoint = (index: number, switchPoints: SwitchPoint[]) => {
        setTimePrograms(
            timePrograms.map((x) =>
                timePrograms.indexOf(x) === index
                    ? {
                          ...x,
                          SwitchPoints: switchPoints,
                      }
                    : x,
            ),
        );
    };

    const addSwitchPoint = (index: number) => {
        const newSwitchPoint: SwitchPoint = {
            Time: `00:00`,
            Heating: type === TimeProgramType.Heating ? 22 : undefined,
            Cooling:
                type === TimeProgramType.Heating && timeProgramDatapoint?.TimeProgram?.CoolingSupport ? 20 : undefined,
            Value: type === TimeProgramType.Timer ? true : undefined,
        };
        const newSwitchPoints: SwitchPoint[] = [...timePrograms[index].SwitchPoints, newSwitchPoint];

        setSwitchPoint(
            index,
            newSwitchPoints.sort((a: SwitchPoint, b: SwitchPoint) => {
                return moment(a.Time, 'HH:mm').diff(moment(b.Time, 'HH:mm'));
            }),
        );
    };

    const removeSwitchPoint = (index: number, switchPoint: SwitchPoint) => {
        setSwitchPoint(
            index,
            timePrograms[index].SwitchPoints.filter((x) => x !== switchPoint),
        );
    };

    const onDayClicked = (index: number, day: number) => {
        if (!dayIsEnabled(index, day)) {
            return;
        }

        setTimePrograms(
            timePrograms.map((x) =>
                x === timePrograms[index]
                    ? {
                          ...timePrograms[index],
                          days: timePrograms[index].days.includes(day)
                              ? timePrograms[index].days.filter((x) => x !== day)
                              : [...timePrograms[index].days, day].sort((a, b) => a - b),
                      }
                    : x,
            ),
        );
    };

    const dayIsEnabled = (index: number, day: number) => {
        return !timePrograms.filter((x) => x !== timePrograms[index]).some((x) => x.days.some((y) => y === day));
    };

    const onSave = async () => {
        await saveTimeProgram();
        closeModalRequested();
    };

    const onTabChanged = (key: string) => {
        setActiveTab(key);
    };

    const dayButtonProps = (
        index: number,
        day: number,
    ): BaseButtonProps & { style: React.CSSProperties } & { onClick: () => void } => ({
        type: 'primary',
        shape: 'circle',
        style: {
            border: `2px solid ${category?.color}`,
            backgroundColor: timePrograms[index].days.includes(day) ? category?.color : 'transparent',
            opacity: dayIsEnabled(index, day) ? 1 : 0.5,
            cursor: dayIsEnabled(index, day) ? 'pointer' : 'auto',
            color: 'black',
        },
        onClick: () => onDayClicked(index, day),
    });

    return (
        <Modal
            title={virtualDevice.name}
            open={true}
            onCancel={onCloseRequested}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{
                disabled: isError,
                loading: isLoading,
            }}
            okText={t('general.save')}
            onOk={onSave}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            width={700}
        >
            <div className={styles.mainContainer}>
                <Tabs
                    activeKey={activeTab}
                    onChange={onTabChanged}
                    onEdit={(targetKey, action) => {
                        if (action === 'add') {
                            addTimeProgram();
                        } else {
                            removeTimeProgram(Number(targetKey));
                        }
                    }}
                    tabBarExtraContent={{
                        left: (
                            <Button
                                onClick={(e) => {
                                    onTabChanged(Number(activeTab) <= 0 ? '0' : String(Number(activeTab) - 1));
                                    e.currentTarget.blur();
                                }}
                                className={styles.leftArrow}
                            >
                                <LeftOutlined />
                            </Button>
                        ),
                        right: (
                            <Button
                                onClick={(e) => {
                                    onTabChanged(
                                        Number(activeTab) >= timePrograms.length - 1
                                            ? String(timePrograms.length - 1)
                                            : String(Number(activeTab) + 1),
                                    );
                                    e.currentTarget.blur();
                                }}
                                className={styles.leftArrow}
                            >
                                <RightOutlined />
                            </Button>
                        ),
                    }}
                    type="editable-card"
                    style={{ width: '100%' }}
                    hideAdd={timePrograms.length === 7}
                    items={timePrograms.map((timeProgram, tpIndex) => ({
                        label: timeProgram.Name,
                        key: tpIndex.toString(),
                        closable: timePrograms.length > 1,
                        children: (
                            <div className={styles.tabContent}>
                                <Input
                                    status={
                                        timePrograms
                                            .filter((x) => x !== timePrograms[tpIndex])
                                            .find((y) => timePrograms[tpIndex].Name === y.Name) ||
                                        isEmpty(timeProgram.Name)
                                            ? 'error'
                                            : undefined
                                    }
                                    onChange={(value) => {
                                        setTimePrograms(
                                            timePrograms.map((x) =>
                                                x === timePrograms[tpIndex]
                                                    ? {
                                                          ...timePrograms[tpIndex],
                                                          Name: value.currentTarget.value,
                                                      }
                                                    : x,
                                            ),
                                        );
                                    }}
                                    value={timeProgram.Name}
                                    placeholder={t('timeProgramEdit.timeProgramName')}
                                />
                                {timePrograms
                                    .filter((x) => x !== timeProgram)
                                    .find((y) => timeProgram.Name === y.Name) && (
                                    <div className={styles.nameError}>{t('errors.NameAlreadyExist')}</div>
                                )}
                                {isEmpty(timeProgram.Name) && (
                                    <div className={styles.nameError}>{t('errors.fieldCannotBeEmpty')}</div>
                                )}
                                <Space className={styles.daysContainer}>
                                    <Button {...dayButtonProps(tpIndex, 1)}>{t('timeProgramEdit.mo')}</Button>
                                    <Button {...dayButtonProps(tpIndex, 2)}>{t('timeProgramEdit.tu')}</Button>
                                    <Button {...dayButtonProps(tpIndex, 3)}>{t('timeProgramEdit.we')}</Button>
                                    <Button {...dayButtonProps(tpIndex, 4)}>{t('timeProgramEdit.th')}</Button>
                                    <Button {...dayButtonProps(tpIndex, 5)}>{t('timeProgramEdit.fr')}</Button>
                                    <Button {...dayButtonProps(tpIndex, 6)}>{t('timeProgramEdit.sa')}</Button>
                                    <Button {...dayButtonProps(tpIndex, 0)}>{t('timeProgramEdit.su')}</Button>
                                </Space>
                                <div className={styles.contentContainer}>
                                    {timeProgram.SwitchPoints.map((switchPoint, index) => (
                                        <div key={index} className={styles.sliderDatapoint}>
                                            {type !== TimeProgramType.Timer && (
                                                <TimePicker
                                                    allowClear={false}
                                                    style={{ width: 100 }}
                                                    format="HH:mm"
                                                    showNow={false}
                                                    value={moment(switchPoint.Time, 'HH:mm')}
                                                    onBlur={() =>
                                                        setSwitchPoint(
                                                            tpIndex,
                                                            timeProgram.SwitchPoints.slice().sort(
                                                                (a: SwitchPoint, b: SwitchPoint) => {
                                                                    return moment(a.Time, 'HH:mm').diff(
                                                                        moment(b.Time, 'HH:mm'),
                                                                    );
                                                                },
                                                            ),
                                                        )
                                                    }
                                                    onSelect={(value) => {
                                                        setSwitchPoint(
                                                            tpIndex,
                                                            timeProgram.SwitchPoints.map((x, spIndex) =>
                                                                spIndex === index
                                                                    ? { ...x, Time: moment(value).format('HH:mm') }
                                                                    : x,
                                                            ),
                                                        );
                                                    }}
                                                    onChange={(value) => {
                                                        setSwitchPoint(
                                                            tpIndex,
                                                            timeProgram.SwitchPoints.map((x, spIndex) =>
                                                                spIndex === index
                                                                    ? { ...x, Time: moment(value).format('HH:mm') }
                                                                    : x,
                                                            ),
                                                        );
                                                    }}
                                                />
                                            )}
                                            <div className={styles.switchPointContainer}>
                                                {timeProgram.SwitchPoints.length > 1 ? (
                                                    <img
                                                        onClick={() => removeSwitchPoint(tpIndex, switchPoint)}
                                                        className={styles.removeImg}
                                                        src={exImg}
                                                    />
                                                ) : (
                                                    <div className={styles.removeImg} />
                                                )}
                                                {type === TimeProgramType.Heating && (
                                                    <div className={styles.slidersContainer}>
                                                        <div className={styles.slider}>
                                                            <Slider
                                                                step={1}
                                                                min={
                                                                    manualHeatingDatapoint?.range?.[0]
                                                                        ? Number(manualHeatingDatapoint?.range?.[0])
                                                                        : 0
                                                                }
                                                                max={
                                                                    manualHeatingDatapoint?.range?.[1]
                                                                        ? Number(manualHeatingDatapoint?.range?.[1])
                                                                        : 40
                                                                }
                                                                trackStyle={{ backgroundColor: '#ea567f' }}
                                                                handleStyle={{
                                                                    borderColor: '#ea567f',
                                                                }}
                                                                value={switchPoint?.Heating ?? 22}
                                                                onChange={(value: number) =>
                                                                    sliderHeatingChanged(tpIndex, switchPoint, value)
                                                                }
                                                            />
                                                            <InputNumber
                                                                className={styles.numberInput}
                                                                addonAfter="°C"
                                                                step="0.1"
                                                                value={switchPoint?.Heating?.toString()}
                                                                min={
                                                                    manualHeatingDatapoint?.range?.[0]
                                                                        ? manualHeatingDatapoint?.range?.[0]
                                                                        : '0'
                                                                }
                                                                max={
                                                                    manualHeatingDatapoint?.range?.[1]
                                                                        ? manualHeatingDatapoint?.range?.[1]
                                                                        : '40'
                                                                }
                                                                onChange={(value) => {
                                                                    if (value === null) {
                                                                        return;
                                                                    }
                                                                    const v = +(value ?? '22');

                                                                    const min = manualHeatingDatapoint?.range?.[0]
                                                                        ? Number(manualHeatingDatapoint?.range?.[0])
                                                                        : 0;
                                                                    if (isNaN(v) || v < min) {
                                                                        sliderHeatingChanged(tpIndex, switchPoint, min);
                                                                        return;
                                                                    }
                                                                    const max = manualHeatingDatapoint?.range?.[1]
                                                                        ? Number(manualHeatingDatapoint?.range?.[1])
                                                                        : 40;
                                                                    if (v > max) {
                                                                        sliderHeatingChanged(tpIndex, switchPoint, max);
                                                                        return;
                                                                    }

                                                                    sliderHeatingChanged(
                                                                        tpIndex,
                                                                        switchPoint,
                                                                        +v.toFixed(1),
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                        {timeProgramDatapoint.TimeProgram.CoolingSupport && (
                                                            <div
                                                                className={classNames(
                                                                    styles.slider,
                                                                    styles.coolingSlider,
                                                                )}
                                                            >
                                                                <Slider
                                                                    step={1}
                                                                    min={
                                                                        manualCoolingDatapoint?.range?.[0]
                                                                            ? Number(manualCoolingDatapoint?.range?.[0])
                                                                            : 15
                                                                    }
                                                                    max={
                                                                        manualCoolingDatapoint?.range?.[1]
                                                                            ? Number(manualCoolingDatapoint?.range?.[1])
                                                                            : 40
                                                                    }
                                                                    trackStyle={{ backgroundColor: '#56B2EA' }}
                                                                    handleStyle={{
                                                                        borderColor: '#56B2EA',
                                                                    }}
                                                                    value={
                                                                        (switchPoint?.Cooling ?? 22) < 15
                                                                            ? 15
                                                                            : switchPoint?.Cooling ?? 22
                                                                    }
                                                                    onChange={(value: number) =>
                                                                        sliderCoolingChanged(
                                                                            tpIndex,
                                                                            switchPoint,
                                                                            value,
                                                                        )
                                                                    }
                                                                />
                                                                <InputNumber
                                                                    className={styles.numberInput}
                                                                    addonAfter="°C"
                                                                    step="0.1"
                                                                    value={switchPoint?.Cooling?.toString()}
                                                                    min={
                                                                        manualCoolingDatapoint?.range?.[0]
                                                                            ? manualCoolingDatapoint?.range?.[0]
                                                                            : '15'
                                                                    }
                                                                    max={
                                                                        manualCoolingDatapoint?.range?.[1]
                                                                            ? manualCoolingDatapoint?.range?.[1]
                                                                            : '40'
                                                                    }
                                                                    onChange={(value) => {
                                                                        if (value === null) {
                                                                            return;
                                                                        }

                                                                        const v = +(
                                                                            value ??
                                                                            (manualCoolingDatapoint?.range?.[0]
                                                                                ? manualCoolingDatapoint?.range?.[0]
                                                                                : '15')
                                                                        );

                                                                        const min = manualCoolingDatapoint?.range?.[0]
                                                                            ? Number(manualCoolingDatapoint?.range?.[0])
                                                                            : 15;

                                                                        if (isNaN(v)) {
                                                                            sliderCoolingChanged(
                                                                                tpIndex,
                                                                                switchPoint,
                                                                                min,
                                                                            );
                                                                            return;
                                                                        }

                                                                        const max = manualCoolingDatapoint?.range?.[1]
                                                                            ? Number(manualCoolingDatapoint?.range?.[1])
                                                                            : 40;
                                                                        if (v > max) {
                                                                            sliderCoolingChanged(
                                                                                tpIndex,
                                                                                switchPoint,
                                                                                max,
                                                                            );
                                                                            return;
                                                                        }

                                                                        sliderCoolingChanged(
                                                                            tpIndex,
                                                                            switchPoint,
                                                                            +v.toFixed(1),
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {type === TimeProgramType.Timer && (
                                                    <TimePicker
                                                        allowClear={false}
                                                        style={{ width: 100 }}
                                                        format="HH:mm"
                                                        showNow={false}
                                                        value={moment(switchPoint.Time, 'HH:mm')}
                                                        onBlur={() =>
                                                            setSwitchPoint(
                                                                tpIndex,
                                                                timeProgram.SwitchPoints.slice().sort(
                                                                    (a: SwitchPoint, b: SwitchPoint) => {
                                                                        return moment(a.Time, 'HH:mm').diff(
                                                                            moment(b.Time, 'HH:mm'),
                                                                        );
                                                                    },
                                                                ),
                                                            )
                                                        }
                                                        onSelect={(value) => {
                                                            setSwitchPoint(
                                                                tpIndex,
                                                                timeProgram.SwitchPoints.map((x, spIndex) =>
                                                                    spIndex === index
                                                                        ? {
                                                                              ...x,
                                                                              Time: moment(value).format('HH:mm'),
                                                                          }
                                                                        : x,
                                                                ),
                                                            );
                                                        }}
                                                        onChange={(value) => {
                                                            setSwitchPoint(
                                                                tpIndex,
                                                                timeProgram.SwitchPoints.map((x, spIndex) =>
                                                                    spIndex === index
                                                                        ? {
                                                                              ...x,
                                                                              Time: moment(value).format('HH:mm'),
                                                                          }
                                                                        : x,
                                                                ),
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {type === TimeProgramType.Timer && (
                                                    <Switch
                                                        disabled={timeProgramDatapoint?.writeprotect ?? false}
                                                        onChange={(checked) =>
                                                            switchChanged(tpIndex, switchPoint, checked)
                                                        }
                                                        checked={switchPoint?.Value ?? false}
                                                        style={{
                                                            backgroundColor: switchPoint?.Value
                                                                ? category?.color
                                                                : 'rgba(0, 0, 0, 0.25)',
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.addSwitchPointButtonContainer}>
                                    <Button
                                        disabled={
                                            type === TimeProgramType.Heating && timeProgram.SwitchPoints.length >= 6
                                        }
                                        onClick={(e) => {
                                            addSwitchPoint(tpIndex);
                                            e.currentTarget.blur();
                                        }}
                                    >
                                        {'+'}
                                    </Button>
                                </div>
                            </div>
                        ),
                    }))}
                />
            </div>
            {yesNoModalVisible && (
                <YesNoModal
                    onYesClicked={yesNoModalVisible.onYesClicked}
                    onNoClicked={yesNoModalVisible.onNoClicked}
                    description={t('timeProgramEdit.changesNotSaved')}
                    isVisible={true}
                />
            )}
        </Modal>
    );
};

export default TimeProgramEditModal;
