import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getNumberDatapointValue } from '../../../../helpers/DatapointHelper';
import { isEmpty } from '../../../../helpers/StringHelper';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { DatapointNames } from '../../../../models/enums/DatapointNames';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { ControllersProps } from '../ControllersProps';
import ControlHeader from '../components/control-header/ControlHeader';
import styles from './WindowsAndDoors.module.scss';

const WindowsAndDoors = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room, order, forceShowAll } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const numberOfOpenDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualNumberOfOpen,
    );
    const numberOfClosedDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualNumberOfClosed,
    );
    const numberOfTiledDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualNumberOfTiledWindows,
    );
    const devicesDatapoints = virtualDevice?.datapoints?.filter(
        (x) => x.type == DatapointType.StatusLight || x.type === DatapointType.eHandleWindow,
    );

    const [datapointsVisible, setDatapointsVisible] = useState<boolean>(false);

    const getDpValue = (value: number) => {
        switch (value) {
            case 0:
                return t('general.closed');
            case 1:
                return t('general.open');
            case 2:
                return t('general.tiled');
            default:
                return '';
        }
    };

    return (
        <div
            className={classNames(styles.mainContainer, numberOfTiledDatapoint && styles.tiledVisible)}
            style={{ zIndex: order, border: border }}
        >
            <ControlHeader virtualDevice={virtualDevice} room={room} />
            {numberOfOpenDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapointTop)}>
                    <div>{t('windowsAndDoors.numberOfOpen')}</div>
                    <div>{getNumberDatapointValue(numberOfOpenDatapoint)}</div>
                </div>
            )}
            {numberOfClosedDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapointTop)}>
                    <div>{t('windowsAndDoors.numberOfClosed')}</div>
                    <div>{getNumberDatapointValue(numberOfClosedDatapoint)}</div>
                </div>
            )}
            {numberOfTiledDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapointTop)}>
                    <div>{t('windowsAndDoors.numberOfTiled')}</div>
                    <div>{getNumberDatapointValue(numberOfTiledDatapoint)}</div>
                </div>
            )}
            {forceShowAll &&
                devicesDatapoints?.length > 0 &&
                devicesDatapoints.map((datapoint) => (
                    <div key={datapoint.id} className={classNames(styles.titleValue, styles.datapointTop)}>
                        <div>{datapoint.name}</div>
                        <div>{getDpValue(getNumberDatapointValue(datapoint))}</div>
                    </div>
                ))}
            {!forceShowAll && devicesDatapoints?.length > 0 && (
                <div
                    className={styles.dots}
                    onMouseEnter={() => {
                        setDatapointsVisible(true);
                    }}
                    onMouseLeave={() => setDatapointsVisible(false)}
                >
                    <span className={styles.dot} />
                    <span className={styles.dot} />
                    <span className={styles.dot} />
                    {datapointsVisible && (
                        <div
                            className={styles.datapointsList}
                            style={{ zIndex: order, borderBottom: border, borderLeft: border, borderRight: border }}
                        >
                            {devicesDatapoints?.length > 0 &&
                                devicesDatapoints.map((datapoint) => (
                                    <div key={datapoint.id} className={classNames(styles.titleValue, styles.datapoint)}>
                                        <div>
                                            {!isEmpty(datapoint.customname) && datapoint.name.startsWith('_')
                                                ? datapoint.customname
                                                : datapoint.name}
                                        </div>
                                        <div>{getDpValue(getNumberDatapointValue(datapoint))}</div>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default WindowsAndDoors;
