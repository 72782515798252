import { Button, DatePicker, Modal, Switch } from 'antd';
import classNames from 'classnames';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useWindowDimensions from '../../helpers/Hooks/useWindowDimensions';
import { isDemo, updateDatapoint } from '../../helpers/HttpMethods';
import exImg from '../../images/ex.svg';
import { CalendarSwitchPoint } from '../../models/CalendarSwitchPoint';
import { Datapoint } from '../../models/Datapoint';
import { VirtualDeviceCategorySettings } from '../../models/constants/VirtualDeviceCategorySettings';
import YesNoModal from '../yes-no-modal/YesNoModal';
import styles from './CalendarTimeProgramEditModal.module.scss';
import { CalendarTimeProgramEditModalProps } from './CalendarTimeProgramEditModalProps';

const CalendarTimeProgramEditModal = (props: CalendarTimeProgramEditModalProps): JSX.Element => {
    const { timeProgramDatapoint, virtualDevice, closeModalRequested } = props;
    const { t } = useTranslation();
    const { windowHeight } = useWindowDimensions();
    const category = useMemo(
        () => VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category),
        [virtualDevice],
    );
    const calendarPoints = useMemo(
        () => timeProgramDatapoint?.TimeProgram?.CalendarSwitchPoints?.map((x, index) => ({ ...x, id: index })) ?? [],
        [timeProgramDatapoint?.TimeProgram?.CalendarSwitchPoints],
    );
    const [calendarSwitchPoints, setCalendarSwitchPoints] = useState<CalendarSwitchPoint[]>(calendarPoints);
    const [yesNoModalVisible, setYesNoModalVisible] = useState<{ onYesClicked: () => void; onNoClicked: () => void }>();
    const [isLoading, setIsLoading] = useState(false);

    const isEdited = calendarPoints != calendarSwitchPoints;

    useEffect(() => {
        if (isEdited) {
            closeModalRequested();
            setIsLoading(false);
        }
    }, [timeProgramDatapoint?.TimeProgram?.CalendarSwitchPoints]);

    const saveTimeProgram = async () => {
        try {
            setIsLoading(true);
            const filteredSwitchPoints: CalendarSwitchPoint[] = [];

            calendarSwitchPoints.forEach((point) => {
                if (
                    !filteredSwitchPoints.find(
                        (x) =>
                            (!x.Date?.includes('Z') ? x.Date + 'Z' : x.Date) ===
                                (!point.Date?.includes('Z') ? point.Date + 'Z' : point.Date) && x.Value === point.Value,
                    )
                ) {
                    filteredSwitchPoints.push({
                        ...point,
                        Date: !point.Date?.includes('Z') ? point.Date + 'Z' : point.Date,
                    });
                }
            });

            const newTimeProgramDatapoint: Datapoint = {
                ...timeProgramDatapoint,
                TimeProgram: {
                    CalendarSwitchPoints: filteredSwitchPoints,
                },
            };

            const result = await updateDatapoint(newTimeProgramDatapoint);

            if (result?.status != StatusCodes.OK) {
                showError();
                setIsLoading(false);
                return;
            }

            if (isDemo) {
                closeModalRequested();
            }
        } catch {
            showError();
            setIsLoading(false);
        }
    };

    const showError = () => {
        toast.error(t('errors.errorWhileSendingValue'));
    };

    const onCloseRequested = () => {
        if (isEdited) {
            setYesNoModalVisible({
                onYesClicked: closeModalRequested,
                onNoClicked: () => setYesNoModalVisible(undefined),
            });
            return;
        }

        closeModalRequested();
    };

    const switchChanged = (switchPoint: CalendarSwitchPoint, value: boolean) => {
        setCalendarSwitchPoints(
            calendarSwitchPoints.map((x) =>
                x.id == switchPoint.id
                    ? {
                          ...x,
                          Value: value,
                      }
                    : x,
            ),
        );
    };

    const addSwitchPoint = () => {
        const newSwitchPoint: CalendarSwitchPoint = {
            Date: moment().utc().format('YYYY-01-01T00:00:00'),
            Value: true,
        };

        const newSwitchPoints: CalendarSwitchPoint[] = [...calendarSwitchPoints, newSwitchPoint]
            .map((x, index) => ({ ...x, id: index }))
            .sort((a: CalendarSwitchPoint, b: CalendarSwitchPoint) => {
                return moment.utc(a.Date).diff(moment.utc(b.Date));
            });

        setCalendarSwitchPoints(newSwitchPoints);
    };

    const removeSwitchPoint = (switchPoint: CalendarSwitchPoint) => {
        setCalendarSwitchPoints(
            calendarSwitchPoints.filter((x) => x.id !== switchPoint.id).map((x, index) => ({ ...x, id: index })),
        );
    };

    return (
        <Modal
            title={virtualDevice.name}
            open={true}
            onCancel={onCloseRequested}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{
                loading: isLoading,
            }}
            okText={t('general.save')}
            onOk={saveTimeProgram}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            width={500}
        >
            <div>
                <div
                    className={classNames(styles.contentContainer, {
                        [styles.contentOverflow]: windowHeight * 0.7 - 116 < calendarSwitchPoints.length * 38,
                    })}
                >
                    {calendarSwitchPoints &&
                        calendarSwitchPoints.map((switchPoint, index) => (
                            <div key={index} className={styles.switchPointContainer}>
                                {calendarSwitchPoints.length > 1 ? (
                                    <img
                                        onClick={() => removeSwitchPoint(switchPoint)}
                                        className={styles.removeImg}
                                        src={exImg}
                                    />
                                ) : (
                                    <div className={styles.removeImg} />
                                )}
                                <DatePicker
                                    showTime
                                    format="DD.MM.yyyy HH:mm"
                                    allowClear={false}
                                    showNow={false}
                                    value={moment.utc(switchPoint.Date, 'YYYY-MM-DDTHH:mm:ss').local()}
                                    onBlur={() =>
                                        setCalendarSwitchPoints(
                                            [...calendarSwitchPoints].sort(
                                                (a: CalendarSwitchPoint, b: CalendarSwitchPoint) => {
                                                    return moment.utc(a.Date).diff(moment.utc(b.Date));
                                                },
                                            ),
                                        )
                                    }
                                    onChange={(value) => {
                                        setCalendarSwitchPoints(
                                            calendarSwitchPoints.map(
                                                (x, spIndex): CalendarSwitchPoint =>
                                                    spIndex === index
                                                        ? {
                                                              ...x,
                                                              Date: !!value
                                                                  ? value.utc().format('YYYY-MM-DDTHH:mm:00')
                                                                  : moment().utc().format('YYYY-MM-DDTHH:mm:00'),
                                                          }
                                                        : x,
                                            ),
                                        );
                                    }}
                                    onSelect={(value) => {
                                        setCalendarSwitchPoints(
                                            calendarSwitchPoints.map(
                                                (x, spIndex): CalendarSwitchPoint =>
                                                    spIndex === index
                                                        ? {
                                                              ...x,
                                                              Date: !!value
                                                                  ? value.utc().format('YYYY-MM-DDTHH:mm:00')
                                                                  : moment().utc().format('YYYY-MM-DDTHH:mm:00'),
                                                          }
                                                        : x,
                                            ),
                                        );
                                    }}
                                />
                                <Switch
                                    disabled={timeProgramDatapoint?.writeprotect ?? false}
                                    onChange={(checked) => switchChanged(switchPoint, checked)}
                                    checked={switchPoint?.Value ?? false}
                                    style={{
                                        backgroundColor: switchPoint?.Value ? category?.color : 'rgba(0, 0, 0, 0.25)',
                                    }}
                                />
                            </div>
                        ))}
                </div>
                <div className={styles.addSwitchPointButtonContainer}>
                    <Button
                        onClick={(e) => {
                            addSwitchPoint();
                            e.currentTarget.blur();
                        }}
                    >
                        {'+'}
                    </Button>
                </div>
            </div>
            {yesNoModalVisible && (
                <YesNoModal
                    onYesClicked={yesNoModalVisible.onYesClicked}
                    onNoClicked={yesNoModalVisible.onNoClicked}
                    description={t('timeProgramEdit.changesNotSaved')}
                    isVisible={true}
                />
            )}
        </Modal>
    );
};

export default CalendarTimeProgramEditModal;
