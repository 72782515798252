import Modal from 'react-modal';

export const defaultModalStyle: Modal.Styles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '100vh',
        overflowY: 'auto',
    },
    overlay: {
        zIndex: 9999,
    },
};
