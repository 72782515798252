export enum VirtualDeviceType {
    Undefined,
    AlarmClock,
    Blinds,
    BurglarAlarm,
    Calendar,
    CentralBattery,
    CentralBlindsController,
    CentralGarageGate,
    CentralHomeButtons,
    CentralLightController,
    CentralRoomControl,
    CentralWindowsAndDoors,
    Dimmer,
    DoorController,
    EnergyMonitor,
    FireAndWaterAlarm,
    GarageGate,
    LightController,
    PresenceSimulation,
    PushButton,
    PushSwitch,
    RoomController,
    Schedule,
    SelectionSwitch,
    StaircaseTimer,
    Switch,
    TemperatureController,
    ToiletVentilationController,
    TotalEnergyMonitor,
    CentralScheduler,
    Camera,
    Monitor,
    FloorPlan,
    ConstantLightController,
    AstroFunction,
    OperationalModeSelector,
    EnergyManagement,
    Alphatronics = 58,
}
