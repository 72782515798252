import styles from './RoomController.module.scss';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { updateDatapointValue } from '../../../../helpers/HttpMethods';
import { DatapointNames } from '../../../../models/enums/DatapointNames';
import { getNumberDatapointValue, getValueValidatedByRange } from '../../../../helpers/DatapointHelper';
import { ControllersProps } from '../ControllersProps';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Select from 'react-select';
import { Datapoint } from '../../../../models/Datapoint';
import { StatusCodes } from 'http-status-codes';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { RoomControllerMode } from '../../../../models/enums/RoomControllerMode';
import CustomRange from '../components/custom-slider/CustomRange';
import ControlHeader from '../components/control-header/ControlHeader';
import TimeProgramEditModal, {
    TimeProgramType,
} from '../../../../components/time-program-edit-modal/TimeProgramEditModal';
import editImg from '../../../../images/edit.svg';
import { Button } from 'antd';

const RoomController = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const currentTemperatureDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Temperature && x.name == DatapointNames.VirtualIsRoomTemperature,
    );
    const humidityDatapoint = virtualDevice?.datapoints?.find((x) => x.type == DatapointType.Humidity);
    const modeDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.RoomControllerOperationMode && x.name == DatapointNames.VirtualOperationMode,
    );
    const manualCoolingDatapoint = virtualDevice?.datapoints?.find(
        (x) =>
            x.type == DatapointType.Temperature &&
            (x.name == DatapointNames.VirtualManualModeCooling ||
                x.name == DatapointNames.VirtualSetpointManualModeCooling),
    );
    const manualHeatingDatapoint = virtualDevice?.datapoints?.find(
        (x) =>
            x.type == DatapointType.Temperature &&
            (x.name == DatapointNames.VirtualManualModeHeating ||
                x.name == DatapointNames.VirtualSetpointManualModeHeating),
    );
    const standbyDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Temperature && x.name == DatapointNames.VirtualStandbyTemperature,
    );
    const timeProgramDatapoint = virtualDevice.datapoints?.find(
        (x) => x.type == DatapointType.HeatingTimeProgram || x.type == DatapointType.CentralHeatingTimeProgram,
    );

    const allModes = [
        {
            id: RoomControllerMode.FullAutomatic,
            name: t('roomController.fullAutomatic'),
        },
        {
            id: RoomControllerMode.AutomaticHeating,
            name: t('roomController.automaticHeating'),
        },
        {
            id: RoomControllerMode.AutomaticCooling,
            name: t('roomController.automaticCooling'),
        },
        {
            id: RoomControllerMode.ManualHeating,
            name: t('roomController.manualHeating'),
        },
        {
            id: RoomControllerMode.ManualCooling,
            name: t('roomController.manualCooling'),
        },
        {
            id: RoomControllerMode.Standby,
            name: t('roomController.standby'),
        },
    ];

    const [isTimeProgramConfigModalOpen, setIsTimeProgramConfigModalOpen] = useState<boolean>(false);
    const [modes, setModes] = useState<{ value: string; label: string; id: number }[] | undefined>([]);

    const onDatapointValueChanged = async (value: boolean | number | string, datapoint?: Datapoint) => {
        if (datapoint) {
            const result = await updateDatapointValue(datapoint, value);
            if (result.status !== StatusCodes.OK) {
                toast.error(t('errors.errorWhileSendingValue'));
                return;
            }
        }
    };

    useEffect(() => {
        setModes(
            modeDatapoint?.range.map((x) => ({
                value: getModeForId(Number(x))?.name ?? '',
                label: getModeForId(Number(x))?.name ?? '',
                id: Number(x),
            })),
        );
    }, [modeDatapoint]);

    const getModeForId = (id: number) => {
        return allModes.find((x) => x.id == id);
    };

    return (
        <div className={styles.mainContainer} style={{ border: border }}>
            <ControlHeader virtualDevice={virtualDevice} room={room} />
            {currentTemperatureDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('roomController.currentTemperature')}</div>
                    <div>{`${getNumberDatapointValue(currentTemperatureDatapoint).toFixed(1)}°C`}</div>
                </div>
            )}
            {humidityDatapoint && (
                <div className={classNames(styles.titleValue, styles.datapoint)}>
                    <div>{t('roomController.humidity')}</div>
                    <div>{`${getNumberDatapointValue(humidityDatapoint)}%`}</div>
                </div>
            )}
            {modeDatapoint && (
                <div className={styles.timeProgramSelectionContainer}>
                    <Select
                        isDisabled={modeDatapoint.writeprotect}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 999, fontSize: 14 }) }}
                        onChange={(value) => onDatapointValueChanged(value?.id ?? 0, modeDatapoint)}
                        value={modes?.find((x) => x.id == getNumberDatapointValue(modeDatapoint))}
                        className={classNames(styles.modeSelect)}
                        options={modes}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#a1a1a1',
                            },
                        })}
                    />
                    <Button
                        disabled={modeDatapoint.writeprotect || (getNumberDatapointValue(modeDatapoint) ?? 1) > 2}
                        size="large"
                        className={styles.editButton}
                        onClick={() => setIsTimeProgramConfigModalOpen(true)}
                        shape="circle"
                    >
                        <img className={styles.editImg} src={editImg} />
                    </Button>
                </div>
            )}
            {getNumberDatapointValue(modeDatapoint) == RoomControllerMode.ManualHeating && manualHeatingDatapoint && (
                <div>
                    <div className={classNames(styles.titleValue, styles.datapoint)}>
                        <div>{t('roomController.heatingTemperature')}</div>
                        <div>{`${getNumberDatapointValue(manualHeatingDatapoint)}°C`}</div>
                    </div>
                    <div className={styles.sliderDatapoint}>
                        <CustomRange
                            disabled={manualHeatingDatapoint.writeprotect}
                            step={1}
                            min={Number(manualHeatingDatapoint?.range[0] ?? 0)}
                            max={Number(manualHeatingDatapoint?.range[1] ?? 100)}
                            value={getValueValidatedByRange(manualHeatingDatapoint)}
                            minTrackColor={category?.color ?? '#e0e0e0'}
                            onValueChanged={(value) => onDatapointValueChanged(value, manualHeatingDatapoint)}
                        />
                    </div>
                </div>
            )}
            {getNumberDatapointValue(modeDatapoint) == RoomControllerMode.ManualCooling && manualCoolingDatapoint && (
                <div>
                    <div className={classNames(styles.titleValue, styles.datapoint)}>
                        <div>{t('roomController.coolingTemperature')}</div>
                        <div>{`${getNumberDatapointValue(manualCoolingDatapoint)}°C`}</div>
                    </div>
                    <div className={styles.sliderDatapoint}>
                        <CustomRange
                            disabled={manualCoolingDatapoint.writeprotect}
                            step={1}
                            min={Number(manualCoolingDatapoint?.range[0] ?? 0)}
                            max={Number(manualCoolingDatapoint?.range[1] ?? 100)}
                            value={getValueValidatedByRange(manualCoolingDatapoint)}
                            minTrackColor={category?.color ?? '#e0e0e0'}
                            onValueChanged={(value) => onDatapointValueChanged(value, manualCoolingDatapoint)}
                        />
                    </div>
                </div>
            )}
            {getNumberDatapointValue(modeDatapoint) == RoomControllerMode.Standby && standbyDatapoint && (
                <div>
                    <div className={classNames(styles.titleValue, styles.datapoint)}>
                        <div>{t('roomController.standbyTemperature')}</div>
                        <div>{`${getNumberDatapointValue(standbyDatapoint)}°C`}</div>
                    </div>
                    <div className={styles.sliderDatapoint}>
                        <CustomRange
                            disabled={standbyDatapoint.writeprotect}
                            step={1}
                            min={Number(standbyDatapoint?.range[0] ?? 0)}
                            max={Number(standbyDatapoint?.range[1] ?? 100)}
                            value={getValueValidatedByRange(standbyDatapoint)}
                            minTrackColor={category?.color ?? '#e0e0e0'}
                            onValueChanged={(value) => onDatapointValueChanged(value, standbyDatapoint)}
                        />
                    </div>
                </div>
            )}
            {timeProgramDatapoint && isTimeProgramConfigModalOpen && (
                <TimeProgramEditModal
                    closeModalRequested={() => setIsTimeProgramConfigModalOpen(false)}
                    timeProgramDatapoint={timeProgramDatapoint}
                    virtualDevice={virtualDevice}
                    type={TimeProgramType.Heating}
                />
            )}
        </div>
    );
};

export default RoomController;
