import { BiTransfer } from 'react-icons/bi';
import { Handle, NodeProps, Position } from 'reactflow';
import styled from 'styled-components';

const Round = styled.div`
    border: 1px solid #e3e3e3;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CenterNode = ({ data }: NodeProps<{ total: number }>) => {
    return (
        <>
            <Handle
                type="target"
                position={Position.Top}
                id="centerTopTarget"
                style={{ background: 'transparent', border: 'transparent' }}
                isConnectable={false}
            />
            <Handle
                type="source"
                position={Position.Top}
                id="centerTopSource"
                style={{ background: 'transparent', border: 'transparent' }}
                isConnectable={false}
            />
            <Handle
                type="target"
                position={Position.Top}
                id="centerRightTopTarget"
                style={{ background: 'transparent', border: 'transparent', left: '90%', top: '10%' }}
                isConnectable={false}
            />
            <Handle
                type="source"
                position={Position.Top}
                id="centerRightTopSource"
                style={{ background: 'transparent', border: 'transparent', left: '90%', top: '10%' }}
                isConnectable={false}
            />
            <Handle
                type="target"
                position={Position.Top}
                id="centerLeftTopTarget"
                style={{ background: 'transparent', border: 'transparent', left: '10%', top: '10%' }}
                isConnectable={false}
            />
            <Handle
                type="source"
                position={Position.Top}
                id="centerLeftTopSource"
                style={{ background: 'transparent', border: 'transparent', left: '10%', top: '10%' }}
                isConnectable={false}
            />
            <Handle
                type="target"
                position={Position.Left}
                id="centerLeftTarget"
                style={{ background: 'transparent', border: 'transparent' }}
                isConnectable={false}
            />
            <Handle
                type="source"
                position={Position.Left}
                id="centerLeftSource"
                style={{ background: 'transparent', border: 'transparent' }}
                isConnectable={false}
            />
            <Round style={{ borderColor: data.total === 0 ? '#e3e3e3' : data.total > 0 ? 'green' : 'red' }}>
                <BiTransfer
                    fontSize={10}
                    style={{
                        transform: 'rotate(90deg)',
                        color: data.total === 0 ? '#e3e3e3' : data.total > 0 ? 'green' : 'red',
                    }}
                />
            </Round>
            <Handle
                type="source"
                position={Position.Bottom}
                id="centerBottomSource"
                style={{ background: 'transparent', border: 'transparent' }}
                isConnectable={false}
            />
            <Handle
                type="target"
                position={Position.Bottom}
                id="centerBottomTarget"
                style={{ background: 'transparent', border: 'transparent' }}
                isConnectable={false}
            />
            <Handle
                type="source"
                position={Position.Bottom}
                id="centerRightBottomSource"
                style={{ background: 'transparent', border: 'transparent', bottom: '10%', left: '90%' }}
                isConnectable={false}
            />
            <Handle
                type="target"
                position={Position.Bottom}
                id="centerRightBottomTarget"
                style={{ background: 'transparent', border: 'transparent', bottom: '10%', left: '90%' }}
                isConnectable={false}
            />
            <Handle
                type="source"
                position={Position.Bottom}
                id="centerLeftBottomSource"
                style={{ background: 'transparent', border: 'transparent', bottom: '10%', left: '10%' }}
                isConnectable={false}
            />
            <Handle
                type="target"
                position={Position.Bottom}
                id="centerLeftBottomTarget"
                style={{ background: 'transparent', border: 'transparent', bottom: '10%', left: '10%' }}
                isConnectable={false}
            />
            <Handle
                type="source"
                position={Position.Right}
                id="centerRightSource"
                style={{ background: 'transparent', border: 'transparent' }}
                isConnectable={false}
            />
            <Handle
                type="target"
                position={Position.Right}
                id="centerRightTarget"
                style={{ background: 'transparent', border: 'transparent' }}
                isConnectable={false}
            />
        </>
    );
};

export default CenterNode;
