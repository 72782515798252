import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import dateFormat from 'dateformat';
import { StatusCodes } from 'http-status-codes';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Icons } from 'react-toastify';
import { getVirtualDevices } from '../../../app/globalSettings';
import { useAppSelector } from '../../../app/hooks';
import { delayNotification, pushButtonDatapoint } from '../../../helpers/HttpMethods';
import { DatapointNames } from '../../../models/enums/DatapointNames';
import { HttpApi } from '../../../models/enums/HttpApi';
import { NotificationType } from '../../../models/enums/NotificationType';
import { NotificationInfo } from '../Notifications';
import styles from './NotificationView.module.scss';

type Props = {
    notification: NotificationInfo;
    onRemoveNotification: (index: number) => void;
    index: number;
};

enum DateSelectOption {
    hour = 60,
    hour6 = 360,
    hour12 = 720,
    day1 = 1440,
    day3 = 4320,
    week = 10080,
    never = 1000000,
}

const NotificationView = ({ notification, index, onRemoveNotification }: Props): JSX.Element => {
    const { t } = useTranslation();
    const virtualDevices = useAppSelector(getVirtualDevices);
    const [isLoading, setIsLoading] = useState(false);
    const [postopneOption, setPostponeOption] = useState<DateSelectOption>(DateSelectOption.hour);

    const isAlarm =
        notification.notificationType === NotificationType.FireAlarm ||
        notification.notificationType === NotificationType.SmokeAlarm ||
        notification.notificationType === NotificationType.WaterAlarm ||
        notification.notificationType === NotificationType.SystemAlarm;

    const canPostpone =
        notification.notificationType === NotificationType.DeviceNotResponding ||
        notification.notificationType === NotificationType.Custom ||
        notification.notificationType === NotificationType.Energy ||
        notification.notificationType === NotificationType.Battery;

    const dateSelectOptions = useMemo(
        () => [
            { value: DateSelectOption.hour, label: t('general.1hour') },
            { value: DateSelectOption.hour6, label: t('general.6hours') },
            { value: DateSelectOption.hour12, label: t('general.12hours') },
            { value: DateSelectOption.day1, label: t('general.1day') },
            { value: DateSelectOption.day3, label: t('general.3days') },
            { value: DateSelectOption.week, label: t('general.week') },
            { value: DateSelectOption.never, label: t('general.never') },
        ],
        [],
    );

    const onDisarm = async () => {
        const vd = virtualDevices?.find((x) => x.id === notification.virtualDeviceId);

        if (!vd) {
            return;
        }

        const quitDatapoint = vd.datapoints.find((x) => x.name === DatapointNames.VirtualQuitAlarm);

        if (!quitDatapoint) {
            return;
        }

        setIsLoading(true);

        const result = await pushButtonDatapoint(quitDatapoint);

        if (result.status === StatusCodes.OK) {
            onRemoveNotification(index);
        }

        setIsLoading(false);
    };

    const onDelay = () => {
        setIsLoading(true);

        const now = new Date();
        const newTime = new Date(now.getTime() + postopneOption * 60000);

        delayNotification(
            notification.notificationType === NotificationType.DeviceNotResponding
                ? HttpApi.Systemnotification
                : HttpApi.Notification,
            {
                id: notification.id,
                postpone: dateFormat(newTime, 'yyyy-mm-dd HH:MM:ss', true).replace(' ', 'T') + 'Z',
            },
        );

        onRemoveNotification(index);

        setIsLoading(false);
    };

    return (
        <div
            className={classNames(styles.notificationWrapper, {
                [styles.notificationError]: notification.type === 'error',
                [styles.notificationInfo]: notification.type === 'info',
            })}
        >
            <div className={styles.iconWrapper}>
                {notification.type === 'error'
                    ? Icons.error({
                          type: 'error',
                          theme: 'light',
                      })
                    : Icons.info({
                          type: 'info',
                          theme: 'light',
                      })}
            </div>
            <div className={styles.titleWrapper}>
                <div className={styles.title}>{notification.title}</div>
                <div>{notification.message}</div>
                {isAlarm && (
                    <div className={styles.notificationButtonWrapper}>
                        <Button disabled={isLoading} onClick={() => onRemoveNotification(index)}>
                            {t('general.close')}
                        </Button>
                        <Button loading={isLoading} danger onClick={onDisarm}>
                            {t('alarmSystem.quiteAlarm')}
                        </Button>
                    </div>
                )}
                {canPostpone && (
                    <div className={styles.notificationButtonWrapper}>
                        <Select
                            isSearchable={false}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999999999999, fontSize: 14 }) }}
                            onChange={(v) => {
                                if (!v) {
                                    return;
                                }
                                setPostponeOption(v.value);
                            }}
                            value={dateSelectOptions.find((x) => x.value === postopneOption)}
                            options={dateSelectOptions}
                            isDisabled={isLoading}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#a1a1a1',
                                },
                            })}
                        />
                        <Button loading={isLoading} danger onClick={onDelay}>
                            {t('alarmSystem.postpone')}
                        </Button>
                    </div>
                )}
            </div>
            {!isAlarm && (
                <div className={styles.closeIcon} onClick={() => onRemoveNotification(index)}>
                    <CloseOutlined style={{ fontSize: 16 }} />
                </div>
            )}
        </div>
    );
};

export default NotificationView;
