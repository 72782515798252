import { useMemo } from 'react';
import 'reactflow/dist/style.css';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import useWindowDimensions from '../../../helpers/Hooks/useWindowDimensions';

const renderActiveShape = (props: any, width: number) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + (width < 800 ? 5 : 30)) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 10} y={ey} dy={-18} textAnchor={textAnchor} fill={fill}>
                {payload.name}
            </text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 10} y={ey} textAnchor={textAnchor} fill="#333">{`${value} kWh`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 10} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`${(percent * 100).toFixed(2)}%`}
            </text>
        </g>
    );
};

type Props = {
    data: { name: string; value: number; color: string }[];
};

const CustomPie = ({ data }: Props): JSX.Element => {
    const { windowWidth } = useWindowDimensions();
    const preparedData = useMemo(
        () => data.filter((x) => x.value !== 0).map((x) => ({ ...x, value: x.value < 0 ? -x.value : x.value })),
        [data],
    );
    return (
        <ResponsiveContainer height={windowWidth < 700 ? 300 : 400}>
            <PieChart>
                <Pie
                    activeIndex={preparedData.map((_, index) => index)}
                    activeShape={(props) => renderActiveShape(props, windowWidth)}
                    data={preparedData}
                    cx={'50%'}
                    cy={'50%'}
                    innerRadius={windowWidth < 700 ? '10%' : '45%'}
                    outerRadius={windowWidth < 700 ? '25%' : '65%'}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                >
                    {preparedData.map((item, index) => (
                        <Cell key={`cell-${index}`} fill={item.color} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default CustomPie;
