export interface IoConfig {
    Color: string;
    Diagram: DiagramType;
    Graph: GraphType;
    Id: number;
    Role: RoleType;
    Invert: boolean;
    Unit: string;
    FlowChart: boolean;
    HistoricalChart: boolean;
}

export interface EnergyConfig {
    IOConfigs: IoConfig[];
    SinglePower: number[];
    SingleEnergy: {
        ioId: number;
        hour: {
            id: number;
        };
    }[];
}

export enum DiagramType {
    Battery = 1,
    Car,
    Grid,
    PV,
    GeneralProducer,
    GeneralConsumer,
}

export enum GraphType {
    Line = 1,
    Beam,
    Area,
}

export enum RoleType {
    Producer = 1,
    Consumer,
    ProducerAndConsumer,
}
