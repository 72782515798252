import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Switch, Tabs } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getVirtualDevices } from '../../app/globalSettings';
import { useAppSelector } from '../../app/hooks';
import {
    getBooleanValue,
    getColorStringWithChangedColor,
    getColorStringWithChangedValue,
    getLightColor,
    getLightValue,
} from '../../helpers/DatapointHelper';
import { updateDatapoint } from '../../helpers/HttpMethods';
import { findDuplicates, findEmpty, isEmpty } from '../../helpers/StringHelper';
import editImg from '../../images/edit.svg';
import CustomRange from '../../main-page/main-controls/controls/components/custom-slider/CustomRange';
import { AstroFunctionBlock } from '../../models/AstroFunctionBlock';
import { CominghomeBlock } from '../../models/CominghomeBlock';
import { Datapoint } from '../../models/Datapoint';
import { Light } from '../../models/Light';
import { Scene } from '../../models/Scene';
import { SwitchPoint } from '../../models/SwitchPoint';
import { Colors } from '../../models/constants/Colors';
import { DatapointType } from '../../models/enums/DatapointType';
import { LightType } from '../../models/enums/LightType';
import { VirtualDeviceType } from '../../models/enums/VirtualDeviceType';
import YesNoModal from '../yes-no-modal/YesNoModal';
import styles from './SceneEditModal.module.scss';
import { SceneEditModalProps } from './SceneEditModalProps';

const ignore = 'Ignore';

const SceneEditModal = (props: SceneEditModalProps): JSX.Element => {
    const { virtualDevice, sceneDatapoint, selectedSceneIndex, closeModalRequested } = props;
    const [scenes, setScenes] = useState<Scene[]>(sceneDatapoint?.Scenes ?? []);
    const { t } = useTranslation();
    const [colorEditLight, setColorEditLight] = useState<Light>();
    const [yesNoModalVisible, setYesNoModalVisible] = useState<{ onYesClicked: () => void; onNoClicked: () => void }>();
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState<string>(selectedSceneIndex.toString());
    const isEdited = sceneDatapoint?.Scenes != scenes;
    const isError = findDuplicates(scenes.map((x) => x.Name)) || findEmpty(scenes.map((x) => x.Name));
    const virtualDevices = useAppSelector(getVirtualDevices);

    useEffect(() => {
        if (isEdited) {
            closeModalRequested();
            setIsLoading(false);
        }
    }, [sceneDatapoint?.Scenes]);

    const addScene = async () => {
        const newScene: Scene = {
            Name: getNewSceneName(),
            PresenceDetector: false,
            values: scenes[0].values.map((value) => ({ ...value, Value: getDefaultLightValue(value) })),
        };

        const newScenes = [...scenes, newScene];

        setScenes(newScenes);
    };

    const removeScene = async (index: number) => {
        const newScenes = scenes.filter((x) => x != scenes[index]);

        if (newScenes.length === 0) {
            return;
        }

        setScenes(newScenes);

        const activeTabNumber = Number(activeTab);
        if (activeTabNumber >= index) {
            const numberToSet = activeTabNumber === 0 ? 0 : activeTabNumber - 1;
            setActiveTab(String(numberToSet));
        }
    };

    const onSave = async () => {
        await saveScene();
        closeModalRequested();
    };

    const saveScene = async () => {
        try {
            setIsLoading(true);
            const newSceneDatapoint = { ...sceneDatapoint, Scenes: scenes };

            const result = await updateDatapoint(newSceneDatapoint);

            if (result?.status != StatusCodes.OK) {
                showError();
                setIsLoading(false);
                return;
            }

            for (const vd of virtualDevices?.filter(
                (x) =>
                    x.type === VirtualDeviceType.AstroFunction ||
                    x.type === VirtualDeviceType.CentralHomeButtons ||
                    x.type === VirtualDeviceType.CentralScheduler,
            ) ?? []) {
                if (vd.type === VirtualDeviceType.AstroFunction) {
                    const dp = vd.datapoints.find((x) => x.type === DatapointType.AstroFunctionConfig);
                    const connectedVirtualDeviceBlock = dp?.AstroConfig?.FunctionBlocks?.find(
                        (x) => x.ObjectID === virtualDevice.id,
                    );

                    if (
                        connectedVirtualDeviceBlock &&
                        dp?.AstroConfig &&
                        ((connectedVirtualDeviceBlock.Dawn !== ignore &&
                            Number(connectedVirtualDeviceBlock.Dawn) > scenes.length - 1) ||
                            (connectedVirtualDeviceBlock.Sunrise !== ignore &&
                                Number(connectedVirtualDeviceBlock.Sunrise) > scenes.length - 1) ||
                            (connectedVirtualDeviceBlock.Sunset !== ignore &&
                                Number(connectedVirtualDeviceBlock.Sunset) > scenes.length - 1) ||
                            (connectedVirtualDeviceBlock.Twilight !== ignore &&
                                Number(connectedVirtualDeviceBlock.Twilight) > scenes.length - 1))
                    ) {
                        const newFb: AstroFunctionBlock = {
                            ...connectedVirtualDeviceBlock,
                            Dawn:
                                connectedVirtualDeviceBlock.Dawn !== ignore &&
                                Number(connectedVirtualDeviceBlock.Dawn) > scenes.length - 1
                                    ? ignore
                                    : connectedVirtualDeviceBlock.Dawn,
                            Sunrise:
                                connectedVirtualDeviceBlock.Sunrise !== ignore &&
                                Number(connectedVirtualDeviceBlock.Sunrise) > scenes.length - 1
                                    ? ignore
                                    : connectedVirtualDeviceBlock.Sunrise,
                            Sunset:
                                connectedVirtualDeviceBlock.Sunset !== ignore &&
                                Number(connectedVirtualDeviceBlock.Sunset) > scenes.length - 1
                                    ? ignore
                                    : connectedVirtualDeviceBlock.Sunset,
                            Twilight:
                                connectedVirtualDeviceBlock.Twilight !== ignore &&
                                Number(connectedVirtualDeviceBlock.Twilight) > scenes.length - 1
                                    ? ignore
                                    : connectedVirtualDeviceBlock.Twilight,
                        };
                        const newDp: Datapoint = {
                            ...dp,
                            AstroConfig: {
                                ...dp?.AstroConfig,
                                FunctionBlocks:
                                    dp?.AstroConfig?.FunctionBlocks?.map((x) =>
                                        x === connectedVirtualDeviceBlock ? newFb : x,
                                    ) ?? [],
                            },
                        };

                        const result = await updateDatapoint(newDp);

                        if (result?.status != StatusCodes.OK) {
                            showError();
                            setIsLoading(false);
                            return;
                        }
                    }
                }

                if (vd.type === VirtualDeviceType.CentralHomeButtons) {
                    const dp = vd.datapoints.find((x) => x.type === DatapointType.CentralComingHomeConfig);
                    const connectedVirtualDeviceBlock = dp?.CominghomeConfig?.Buttons?.find(
                        (x) => x.ObjectID === virtualDevice.id,
                    );

                    if (
                        connectedVirtualDeviceBlock &&
                        dp?.CominghomeConfig?.Buttons &&
                        ((connectedVirtualDeviceBlock.ComingHome !== ignore &&
                            Number(connectedVirtualDeviceBlock.ComingHome) > scenes.length - 1) ||
                            (connectedVirtualDeviceBlock.LeavingHome !== ignore &&
                                Number(connectedVirtualDeviceBlock.LeavingHome) > scenes.length - 1) ||
                            (connectedVirtualDeviceBlock.Sleep !== ignore &&
                                Number(connectedVirtualDeviceBlock.Sleep) > scenes.length - 1))
                    ) {
                        const newFb: CominghomeBlock = {
                            ...connectedVirtualDeviceBlock,
                            ComingHome:
                                connectedVirtualDeviceBlock.ComingHome !== ignore &&
                                Number(connectedVirtualDeviceBlock.ComingHome) > scenes.length - 1
                                    ? ignore
                                    : connectedVirtualDeviceBlock.ComingHome,
                            LeavingHome:
                                connectedVirtualDeviceBlock.LeavingHome !== ignore &&
                                Number(connectedVirtualDeviceBlock.LeavingHome) > scenes.length - 1
                                    ? ignore
                                    : connectedVirtualDeviceBlock.LeavingHome,
                            Sleep:
                                connectedVirtualDeviceBlock.Sleep !== ignore &&
                                Number(connectedVirtualDeviceBlock.Sleep) > scenes.length - 1
                                    ? ignore
                                    : connectedVirtualDeviceBlock.Sleep,
                        };
                        const newDp: Datapoint = {
                            ...dp,
                            CominghomeConfig: {
                                ...dp?.CominghomeConfig,
                                Buttons:
                                    dp?.CominghomeConfig?.Buttons?.map((x) =>
                                        x === connectedVirtualDeviceBlock ? newFb : x,
                                    ) ?? [],
                            },
                        };

                        const result = await updateDatapoint(newDp);

                        if (result?.status != StatusCodes.OK) {
                            showError();
                            setIsLoading(false);
                            return;
                        }
                    }
                }

                if (vd.type === VirtualDeviceType.CentralScheduler) {
                    const dp = vd.datapoints.find((x) => x.type === DatapointType.CentralSchedulerConfig);

                    let anyChange = false;
                    let timeProgramList = dp?.TimeProgramList;

                    for (const tp of dp?.TimeProgramList ?? []) {
                        for (const sp of tp.SwitchPoints) {
                            const connectedVirtualDeviceBlock = sp.FB?.find((x) => x.ObjectID === virtualDevice.id);

                            if (
                                connectedVirtualDeviceBlock &&
                                sp.FB &&
                                connectedVirtualDeviceBlock.Value !== ignore &&
                                Number(connectedVirtualDeviceBlock.Value) > scenes.length - 1
                            ) {
                                const newFb: SwitchPoint = {
                                    ...sp,
                                    FB: sp.FB.map((fb) =>
                                        fb === connectedVirtualDeviceBlock
                                            ? {
                                                  ...fb,
                                                  Value:
                                                      connectedVirtualDeviceBlock.Value !== ignore &&
                                                      Number(connectedVirtualDeviceBlock.Value) > scenes.length - 1
                                                          ? ignore
                                                          : connectedVirtualDeviceBlock.Value,
                                              }
                                            : fb,
                                    ),
                                };
                                timeProgramList = timeProgramList?.map((tpx) =>
                                    tpx === tp
                                        ? {
                                              ...tpx,
                                              SwitchPoints: tpx?.SwitchPoints?.map((x) => (x === sp ? newFb : x)) ?? [],
                                          }
                                        : tpx,
                                );
                                anyChange = true;
                            }
                        }
                    }

                    if (anyChange && timeProgramList && dp) {
                        const newDp: Datapoint = {
                            ...dp,
                            TimeProgramList: timeProgramList,
                        };
                        const result = await updateDatapoint(newDp);

                        if (result?.status != StatusCodes.OK) {
                            showError();
                            setIsLoading(false);
                            return;
                        }
                    }
                }
            }
        } catch {
            showError();
            setIsLoading(false);
        }
    };

    const showError = () => {
        toast.error(t('errors.errorWhileSendingValue'));
    };

    const setSceneValues = (index: number, lights: Light[]) => {
        setScenes(
            scenes.map((x) =>
                scenes.indexOf(x) === index
                    ? {
                          ...x,
                          values: lights,
                      }
                    : x,
            ),
        );
    };

    const lightValueChanged = (index: number, light: Light, value: number | boolean) => {
        let newValue: string | boolean = value.toString();

        if (light.LightType === LightType.Color || light.LightType === LightType.Color_Strip) {
            newValue = getColorStringWithChangedValue(+value, light?.Value?.toString() ?? '');
        } else if (typeof value === 'boolean') {
            newValue = value;
        }

        setSceneValues(
            index,
            scenes[index].values.map((x) =>
                x.Name == light.Name
                    ? {
                          ...x,
                          Value: newValue,
                      }
                    : x,
            ),
        );
    };

    const lightColorChanged = (index: number, light: Light, value: string) => {
        let newValue = value.toString();

        if (light.LightType === LightType.Color || light.LightType === LightType.Color_Strip) {
            newValue = getColorStringWithChangedColor(value, light?.Value?.toString() ?? '');
        }

        setSceneValues(
            index,
            scenes[index].values.map((x) =>
                x.Name == light.Name
                    ? {
                          ...x,
                          Value: newValue,
                      }
                    : x,
            ),
        );
    };

    const getDefaultLightValue = (light: Light) => {
        switch (light.LightType) {
            case LightType.Color:
            case LightType.Color_Strip:
                return '#640000000000FFFFFF';
            case LightType.Switch:
                return 'true';
            default:
                return '100';
        }
    };

    const getNewSceneName = () => {
        const newSceneTranslation = t('sceneEdit.newScene');
        if (!scenes.find((x) => x.Name == newSceneTranslation)) {
            return newSceneTranslation;
        }

        let i = 1;
        while (scenes.find((x) => x.Name == `${newSceneTranslation} ${i}`)) {
            i++;
        }

        return `${newSceneTranslation} ${i}`;
    };

    const onCloseRequested = () => {
        if (isEdited) {
            setYesNoModalVisible({
                onYesClicked: closeModalRequested,
                onNoClicked: () => setYesNoModalVisible(undefined),
            });
            return;
        }

        closeModalRequested();
    };

    const onTabChanged = (key: string) => {
        setActiveTab(key);
    };

    return (
        <Modal
            title={virtualDevice.name}
            open={true}
            onCancel={onCloseRequested}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{
                disabled: isError,
                loading: isLoading,
            }}
            okText={t('general.save')}
            onOk={onSave}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            width={700}
        >
            <div className={styles.mainContainer}>
                <Tabs
                    activeKey={activeTab}
                    onChange={onTabChanged}
                    onEdit={(targetKey, action) => {
                        if (action === 'add') {
                            addScene();
                        } else {
                            removeScene(Number(targetKey));
                        }
                    }}
                    tabBarExtraContent={{
                        left: (
                            <Button
                                onClick={(e) => {
                                    onTabChanged(Number(activeTab) <= 0 ? '0' : String(Number(activeTab) - 1));
                                    e.currentTarget.blur();
                                }}
                                className={styles.leftArrow}
                            >
                                <LeftOutlined />
                            </Button>
                        ),
                        right: (
                            <Button
                                onClick={(e) => {
                                    onTabChanged(
                                        Number(activeTab) >= scenes.length - 1
                                            ? String(scenes.length - 1)
                                            : String(Number(activeTab) + 1),
                                    );
                                    e.currentTarget.blur();
                                }}
                                className={styles.leftArrow}
                            >
                                <RightOutlined />
                            </Button>
                        ),
                    }}
                    type="editable-card"
                    style={{ width: '100%' }}
                    items={scenes.map((scene, tpIndex) => ({
                        label: scene.Name,
                        key: tpIndex.toString(),
                        closable: tpIndex !== 0,
                        children: (
                            <div className={styles.tabContent}>
                                <Input
                                    disabled={tpIndex === 0}
                                    status={
                                        scenes
                                            .filter((x) => x !== scenes[tpIndex])
                                            .find((y) => scenes[tpIndex].Name === y.Name) || isEmpty(scene.Name)
                                            ? 'error'
                                            : undefined
                                    }
                                    onChange={(value) => {
                                        setScenes(
                                            scenes.map((x) =>
                                                x === scenes[tpIndex]
                                                    ? {
                                                          ...scenes[tpIndex],
                                                          Name: value.currentTarget.value,
                                                      }
                                                    : x,
                                            ),
                                        );
                                    }}
                                    value={scene.Name}
                                    placeholder={t('sceneEdit.sceneName')}
                                />
                                {scenes.filter((x) => x !== scene).find((y) => scene.Name === y.Name) && (
                                    <div className={styles.nameError}>{t('errors.NameAlreadyExist')}</div>
                                )}
                                {isEmpty(scene.Name) && (
                                    <div className={styles.nameError}>{t('errors.fieldCannotBeEmpty')}</div>
                                )}
                                {scene.values.map((light, index) => (
                                    <div className={styles.lights} key={index}>
                                        {(light.LightType == LightType.Color ||
                                            light.LightType == LightType.Color_Strip) && (
                                            <div className={styles.sliderDatapoint}>
                                                <div className={styles.sliderDatapointTitle}>{light.Description}</div>
                                                <div className={styles.sliderContainer}>
                                                    <CustomRange
                                                        disabled={tpIndex == 0}
                                                        step={1}
                                                        min={Number(light?.Range?.[0] ?? 0)}
                                                        max={Number(light?.Range?.[1] ?? 100)}
                                                        value={getLightValue(
                                                            light.Value?.toString() ?? '',
                                                            light.Range ?? [],
                                                        )}
                                                        minTrackColor={getLightColor(light.Value?.toString() ?? '')}
                                                        onValueChanged={(value) =>
                                                            lightValueChanged(tpIndex, light, value)
                                                        }
                                                    />
                                                    <Button
                                                        className={styles.colorPickerButton}
                                                        disabled={tpIndex == 0}
                                                        onClick={() => {
                                                            if (colorEditLight) {
                                                                setColorEditLight(undefined);
                                                                return;
                                                            }
                                                            setColorEditLight(light);
                                                        }}
                                                        shape="circle"
                                                    >
                                                        <img className={styles.editImg} src={editImg} />
                                                    </Button>
                                                </div>
                                                {colorEditLight?.Name == light.Name && (
                                                    <div
                                                        className={styles.colorPicker}
                                                        style={{
                                                            top: index === 0 ? 0 : undefined,
                                                            bottom: index === scene.values.length - 1 ? 0 : undefined,
                                                        }}
                                                        onMouseLeave={() => setColorEditLight(undefined)}
                                                    >
                                                        <SketchPicker
                                                            color={getLightColor(light.Value?.toString() ?? '')}
                                                            onChange={(value) =>
                                                                lightColorChanged(
                                                                    tpIndex,
                                                                    light,
                                                                    value.hex.substring(1),
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {(light.LightType == LightType.DimmerPercent ||
                                            light.LightType == LightType.DimmerVoltage0 ||
                                            light.LightType == LightType.DimmerVoltage1) && (
                                            <div className={styles.sliderDatapoint}>
                                                <div className={styles.sliderDatapointTitle}>{light.Description}</div>
                                                <CustomRange
                                                    disabled={tpIndex == 0}
                                                    step={1}
                                                    min={Number(light?.Range?.[0] ?? 0)}
                                                    max={Number(light?.Range?.[1] ?? 100)}
                                                    value={
                                                        light?.Value &&
                                                        Number(light?.Value) >= Number(light?.Range?.[0] ?? 0)
                                                            ? Number(light?.Value)
                                                            : Number(light?.Range?.[0] ?? 0)
                                                    }
                                                    minTrackColor={'#ffffff'}
                                                    onValueChanged={(value) => lightValueChanged(tpIndex, light, value)}
                                                />
                                            </div>
                                        )}
                                        {light.LightType == LightType.Switch && (
                                            <div className={styles.switchDatapoint}>
                                                {light.Description}
                                                <Switch
                                                    disabled={tpIndex == 0}
                                                    onChange={(checked) => lightValueChanged(tpIndex, light, checked)}
                                                    checked={getBooleanValue(light.Value?.toString())}
                                                    style={{
                                                        backgroundColor: getBooleanValue(light?.Value?.toString())
                                                            ? Colors.lightCategory
                                                            : 'rgba(0, 0, 0, 0.25)',
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ),
                    }))}
                />
            </div>
            {yesNoModalVisible && (
                <YesNoModal
                    onYesClicked={yesNoModalVisible.onYesClicked}
                    onNoClicked={yesNoModalVisible.onNoClicked}
                    description={t('timeProgramEdit.changesNotSaved')}
                    isVisible={true}
                />
            )}
        </Modal>
    );
};

export default SceneEditModal;
