import { useEffect, useState } from 'react';
import { fetchCameraImg, isDemo } from '../../helpers/HttpMethods';
import { ImageModalProps } from './ImageModalProps';
import Modal from 'react-modal';
import { defaultModalStyle } from '../../helpers/ModalHelper';
import { StatusCodes } from 'http-status-codes';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import styles from './ImageModal.module.scss';
import CustomButton from '../../main-page/main-controls/controls/components/custom-button/CustomButton';
import exImg from '../../images/ex.svg';
import refreshImg from '../../images/refresh.svg';
import logoutImg from '../../images/logout.svg';
import classNames from 'classnames';
import { saveAs } from 'file-saver';
import demoImage from './demoImage.png';

const ImageModal = (props: ImageModalProps): JSX.Element => {
    const { imageUrl, title, closeModalRequested } = props;
    const [image, setImage] = useState<string>();
    const [isError, setIsError] = useState<boolean>();
    const { t } = useTranslation();
    const isLoaded = image && !isError;

    useEffect(() => {
        loadImage();
    }, []);

    const loadImage = async () => {
        if (isDemo) {
            setImage(demoImage);
            return;
        }
        setImage(undefined);
        const img = await fetchCameraImg(btoa(atob(imageUrl).replace('https', 'http').replace('443', '80')));

        if (img?.status == StatusCodes.OK) {
            setImage(img.data);
            setIsError(false);
            return;
        }

        setIsError(true);
    };

    const saveFile = async () => {
        if (!image) {
            return;
        }

        const imageTitle = `${title}_${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}.png`;

        if (isDemo) {
            saveAs(image, imageTitle);
            return;
        }

        const imgBlob = await (await fetch(`data:image/png;base64, ${image}`)).blob();
        saveAs(imgBlob, imageTitle);
    };

    return (
        <Modal
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            isOpen={true}
            onRequestClose={closeModalRequested}
            style={defaultModalStyle}
        >
            <div className={styles.mainContainer}>
                <div className={styles.title}>{title}</div>
                <div className={styles.buttons}>
                    {isLoaded && (
                        <CustomButton
                            onClick={loadImage}
                            className={styles.roundButton}
                            imgClassName={styles.buttonImg}
                            img={refreshImg}
                        />
                    )}
                    {isLoaded && (
                        <CustomButton
                            onClick={saveFile}
                            className={styles.roundButton}
                            imgClassName={classNames(styles.buttonImg, styles.logoutButton)}
                            img={logoutImg}
                        />
                    )}
                    <CustomButton
                        onClick={closeModalRequested}
                        className={styles.roundButton}
                        imgClassName={styles.buttonImg}
                        img={exImg}
                    />
                </div>
                <div className={styles.contentContainer}>
                    {isLoaded && <img src={isDemo ? demoImage : `data:image/png;base64, ${image}`} />}
                    {!image && !isError && <Loader type="Circles" color="#c7c7c7" height={100} width={100} />}
                    {isError && <div>{t('imageModal.error')}</div>}
                </div>
            </div>
        </Modal>
    );
};

export default ImageModal;
