import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ImageModal from '../../../../components/image-modal/ImageModal';
import { isCloud } from '../../../../env.json';
import { isDemo } from '../../../../helpers/HttpMethods';
import cameraImg from '../../../../images/camera.svg';
import playBackImg from '../../../../images/playBack.svg';
import { CameraData } from '../../../../models/CameraData';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { CameraType } from '../../../../models/enums/CameraType';
import { DatapointType } from '../../../../models/enums/DatapointType';
import ControlHeader from '../components/control-header/ControlHeader';
import CustomButton from '../components/custom-button/CustomButton';
import { ControllersProps } from '../ControllersProps';
import styles from './Camera.module.scss';

const Camera = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room, order } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;
    const camerasDatapoint = virtualDevice?.datapoints?.find((x) => x.type == DatapointType.CameraConfig)?.Cameras;
    const [selectedCamera, setSelectedCamera] = useState<CameraData>();

    const [datapointsVisible, setDatapointsVisible] = useState<boolean>(false);

    const getCameraStreamUrl = (type: CameraType): string | undefined => {
        switch (type) {
            case CameraType.SMAVID:
                return 'ISAPI/Streaming/channels/101/httppreview';
            case CameraType.DoorBird:
                return 'mpeg/media.amp';
            default:
                return undefined;
        }
    };

    const openCameraStream = (camera: CameraData) => {
        const rtsp = atob(camera.rtsp);
        const splitedRtsp = rtsp.split(':');
        const streamApi = getCameraStreamUrl(camera.CameraType);
        if (!streamApi) {
            toast.error(t('camera.cameraStreamNotSupported'));
            return;
        }
        const url = `${splitedRtsp[0].replace('rtsp', 'http')}:${splitedRtsp[1]}:${splitedRtsp[2]}/${streamApi}`;
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <div className={styles.mainContainer} style={{ zIndex: order, border: border }}>
            <ControlHeader virtualDevice={virtualDevice} room={room} />
            {camerasDatapoint && camerasDatapoint.length > 0 && (
                <div className={styles.cameraContainer}>
                    <div className={styles.name}>{camerasDatapoint[0].Name}</div>
                    {!isCloud && !isDemo && (
                        <CustomButton
                            className={classNames(styles.button, styles.roundButton)}
                            imgClassName={styles.buttonImg}
                            onClick={() => openCameraStream(camerasDatapoint[0])}
                            img={playBackImg}
                        />
                    )}
                    <CustomButton
                        className={classNames(styles.button, styles.roundButton)}
                        imgClassName={styles.buttonImg}
                        onClick={() => setSelectedCamera(camerasDatapoint[0])}
                        img={cameraImg}
                    />
                </div>
            )}
            {camerasDatapoint && camerasDatapoint?.length > 1 && (
                <div
                    className={styles.datapointsContainer}
                    onMouseEnter={() => {
                        setDatapointsVisible(true);
                    }}
                    onMouseLeave={() => setDatapointsVisible(false)}
                >
                    <div className={styles.dots}>
                        <span className={styles.dot} />
                        <span className={styles.dot} />
                        <span className={styles.dot} />
                    </div>
                    {datapointsVisible && (
                        <div
                            className={styles.datapointsList}
                            style={{ zIndex: order, borderBottom: border, borderLeft: border, borderRight: border }}
                        >
                            {camerasDatapoint &&
                                camerasDatapoint.length > 0 &&
                                camerasDatapoint.slice(1).map((camera) => (
                                    <div className={styles.cameraContainer} key={camerasDatapoint.indexOf(camera)}>
                                        <div className={styles.name}>{camera.Name}</div>
                                        {!isCloud && !isDemo && (
                                            <CustomButton
                                                onClick={() => openCameraStream(camera)}
                                                className={classNames(styles.button, styles.roundButton)}
                                                imgClassName={styles.buttonImg}
                                                img={playBackImg}
                                            />
                                        )}
                                        <CustomButton
                                            className={classNames(styles.button, styles.roundButton)}
                                            imgClassName={styles.buttonImg}
                                            onClick={() => setSelectedCamera(camera)}
                                            img={cameraImg}
                                        />
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            )}
            {selectedCamera && (
                <ImageModal
                    title={selectedCamera.Name}
                    imageUrl={selectedCamera.image}
                    closeModalRequested={() => setSelectedCamera(undefined)}
                />
            )}
        </div>
    );
};

export default Camera;
