import { ConfigProvider, Spin } from 'antd';
import 'antd/dist/antd.css';
import deDe from 'antd/es/locale/de_DE';
import enUS from 'antd/es/locale/en_US';
import frFr from 'antd/es/locale/fr_FR';
import itIt from 'antd/es/locale/it_IT';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import { useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import Modal from 'react-modal';
import { HashRouter, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdvancedPropertiesPage from './advanced-properties/AdvancedProperties';
import NetatmoValidator from './components/netatmo-validation/NetatmoValidator';
import Notifications from './components/notifications/Notifications';
import SignalrRUpdater from './components/websocket-updater/SignalrRUpdater';
import WebsocketUpdater from './components/websocket-updater/WebsocketUpdater';
import CustomDemo from './custom-demo/CustomDemo';
import DataLogger from './data-logger/DataLogger';
import { isCloud, isRelease } from './env.json';
import FloorPlan from './floor-plan/FloorPlan';
import { reactRedirectUri } from './helpers/HttpMethods';
import LoginPage from './login-page/LoginPage';
import MainPage from './main-page/MainPage';
import { Routings } from './models/enums/Routings';
import SettingsPage from './settings/Settings';
import UsersConfigurationPage from './usersConfiguration/UsersConfiguration';
import CloudPage from './cloud/CloudPage';

const App = (): JSX.Element => {
    const params = new URLSearchParams(window.location.search);
    const netatmoDevice = params.get('netatmoDevice');
    const code = params.get('code');
    const [isInitialized, setIsInitialized] = useState(false);
    useEffect(() => {
        toast.configure();
        Modal.setAppElement('body');
        i18n.use(Backend)
            .use(LanguageDetector)
            .use(initReactI18next)
            .init(
                {
                    fallbackLng: 'en',
                    debug: !isRelease,
                    interpolation: { escapeValue: false },
                    backend: {
                        requestOptions: {
                            cache: 'no-store',
                        },
                    },
                },
                () => setIsInitialized(true),
            );
    }, []);

    const locale = () => {
        if (i18n?.resolvedLanguage?.includes('fr')) {
            return frFr;
        }

        if (i18n?.resolvedLanguage?.includes('de')) {
            return deDe;
        }

        if (i18n?.resolvedLanguage?.includes('it')) {
            return itIt;
        }
        return enUS;
    };

    useEffect(() => {
        try {
            Notification.requestPermission();
        } catch {}
    }, []);

    useEffect(() => {
        if (netatmoDevice && code) {
            window.location.href = `${reactRedirectUri}#/main?netatmoDevice=${netatmoDevice}&code=${code}`;
        }
    }, [netatmoDevice, code]);

    return isInitialized ? (
        <ConfigProvider locale={locale()}>
            <HashRouter>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ zIndex: 999999998 }}
                />
                {isCloud ? <SignalrRUpdater /> : <WebsocketUpdater />}
                <Notifications />
                <NetatmoValidator />
                <Route exact path={Routings.LoginPage} component={LoginPage} />
                <Route path={Routings.MainPage} component={MainPage} />
                <Route path={Routings.FloorPlanPage} component={FloorPlan} />
                <Route path={Routings.DatalogPage} component={DataLogger} />
                <Route path={Routings.SettingsPage} component={SettingsPage} />
                <Route path={Routings.UsersConfigurationPage} component={UsersConfigurationPage} />
                <Route path={Routings.AdvancedPropertiesPage} component={AdvancedPropertiesPage} />
                <Route path={Routings.DemoPage} component={CustomDemo} />
            </HashRouter>
        </ConfigProvider>
    ) : (
        <Spin />
    );
};

export default App;
