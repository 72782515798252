import { Button } from 'antd';
import classNames from 'classnames';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { toast } from 'react-toastify';
import ColorSwitch from '../../../../../components/color-switch/ColorSwitch';
import { getBooleanDatapointValue } from '../../../../../helpers/DatapointHelper';
import { updateDatapointValue, updateVirtualDevice } from '../../../../../helpers/HttpMethods';
import EditImg from '../../../../../images/edit.svg';
import FavoritesImg from '../../../../../images/favorite_dark.svg';
import FavoriteEmptyImg from '../../../../../images/favorite_empty.svg';
import { Datapoint } from '../../../../../models/Datapoint';
import { VirtualDeviceCategorySettings } from '../../../../../models/constants/VirtualDeviceCategorySettings';
import { Routings } from '../../../../../models/enums/Routings';
import styles from './ControlHeader.module.scss';
import { ControlHeaderProps } from './ControlHeaderProps';

const ControlHeader = (props: ControlHeaderProps): JSX.Element => {
    const {
        virtualDevice,
        room,
        switchDatapoint,
        value,
        valueUnit,
        editButtonDisabled,
        editButtonImg,
        imgStyle,
        onEditButtonClick,
    } = props;
    const { t } = useTranslation();
    const rankingRef = useRef<HTMLDivElement>(null);
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.maxTouchPoints > 0;
    const [rankingVisible, setRankingVisible] = useState(false);

    const isFloorPlanView = window.location.hash.substring(1) == Routings.FloorPlanPage;

    const onDatapointValueChanged = async (value: boolean | number | string, datapoint?: Datapoint) => {
        if (datapoint) {
            const result = await updateDatapointValue(datapoint, value);
            if (result.status !== StatusCodes.OK) {
                toast.error(t('errors.errorWhileSendingValue'));
                return;
            }
        }
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (rankingRef.current && !rankingRef.current.contains(event.target as Element)) {
                setRankingVisible(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const onFavoriteClick = async () => {
        if (isTouchDevice && !rankingVisible) {
            setRankingVisible(true);
            return;
        }

        const result = await updateVirtualDevice(virtualDevice.id, !virtualDevice.favourite, virtualDevice.ranking);
        if (result.status !== StatusCodes.OK) {
            toast.error(t('errors.errorWhileSendingValue'));
            return;
        }
    };

    const onRankingClick = async (ranking: number) => {
        const result = await updateVirtualDevice(
            virtualDevice.id,
            virtualDevice.favourite,
            ranking === 1 && ranking === virtualDevice.ranking ? 0 : ranking,
        );
        if (result.status !== StatusCodes.OK) {
            toast.error(t('errors.errorWhileSendingValue'));
            return;
        }
    };

    const favoriteView = () => {
        return (
            <div
                onMouseEnter={!isTouchDevice ? () => setRankingVisible(true) : undefined}
                onMouseLeave={() => setRankingVisible(false)}
                ref={rankingRef}
                className={styles.favoritesContainer}
            >
                <ReactSVG
                    onClick={onFavoriteClick}
                    className={classNames(styles.titleImg, styles.favoriteImg)}
                    src={virtualDevice.favourite ? FavoritesImg : FavoriteEmptyImg}
                />
                {rankingVisible && (
                    <div className={styles.rankingContainer}>
                        <div
                            onClick={() => onRankingClick(1)}
                            className={classNames(styles.circle, { [styles.circleFilled]: virtualDevice.ranking >= 1 })}
                        />
                        <div
                            onClick={() => onRankingClick(2)}
                            className={classNames(styles.circle, { [styles.circleFilled]: virtualDevice.ranking >= 2 })}
                        />
                        <div
                            onClick={() => onRankingClick(3)}
                            className={classNames(styles.circle, { [styles.circleFilled]: virtualDevice.ranking >= 3 })}
                        />
                        <div
                            onClick={() => onRankingClick(4)}
                            className={classNames(styles.circle, { [styles.circleFilled]: virtualDevice.ranking >= 4 })}
                        />
                        <div
                            onClick={() => onRankingClick(5)}
                            className={classNames(styles.circle, { [styles.circleFilled]: virtualDevice.ranking >= 5 })}
                        />
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={classNames(styles.mainContainer)}>
            <div className={styles.headerContainer}>
                {category && category.img && <ReactSVG className={styles.titleImg} src={category?.img} />}
                <div className={styles.title}>{`${room ? room.name + ' - ' : ''}${virtualDevice.name}`}</div>
                <div className={styles.favoriteContainer}>
                    {onEditButtonClick && (
                        <Button
                            disabled={editButtonDisabled}
                            className={styles.editButton}
                            onClick={onEditButtonClick}
                            shape="circle"
                        >
                            <img className={styles.editImg} style={imgStyle} src={editButtonImg ?? EditImg} />
                        </Button>
                    )}
                    {!isFloorPlanView && favoriteView()}
                </div>
            </div>
            {(value || switchDatapoint) && (
                <div className={styles.titleValue}>
                    <div className={styles.value}>
                        {!!value ? t('general.value') : switchDatapoint ? t('general.mainSwitch') : ''}
                    </div>
                    {value && <div className={styles.value}>{`${value}${valueUnit}`}</div>}
                    {switchDatapoint && (
                        <ColorSwitch
                            className={styles.switch}
                            onColor={category?.color}
                            onChange={(checked) => onDatapointValueChanged(checked, switchDatapoint)}
                            checked={getBooleanDatapointValue(switchDatapoint)}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default ControlHeader;
