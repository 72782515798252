import { StatusCodes } from 'http-status-codes';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ColorSwitch from '../../../../components/color-switch/ColorSwitch';
import { getBooleanDatapointValue, getValueValidatedByRange } from '../../../../helpers/DatapointHelper';
import { updateDatapointValue } from '../../../../helpers/HttpMethods';
import { Datapoint } from '../../../../models/Datapoint';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { DatapointNames } from '../../../../models/enums/DatapointNames';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { ControllersProps } from '../ControllersProps';
import ControlHeader from '../components/control-header/ControlHeader';
import CustomRange from '../components/custom-slider/CustomRange';
import styles from './Dimmer.module.scss';

const Dimmer = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const sliderDatapoint = virtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Brightness && x.name == DatapointNames.VirtualSlider,
    );
    const mainSwitchDatapoint = virtualDevice?.datapoints?.find(
        (x) =>
            x.type == DatapointType.Switch &&
            (x.name == DatapointNames.VirtualSwitch || x.name == DatapointNames.VirtualEnable),
    );
    const valueDatapoint = virtualDevice?.datapoints?.find(
        (x) =>
            (x.type == DatapointType.Brightness && x.name == DatapointNames.VirtualOutput) ||
            (x.type == DatapointType.Number && x.name == DatapointNames.VirtualOutput),
    );

    const onDatapointValueChanged = async (value: boolean | number | string, datapoint?: Datapoint) => {
        if (datapoint) {
            const result = await updateDatapointValue(datapoint, value);
            if (result.status !== StatusCodes.OK) {
                toast.error(t('errors.errorWhileSendingValue'));
                return;
            }
        }
    };

    const onLightSliderValueChanged = (value: number, datapoint?: Datapoint) => {
        onDatapointValueChanged(value, datapoint);
    };

    return (
        <div className={styles.mainContainer} style={{ border: border }}>
            <ControlHeader
                virtualDevice={virtualDevice}
                room={room}
                value={valueDatapoint?.value?.toString()}
                valueUnit="%"
            />
            <div className={styles.sliderDatapoint}>
                <CustomRange
                    step={1}
                    min={Number(sliderDatapoint?.range[0] ?? 0)}
                    max={Number(sliderDatapoint?.range[1] ?? 100)}
                    value={getValueValidatedByRange(sliderDatapoint)}
                    minTrackColor={category?.color ?? '#e0e0e0'}
                    onValueChanged={(value) => onLightSliderValueChanged(value, sliderDatapoint)}
                />
                <ColorSwitch
                    className={styles.switch}
                    onColor={category?.color}
                    onChange={(checked) => onDatapointValueChanged(checked, mainSwitchDatapoint)}
                    checked={getBooleanDatapointValue(mainSwitchDatapoint)}
                />
            </div>
        </div>
    );
};

export default Dimmer;
