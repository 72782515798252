import { LineChartOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import {
    getAdvancedPropertyRoom,
    getSelectedFloor,
    getSelectedTab,
    getSetupInfo,
    setAdvancedPropertyRoom,
    setSelectedFloor,
    setSelectedTab,
    setSetupInfo,
} from '../../app/globalSettings';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { isCloud } from '../../env.json';
import {
    getCloudCredentials,
    getSavedSelectedFloor,
    getUserCredential,
    removeUserCredential,
    saveSelectedFloor,
    saveSelectedTab,
} from '../../helpers/CookieHelper';
import { GetRoomCategoryImage } from '../../helpers/DatapointHelper';
import { isDemo, logout, setDemo } from '../../helpers/HttpMethods';
import { useImages } from '../../helpers/ImagesHelper';
import { isEmpty } from '../../helpers/StringHelper';
import { SettingsTabs } from '../../helpers/TabsHelper';
import FavoritesImg from '../../images/favorite.svg';
import HomeImg from '../../images/home_white.svg';
import LogoutImg from '../../images/logout_white.svg';
import RoomIconImg from '../../images/room_icon_white.svg';
import SettingsImg from '../../images/settings.svg';
import { Room } from '../../models/Room';
import { VirtualDeviceCategorySettings } from '../../models/constants/VirtualDeviceCategorySettings';
import { DatapointType } from '../../models/enums/DatapointType';
import { RoomType } from '../../models/enums/RoomType';
import { Routings } from '../../models/enums/Routings';
import { TabType } from '../../models/enums/TabType';
import { VirtualDeviceCategory } from '../../models/enums/VirtualDeviceCategory';
import { VirtualDeviceType } from '../../models/enums/VirtualDeviceType';
import styles from './Header.module.scss';

const Header = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { darkImages } = useImages();
    const history = useHistory();
    const setupInfo = useAppSelector(getSetupInfo);
    const cloudServers = getCloudCredentials();
    const currentServer = cloudServers;
    const loggedUser = isDemo ? { username: 'admin' } : isCloud ? currentServer : getUserCredential();
    const isAdmin = setupInfo?.users?.find((x) => x.name === loggedUser?.username)?.isadmin ?? false;
    const floorPlans = setupInfo?.objects?.items?.filter((x) => x.type == VirtualDeviceType.FloorPlan);
    const selectedTab = useAppSelector(getSelectedTab);
    const selectedAdvancedRoom = useAppSelector(getAdvancedPropertyRoom);
    const savedSelectedFloor = getSavedSelectedFloor();
    const reduxSelectedFloor = useAppSelector(getSelectedFloor);

    const selectedFloor =
        (floorPlans?.some((x) => x.datapoints.some((y) => y.id === reduxSelectedFloor?.datapointId))
            ? reduxSelectedFloor
            : undefined) ??
        (floorPlans?.some((x) => x.datapoints.some((y) => y.id === savedSelectedFloor?.datapointId))
            ? savedSelectedFloor
            : undefined) ??
        (floorPlans && floorPlans?.length > 0
            ? {
                  datapointId: floorPlans?.[0]?.datapoints?.find((x) => x.type == DatapointType.FloorConfig)?.id,
                  floorId: floorPlans[0]?.datapoints?.find((x) => x.type == DatapointType.FloorConfig)?.Floors?.[0]
                      ?.Name,
              }
            : undefined);
    const virtualDeviceCategorySettings = VirtualDeviceCategorySettings(t);
    const scrollRef = useRef<ScrollContainer>(null);
    const [isScrolled, setIsScrolled] = useState(false);
    const [finishedLoading, setFinishedLoading] = useState(false);

    const isMainView = window.location.hash.substring(1) == Routings.MainPage;
    const isFloorPlanView = window.location.hash.substring(1) == Routings.FloorPlanPage;
    const isDatalog = window.location.hash.substring(1) == Routings.DatalogPage;
    const isAdvancedProperties = window.location.hash.substring(1) == Routings.AdvancedPropertiesPage;
    const isSettings =
        window.location.hash.substring(1) == Routings.SettingsPage ||
        window.location.hash.substring(1) == Routings.UsersConfigurationPage ||
        isAdvancedProperties;
    const currentDatapointFloors =
        floorPlans
            ?.find((x) => x.datapoints.find((y) => y.id == selectedFloor?.datapointId))
            ?.datapoints.find((x) => x.id == selectedFloor?.datapointId)?.Floors ??
        floorPlans?.[0]?.datapoints.find((x) => x.type == DatapointType.FloorConfig)?.Floors;
    const firstTabVisible =
        setupInfo &&
        ((isMainView && setupInfo?.rooms?.length > 0) ||
            (isFloorPlanView && floorPlans && floorPlans.length > 1) ||
            isAdvancedProperties);
    const anyTabVisible = isMainView || isFloorPlanView || isSettings;
    const roomsWithUnassigned = setupInfo?.rooms
        ? [{ id: 0, category: RoomType.Undefined, ranking: 0, name: t('general.unassigned') }, ...setupInfo.rooms]
        : [];

    const onLogout = async () => {
        if (isDemo) {
            setDemo(false);
            history.replace(Routings.LoginPage);
            return;
        }

        removeUserCredential();

        if (isCloud) {
            await logout();
        }

        dispatch(setSetupInfo(undefined));
        history.replace(Routings.LoginPage);
    };

    const tabSelected = (tab: TabType, room?: Room, category?: VirtualDeviceCategory): void => {
        if (!isMainView) {
            history.push(Routings.MainPage);
        }
        saveSelectedTab(tab, room, category);
        dispatch(setSelectedTab({ type: tab, room: room, category: category }));
    };

    const floorSelected = (datapointId?: number, floorId?: string): void => {
        if (!datapointId || !floorId) {
            return;
        }
        saveSelectedFloor(datapointId, floorId);
        dispatch(setSelectedFloor({ datapointId: datapointId, floorId: floorId }));
    };

    useEffect(() => {
        (async () => {
            if (scrollRef?.current && isScrolled) {
                await new Promise((r) => setTimeout(r, 800));
                scrollRef?.current?.getElement()?.scroll({
                    top: 0,
                    left: -50,
                    behavior: 'smooth',
                });
            }
        })();
    }, [isScrolled]);

    useEffect(() => {
        (async () => {
            if (scrollRef?.current && setupInfo && !isScrolled) {
                await new Promise((r) => setTimeout(r, 800));
                scrollRef?.current?.getElement()?.scroll({
                    top: 0,
                    left: 50,
                    behavior: 'smooth',
                });
                setIsScrolled(true);
            }
        })();
    }, [setupInfo]);

    useEffect(() => {
        (async () => {
            if (setupInfo) {
                await new Promise((r) => setTimeout(r, 250));
                setFinishedLoading(true);
            }
        })();
    }, [setupInfo]);

    return (
        <>
            <div className={classNames(styles.mainContainer)}>
                <div className={styles.subContainer}>
                    <Space style={{ width: '100%' }} size={[20, 10]} wrap className={styles.space}>
                        <div onClick={() => tabSelected(TabType.All)} className={styles.titleContainer}>
                            <ReactSVG className={styles.titleImg} src={HomeImg} />
                            <div
                                className={classNames(styles.title, {
                                    [styles.titleBold]: isMainView && selectedTab?.type === TabType.All,
                                })}
                            >
                                {t('header.all')}
                            </div>
                        </div>
                        <div
                            onClick={() => tabSelected(TabType.Favourite)}
                            className={classNames(styles.titleContainer, styles.favoriteContainer)}
                        >
                            <ReactSVG className={styles.titleImg} src={FavoritesImg} />
                            <div
                                className={classNames(styles.title, {
                                    [styles.titleBold]: isMainView && selectedTab?.type === TabType.Favourite,
                                })}
                            >
                                {t('header.favorites')}
                            </div>
                        </div>
                        {floorPlans && floorPlans?.length > 0 && (
                            <div
                                onClick={() => history.push(Routings.FloorPlanPage)}
                                className={classNames(styles.titleContainer, styles.floorPlanContainer)}
                            >
                                <ReactSVG className={styles.titleImg} src={RoomIconImg} />
                                <div
                                    className={classNames(styles.title, {
                                        [styles.titleBold]: isFloorPlanView,
                                    })}
                                >
                                    {t('header.floorPlan')}
                                </div>
                            </div>
                        )}
                        {setupInfo?.objects?.items?.some(
                            (x) =>
                                x.type === VirtualDeviceType.Monitor &&
                                x.datapoints
                                    .find((x) => x.type === DatapointType.MonitorConfig)
                                    ?.Monitor?.some((x) => x.Log),
                        ) && (
                            <div
                                onClick={() => history.push(Routings.DatalogPage)}
                                className={classNames(styles.titleContainer, styles.favoriteContainer)}
                            >
                                <LineChartOutlined style={{ fontSize: 22, color: 'white' }} />
                                <div
                                    className={classNames(styles.title, {
                                        [styles.titleBold]: isDatalog,
                                    })}
                                >
                                    {t('header.datalog')}
                                </div>
                            </div>
                        )}
                    </Space>
                    <Space size={[20, 10]} className={styles.space2}>
                        {!isDemo && isCloud && (
                            <div
                                style={{
                                    display: 'flex',
                                    borderRadius: 4,
                                    alignItems: 'center',
                                    color: 'white',
                                    width: 130,
                                }}
                            >
                                <img
                                    style={{ width: 25, height: 25, marginRight: 10 }}
                                    src={darkImages.server.default}
                                />
                                <div>
                                    {isEmpty(currentServer?.serverName) || !currentServer?.save
                                        ? t('general.unSavedServer')
                                        : currentServer?.serverName}
                                </div>
                            </div>
                        )}
                        {isAdmin && !isDemo && (
                            <div
                                onClick={() => history.push(Routings.SettingsPage)}
                                className={styles.settingsContainer}
                            >
                                <ReactSVG className={styles.titleImg} src={SettingsImg} />
                                <div
                                    className={classNames(styles.title, {
                                        [styles.titleBold]: isSettings,
                                    })}
                                >
                                    {t('header.settings')}
                                </div>
                            </div>
                        )}

                        <div onClick={onLogout} className={styles.logoutContainer}>
                            <ReactSVG className={styles.titleImg} src={LogoutImg} />
                            <div className={styles.title}>{t('header.logout')}</div>
                        </div>
                    </Space>
                </div>
                {firstTabVisible && (
                    <ScrollContainer className={styles.roomsScroll} ref={scrollRef}>
                        {setupInfo && (
                            <div className={classNames(styles.subTabContainer)}>
                                {isMainView &&
                                    setupInfo.rooms
                                        .slice()
                                        .sort((a, b) => b.ranking - a.ranking)
                                        .map((room, index) => (
                                            <div
                                                onClick={() => tabSelected(TabType.Room, room)}
                                                key={room.id}
                                                className={classNames(styles.roomItem, {
                                                    [styles.bold]:
                                                        isMainView &&
                                                        selectedTab?.type == TabType.Room &&
                                                        selectedTab.room &&
                                                        selectedTab.room.id == room.id,
                                                    [styles.lastRoom]: index === setupInfo.rooms.length - 1,
                                                })}
                                            >
                                                <img
                                                    loading="lazy"
                                                    className={styles.roomImg}
                                                    src={GetRoomCategoryImage(room.category)}
                                                />
                                                {room.name}
                                            </div>
                                        ))}
                                {isFloorPlanView &&
                                    floorPlans &&
                                    floorPlans.length > 0 &&
                                    floorPlans.map((floor) => (
                                        <div
                                            onClick={() => {
                                                floorSelected(
                                                    floor.datapoints.find((x) => x.type == DatapointType.FloorConfig)
                                                        ?.id,
                                                    floor.datapoints.find((x) => x.type == DatapointType.FloorConfig)
                                                        ?.Floors[0].Name,
                                                );
                                            }}
                                            key={floor.id}
                                            className={classNames(styles.roomItem, {
                                                [styles.bold]: floor.datapoints.find(
                                                    (x) => x.id == selectedFloor?.datapointId,
                                                ),
                                            })}
                                        >
                                            {floor.name}
                                        </div>
                                    ))}
                                {finishedLoading &&
                                    isAdvancedProperties &&
                                    SettingsTabs(t).map((tab, index) => (
                                        <div
                                            onClick={() => history.push(tab.route)}
                                            key={index}
                                            className={classNames(styles.roomItem, {
                                                [styles.bold]: tab.route == window.location.hash.substring(1),
                                            })}
                                        >
                                            {tab.name}
                                        </div>
                                    ))}
                            </div>
                        )}
                    </ScrollContainer>
                )}

                {setupInfo && finishedLoading && anyTabVisible && (
                    <ScrollContainer className={styles.categories}>
                        <div className={styles.subTabContainer}>
                            {isMainView &&
                                setupInfo.objects.items
                                    .map((x) => x.category)
                                    .filter((elem, index, self) => {
                                        return index === self.indexOf(elem);
                                    })
                                    .sort((x, y) => x - y)
                                    .map((category) => (
                                        <div
                                            onClick={() => {
                                                tabSelected(TabType.Category, undefined, category);
                                            }}
                                            key={category}
                                            className={classNames(styles.roomItem, styles.imgContainer, {
                                                [styles.categorySelected]:
                                                    selectedTab?.type == TabType.Category &&
                                                    selectedTab.category == category,
                                            })}
                                        >
                                            <img
                                                className={styles.categoryImg}
                                                src={
                                                    virtualDeviceCategorySettings.find((x) => x.category == category)
                                                        ?.img
                                                }
                                            />
                                        </div>
                                    ))}
                            {isFloorPlanView &&
                                finishedLoading &&
                                currentDatapointFloors &&
                                currentDatapointFloors.map((floor) => (
                                    <div
                                        onClick={() => {
                                            floorSelected(selectedFloor?.datapointId, floor.Name);
                                        }}
                                        key={floor.Name}
                                        className={classNames(styles.roomItem, {
                                            [styles.bold]: selectedFloor?.floorId == floor.Name,
                                        })}
                                    >
                                        {floor.Name}
                                    </div>
                                ))}
                            {isSettings &&
                                finishedLoading &&
                                !isAdvancedProperties &&
                                SettingsTabs(t).map((tab, index) => (
                                    <div
                                        onClick={() => history.push(tab.route)}
                                        key={index}
                                        className={classNames(styles.roomItem, {
                                            [styles.bold]: tab.route == window.location.hash.substring(1),
                                        })}
                                    >
                                        {tab.name}
                                    </div>
                                ))}
                            {isAdvancedProperties &&
                                finishedLoading &&
                                roomsWithUnassigned.map((room, index) => (
                                    <div
                                        onClick={() => dispatch(setAdvancedPropertyRoom(room.id))}
                                        key={room.id}
                                        className={classNames(styles.roomItem, {
                                            [styles.bold]: selectedAdvancedRoom == room.id,
                                            [styles.lastRoom]: index === setupInfo.rooms.length - 1,
                                        })}
                                    >
                                        <img
                                            loading="lazy"
                                            className={styles.roomImg}
                                            src={GetRoomCategoryImage(room.category)}
                                        />
                                        {room.name}
                                    </div>
                                ))}
                        </div>
                    </ScrollContainer>
                )}
            </div>
        </>
    );
};

export default Header;
