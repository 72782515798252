import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, InputNumber, Modal, Select, Space, Tabs, TimePicker } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import classNames from 'classnames';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { updateDatapoint } from '../../helpers/HttpMethods';
import { isEmpty } from '../../helpers/StringHelper';
import exImg from '../../images/ex.svg';
import { Datapoint } from '../../models/Datapoint';
import { EnergyManagementDevice, EnergyManagementDeviceProperty } from '../../models/EnergyManagementDevice';
import { VirtualDevice } from '../../models/VirtualDevice';
import YesNoModal from '../yes-no-modal/YesNoModal';
import styles from './EnergyManagementConfigurationModal.module.scss';
import SummaryTab from './SummaryTab/SummaryTab';

interface Props {
    onCloseRequested: () => void;
    virtualDevice: VirtualDevice;
    datapoint: Datapoint;
}

const checkboxOptions = [
    { label: 'L1', value: 'L1' },
    { label: 'L2', value: 'L2' },
    { label: 'L3', value: 'L3' },
];

export const getFreePriorities = (device: EnergyManagementDevice[]): number[] => {
    const usedPrio = device.flatMap((z) => z.Priorities.map((x) => x.Priority));
    const allPriorities = Array.from(Array(100).keys()).filter((x) => x !== 0);
    return allPriorities.filter((x) => !usedPrio.includes(x));
};

const EnergyManagementConfigurationModal = (props: Props): JSX.Element => {
    const { virtualDevice, datapoint, onCloseRequested } = props;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState<string>('0');
    const [functionBlocks, setFunctionBlocks] = useState(
        datapoint?.EnergyManagementConfig?.Devices?.filter((x) => !isEmpty(x.Name)),
    );
    const [yesNoModalVisible, setYesNoModalVisible] = useState<{ onYesClicked: () => void; onNoClicked: () => void }>();
    const [applyToAllDays, setApplyToAllDays] = useState<number[]>([]);

    const isEdited = datapoint?.EnergyManagementConfig?.Devices !== functionBlocks;

    const selectOptions = useMemo(
        () => [
            {
                value: 0,
                label: t('general.off'),
            },
            {
                value: 1,
                label: t('general.on'),
            },
            {
                value: 2,
                label: t('general.solar'),
            },
            {
                value: 3,
                label: t('general.solarAndLowPrice'),
            },
        ],
        [],
    );

    const days = [
        t('general.sunday'),
        t('general.monday'),
        t('general.tuesday'),
        t('general.wednesday'),
        t('general.thursday'),
        t('general.friday'),
        t('general.saturady'),
    ];

    const onTabChanged = (key: string) => {
        setActiveTab(key);
    };

    const onClose = () => {
        if (isEdited) {
            setYesNoModalVisible({
                onYesClicked: onCloseRequested,
                onNoClicked: () => setYesNoModalVisible(undefined),
            });
            return;
        }

        onCloseRequested();
    };

    const onSave = async () => {
        try {
            setIsLoading(true);
            const newDatapoint: Datapoint = {
                ...datapoint,
                EnergyManagementConfig: {
                    Devices: functionBlocks,
                },
            };

            const result = await updateDatapoint(newDatapoint);

            if (result?.status != StatusCodes.OK) {
                showError();
                setIsLoading(false);
                return;
            }

            onCloseRequested();
        } catch {
            showError();
        } finally {
            setIsLoading(false);
        }
    };

    const showError = () => {
        toast.error(t('errors.errorWhileSendingValue'));
    };

    const onCheckboxChange = (device: EnergyManagementDevice, newValues: CheckboxValueType[]) => {
        const newDevice: EnergyManagementDevice = {
            ...device,
            L1: newValues.includes('L1'),
            L2: newValues.includes('L2'),
            L3: newValues.includes('L3'),
        };

        setFunctionBlocks((prev) => prev.map((x) => (x === device ? newDevice : x)));
    };

    const onDeviceChanged = (device: EnergyManagementDevice, newDevice: EnergyManagementDevice) => {
        setFunctionBlocks((prev) => prev.map((x) => (x === device ? newDevice : x)));
    };

    const onPropertyChanged = (
        device: EnergyManagementDevice,
        property: EnergyManagementDeviceProperty,
        newProperty: EnergyManagementDeviceProperty,
    ) => {
        const newDevice: EnergyManagementDevice = {
            ...device,
            Priorities: device.Priorities.map((x) => (x === property ? newProperty : x)),
        };
        setFunctionBlocks((prev) => prev.map((x) => (x === device ? newDevice : x)));
    };

    const addProp = (device: EnergyManagementDevice) => {
        const freePrio = getFreePriorities(functionBlocks);
        const newProperty: EnergyManagementDeviceProperty = {
            FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
            Level: Math.max(...device.Priorities.map((x) => x.Level)),
            Priority: freePrio[0],
            Mode: 0,
            Hysteresis: 1,
            FinishTimeCalendar: [],
        };
        const newDevice: EnergyManagementDevice = {
            ...device,
            Priorities: [...device.Priorities, newProperty],
        };
        setFunctionBlocks((prev) => prev.map((x) => (x === device ? newDevice : x)));
    };

    const addCustomTime = (device: EnergyManagementDevice, property: EnergyManagementDeviceProperty) => {
        const newTime = moment().utc().add(1, 'm').format('YYYY-MM-DDTHH:mm:00') + 'Z';
        const newProp: EnergyManagementDeviceProperty = {
            ...property,
            FinishTimeCalendar: [...(property.FinishTimeCalendar ?? []), newTime],
        };

        onPropertyChanged(device, property, newProp);
    };

    const onRemoveCustomTime = (
        device: EnergyManagementDevice,
        property: EnergyManagementDeviceProperty,
        index: number,
    ) => {
        const calendarItems = [...property.FinishTimeCalendar];
        calendarItems.splice(index, 1);
        const newProp: EnergyManagementDeviceProperty = {
            ...property,
            FinishTimeCalendar: calendarItems,
        };
        onPropertyChanged(device, property, newProp);
    };

    const onRemovePriority = (device: EnergyManagementDevice, index: number) => {
        const calendarItems = [...device.Priorities];
        calendarItems.splice(index, 1);
        const newDevice: EnergyManagementDevice = {
            ...device,
            Priorities: calendarItems,
        };
        setFunctionBlocks((prev) => prev.map((x) => (x === device ? newDevice : x)));
    };

    return (
        <Modal
            title={virtualDevice.name}
            open={true}
            onCancel={onClose}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{
                loading: isLoading,
                disabled: !functionBlocks || functionBlocks.length === 0,
            }}
            okText={t('general.save')}
            onOk={onSave}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            width="90vw"
        >
            <div className={styles.tabs}>
                <Tabs
                    activeKey={activeTab}
                    onChange={onTabChanged}
                    tabBarExtraContent={{
                        left: (
                            <Button
                                onClick={(e) => {
                                    onTabChanged(Number(activeTab) <= 0 ? '0' : String(Number(activeTab) - 1));
                                    e.currentTarget.blur();
                                }}
                                className={styles.leftArrow}
                            >
                                <LeftOutlined />
                            </Button>
                        ),
                        right: (
                            <Button
                                onClick={(e) => {
                                    onTabChanged(
                                        Number(activeTab) >= functionBlocks.length
                                            ? String(functionBlocks.length)
                                            : String(Number(activeTab) + 1),
                                    );
                                    e.currentTarget.blur();
                                }}
                                className={styles.leftArrow}
                            >
                                <RightOutlined />
                            </Button>
                        ),
                    }}
                    type="editable-card"
                    style={{ width: '100%' }}
                    hideAdd={true}
                    items={[
                        {
                            label: t('general.summary'),
                            key: '0',
                            closable: false,
                            children: (
                                <SummaryTab onPropertyChanged={onPropertyChanged} functionBlocks={functionBlocks} />
                            ),
                        },
                        ...functionBlocks?.map((dev, tpIndex) => ({
                            label: dev.Name,
                            key: (tpIndex + 1).toString(),
                            closable: false,
                            children: (
                                <div className={styles.mainContainer}>
                                    <div className={styles.contentContainer}>
                                        <Checkbox.Group
                                            options={checkboxOptions}
                                            value={
                                                [
                                                    dev.L1 ? 'L1' : undefined,
                                                    dev.L2 ? 'L2' : undefined,
                                                    dev.L3 ? 'L3' : undefined,
                                                ].filter((x) => x !== undefined) as CheckboxValueType[]
                                            }
                                            onChange={(checkedValue) => onCheckboxChange(dev, checkedValue)}
                                        />
                                        <Space size={[20, 10]} wrap style={{ justifyContent: 'center', marginTop: 10 }}>
                                            <div>
                                                <div className={styles.inputTitle}>
                                                    {t('energyManagementConfiguration.minPower') + ' (kW)'}
                                                </div>
                                                <InputNumber
                                                    className={styles.inputNumber}
                                                    precision={1}
                                                    value={dev.MinPower}
                                                    min={0}
                                                    max={dev.MaxPower}
                                                    onChange={(value) =>
                                                        value !== null &&
                                                        onDeviceChanged(dev, { ...dev, MinPower: value })
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <div className={styles.inputTitle}>
                                                    {t('energyManagementConfiguration.maxPower') + ' (kW)'}
                                                </div>
                                                <InputNumber
                                                    className={styles.inputNumber}
                                                    precision={1}
                                                    value={dev.MaxPower}
                                                    min={dev.MinPower}
                                                    onChange={(value) =>
                                                        value !== null &&
                                                        onDeviceChanged(dev, { ...dev, MaxPower: value })
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <div className={styles.inputTitle}>
                                                    {t('energyManagementConfiguration.maxEnergy') + ' (kWh)'}
                                                </div>
                                                <InputNumber
                                                    className={styles.inputNumber}
                                                    value={dev.MaxEnergy}
                                                    min={0}
                                                    onChange={(value) =>
                                                        value !== null &&
                                                        onDeviceChanged(dev, { ...dev, MaxEnergy: value })
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <div className={styles.inputTitle}>
                                                    {t('energyManagementConfiguration.preparationTime') + ' (min)'}
                                                </div>
                                                <InputNumber
                                                    className={styles.inputNumber}
                                                    value={dev.PreparationTime}
                                                    min={0}
                                                    onChange={(value) =>
                                                        value !== null &&
                                                        onDeviceChanged(dev, { ...dev, PreparationTime: value })
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <div className={styles.inputTitle}>
                                                    {t('energyManagementConfiguration.minRuntime') + ' (min)'}
                                                </div>
                                                <InputNumber
                                                    className={styles.inputNumber}
                                                    value={dev.MinRuntime}
                                                    min={0}
                                                    onChange={(value) =>
                                                        value !== null &&
                                                        onDeviceChanged(dev, { ...dev, MinRuntime: value })
                                                    }
                                                />
                                            </div>
                                        </Space>
                                    </div>
                                    <div className={styles.separator} />
                                    <div className={styles.configurationContentWrapper}>
                                        <div className={styles.configurationTitle}>
                                            {t('energyManagementConfiguration.schedule')}
                                        </div>
                                        <div className={styles.propertiesContainer}>
                                            {dev.Priorities.slice()
                                                .sort((a, b) => a.Level - b.Level)
                                                .map((prop, schedulerIndex, schedulerItems) => (
                                                    <div key={schedulerIndex} className={styles.prioritiesWrapper}>
                                                        {schedulerIndex !== 0 && (
                                                            <img
                                                                onClick={() => onRemovePriority(dev, schedulerIndex)}
                                                                className={classNames(
                                                                    styles.removeImg,
                                                                    styles.removePrioButton,
                                                                )}
                                                                src={exImg}
                                                            />
                                                        )}
                                                        <Space
                                                            size={[20, 10]}
                                                            wrap
                                                            style={{ justifyContent: 'center', marginTop: 10 }}
                                                        >
                                                            <div className={styles.timeWrapper}>
                                                                <div>
                                                                    <div className={styles.inputTitle}>
                                                                        {t('energyManagementConfiguration.priority')}
                                                                    </div>
                                                                    <InputNumber
                                                                        className={styles.inputNumber}
                                                                        precision={0}
                                                                        value={prop.Priority}
                                                                        min={1}
                                                                        disabled={prop.Mode === 0 || prop.Mode === 1}
                                                                        onChange={(value) => {
                                                                            if (value !== null) {
                                                                                const usedPrio = functionBlocks.flatMap(
                                                                                    (z) =>
                                                                                        z.Priorities.map(
                                                                                            (x) => x.Priority,
                                                                                        ),
                                                                                );
                                                                                if (usedPrio.includes(value)) {
                                                                                    const freePrio =
                                                                                        getFreePriorities(
                                                                                            functionBlocks,
                                                                                        );
                                                                                    const freeAbovePrio =
                                                                                        value > (prop?.Priority ?? 0)
                                                                                            ? freePrio.filter(
                                                                                                  (x) => x > value,
                                                                                              )
                                                                                            : freePrio.filter(
                                                                                                  (x) => x < value,
                                                                                              );
                                                                                    onPropertyChanged(dev, prop, {
                                                                                        ...prop,
                                                                                        Priority:
                                                                                            (value >
                                                                                            (prop?.Priority ?? 0)
                                                                                                ? freeAbovePrio?.[0]
                                                                                                : freeAbovePrio?.[
                                                                                                      freeAbovePrio.length -
                                                                                                          1
                                                                                                  ]) ?? freePrio[0],
                                                                                    });
                                                                                } else {
                                                                                    onPropertyChanged(dev, prop, {
                                                                                        ...prop,
                                                                                        Priority: value,
                                                                                    });
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={styles.timeWrapper}>
                                                                <div>
                                                                    <div className={styles.inputTitle}>
                                                                        {`${t(
                                                                            'energyManagementConfiguration.level',
                                                                        )} (${prop.Level}${
                                                                            schedulerIndex === schedulerItems.length - 1
                                                                                ? '+'
                                                                                : ' - ' +
                                                                                  schedulerItems[schedulerIndex + 1]
                                                                                      .Level
                                                                        })`}
                                                                    </div>
                                                                    <InputNumber
                                                                        className={styles.inputNumber}
                                                                        precision={0}
                                                                        disabled={schedulerIndex === 0}
                                                                        value={prop.Level}
                                                                        min={0}
                                                                        onChange={(value) =>
                                                                            value !== null &&
                                                                            onPropertyChanged(dev, prop, {
                                                                                ...prop,
                                                                                Level: value,
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={styles.scheduleCheckboxWrapper}>
                                                                <div className={styles.inputTitle}>
                                                                    {t('energyManagementConfiguration.mode')}
                                                                </div>
                                                                <Select
                                                                    style={{ width: 158 }}
                                                                    value={prop.Mode}
                                                                    options={selectOptions}
                                                                    onChange={(v) => {
                                                                        const freePrio =
                                                                            getFreePriorities(functionBlocks);
                                                                        onPropertyChanged(dev, prop, {
                                                                            ...prop,
                                                                            Mode: v,
                                                                            Priority:
                                                                                v === 0 || v === 1
                                                                                    ? undefined
                                                                                    : prop.Priority
                                                                                    ? prop.Priority
                                                                                    : freePrio[0],
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className={styles.timeWrapper}>
                                                                <div>
                                                                    <div className={styles.inputTitle}>
                                                                        {t('energyManagementConfiguration.hysteresis')}
                                                                    </div>
                                                                    <InputNumber
                                                                        className={styles.inputNumber}
                                                                        precision={1}
                                                                        step={0.1}
                                                                        value={prop.Hysteresis}
                                                                        min={0}
                                                                        max={100}
                                                                        onChange={(value) =>
                                                                            value !== null &&
                                                                            onPropertyChanged(dev, prop, {
                                                                                ...prop,
                                                                                Hysteresis: value,
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Space>
                                                        <Space
                                                            size={[20, 10]}
                                                            wrap
                                                            style={{ justifyContent: 'center', marginTop: 10 }}
                                                        >
                                                            {prop.FinishTimeScheduler.map((time, index) => (
                                                                <div key={index} className={styles.timeWrapper}>
                                                                    <div>
                                                                        <div className={styles.inputTitle}>
                                                                            {days[index]}
                                                                        </div>
                                                                        <div>
                                                                            <TimePicker
                                                                                disabled={
                                                                                    applyToAllDays.includes(
                                                                                        schedulerIndex,
                                                                                    ) && index !== 0
                                                                                }
                                                                                allowClear={false}
                                                                                format="HH:mm"
                                                                                showNow={false}
                                                                                value={moment('00:00', 'HH:mm').add(
                                                                                    time === -1 ? 0 : time,
                                                                                    'seconds',
                                                                                )}
                                                                                onChange={(value) => {
                                                                                    if (value) {
                                                                                        if (
                                                                                            index === 0 &&
                                                                                            applyToAllDays.includes(
                                                                                                schedulerIndex,
                                                                                            )
                                                                                        ) {
                                                                                            onPropertyChanged(
                                                                                                dev,
                                                                                                prop,
                                                                                                {
                                                                                                    ...prop,
                                                                                                    FinishTimeScheduler:
                                                                                                        prop.FinishTimeScheduler.map(
                                                                                                            () =>
                                                                                                                value.hour() *
                                                                                                                    60 *
                                                                                                                    60 +
                                                                                                                value.minute() *
                                                                                                                    60,
                                                                                                        ),
                                                                                                },
                                                                                            );
                                                                                        } else {
                                                                                            onPropertyChanged(
                                                                                                dev,
                                                                                                prop,
                                                                                                {
                                                                                                    ...prop,
                                                                                                    FinishTimeScheduler:
                                                                                                        prop.FinishTimeScheduler.map(
                                                                                                            (
                                                                                                                z,
                                                                                                                zIndex,
                                                                                                            ) =>
                                                                                                                zIndex ===
                                                                                                                index
                                                                                                                    ? value.hour() *
                                                                                                                          60 *
                                                                                                                          60 +
                                                                                                                      value.minute() *
                                                                                                                          60
                                                                                                                    : z,
                                                                                                        ),
                                                                                                },
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className={styles.row}>
                                                                <Checkbox
                                                                    checked={applyToAllDays.includes(schedulerIndex)}
                                                                    onChange={(v) => {
                                                                        const value = v.target.checked;

                                                                        if (value) {
                                                                            onPropertyChanged(dev, prop, {
                                                                                ...prop,
                                                                                FinishTimeScheduler:
                                                                                    prop.FinishTimeScheduler.map(
                                                                                        (z, index, array) => array[0],
                                                                                    ),
                                                                            });
                                                                            setApplyToAllDays((prev) => [
                                                                                ...prev,
                                                                                schedulerIndex,
                                                                            ]);
                                                                        } else {
                                                                            setApplyToAllDays((prev) =>
                                                                                prev.filter(
                                                                                    (x) => x !== schedulerIndex,
                                                                                ),
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                                {t('energyManagementConfiguration.applyToAllDays')}
                                                            </div>
                                                        </Space>
                                                        <div style={{ width: '100%' }}>
                                                            <div className={styles.configurationTitle}>
                                                                {t('energyManagementConfiguration.customFinish')}
                                                            </div>
                                                            <Space
                                                                size={[20, 10]}
                                                                wrap
                                                                style={{
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    marginTop: 10,
                                                                }}
                                                            >
                                                                {prop.FinishTimeCalendar?.map((item, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className={classNames(
                                                                            styles.timeWrapper,
                                                                            styles.datePickerWrapper,
                                                                        )}
                                                                        style={{
                                                                            opacity:
                                                                                moment.utc(
                                                                                    item,
                                                                                    'YYYY-MM-DDTHH:mm:ssT',
                                                                                ) < moment.utc()
                                                                                    ? 0.6
                                                                                    : 1,
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            <div className={styles.inputTitle}>
                                                                                {t('general.date')}
                                                                            </div>
                                                                            <DatePicker
                                                                                className={styles.datePicker}
                                                                                showTime
                                                                                format="DD.MM.yyyy HH:mm"
                                                                                allowClear={false}
                                                                                showNow={false}
                                                                                value={moment
                                                                                    .utc(item, 'YYYY-MM-DDTHH:mm:ssT')
                                                                                    .local()}
                                                                                onChange={(value) => {
                                                                                    onPropertyChanged(dev, prop, {
                                                                                        ...prop,
                                                                                        FinishTimeCalendar:
                                                                                            prop.FinishTimeCalendar.map(
                                                                                                (x, dIndex) =>
                                                                                                    dIndex == index
                                                                                                        ? !!value
                                                                                                            ? value
                                                                                                                  .utc()
                                                                                                                  .format(
                                                                                                                      'YYYY-MM-DDTHH:mm:00',
                                                                                                                  ) +
                                                                                                              'Z'
                                                                                                            : moment()
                                                                                                                  .utc()
                                                                                                                  .format(
                                                                                                                      'YYYY-MM-DDTHH:mm:00',
                                                                                                                  ) +
                                                                                                              'Z'
                                                                                                        : x,
                                                                                            ),
                                                                                    });
                                                                                }}
                                                                                onSelect={(value) => {
                                                                                    onPropertyChanged(dev, prop, {
                                                                                        ...prop,
                                                                                        FinishTimeCalendar:
                                                                                            prop.FinishTimeCalendar.map(
                                                                                                (x, dIndex) =>
                                                                                                    dIndex == index
                                                                                                        ? !!value
                                                                                                            ? value
                                                                                                                  .utc()
                                                                                                                  .format(
                                                                                                                      'YYYY-MM-DDTHH:mm:00',
                                                                                                                  ) +
                                                                                                              'Z'
                                                                                                            : moment()
                                                                                                                  .utc()
                                                                                                                  .format(
                                                                                                                      'YYYY-MM-DDTHH:mm:00',
                                                                                                                  ) +
                                                                                                              'Z'
                                                                                                        : x,
                                                                                            ),
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <img
                                                                            onClick={() =>
                                                                                onRemoveCustomTime(dev, prop, index)
                                                                            }
                                                                            className={styles.removeImg}
                                                                            src={exImg}
                                                                        />
                                                                    </div>
                                                                ))}
                                                                <Button
                                                                    className={classNames(styles.addButton)}
                                                                    onClick={() => addCustomTime(dev, prop)}
                                                                >
                                                                    {t('general.add')}
                                                                </Button>
                                                            </Space>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                        <Button
                                            className={styles.addButton}
                                            disabled={dev.Priorities.length === 99}
                                            onClick={() => addProp(dev)}
                                        >
                                            {t('general.add')}
                                        </Button>
                                    </div>
                                </div>
                            ),
                        })),
                    ]}
                />
            </div>
            {yesNoModalVisible && (
                <YesNoModal
                    onYesClicked={yesNoModalVisible.onYesClicked}
                    onNoClicked={yesNoModalVisible.onNoClicked}
                    description={t('timeProgramEdit.changesNotSaved')}
                    isVisible={true}
                />
            )}
        </Modal>
    );
};

export default EnergyManagementConfigurationModal;
