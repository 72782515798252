import { Button, Modal } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Icons, toast } from 'react-toastify';
import { getDevices } from '../../app/globalSettings';
import { useAppSelector } from '../../app/hooks';
import { fetchNetatmoDevices, isDemo, reactRedirectUri, updateNetatmoDeviceToken } from '../../helpers/HttpMethods';
import { NetatmoValidation } from '../../models/NetatmoValidation';
import { Routings } from '../../models/enums/Routings';
import * as Styled from './NetatmoValidator.style';

const ClientId = '5e171567c52009f6d9481c94';
const AuthURL = 'https://api.netatmo.com/oauth2/authorize';
const Scopes = 'read_station read_thermostat write_thermostat read_homecoach';
const redirectURI = (deviceId: number) => `${reactRedirectUri}?netatmoDevice=${deviceId}`;

const NetatmoValidator = (): JSX.Element => {
    const { t } = useTranslation();
    const [init, setInit] = useState(false);
    const devices = useAppSelector(getDevices);
    const [validationDevices, setValidationDevices] = useState<NetatmoValidation[]>([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const locationParams = new URLSearchParams(location.search);
    const locationNetatmoDevice = locationParams.get('netatmoDevice');
    const locationCode = locationParams.get('code');

    const fetchDevices = async () => {
        const result = await fetchNetatmoDevices();

        if (result.status !== StatusCodes.OK) {
            return result;
        }

        setValidationDevices(result.data);

        return result;
    };

    const initValidation = async () => {
        const result = await fetchDevices();

        if (result.status !== StatusCodes.OK) {
            return;
        }

        setModalVisible(result.data.some((x) => !x.refreshTokenValid));

        if (locationNetatmoDevice && locationCode) {
            setIsLoading(true);

            const refreshResult = await updateNetatmoDeviceToken(
                Number(locationNetatmoDevice),
                locationCode,
                redirectURI(Number(locationNetatmoDevice)),
            );

            if (refreshResult.status !== StatusCodes.OK) {
                toast.error(t('errors.netatmoConnectionError'));
                setIsLoading(false);
                history.push({ pathname: Routings.MainPage, search: undefined });
                return;
            }

            history.push({ pathname: Routings.MainPage, search: undefined });

            await fetchDevices();

            setIsLoading(false);
        }
    };

    useEffect(() => {
        setInit(false);
    }, [devices]);

    useEffect(() => {
        if (devices === undefined || init || isDemo) {
            return;
        }

        setInit(true);
        initValidation();
    }, [devices, init]);

    const onClose = () => {
        setModalVisible(false);
    };

    const onReconnect = (device: NetatmoValidation) => {
        const authorizationRequest = `${AuthURL}?client_id=${encodeURIComponent(
            ClientId,
        )}&redirect_uri=${encodeURIComponent(redirectURI(device.id))}&scope=${encodeURIComponent(
            Scopes,
        )}&response_type=code`;

        window.open(authorizationRequest, '_self');
    };

    return (
        <>
            {modalVisible && (
                <Modal
                    title={t('netatmoModal.netatmoDevices')}
                    open={true}
                    onCancel={onClose}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okText={t('general.close')}
                    onOk={onClose}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    width={450}
                >
                    <Styled.NetatmoDevices>
                        {validationDevices.map((dev, index) => (
                            <Styled.NetatmoDevice key={index}>
                                <Styled.DeviceTitle $connected={dev.refreshTokenValid}>{dev.name}</Styled.DeviceTitle>
                                {dev.refreshTokenValid ? (
                                    <Styled.StatusWrapper>
                                        {Icons.success({
                                            type: 'success',
                                            theme: 'light',
                                            height: 16,
                                        })}
                                        {t('general.connected')}
                                    </Styled.StatusWrapper>
                                ) : (
                                    <Button loading={isLoading} danger type="primary" onClick={() => onReconnect(dev)}>
                                        {t('general.reconnect')}
                                    </Button>
                                )}
                            </Styled.NetatmoDevice>
                        ))}
                    </Styled.NetatmoDevices>
                </Modal>
            )}
        </>
    );
};

export default NetatmoValidator;
