import crypto from 'node-forge';

const pemPublicKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAubCo6OCk3/gtsVS6ChAC
WDqdB6+No3DTccKJal2h82aPYQv7+C9ekHQ9dW+GGDeKW7E+UrYtG4Mcr57JrxlL
VQ9M31TumTn9DrbhcJufusDG2Us+rj7HSMIkwDO7hA77Tn38ZTHS16hVXxnMRoAQ
q4EFTjNr9o6AQeQqEpc0weq7Zu/584dnKRlVSpMVTpYasx8nvpjyEB4esk0zULR/
VzqecxrlBTDswQ4Msnu2f9OgZqELe1CBRCZ7PhHHBD7JQhdRs2E+yg/dF2V0LaoA
xf6tXMCDGJRpkWc04ZCM1I/r5sBou8wnQvueuaTwAQtJlXQY02hxMU94ScpvZemk
I8hUAWDzvUAUxFcQc6gyNAbwg4JJ7/F8VwuwIWiBrw8J/tUgM5HDy8bl9uDO58xx
BIkDvWSFmlt8csMO9SMVloYuzC81o08ceLgIhqgG6rHX2O5LGeX3ORJzko89X/24
j7nkVEcSLToft7LKBwi20wlvdvevd3H5w49X3i70h7gh4z2C1ge9L9s59PKN1/1y
VJKDqfuDTUH9ZwntAVH56H3mFotTbUDAloswsvbY92AfnsO5E3+5qqanSVpQxW0U
Bzk7C7fyxSMszi0RWcbjptzxNd5WLS6m3pUxsLXH2OaftAUo9F4SEOvdxhoGdQfT
uSJgAQjvgPHpmK2Bd0oWN88CAwEAAQ==
-----END PUBLIC KEY-----`;

const importEncryptDecryptKey = () => {
    return crypto.pki.publicKeyFromPem(pemPublicKey);
};

export const encryptRSA = async (text: string): Promise<string> => {
    const pub = importEncryptDecryptKey();
    if (!pub) return '';
    const encryptedBase64 = crypto.util.encode64(pub.encrypt(text, 'RSA-OAEP'));
    return encryptedBase64 ? encryptedBase64 : '';
};
