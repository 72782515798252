import { Slider } from 'antd';
import { useEffect, useState } from 'react';
import { isEmpty } from '../../../../../helpers/StringHelper';
import { CustomRangeProps } from './CustomRangeProps';

const CustomRange = (props: CustomRangeProps): JSX.Element => {
    const { min, max, step, value, minTrackColor, disabled, unit, onValueChanged } = props;
    const [sliderValue, setSliderValue] = useState(value);

    useEffect(() => {
        setSliderValue(value);
    }, [value]);

    return (
        <Slider
            disabled={disabled ?? false}
            step={step}
            min={min}
            max={max}
            trackStyle={{
                backgroundColor: minTrackColor,
                border: minTrackColor.toLowerCase() === '#ffffff' ? '1px solid #e5e5e5' : minTrackColor,
            }}
            handleStyle={{
                borderColor: minTrackColor.toLowerCase() === '#ffffff' ? '#e5e5e5' : minTrackColor,
            }}
            tooltip={{ formatter: (v) => (!isEmpty(unit) ? `${v} ${unit}` : v) }}
            onAfterChange={(value: number) => onValueChanged(value)}
            value={sliderValue}
            onChange={(value: number) => setSliderValue(value)}
        />
    );
};

export default CustomRange;
