import { useEffect, useRef, useState } from 'react';
import * as Styled from './EditableCell.style';

type Props = {
    value: number;
    onChange: (v: number) => void;
    takenNumbers: number[];
};

const EditableCell = ({ value, onChange, takenNumbers }: Props): JSX.Element => {
    const [inputVisible, setInputVisible] = useState(false);
    const [currentValue, setCurrentValue] = useState<number>(value);
    const ref = useRef<HTMLInputElement>(null);

    const onBlur = () => {
        setInputVisible(false);

        if (!takenNumbers.includes(currentValue)) {
            onChange(currentValue);
        } else {
            setCurrentValue(value);
        }
    };

    useEffect(() => {
        if (!inputVisible) {
            return;
        }
        ref.current?.focus();
    }, [inputVisible]);

    if (!inputVisible) {
        return (
            <div
                style={{ width: '100%', cursor: 'pointer' }}
                onClick={() => {
                    setInputVisible(true);
                }}
            >
                {value}
            </div>
        );
    }

    return (
        <Styled.Wrapper>
            <Styled.NumInput
                ref={ref}
                precision={0}
                value={currentValue}
                min={0}
                status={takenNumbers.includes(currentValue) ? 'error' : undefined}
                onBlur={onBlur}
                onChange={(v) => v && setCurrentValue(v as number)}
            />
        </Styled.Wrapper>
    );
};

export default EditableCell;
