export const Colors = {
    undefinedCategory: '#cccccc',
    lightCategory: '#F6F1B1',
    blindsCategory: '#7585D5',
    windowsAndDoorsCategory: '#7868AF',
    heatingCategory: '#D97C98',
    energyCategory: '#6DC09E',
    centralCategory: '#A8CAFD',
    airingCategory: '#B1EBF8',
    presenceDetectorCategory: '#F3AA75',
    waterCategory: '#92C9E6',
    smokeCategory: '#AAE3C4',
    securityCategory: '#E8AD7D',
    batteryCategory: '#A5ECBD',
    sirenCategory: '#F38075',
    powerSocketCategory: '#A680AE',
};
